import React, { useState, useRef, useEffect } from "react";
import { Text, Grid, Box, Image, TextInput, useMantineTheme, PasswordInput, Button, Loader, Select, Avatar, LoadingOverlay, UnstyledButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import loginImage from "../assets/Images/login.svg";
import { login, signUp, verifyOtp, resendOTPReq, veryFyEmailReq, setNewPasswordFun, forgotPasswordVerifyOtp, checkDuplicateEmail, inviteDetails, inviteDecline } from "../apis/loginApi";
import '../Custom.css'
import phoneData from './phoneCode.json'
import { IconCheck, IconCircleX, IconEyeCheck, IconEyeOff } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { InputLabel, LoginSubTitle, LoginTitle, LoginTitle3, TextInfo } from "../Component/textLabel";
import { ButtonNoBg } from "../Component/inputTypes";

let currentOTPIndex = 0;
let inviteTocken = (window.location.href).split("code=")[1]


const Login = ({ navigate, setLoggedIn }) => {
    //    const {setAuth} = useAuth()
    const theme = useMantineTheme("");
    const { height } = useViewportSize();
    const [newUser, setNewUser] = useState(false);
    const [otpScreen, setOtpScreen] = useState(false)
    // Forgot Password 
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    //Reset Password
    const [resetPassword, setResetPassword] = useState(false);
    //otp screen
    const [tokenScreen, setTockenScreen] = useState(false);

    const [token, setToken] = useState(inviteTocken === undefined ? false : inviteTocken);

    const [inviteDetail, setInviteDetail] = useState()
    const [profileImage, setProfileImage] = useState(null)
    const [showInvetationData, setShowInvetationData] = useState(false)

    const [error, setError] = useState(false);

    // otp input field setting
    const [otp, setOtp] = useState(new Array(6).fill(""));  // define 6 otp field
    const [activeOTPIndex, setActiveOTPIndex] = useState(0);  // set active otp index
    const [counter, setCounter] = useState(60);   // for resend otp counter
    const inputRef = useRef(null) // for otp input  ref

    //Change Password
    const [newPassword, setNewPassword] = useState({ "submit": "Submit" });
    const [newPasswordErr, setNewPasswordErr] = useState(false);

    //for OTP Screen
    const [timeCounter, setTimeCounter] = useState(true)
    const [resendOTPbtn, setResendOTPbtn] = useState(false)

    // for signup form
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        phone_c_code: "",
        phone_no: "",
        password: "",
        confirm_password: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [signupForm, setSignupForm] = useState(true);
    //regex for different validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;  // for email
    const onlyAzSpace = /^[a-zA-Z ]*$/;                // for first name (with space in case of middle name)
    const onlyAz = /^[A-Za-z]+$/;                    // last name (no space)
    const onlyNumbers = /^[0-9]*$/;                  // only numberd
    const alphNumeric = /^[a-z0-9]+$/i;              // alpha numeric
    const checkPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;  //for password

    //for Login Form
    const initialValuesLogin = {
        email: "",
        password: "",

    };
    const [formValuesLogin, setFormValuesLogin] = useState(initialValuesLogin);
    const [formErrorsLogin, setFormErrorsLogin] = useState({});
    const [isSubmitLogin, setIsSubmitLogin] = useState(false);
    const [loginForm, setLoginForm] = useState(true);

    // for email verification
    const emailVerifyinitial = {
        email: "",
    };

    const [formValuesEmailVerify, setFormValuesEmailVerify] = useState(emailVerifyinitial);
    const [formErrorsEmailVerify, setFormErrorsEmailVerify] = useState({});
    const [isSubmitEmailVerify, setIsSubmitEmailVerify] = useState(false);

    // for Reset password
    const resetNewPasswordInitial = {
        password: "",
        confirm_password: ""
    };

    const [formValuesResetPass, setFormValuesResetPass] = useState(resetNewPasswordInitial);
    const [formErrorsResetPass, setFormErrorsResetPass] = useState({});
    const [isSubmitResetPass, setIsSubmitResetPass] = useState(false);

    // sign up form
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    // login Form
    const handleSubmitLogin = (e) => {
        console.log(formValuesLogin)
        e.preventDefault();
        setFormErrorsLogin(validateLogin(formValuesLogin));
        setIsSubmitLogin(true);

    };

    // Email Veryfy form (forgot Passcode Click)
    const handleSubmitEmailVerify = (e) => {
        e.preventDefault();
        setFormErrorsEmailVerify(validateEmailVerify(formValuesEmailVerify));
        setIsSubmitEmailVerify(true);

    };

    // Email Veryfy form (forgot Passcode Click)
    const handleSubmitResetPass = (e) => {
        e.preventDefault();
        // console.log(formErrorsResetPass);
        // console.log(formValuesResetPass);
        setFormErrorsResetPass(validateResetPass(formValuesResetPass));
        setIsSubmitResetPass(true);

    };

    // call Reset New Password 
    useEffect(() => {
        console.log(formErrorsResetPass);
        if (Object.keys(formErrorsResetPass).length === 0 && isSubmitResetPass) {
            console.log(formValuesResetPass);
            const tempVal = {
                submit: "submit",
                "verification_code": otp.join(""),
                "email": formValuesEmailVerify.email,
                "password": formValuesResetPass.password,
                "confirm_password": formValuesResetPass.confirm_password
            }
            console.log(tempVal)
            setLoading(true)
            setError(false)
            // console.log(loginDetails)
            setNewPasswordFun(tempVal).then((response) => {
                console.log("Reset New Password: ", response.data)
                if (response.data.status === "success") {
                    setResetPassword(false);
                    setForgotPassword(false);
                    setNewUser(!newUser)
                    setOtpScreen(false);
                    setLoading(false);
                    setNewPassword('');
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                }
                if (response.data.status === "error") {
                    if (response.data.message === "Email already registerd.") {
                        setTimeout(() => { setOtpScreen(true) }, 500)
                    }
                    setNewPasswordErr(response.data)
                    // console.log("Setting Error: ", response.data)
                }
                setLoading(false)
            }, (error) => {
                // console.log("Sign Up Failur : ", error)
            })

        }
    }, [formErrorsResetPass]);

    // call verify email when no error
    useEffect(() => {
        console.log(formErrorsEmailVerify);
        if (Object.keys(formErrorsEmailVerify).length === 0 && isSubmitEmailVerify) {
            console.log(formValuesEmailVerify);
            const tempVal = {
                submit: "submit",
                ...formValuesEmailVerify
            }
            console.log(tempVal)
            setLoading(true)
            setError(false)
            // console.log(loginDetails)
            veryFyEmailReq(tempVal).then((response) => {
                // console.log("OTP Verification Data: ", response.data)
                if (response.data.status === "success") {
                    setForgotPassword(false);
                    setNewUser(false);
                    setOtpScreen(true);
                    setLoading(false);
                    setResetPassword(false);
                    setCounter(60)
                }
                if (response.data.status === "error") {
                    setError(response.data)
                    setLoading(false)
                }
            }, (error) => {
                // console.log("OTP Failur : ", error);
                setError(error)
                setLoading(false)
            })

        }
    }, [formErrorsEmailVerify]);

    // call Login Form when no error
    useEffect(() => {
        console.log(formErrorsLogin);
        if (Object.keys(formErrorsLogin).length === 0 && isSubmitLogin && loginForm === true) {
            console.log(formValuesLogin);
            const tempVal = {
                submit: "submit",
                ...formValuesLogin
            }
            setLoading(true)
            setError(false)
            // console.log(loginDetails)
            login(tempVal).then((response) => {
                // console.log(response)
                if (response.data.status === "success" && response.data.verification_status === 1) {
                    // console.log("verification status True")
                    localStorage.setItem("project_whiteney_email", formValuesLogin.email)
                    localStorage.setItem("project_whiteney_user_data", JSON.stringify(response.data.user_data))
                    setLoggedIn(true)

                } else if (response.data.status === "error" && response.data.verification_status === 2) {
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCircleX size={16} />,
                        autoClose: 2000,
                    })
                    setOtpScreen(true)
                    setCounter(60)
                }
                else if (response.data.status === "error" && response.data.verification_status === 3) {
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCircleX size={16} />,
                        autoClose: 2000,
                    })
                    setError(response.data)

                } else if (response.data.status === "error") {
                    setError(response.data)
                }
                setLoading(false)
            }, (error) => {
                // console.log("Login API Failur: ", error.message)
                setLoading(false)
            })

        }
    }, [formErrorsLogin]);

    // useEffect(() => {
    //     console.log(formErrorsLogin)
    //     console.log(loginForm)
    //     const keyDownHandler = event => {
    //         if (event.key === 'Enter' && loginForm === true) {
    //             handleSubmitLogin(event)
    //         }
    //     };
    //     document.addEventListener('keydown', keyDownHandler);

    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };
    // }, [formErrorsLogin])

    // call signup form when no error
    useEffect(() => {
        console.log(formErrors);

        if (Object.keys(formErrors).length === 0 && isSubmit && signupForm === true) {
            console.log(formValues);
            const tempVal = {
                submit: "submit",
                ...formValues
            }
            setLoading(true)
            setError(false)
            let submitOk = false
            let tempLength = Object.keys(tempVal).length
            if (tempLength < 8) {
                setError({ "message": "All fields are required" })
                setLoading(false)
                submitOk = false
            } else {
                submitOk = true
            }
            if (submitOk) {
                signUp(tempVal).then((response) => {
                    console.log("Signup API Success: ", response.data)
                    if (response.data.status === "success") {
                        setOtpScreen(true)
                        setCounter(60)
                    }
                    if (response.data.status === "error") {
                        if (response.data.message === "Email already registerd.") {
                            setTimeout(() => { setOtpScreen(true) }, 500)
                        }
                        setError(response.data)
                        console.log("Setting Error: ", response.data)
                    }
                    setLoading(false)
                }, (error) => {
                    // console.log("Sign Up Failur : ", error)
                })
            }
        }
    }, [formErrors]);


    // call signup form on enter key
    // useEffect(() => {
    //     console.log(formErrors)
    //     console.log(signupForm)
    //     const keyDownHandler = event => {
    //         if (event.key === 'Enter' && signupForm === true) {
    //             handleSubmit(event)
    //         }
    //     };
    //     document.addEventListener('keydown', keyDownHandler);

    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };
    // }, [formErrors])

    // validate signup form inputs
    const validate = (values) => {
        const errors = {};

        //validate first Name
        if (!values.first_name) {
            errors.first_name = "First name is required!";
        } else if (!onlyAzSpace.test(values.first_name)) {
            errors.first_name = "Enter a valid name!";
        } else if (values.first_name.length < 2) {
            errors.first_name = "Enter minimum 2 characters!";
        }

        //validate Last Name
        if (!values.last_name) {
            errors.last_name = "Last name is required!";
        } else if (!onlyAz.test(values.last_name)) {
            errors.last_name = "Enter a valid name!";
        } else if (values.last_name.length < 2) {
            errors.first_name = "Enter minimum 2 characters!";
        }
        //validate Email
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        //validate user Name
        if (!values.username) {
            errors.username = "Username is required!";
        } else if (!alphNumeric.test(values.username)) {
            errors.username = "Only  letters(a-z) and numbers(0-9) allowd!";
        }
        //validate Phone Country Code
        if (!values.phone_c_code) {
            errors.phone_c_code = "Countery is required!";
        }
        //validate Phone Number
        if (!values.phone_no) {
            errors.phone_no = "Phone number is required!";
        } else if (!onlyNumbers.test(values.phone_no)) {
            errors.phone_no = "Enter a valid mobile number!";
        } else if (values.phone_no.length < 10) {
            errors.phone_no = "Enter minimum 10 digit mobile number!";
        } else if (values.phone_no.length > 10) {
            errors.phone_no = "Maximum 10 digit allowed!";
        }
        //validate Password
        if (!values.password) {
            errors.password = "Password is required";
        } else if (!checkPassword.test(values.password)) {
            errors.password = "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character(allowed characters !@#$%^&* )";
        }
        //validate confirm Password
        if (!values.confirm_password) {
            errors.confirm_password = "Confirm Password is required";
        } else if (values.confirm_password !== formValues.password) {
            errors.confirm_password = "Password not matched!";
        }
        return errors;
    };

    // validate login form inputs
    const validateLogin = (values) => {
        const errors = {};

        //validate Email
        if (!values.email) {
            console.log("email not enter")
            errors.email = "Email is required!"
        } else if (/\@/.test(values.email)) {
            console.log("check email")
            if (!regex.test(values.email)) {
                errors.email = "This is not a valid email format!";
            }
        } else {
            if (!alphNumeric.test(values.email)) {
                console.log("check user name")
                errors.email = "This is not a valid user name format!";

            }
        }
        //validate Password
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    }
    // validate veryfy email form inputs
    const validateEmailVerify = (values) => {
        const errors = {};
        // console.log(values)
        //validate Email
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        return errors;
    }

    // validate verify Reset Password
    const validateResetPass = (values) => {
        const errors = {};
        console.log(values.confirm_password)
        console.log(values.password)
        //validate Email
        if (!values.password) {
            errors.password = "Password is required!";
        } else if (!checkPassword.test(values.password)) {
            errors.password = "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (allowed characters !@#$%^&* )";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Confirm password is required!";
        }
        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Confirm password not matched!";
        }
        return errors;
    }

    // check email is already in DB
    function checkEmail(emailData) {
        console.log("check email: ", emailData)
        let data = emailData
        checkDuplicateEmail(data).then((response) => {
            // console.log("OTP Verification Data: ", response.data)
            if (response.data.status === "success") {
                // console.log("sucess true")
                let tempErr = { ...formErrors, email: response.data.message }
                setFormErrors(tempErr)
            }
            if (response.data.status === "error") {
                // console.log("error true")


                if (response.data.message === "Invalid request") {
                    let tempErr = { ...formErrors, email: "" }
                    setFormErrors(tempErr)
                } else {
                    let tempErr = { ...formErrors, email: response.data.message }
                    setFormErrors(tempErr)
                }

            }
        }, (error) => {
            // console.log("OTP Failur : ", error);
            setError(error)
            setLoading(false)
        })
    }
    // check user anme is already in DB
    function checkUsername(userNameData) {
        // console.log("check email: ", userNameData)
        let data = userNameData
        checkDuplicateEmail(data).then((response) => {
            // console.log("OTP Verification Data: ", response.data)
            if (response.data.status === "success") {
                // console.log("sucess true")
                let tempErr = { ...formErrors, userName: response.data.message }
                setFormErrors(tempErr)
            }
            if (response.data.status === "error") {
                // console.log("error true")


                if (response.data.message === "Invalid request") {
                    let tempErr = { ...formErrors, userName: "" }
                    setFormErrors(tempErr)
                } else {
                    let tempErr = { ...formErrors, userName: response.data.message }

                    setFormErrors(tempErr)
                }

            }
        }, (error) => {
            // console.log("OTP Failur : ", error);
            setError(error)
            setLoading(false)
        })
    }
    //match password and confirm password
    function matchPassword(e) {
        // console.log(e.target.value)
        // console.log(signUpDetails.password)
        if (e.target.value === formValues.password) {
            // console.log("password match")
            let tempErr = { ...formErrors, confirm_password: "" }
            setFormErrors(tempErr)
        } else {
            // console.log("password not match")
            let tempErr = { ...formErrors, confirm_password: "Password not matched!" }
            setFormErrors(tempErr)
        }
    }

    //match password and confirm password (Reset new Password)formValuesResetPass, setFormValuesResetPass formErrorsResetPass, setFormErrorsResetPass
    function matchPasswordReset(e) {
        // console.log(e.target.value)
        // console.log(signUpDetails.password)
        if (e.target.value === formValuesResetPass.password) {
            // console.log("password match")
            let tempErr = { ...formErrorsResetPass, confirm_password: "" }
            setFormErrorsResetPass(tempErr)
        } else {
            // console.log("password not match")
            let tempErr = { ...formErrorsResetPass, confirm_password: "Password not matched!" }
            setFormErrorsResetPass(tempErr)
        }
    }

    //for login Form
    useEffect(() => {
        console.log(token)
        if (token !== undefined && token.length > 0) {
            setTockenScreen(true)
        }
        let invite_token = { 'invitation_code': token }
        inviteDetails(invite_token).then((response) => {
            console.log(response)
            if (response.data.status === "success") {
                // console.log(response.data)
                setInviteDetail(response.data)
                setProfileImage(response.data.invitation_details[0].image)
                setShowInvetationData(true)
                setLoading(false)
            } else if (response.data.status === "error") {
                setLoading(false)
            }

        }, (error) => {
            // console.log("Login API Failur: ", error.message)
            setLoading(false)
        })

    }, [tokenScreen])




    //  const [passOne]

    // const [value, setValue] = useState()
    let options = []
    // // console.log(data.options)
    phoneData.options.map((item) => {
        let temp = { value: item.dial_code , label: item.name, key: item.code + item.dial_code }   // make it array as per mantine select
        options.push(temp)
    })

    //handleChange innput fields for otp Screen
    const handleChange = ({ target }) => {
        const { value } = target;
        const newOTP = [...otp];
        newOTP[currentOTPIndex] = value.substring(value.length - 1);
        if (!value) {
            setActiveOTPIndex(currentOTPIndex - 1);
        } else {
            setActiveOTPIndex(currentOTPIndex + 1);
        }


        setOtp(newOTP)
        // // console.log(val)
    }
    // set backspace key on otp screen
    const handleOnKeyDown = (e, index) => {

        // // console.log(e)
        // // console.log(key)
        currentOTPIndex = index
        if (e.key === 'Backspace') {
            setActiveOTPIndex(currentOTPIndex - 1)
        }

        if (e.ctrlKey && (e.which === 86 || e.which === 118)) {
            var clipboardData, pastedData;

            // Get pasted data via clipboard API
            clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;;
            pastedData = clipboardData.getData('Text').toUpperCase();
            // Do whatever with pasteddata
            alert(pastedData);
            // console.log("Ctrl + V Pressed !");
        }
    }

    // set intial input focus when page load on otp screen   
    // // console.log(otp)
    useEffect(() => {
        inputRef.current?.focus();

    }, [activeOTPIndex])

    // call OTP Reset counter
    useEffect(() => {
        if (counter === 0) {
            setTimeCounter(false)
            setResendOTPbtn(true)
        }
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);

    }, [counter])

    // verify otp after signup (otp screen)
    const verifyOtpBtn = () => {

        setLoading(true)
        setError(false)
        let emailData;
        console.log(formValues.email)
        console.log(formValuesEmailVerify.email)
        console.log(formValuesLogin.email)
        if (formValues.email !== undefined && formValues.email.length > 2) {
            console.log("signup")
            emailData = formValues.email
        } else if (formValuesEmailVerify.email !== undefined && formValuesEmailVerify.email.length > 2) {
            console.log("verify")
            emailData = formValuesEmailVerify.email
        } else if (formValuesLogin.email !== undefined && formValuesLogin.email.length > 2) {
            console.log("login")
            emailData = formValuesLogin.email
        }

        let data = {
            "submit": "Submit",
            "email": emailData,
        }

        console.log(data)
        if (otp.length > 0) {
            data["verification_code"] = otp.join(""); // change array to string without comma
        } else {
            setError({ "message": "Enter OTP" })
        }

        if (formValues.email !== undefined && formValues.email.length > 2 || formValuesLogin.email !== undefined && formValuesLogin.email.length > 2) {
            verifyOtp(data).then((response) => {
                console.log("OTP Verification Data: ", response.data)
                if (response.data.status === "success") {
                    setForgotPassword(false);
                    setLoading(false);


                    // console.log("signup")
                    setResetPassword(false);
                    setForgotPassword(false);
                    // setNewUser(!newUser);
                    setOtpScreen(false);
                    setLoading(false);
                    localStorage.setItem("project_whiteney_email", formValuesLogin.email)
                    localStorage.setItem("project_whiteney_user_data", JSON.stringify(response.data.user_data))
                    setLoggedIn(true)

                }
                if (response.data.status === "error") {
                    setError(response.data)
                    setLoading(false)
                }
            }, (error) => {
                // console.log("OTP Failur : ", error);
                setError(error)
                setLoading(false)
            })
        }
        if (formValuesEmailVerify.email !== undefined && formValuesEmailVerify.email.length > 2) {
            forgotPasswordVerifyOtp(data).then((response) => {
                console.log("OTP Verification Data: ", response.data)
                if (response.data.status === "success") {
                    setForgotPassword(false);
                    setLoading(false);
                    setNewUser(false);
                    setResetPassword(true);

                }
                if (response.data.status === "error") {
                    setError(response.data)
                    setLoading(false)
                }
            }, (error) => {
                // console.log("OTP Failur : ", error);
                setError(error)
                setLoading(false)
            })
        }

    }

    // Resend OTP Request
    const resendOTPBtn = () => {
        console.log("otp resend");
        setLoading(true)
        setError(false)
        // check OTP Request for Signup Verification Or Other
        let emailData;
        console.log(formValues.email)
        console.log(formValuesEmailVerify.email)
        console.log(formValuesLogin.email)
        if (formValues.email !== undefined && formValues.email.length > 2) {

            emailData = formValues.email
        } else if (formValuesEmailVerify.email !== undefined && formValuesEmailVerify.email.length > 2) {
            emailData = formValuesEmailVerify.email
        } else if (formValuesLogin.email !== undefined && formValuesLogin.email.length > 2) {
            emailData = formValuesLogin.email
        }

        let data = {
            "submit": "Submit",
            "email": emailData,
        }

        if (formValues.email !== undefined && formValues.email.length > 2 || formValuesLogin.email !== undefined && formValuesLogin.email.length > 2) {

            resendOTPReq(data).then((response) => {
                console.log("OTP Verification Data: ", response.data)
                if (response.data.status === "success") {
                    setCounter(60)
                    setLoading(false)
                    setActiveOTPIndex(0)
                }
                if (response.data.status === "error") {
                    setError(response.data)
                    setLoading(false)
                }
            }, (error) => {
                // console.log("OTP Failur : ", error);
                setError(error)
                setLoading(false)
            })
        }
        if (formValuesEmailVerify.email !== undefined && formValuesEmailVerify.email.length > 2) {
            veryFyEmailReq(data).then((response) => {
                console.log("OTP Verification Data: ", response.data)
                if (response.data.status === "success") {
                    setCounter(60)
                    setLoading(false)
                    setActiveOTPIndex(0)
                }
                if (response.data.status === "error") {
                    setError(response.data)
                    setLoading(false)
                }
            }, (error) => {
                // console.log("OTP Failur : ", error);
                setError(error)
                setLoading(false)
            })
        }
    }

    // css part
    // left container

    const imageStyle = {
        paddingTop: '-50px'
    }
    const textLeftTop = {
        fontSize: 22,
        weight: 100,
        color: "#ffffff",
        paddingTop: 22,
        paddingLeft: 20,
    }


    function becomeExecutor() {
        
        setTimeout(function () {
            setTockenScreen(false)
            window.location.href = '/';
        }, 2000);
    }
    function decline(tkn) {
        // setTockenScreen(false)

        let invite_token = { 'invitation_code': token }
        inviteDecline(invite_token).then((response) => {
            // console.log(response)
            
            if (response.data.status === "success") {
                // console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setTimeout(function () {
                    window.location.href = '/';
                }, 2000);

                setLoading(false)
            } else if (response.data.status === "error") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCircleX size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            }




        }, (error) => {
            // // console.log("Login API Failur: ", error.message)
            showNotification({
                id: 'load-data',
                color: 'red',
                // title: error.status,
                message: error.message,
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    // Render on Screen 

    return (
        <>
            {height ?
                <Grid className="mainBody" style={{ "height": height - 1 }}>
                    {/*left section*/}
                    <Grid.Col xs={12} sm={6} md={6} lg={6} className="loginBgColor" p="0" style={{ display: "flex", alignItems: "stretch", }}>
                        <Grid columns={12} >
                            <Grid.Col span={12} sx={(theme) => ({ display: "flex", justifyContent: "flex-start" })}>
                                <Text sx={(theme) => ({ fontSize: 30, fontWeight: 500, textAlign: "left", color: "#fff" })}>Project Whitney</Text>
                            </Grid.Col>
                            <Grid.Col span={12}  >

                                <Image style={imageStyle} src={loginImage} pb="18%" alt="Whitney" />

                            </Grid.Col>
                        </Grid>


                    </Grid.Col>
                    {/*Right section*/}
                    <Grid.Col xs={12} sm={6} md={6} lg={6} style={{}} className="formContainer"

                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "stretch",
                            "justifyContent": "center",
                            flexDirection: "column",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            '@media (max-width: 1280px)': {
                                paddingLeft: "8%",
                                paddingRight: "8%",
                            },

                        })}
                    >


                        {!newUser && !otpScreen && !forgotPassword && !resetPassword && !tokenScreen &&
                            <form onSubmit={handleSubmit}>
                                <div className="" >
                                    <Box className="title1">
                                        <LoginTitle title={"Welcome"} />
                                    </Box>
                                    <Box className="title2">
                                        <LoginTitle3 title={"Create new account"} />
                                    </Box>

                                    <TextInput
                                        placeholder="First name"
                                        label="First name"
                                        name="first_name"
                                        value={formValues.first_name}
                                        error={formErrors?.first_name}
                                        withAsterisk
                                        onChange={(e) => {
                                            //validate first Name
                                            const errors = {};
                                            if (!e.target.value) {
                                                setFormErrors({ ...formErrors, first_name: "First name is required!" });
                                            } else if (!onlyAzSpace.test(e.target.value)) {
                                                setFormErrors({ ...formErrors, first_name: "Enter a valid name!" });
                                            } else if (e.target.value.length < 2) {
                                                setFormErrors({ ...formErrors, first_name: "Enter minimum 2 characters!" });
                                            } else {
                                                setFormErrors({ ...formErrors, first_name: "" });
                                            }

                                            setFormValues({ ...formValues, "first_name": e.target.value })
                                        }}

                                    />
                                    <TextInput
                                        placeholder="Last name"
                                        label="Last name"
                                        style={{ paddingTop: "0.4vw", }}
                                        name="last_name"
                                        value={formValues.last_name}
                                        error={formErrors?.last_name}
                                        withAsterisk
                                        onChange={(e) => {
                                            //validate last Name
                                            const errors = {};
                                            if (!e.target.value) {
                                                setFormErrors({ ...formErrors, last_name: "Last name is required!" });
                                            } else if (!onlyAz.test(e.target.value)) {
                                                setFormErrors({ ...formErrors, last_name: "Enter a valid name!" });
                                            } else if (e.target.value.length < 2) {
                                                setFormErrors({ ...formErrors, last_name: "Enter minimum 2 characters!" });
                                            } else {
                                                setFormErrors({ ...formErrors, last_name: "" });
                                            }
                                            setFormValues({ ...formValues, "last_name": e.target.value })
                                        }}

                                    />
                                    <TextInput
                                        placeholder="Email"
                                        label="Email"
                                        style={{ paddingTop: "0.4vw" }}
                                        name="email"
                                        value={formValues.email}
                                        error={formErrors?.email}
                                        withAsterisk
                                        onChange={(e) => {
                                            //validate Email
                                            if (!e.target.value) {
                                                setFormErrors({ ...formErrors, email: "Email is required!" });
                                            } else if (!regex.test(e.target.value)) {
                                                setFormErrors({ ...formErrors, email: "This is not a valid email format!" });
                                            } else {
                                                setFormErrors({ ...formErrors, email: "" });
                                            }
                                            setFormValues({ ...formValues, "email": e.target.value })
                                        }}

                                        onBlur={(e) => {
                                            checkEmail({
                                                "submit": "yes",
                                                "email": e.target.value,
                                            })
                                        }}

                                    />
                                    <TextInput
                                        placeholder="Username"
                                        label="Username"
                                        name="username"
                                        style={{ paddingTop: "0.4vw" }}
                                        value={formValues.username}
                                        error={formErrors?.username}
                                        withAsterisk
                                        onChange={(e) => {
                                            //validate User Name
                                            if (!e.target.value) {
                                                setFormErrors({ ...formErrors, username: "Username is required!" });
                                            } else if (!alphNumeric.test(e.target.value)) {
                                                setFormErrors({ ...formErrors, username: "Only  letters(a-z) and numbers(0-9) allowd!" });
                                            } else {
                                                setFormErrors({ ...formErrors, username: "" });
                                            }
                                            setFormValues({ ...formValues, "username": e.target.value })
                                        }}

                                        onBlur={(e) => {
                                            checkUsername({
                                                "submit": "yes",
                                                "username": e.target.value,
                                            })
                                        }}

                                    />

                                    <TextInfo title=" You can use letters and numbers" />


                                    <div className="selectInput">
                                        <div style={{ 'paddingTop': '0.4vw', 'width': '35%' }}>
                                            <Select
                                                withAsterisk
                                                label={"Country"}
                                                placeholder={"Country"}

                                                data={options}

                                                styles={{

                                                    dropdown: { 'border': 'none', "boxShadow": "1px 1px 5px 2px rgba(0, 0, 0, 0.1)" },
                                                    item: {
                                                        fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0],
                                                        '@media (max-width: 992px)': {
                                                            fontSize: 12,
                                                            lineHeight: "16px"
                                                        },
                                                    },
                                                    hovered: { 'backgroundColor': theme.colors.bgColor[0] },


                                                }}
                                                name="phone_c_code"
                                                value={formValues?.phone_c_code}
                                                error={formErrors?.phone_c_code}
                                                onChange={(e) => {
                                                    //validate User Name
                                                    if (!e) {
                                                        setFormErrors({ ...formErrors, phone_c_code: "Country is required!" });
                                                    } else {
                                                        setFormErrors({ ...formErrors, phone_c_code: "" });
                                                    }
                                                    setFormValues({ ...formValues, "phone_c_code": e })
                                                }}
                                                radius="xs"
                                                searchable

                                            />
                                        </div>

                                        <TextInput
                                            withAsterisk
                                            placeholder="Mobile number"
                                            label="Mobile number"
                                            style={{ paddingTop: "0.4vw" }}
                                            styles={{
                                                root: { 'width': '60%' }
                                            }}
                                            name="phone_no"
                                            value={formValues.phone_no}
                                            error={formErrors?.phone_no}
                                            onChange={(e) => {
                                                //validate Phone Number
                                                if (!e.target.value) {
                                                    setFormErrors({ ...formErrors, phone_no: "Phone number is required!" });
                                                } else if (!onlyNumbers.test(e.target.value)) {
                                                    setFormErrors({ ...formErrors, phone_no: "Enter a valid phone number!" });
                                                } else if (e.target.value.length < 10) {
                                                    setFormErrors({ ...formErrors, phone_no: "Enter minimum 10 digit phone number!" });
                                                } else if (e.target.value.length > 10) {
                                                    setFormErrors({ ...formErrors, phone_no: "Maximum 10 digit allowed!" });
                                                } else {
                                                    setFormErrors({ ...formErrors, phone_no: "" });
                                                }
                                                setFormValues({ ...formValues, "phone_no": e.target.value })
                                            }}


                                        />
                                    </div>
                                    <Grid columns={24} >
                                        <Grid.Col span={12} style={{ paddingRight: 0, paddingTop: "0.4vw", paddingBottom: 0, paddingLeft: 0 }}>
                                            <PasswordInput
                                                withAsterisk
                                                placeholder="Password"
                                                label="Password"

                                                sx={(theme) => ({
                                                    input: { 'height': 45, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], }
                                                })}
                                                // description="Password must include at least one letter, number and special character"
                                                visibilityToggleIcon={({ reveal, size }) =>
                                                    reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                                                }
                                                onChange={(e) => {
                                                    //validate Password
                                                    if (!e.target.value) {
                                                        setFormErrors({ ...formErrors, password: "Password is required!" });
                                                    } else if (!checkPassword.test(e.target.value)) {
                                                        setFormErrors({ ...formErrors, password: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (allowed characters !@#$%^&* )" });
                                                    } else {
                                                        setFormErrors({ ...formErrors, password: "" });
                                                    }
                                                    setFormValues({ ...formValues, "password": e.target.value })
                                                }}
                                                name="password"
                                                value={formValues.password}
                                                error={formErrors?.password}

                                            />
                                        </Grid.Col>
                                        {/* <Grid.Col span={2}></Grid.Col> */}
                                        <Grid.Col span={12} style={{ paddingRight: 0, paddingTop: "0.4vw", paddingBottom: 0 }}>
                                            <PasswordInput
                                                withAsterisk
                                                placeholder="Confirm password"
                                                label="Confirm password"
                                                sx={(theme) => ({
                                                    input: { 'height': 45, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], }
                                                })}
                                                visibilityToggleIcon={({ reveal, size }) =>
                                                    reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                                                }
                                                name="confirm_password"
                                                value={formValues.confirm_password}
                                                error={formErrors?.confirm_password}
                                                onChange={(e) => {
                                                    if (!e.target.value) {
                                                        setFormErrors({ ...formErrors, confirm_password: "Password is required!" });
                                                    } else {
                                                        setFormErrors({ ...formErrors, confirm_password: "" });
                                                    }
                                                    setFormValues({ ...formValues, "confirm_password": e.target.value })
                                                }}
                                                onBlur={(e) => {
                                                    matchPassword(e)
                                                }}

                                            />
                                        </Grid.Col>
                                        <Grid.Col span={24} pl="zx" pb="zx" style={{ paddingTop: "0.4vw" }}>
                                            <TextInfo title="Use 8 or more characters with a mix of letters, numbers & symbols" />
                                        </Grid.Col>
                                    </Grid>



                                    <div style={{ paddingTop: "1.0vw" }}>
                                        <Button
                                            radius="xxs"
                                            fullWidth
                                            sx={(theme) => ({
                                                backgroundColor: theme.colors.primary[0],
                                                height: 40,
                                                fontSize: theme.fontSizes.xxl,
                                                fontWeight: 300,
                                                textTransform: 'capitalize',
                                                marginRight: '1.0vw',
                                                fontFamily: theme.fontFamily[0],
                                                '&:hover': {
                                                    backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                }
                                            })}
                                            type="submit"
                                        // onClick={handleSubmit}
                                        >
                                            {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Create account"}
                                        </Button>
                                    </div>


                                    {error !== false && <Text style={{ "color": "red", paddingTop: "0.4vw", "fontSize": 12 }}>{error.message}</Text>}
                                    <div style={{ paddingTop: "0.8vw" }}>
                                        <Box
                                            className="resendOTPBtn customButton"
                                            onClick={(e) => {
                                                setError(false);
                                                // setFormErrors({});
                                                // setSignupForm(!signupForm);
                                                // setLoginForm(!loginForm);
                                                setFormValues(initialValues)
                                                setNewUser(!newUser)
                                            }
                                            }
                                        >{"Sign in with existing account"}
                                        </Box>
                                    </div>
                                </div>
                            </form>
                        }
                        {newUser && !otpScreen && !forgotPassword && !resetPassword && !tokenScreen &&

                            <form onSubmit={handleSubmitLogin}>
                                <div className="">
                                    <Box style={{ paddingBottom: "0.6vw" }}>
                                        <LoginTitle title={"Welcome"} />
                                    </Box>
                                    <Box style={{ paddingBottom: "1.5vw" }}>
                                        <LoginTitle3 title={"Sign in"} />
                                    </Box>
                                    <TextInput
                                        withAsterisk
                                        placeholder="Enter username or email"
                                        label="Username/email"
                                        name="email"
                                        error={formErrorsLogin?.email}
                                        value={formValuesLogin.email}

                                        onChange={(e) => {
                                            //validate Email
                                            if (!e.target.value) {
                                                console.log("email not enter")
                                                setFormErrorsLogin({ ...formErrorsLogin, email: "Email is required!" });
                                            } else if (/\@/.test(e.target.value)) {
                                                console.log("check email")
                                                if (!regex.test(e.target.value)) {
                                                    setFormErrorsLogin({ ...formErrorsLogin, email: "This is not a valid email format!" });
                                                } else {
                                                    setFormErrorsLogin({ ...formErrorsLogin, email: "" });
                                                }
                                            } else {
                                                if (!alphNumeric.test(e.target.value)) {
                                                    console.log("check user name")
                                                    setFormErrorsLogin({ ...formErrorsLogin, email: "This is not a valid user name format!" });

                                                } else {
                                                    console.log("alpha numeric else")
                                                    setFormErrorsLogin({ ...formErrorsLogin, email: "" });
                                                }
                                            }

                                            setFormValuesLogin({ ...formValuesLogin, "email": e.target.value })
                                        }}

                                        onBlur={(e) => {
                                            checkEmail({
                                                "submit": "yes",
                                                "email": e.target.value,
                                            })
                                        }}

                                    />
                                    <div style={{ paddingTop: "0.5vw", }}>
                                        <PasswordInput
                                            withAsterisk
                                            placeholder="Password"
                                            label="Password"

                                            sx={(theme) => ({
                                                input: { 'height': 45, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], }
                                            })}
                                            // description="Password must include at least one letter, number and special character"
                                            visibilityToggleIcon={({ reveal, size }) =>
                                                reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                                            }
                                            onChange={(e) => {
                                                //validate Password
                                                if (!e.target.value) {
                                                    setFormErrorsLogin({ ...formErrorsLogin, password: "Password is required!" });
                                                } else {
                                                    setFormErrorsLogin({ ...formErrorsLogin, password: "" });
                                                }
                                                setFormValuesLogin({ ...formValuesLogin, "password": e.target.value })
                                            }}
                                            name="password"

                                            error={formErrorsLogin?.password}

                                        />
                                    </div>

                                    <div style={{ paddingTop: "1.0vw" }}>
                                        <Button
                                            radius="xxs"
                                            fullWidth
                                            sx={(theme) => ({
                                                backgroundColor: theme.colors.primary[0],
                                                height: 40,
                                                fontSize: theme.fontSizes.xxl,
                                                fontWeight: 300,
                                                textTransform: 'capitalize',
                                                marginRight: '1.0vw',
                                                fontFamily: theme.fontFamily[0],
                                                '&:hover': {
                                                    backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                }
                                            })}
                                            type="submit"
                                        // onClick={handleSubmitLogin}
                                        >
                                            {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Sign in"}
                                        </Button>

                                        <div style={{ height: 15 }}>
                                            {error !== false && <Text style={{ "color": "red", paddingTop: "0.3vw", paddingBottom: "0.3vw", "fontSize": 14 }}>{error.message}</Text>}
                                        </div>

                                    </div>
                                    <div className="ForgotPassWord">
                                        <Box
                                            className="resendOTPBtn customButton"
                                            onClick={(e) => { setError(false); setForgotPassword(!forgotPassword) }}
                                        >{"Forgot password"}
                                        </Box>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <Box
                                            className="resendOTPBtn customButton"

                                            onClick={(e) => {
                                                setError(false);
                                                // setFormErrorsLogin({});
                                                // setLoginForm(!loginForm);
                                                // setSignupForm(!signupForm);
                                                setFormValuesLogin(initialValuesLogin)
                                                setNewUser(!newUser)
                                            }}


                                        >{"Create new account"}
                                        </Box>
                                    </div>
                                </div>
                            </form>
                        }
                        {otpScreen && !forgotPassword && !resetPassword && !tokenScreen &&
                            <form onSubmit={verifyOtpBtn}>



                                <div className="">
                                    <Box style={{ paddingBottom: "0.6vw" }}>
                                        <LoginTitle title={"Please verify your email address."} />
                                    </Box>
                                    <Box >
                                        <LoginSubTitle className="title2" title={"You will receive a one-time password (OTP) by email. Please enter it below within the next 10 minutes."} />
                                    </Box>
                                    <InputLabel className="title1" title="OTP"></InputLabel>
                                    <div style={{ "display": "flex", "flexDirection": "row", "marginBottom": "0.5vw", justifyContent: 'space-between' }}>

                                        {otp.map((_, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <input
                                                        ref={index === activeOTPIndex ? inputRef : null}
                                                        type="number"
                                                        className="otpbox textColor1"
                                                        styles={{ fontSize: theme.fontSizes.lg }}
                                                        placeholder='#'

                                                        onChange={handleChange}
                                                        onKeyDown={(e) => handleOnKeyDown(e, index)}
                                                        value={otp[index]}

                                                    />
                                                </React.Fragment>
                                            )


                                        })}


                                    </div>
                                    <div style={{ height: 15, marginBottom: 12 }}>
                                        {error !== false && <Text style={{ "color": "red", paddingTop: "0.3vw", paddingBottom: "0.3vw", "fontSize": 14 }}>{error.message}</Text>}
                                    </div>
                                    {/* <ButtonForm
                                    btnAction={verifyOtpBtn}
                                    loading={loading}
                                    label={"Verify email"}
                                /> */}
                                    <Button
                                        radius="xxs"
                                        fullWidth
                                        sx={(theme) => ({
                                            backgroundColor: theme.colors.primary[0],
                                            height: 40,
                                            fontSize: theme.fontSizes.xxl,
                                            fontWeight: 300,
                                            textTransform: 'capitalize',
                                            marginRight: '1.0vw',
                                            fontFamily: theme.fontFamily[0],
                                            '&:hover': {
                                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                            }
                                        })}
                                        type="submit"
                                    // onClick={handleSubmit}
                                    >
                                        {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Verify email"}
                                    </Button>
                                    <div className="resendOtpContainer">
                                        {timeCounter && <div className="resendText fontSize2">Resend OTP in<span className="otp-counter"> {counter}</span></div>}
                                    </div>
                                    <div className="resendOtpContainer">
                                        {resendOTPbtn && <UnstyledButton
                                            // disabled={counter !== 0}

                                            className="resendOTPBtn fontSize2"
                                            onClick={() => {
                                                setResendOTPbtn(!resendOTPbtn)
                                                setTimeCounter(!timeCounter)
                                                resendOTPBtn()
                                            }}
                                        >
                                            <Text size="xs" className={counter === 0 ? "resendOTPBtn customButton" : ""}>Resend OTP</Text>
                                        </UnstyledButton>
                                        }
                                    </div>

                                </div>
                            </form>
                        }
                        {forgotPassword && !resetPassword && !tokenScreen &&
                            <form onSubmit={handleSubmitEmailVerify}>
                                <div className="">
                                    <Box style={{ paddingBottom: "0.6vw" }}>
                                        <LoginTitle title={"Please verify your email address."} />
                                    </Box>
                                    <Box>
                                        <LoginSubTitle className="title2" title={"Enter your email address, and click on continue."} />
                                    </Box>
                                    <TextInput
                                        withAsterisk
                                        placeholder="example@gmail.com"
                                        label="Email"

                                        error={formErrorsEmailVerify?.email}
                                        value={formValuesEmailVerify.email}
                                        onChange={(e) => {
                                            //validate Email
                                            // formValuesEmailVerify setFormValuesEmailVerify formErrorsEmailVerify setFormErrorsEmailVerify
                                            if (!e.target.value) {
                                                setFormErrorsEmailVerify({ ...formValuesEmailVerify, email: "Email is required!" });
                                            } else if (!regex.test(e.target.value)) {
                                                setFormErrorsEmailVerify({ ...formValuesEmailVerify, email: "This is not a valid email format!" });
                                            } else {
                                                setFormErrorsEmailVerify({ ...formValuesEmailVerify, email: "" });
                                            }
                                            setFormValuesEmailVerify({ ...formErrorsEmailVerify, "email": e.target.value })
                                        }}


                                    />

                                    <div style={{ height: 15, marginBottom: 12 }}>
                                        {error !== false && <Text style={{ "color": "red", paddingTop: "0.3vw", paddingBottom: "0.3vw", "fontSize": 14 }}>{error.message}</Text>}
                                    </div>
                                    {/* <ButtonForm
                                    btnAction={handleSubmitEmailVerify}
                                    loading={loading}
                                    label={"Continue"}
                                /> */}

                                    <Button
                                        radius="xxs"
                                        fullWidth
                                        sx={(theme) => ({
                                            backgroundColor: theme.colors.primary[0],
                                            height: 40,
                                            fontSize: theme.fontSizes.xxl,
                                            fontWeight: 300,
                                            textTransform: 'capitalize',
                                            marginRight: '1.0vw',
                                            fontFamily: theme.fontFamily[0],
                                            '&:hover': {
                                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                            }
                                        })}
                                        type="submit"
                                    // onClick={handleSubmit}
                                    >
                                        {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Continue"}
                                    </Button>
                                </div>
                            </form>
                        }
                        {resetPassword && !tokenScreen &&
                            <form onSubmit={handleSubmitResetPass}>
                                <div >
                                    <Box style={{ paddingBottom: "0.6vw" }}>
                                        <LoginTitle title={"Reset Password"} />
                                    </Box>
                                    <PasswordInput
                                        withAsterisk
                                        placeholder="New Password"
                                        label="Password"
                                        style={{ paddingTop: "0.5vw", }}
                                        visibilityToggleIcon={({ reveal, size }) =>
                                            reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                                        }
                                        error={formErrorsResetPass?.password}
                                        value={formValuesResetPass.password}

                                        onChange={(e) => {
                                            //validate Password
                                            if (!e.target.value) {
                                                setFormErrorsResetPass({ ...formErrorsResetPass, password: "Password is required!" });
                                            } else if (!checkPassword.test(e.target.value)) {
                                                setFormErrorsResetPass({ ...formErrorsResetPass, password: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (allowed characters !@#$%^&* )" });
                                            } else {
                                                setFormErrorsResetPass({ ...formErrorsResetPass, password: "" });
                                            }
                                            setFormValuesResetPass({ ...formValuesResetPass, "password": e.target.value })
                                        }}

                                    />

                                    <PasswordInput
                                        withAsterisk
                                        placeholder="Confirm Password"
                                        label="Confirm Password"
                                        style={{ paddingTop: "0.5vw", }}
                                        visibilityToggleIcon={({ reveal, size }) =>
                                            reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                                        }

                                        error={formErrorsResetPass?.confirm_password}
                                        value={formValuesResetPass.confirm_password}
                                        onChange={(e) => {
                                            if (!e.target.value) {
                                                setFormErrorsResetPass({ ...formErrorsResetPass, confirm_password: "Password is required!" });
                                            } else {
                                                setFormErrorsResetPass({ ...formErrorsResetPass, confirm_password: "" });
                                            }
                                            setFormValuesResetPass({ ...formValuesResetPass, "confirm_password": e.target.value })
                                        }}
                                        onBlur={(e) => {
                                            matchPasswordReset(e)
                                        }}

                                    />


                                    <div style={{ height: 15, marginBottom: 12 }}>
                                        {newPasswordErr !== false && <Text style={{ "color": "red", paddingTop: "0.3vw", paddingBottom: "0.3vw", "fontSize": 14 }}>{newPasswordErr.commonMessage}</Text>}
                                    </div>
                                    {/* <ButtonForm
                                    btnAction={handleSubmitResetPass}
                                    // btnAction={changingPassword}
                                    loading={loading}
                                    label={"Continue"}
                                /> */}
                                    <Button
                                        radius="xxs"
                                        fullWidth
                                        sx={(theme) => ({
                                            backgroundColor: theme.colors.primary[0],
                                            height: 40,
                                            fontSize: theme.fontSizes.xxl,
                                            fontWeight: 300,
                                            textTransform: 'capitalize',
                                            marginRight: '1.0vw',
                                            fontFamily: theme.fontFamily[0],
                                            '&:hover': {
                                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                            }
                                        })}
                                        type="submit"
                                    // onClick={handleSubmit}
                                    >
                                        {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Continue"}
                                    </Button>
                                </div>
                            </form>
                        }
                        {tokenScreen &&
                            <form onSubmit={becomeExecutor}>
                                <Box >
                                    {showInvetationData && <><Grid columns={12}>
                                        <Grid.Col span={12}>
                                            <Text className="title1 fontSize3 textColor1 fontFamily2">You have been invited by</Text>
                                        </Grid.Col>
                                        <Grid.Col span={2}>
                                            <div className="profile-image">
                                                {profileImage ? <Image

                                                    src={profileImage}
                                                    alt="Random unsplash image"
                                                    width={50}
                                                    height={50}
                                                /> : <Avatar radius="50%" size={50} />}

                                            </div>
                                        </Grid.Col>
                                        <Grid.Col span={10} style={{ display: "flex", alignItems: "center" }}>
                                            <Box style={{ display: "flex" }}>
                                                <Text className="title1 fontSize1 textColor2">{inviteDetail.invitation_details[0].firstname}</Text>
                                                <Text className="title1 fontSize1 textColor2" style={{ paddingLeft: 10 }}>{inviteDetail.invitation_details[0].lastname}</Text>
                                            </Box>
                                        </Grid.Col>
                                        <Grid.Col span={12}>
                                            <Text className="fontSize1  fontWeight2  textColor1 fontFamily1">To become his Trusted Person(s).</Text>
                                        </Grid.Col>
                                        <Grid.Col span={12}>
                                            <Text className="fontSize8  fontWeight2  textColor1 fontFamily1">his message to  you:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={12}>
                                            <Text className="fontSize10  fontWeight2  textColor1 fontFamily2" style={{ textAlign: 'justify' }}>{inviteDetail.invitation_details[0].invitation_message}</Text>
                                        </Grid.Col>
                                        <Grid.Col span={6}></Grid.Col>
                                        <Grid.Col span={6}>
                                            <Box style={{ justifyContent: "end" }}>
                                                <Text className="fontSize9  fontWeight2  textColor1 fontFamily1"> Yours truly</Text>
                                                <Box style={{ display: "flex" }}>
                                                    <Text className="fontSize9  fontWeight2  textColor1 fontFamily1">{inviteDetail.invitation_details[0].firstname}</Text>
                                                    <Text className="fontSize9  fontWeight2  textColor1 fontFamily1" style={{ paddingLeft: 10 }}>{inviteDetail.invitation_details[0].lastname}</Text>
                                                </Box>
                                            </Box>
                                        </Grid.Col>

                                    </Grid>
                                        <Grid columns={12} >
                                            <Grid.Col span={12}>
                                                <Button
                                                    radius="xxs"
                                                    fullWidth
                                                    sx={(theme) => ({
                                                        backgroundColor: theme.colors.primary[0],
                                                        height: 40,
                                                        fontSize: theme.fontSizes.xxl,
                                                        fontWeight: 300,
                                                        textTransform: 'capitalize',
                                                        marginRight: '1.0vw',
                                                        fontFamily: theme.fontFamily[0],
                                                        '&:hover': {
                                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                        }
                                                    })}
                                                    // onClick={() => {
                                                    //     becomeExecutor()
                                                    // }}
                                                    type="submit"
                                                >{
                                                        loading ?
                                                            <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> :
                                                            "Become" + " " + inviteDetail.invitation_details[0].firstname + " " + "Trusted Person(s)"}
                                                </Button>
                                            </Grid.Col>
                                            <Grid.Col>


                                                <ButtonNoBg
                                                    className="resendOTPBtn"
                                                    btnAction={decline}
                                                    actionVariable={token}
                                                    label={"Decline"}
                                                />
                                            </Grid.Col>
                                        </Grid>
                                    </>
                                    }

                                </Box>
                            </form>
                        }


                    </Grid.Col>
                </Grid>
                : <LoadingOverlay visible={loading} />
            }
        </>

    )
}

export default Login;