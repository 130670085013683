import { Box, Grid, Text, useMantineTheme } from "@mantine/core";
import FlowTitle from "../Component/flowTitle";
import Footer from "../Component/footer";

const LicenceAgreement = ({ navigate, setLoggedIn }) => {
    const theme = useMantineTheme();





    return (
        <div  >

            <div style={{ paddingLeft: 10, paddingBottom: 15 }}>
                <div sx={(theme) => ({ fontWeight: 700 })}>
                    <FlowTitle title={"Licence Agreement"} description={"Licence Agreement Description...!"} />
                </div>

            </div>
            <Grid>
                <Grid.Col xs={12} sm={12} md={12} lg={12} >
                    <Box sx={(theme) => ({
                        display: "flex",
                        justifyContent: "center"
                    })}><Text style={{ fontSize: 80, color: "#023047", fontWeight: 600 }}>Comming Soon...</Text></Box>
                </Grid.Col>
            </Grid>

            <Footer navigate={navigate}/>

        </div>

    )
}

export default LicenceAgreement