import React, { useEffect, useState } from "react";
import {useMantineTheme, Select, TextInput,  Grid } from '@mantine/core'
import '../Custom.css';
import phoneData from "../Pages/phoneCode.json"
import InputMask from 'react-input-mask';

const InputControlPersonalInfo = ({
    data, setAnswer, answer, setPrefill, formErrors, setFormErrors, onlyAzSpace, onlyNumbers

}) => {
    // console.log(answer)
    const theme = useMantineTheme("");
    const [textInputError, setTextInputError] = useState("")

    // console.log(data)
    switch (data.type) {

        case "dropDown":
            //dropDownInput , setDropDownInput
            let options = []
            // console.log(data)
            data.options.map((item) => {
                let temp = { value: item.value, label: item.label, key: item.value + item.label }   // make it array as per mantine select
                options.push(temp)
            })
            // console.log("Options array for drop down: ", options)
            let dropdownSize;
            let dropDownWidth;
            if (data.label === "Relationship") {
                dropdownSize = 24;
                dropDownWidth = '50%';
            } else {
                dropdownSize = 12;
                dropDownWidth = '100%';
            }


            let dropdownFlag = false;


            return (

                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.7vw' }} >
                <Grid.Col span={dropdownSize} pt="xs"
                    pl={data.id === "country_id" || data.id === "category" || data.id === "gender" ? "zx" : 10}
                >
                    <Select
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        data={options}
                        id={data.id}
                        styles={{
                            label: {
                                'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0], 'fontWeight': 600
                            },
                            root: { 'width': dropDownWidth },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                            dropdown: { 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                            item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                            hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                        }}
                        clearable
                        searchable
                        onChange={(e) => {

                            let temp = {
                                ...answer,
                                // [data.label.toLowerCase().replace(" ", "_")]: e
                                [data.id.toLowerCase().replace(" ", "_")]: e
                            }
                            // console.log("Temp Var: ", temp)
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "dropDownGender":
            //dropDownInput , setDropDownInput
            let optionGender = []
            // console.log(data)
            data.options.map((item) => {
                let temp = { value: item.value, label: item.label, key: item.value + item.label }   // make it array as per mantine select
                optionGender.push(temp)
            })
          
            console.log(typeof(answer[data.id]))

            return (

                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.7vw' }} >
                <Grid.Col span={12} pt="xs"
                    pl={data.id === "country_id" || data.id === "category" || data.id === "gender" ? "zx" : 10}
                >
                    <Select
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        data={optionGender}
                        id={data.id}
                        styles={{
                            label: {
                                'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0], 'fontWeight': 600
                            },
                            root: { 'width': "100%" },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                            dropdown: { 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                            item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                            hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                        }}
                        clearable
                        searchable
                        onChange={(e) => {

                            let temp = {
                                ...answer,
                                // [data.label.toLowerCase().replace(" ", "_")]: e
                                [data.id.toLowerCase().replace(" ", "_")]: e
                            }
                            // console.log("Temp Var: ", temp)
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "smallDropDown":
            //smallDropDownInput , setSmallDropDownInput
            let smallOptions = []
            // console.log(data.options)
            // data.options.map((item) => {
            //     let temp = { value: item.value, label: item.label, key: item.code + item.value }   // make it array as per mantine select
            //     smallOptions.push(temp)
            // })
            phoneData.options.map((item) => {
                let temp = { value: item.dial_code + item.code, label: item.name, key: item.code + item.dial_code }   // make it array as per mantine select
                smallOptions.push(temp)
            })
            // console.log("Options array for drop down: ", options)
            let smallDropdownSize;
            let smalldropDownWidth;
            if (data.label === "Relationship") {
                smallDropdownSize = 4;
                smalldropDownWidth = '10%';
            } else {
                smallDropdownSize = 4;
                smalldropDownWidth = '10';
            }



            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.7vw' }} >
                <Grid.Col span={smallDropdownSize} pt="xs"
                    pl={data.id === "phone_c_code" || data.id === "home_phone_c_code" ? "zx" : 10}
                >
                    <Select
                        label={"Phone No"}
                        placeholder={data.placeholder}
                        defaultValue={answer[data.id]}
                        data={smallOptions}
                        id={data.id}
                        styles={{
                            label: {
                                'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0], 'fontWeight': 600
                            },
                            root: { 'width': smalldropDownWidth },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                            dropdown: { 'border': 'none', 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                            item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                            hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                        }}
                        clearable
                        searchable
                        onChange={(e) => {
                            let temp = {
                                ...answer,

                                [data.id]: e
                            }
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "textInput":
            // textInputs , setTextInputs

            // check size in case of relationship
            let testInputSize;
            let testInputWidth;
            let required = false
            if (data.label === "Relationship") {
                testInputSize = 12;
                testInputWidth = '100%';
            } else {
                testInputSize = 12;
                testInputWidth = '100%';
            }
            if(data.id === "first_name" || data.id === "last_name"){
                required = true
            }
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={testInputSize} pt="xs"
                    pl={data.id === "relationship"
                        || data.id === "first_name"
                        || data.id === "last_name"
                        || data.id === "address_line2"
                        || data.id === "state"
                        || data.id === "category"



                        ? "zx" : 10}

                    pr={data.id === "company_name"
                        || data.id === "middle_name"
                        || data.id === "nick_name"
                        || data.id === "ssn"
                        || data.id === "address_line1"
                        || data.id === "city"
                        || data.id === "phone_no"
                        || data.id === "category"
                        || data.id === "email"
                        || data.id === "zip_code"
                        || data.id === "county"

                        ? "zx" : 10}
                >
                    <TextInput
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}

                        error={formErrors[data.id]}
                        // required={required}
                        // mask="(999) 999 9999" 
                        withAsterisk ={ data.id === "first_name" || data.id === "last_name" ? true : false}
                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            // root: { 'width': testInputWidth },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            const errors = {};
                            if(data.id === "first_name"){
                                if (!e.target.value) {
                                    setFormErrors({ ...formErrors, first_name: "First name is required!" });
                                } else if (!onlyAzSpace.test(e.target.value)) {
                                    setFormErrors({ ...formErrors, first_name: "Enter a valid name!" });
                                } else if (e.target.value.length < 2) {
                                    setFormErrors({ ...formErrors, first_name: "Enter minimum 2 characters!" });
                                } else {
                                    setFormErrors({ ...formErrors, first_name: "" });
                                }
                            }else if(data.id === "last_name"){
                                if (!e.target.value) {
                                    setFormErrors({ ...formErrors, last_name: "Last name is required!" });
                                } else if (!onlyAzSpace.test(e.target.value)) {
                                    setFormErrors({ ...formErrors, last_name: "Enter a valid last name!" });
                                } else if (e.target.value.length < 2) {
                                    setFormErrors({ ...formErrors, last_name: "Enter minimum 2 characters!" });
                                } else {
                                    setFormErrors({ ...formErrors, last_name: "" });
                                }
                            }else if(data.id === "middle_name"){
                                if (!onlyAzSpace.test(e.target.value)) {
                                    setFormErrors({ ...formErrors, middle_name: "Enter a valid middle name!" });
                                } else {
                                    setFormErrors({ ...formErrors, middle_name: "" });
                                }
                            }else if(data.id === "nick_name"){
                                if (!onlyAzSpace.test(e.target.value)) {
                                    setFormErrors({ ...formErrors, nick_name: "Enter a valid nick name!" });
                                } else {
                                    setFormErrors({ ...formErrors, nick_name: "" });
                                }
                            }else if(data.id === "gender"){
                                if (e.target.value === "male" || e.target.value === "female") {
                                    setFormErrors({ ...formErrors, gender: "Select a valid value" });
                                } else {
                                    setFormErrors({ ...formErrors, gender: "" });
                                }
                            }else if(data.id === "home_phone_c_code"){
                                if (e.target.value === "male" || e.target.value === "female") {
                                    setFormErrors({ ...formErrors, gender: "Select a valid value" });
                                } else {
                                    setFormErrors({ ...formErrors, gender: "" });
                                }
                            }else if(data.id === "gender"){
                                if (!onlyNumbers.test(e.target.value)) {
                                    errors.home_phone = "Enter a valid phone number!";
                                } else if (e.target.value.length < 10) {
                                    errors.home_phone = "Enter minimum 10 digit phone number!";
                                } else if (e.target.value.length > 10) {
                                    errors.home_phone = "Maximum 10 digit allowed!";
                                }
                            }
                            let temp = {
                                ...answer,
                                [data.id]: e.target.value
                            }
                            console.log(answer)
                            console.log(temp)
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "ssnInput":
            return(
                <Grid.Col span={12} pt="xs"
                // pl={ data.id === "ssn"     ? "zx" : 10}

                pr={ data.id === "ssn" ? "zx" : 10}
            >
                <TextInput
                    label={data.label}
                    placeholder={"xxx-xx-xxxx"}
                    value={answer[data.id]}
                    component={InputMask}
                    mask="999-99-9999"
                    error={formErrors[data.id]}
                    styles={{
                        label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                        // root: { 'width': testInputWidth },
                        // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                        input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                    }}
                    onChange={(e) => {
                        const errors = {};
                       
                        let ssnNumber = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
                        console.log(ssnNumber)
                        if (ssnNumber.length < 9 )  {
                            setFormErrors({ ...formErrors, ssn: "Enter 9 digit social security number!" });
                        } else {
                            setFormErrors({ ...formErrors, ssn: "" });
                        }
                        let temp = {
                            ...answer,
                            [data.id]: ssnNumber
                        }
                        console.log(answer)
                        console.log(temp)
                        setAnswer(temp)
                    }}
                />
            </Grid.Col>
            )    
        case "mediumTextInput":
            //mediumTextInputs , setTmediumTextInputs
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={8} pt="xs" pl={data.id === "phone_no" ? "zx" : 10}>
                    <TextInput
                        label={"."}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        error={textInputError}
                        styles={{
                            label: { 'color': "#ffffff", fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': '100%' },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            if (e.target.value.length > 0) {
                                if (data.label === "Email") {
                                    if (String(e.target.value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct email address")
                                    }
                                } else if (data.label === "Phone No") {
                                    let phone = e.target.value;
                                    let phoneNum = phone.replace(/[^\d]/g, '');
                                    if (phoneNum.length > 6 && phoneNum.length < 11) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct phone number")
                                    }
                                }
                            } else {
                                setTextInputError()
                            }
                            let temp = {
                                ...answer,
                                [data.id]: e.target.value
                            }
                            console.log("Temp Var: ", temp)
                            setAnswer(temp)

                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "smallTextInput":
            //smallTextInputs, setSmallTextInputs
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={12} pt="xs">
                    <TextInput
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': '80px' },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            let temp = {
                                ...answer,
                                [data.id]: e.target.value
                            }
                            console.log("Temp Var: ", temp)
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

                // </div>
            )

        default:
            return (
                <div>Default Case</div>
            )
    }
}

export default InputControlPersonalInfo