import { Grid, Text, Affix, Button, LoadingOverlay } from "@mantine/core";
import { Formik, Form } from 'formik';
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MultipleFileUploadField } from "../../Component/uploads/MultipleFileUploadField";
import { BiChevronLeft } from "react-icons/bi";
import '../../Custom.css';
import FooterWill from "../../Component/footer2";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { BASE_URL } from "../../apis/flowReviewApis/global";

const UploadDeceDocs = ({ navigate, setLoggedIn }) => {
    const { state } = useLocation();
    const { testatorId } = state;
    const [deseDocs, setDeseDocs] = useState(true)
    const [answer, setAnswer] = useState()
    const [loading, setLoading] = useState(false) 
    // console.log(testatorId)
    function uploadDeseDocs() {
        setLoading(true)
        // console.log(answer)
        let data = {}
        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        const url = BASE_URL + "/whitney/public/api/v1/user-deceased"
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'deceased_testator_id': testatorId,
            'SaveDocument': 'yes',
            ...answer
        }
        console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === "success") {
                console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                navigate('/myRoles');
                setLoading(false)
            } else if (response.data.status === "error") {
                // console.log(response)
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCircleX size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })

    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    return (
        <>
            <Grid columns={24}>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 600, fontSize: theme.fontSizes.xxl, color: theme.colors.textColor[2], fontFamily: theme.fontFamily[0] })} >Report as deceased</Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 700, fontSize: theme.fontSizes.xl, color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })} >In order to report the testator as deceased, please follow the steps:</Text>
                </Grid.Col>

                <Grid.Col>
                    <Text sx={(theme) => ({ fontWeight: 700, fontSize: theme.fontSizes.xxl, color: theme.colors.textColor[4], fontFamily: theme.fontFamily[0] })}>Step 1</Text>
                </Grid.Col>
                <Grid.Col>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })}>Please upload a copy of the death certificate below.</Text>
                </Grid.Col>
                <Grid.Col>
                    <Formik initialValues={{ files: [] }} onSubmit={() => { }}>
                        {({ values, errors }) => (
                            <Form>
                                <Grid columns={24}>
                                    <Grid.Col span={24}>
                                        {/* <h1>Hello Dropzone</h1> */}
                                        <MultipleFileUploadField
                                            name="files"
                                            testatorId={testatorId}
                                            deseDocs={deseDocs}
                                            answer={answer}
                                            setAnswer={setAnswer}
                                        />
                                    </Grid.Col>
                                </Grid>

                            </Form>
                        )}

                    </Formik>
                </Grid.Col>
            </Grid>
            <Affix
                sx={(theme) => ({
                    "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                        'left': 300
                    },
                    [`@media (max-width:1280px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        'height': '110px',
                        left: "0px"
                    },
                })}
            >
                <Affix position={{}} sx={(theme) => ({
                    bottom: 70, left: 330, zIndex: 2,
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        left: "45px"
                    },
                })}>
                    <Button
                        radius="xxs"
                        sx={(theme) => ({
                            color: theme.colors.textColor[0],
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            fontWeight: 300,
                            height: 50,
                            backgroundColor: "transparent",

                            '&:hover': {
                                backgroundColor: theme.colors.buttonColor[9],
                                borderWidth: 1,
                                BorderStyle: "solid",
                                borderColor: theme.colors.primary[0],
                            }
                        })}
                        leftIcon={<BiChevronLeft />}
                        onClick={() => {
                            navigate('/reportDeceased', { state: { testatorId: testatorId } });
                        }}
                    >
                        Back
                    </Button>
                </Affix>
                <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                    <Button
                        radius="xxs"
                        sx={(theme) => ({
                            backgroundColor: theme.colors.primary[0],
                            height: 50,
                            fontSize: theme.fontSizes.xxl,
                            fontWeight: 300,
                            fontFamily: theme.fontFamily[0],
                            '&:hover': {
                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                            }
                        })}
                        onClick={() => {

                            uploadDeseDocs()
                        }}
                    >
                        Continue
                    </Button>
                </Affix>

                <FooterWill navigate={navigate}/>
            </Affix>
        </>
    )
}

export default UploadDeceDocs;