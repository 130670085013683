import { useEffect, useState, useRef } from "react";
import { Progress, Grid } from '@mantine/core';
import { FileHeader } from "./FileHeader";
import axios from "axios";
import { BASE_URL } from "../../apis/flowReviewApis/global";
import { IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

export function SingleFileUploadWithProgress({ file , onDelete, onUpload, question_id, dropedFile, 
    setDropedFile,
    setAnswer,
    answer,
    binderId,
    keyContactUpload,
    editFileUpload,
    deseDocs,
    testatorId,
    navigate, 
    setLoggedIn
}) {
    console.log(dropedFile)
    const [progress, setProgress] = useState(0);
    const [fileSize, setFileSize] = useState(0);
    const [allFiles, setAllFiles] = useState([]) 
    const [loading, setLoading] = useState(true)
  
    // setAllFiles(prevState => [...prevState, file])
    // console.log(allFiles)
    useEffect(() => {
        async function upload() {
            // const url = await uploadFile(file, setProgress)
            // const url = await uploadFile(file, setProgress, question_id, dropedFile, setDropedFile, setAnswer,answer)
            // console.log(url)
            // onUpload(file, url)
            let url ;
            // console.log(keyContactUpload)
            if(keyContactUpload === true){
               url = BASE_URL + '/whitney/public/api/v1/uploadKCfiles';
            }else if(editFileUpload === true){
               url = BASE_URL + "/whitney/public/api/v1/uploadKCfiles" ;
            }else if(deseDocs === true){
                url = BASE_URL + "/whitney/public/api/v1/uploadDeceasedFiles";            
            }
            else{
                url = BASE_URL + '/whitney/public/api/v1/upload-files/category/10001/topic/' +binderId;
            }
            
            console.log(url)
            let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
            let data = {}
            if(editFileUpload === true){
                data['uuid'] = userData.uuid
                data['access_token'] = userData.access_token
                // data['question_id'] = question_id
            }else if(deseDocs === true){
                data['uuid'] = userData.uuid
                data['access_token'] = userData.access_token
            }else{
                data['uuid'] = userData.uuid
                data['access_token'] = userData.access_token
                data['question_id'] = question_id
            }
          
            // console.log(dropedFile)
            const formData = new FormData();
            Object.keys(data).map((item, index)=>{
                formData.set(item, data[item])
            })
            dropedFile.map((item, index)=>{
                // console.log(item)
                item.map((it, idx)=>{
                    formData.append("uploaded_files[]", it)
                })
               
            })
            // console.log(file)
        

            let axiosData =   axios.post(url, formData, {
                onUploadProgress: (event) => {
                    console.log(event)
                    if (event.lengthComputable) {
                        const precentage = (event.loaded / event.total) * 100;
                        setProgress(Math.round(precentage));
                        setFileSize(event.total)

                    }
                }
               })
            //    console.log(axiosData)
               axiosData.then((response)=>{
                // console.log(response.data)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                }else  if(response.data.status  === "success" ){
                    console.log(response.data)
                    // console.log(response.data.document_ids.toString())
                    // setAllFiles((curr)=> [...curr, response.data.document_ids])
                    // console.log(allFiles.toString())
                     setAnswer({...answer, "document_ids": response.data.document_ids.toString()})
                    //  setDropedFile([])
                   
                }
               })
        }
        upload()
    }, [])
    // console.log(allFiles)
    return (
                
                <FileHeader file={file} fileSize={dropedFile} progress = {progress} onDelete={()=>{onDelete(file)}} />
               
    

    )
}

// function uploadFile(file, onProgress, question_id, dropedFile, setDropedFile, setAnswer,answer) {
//     const url = BASE_URL + '/whitney/public/api/v1/upload-files/category/10001/topic/200000001';
//     const key = "docs_upload_example_us_preset"
//     let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
//     let data = {}
//     data['uuid'] = userData.uuid
//     data['access_token'] = userData.access_token
//     data['question_id'] = question_id
//     console.log(dropedFile)
//     //uuid  access_token uploaded_files[]   question_id

//     return new Promise((res, rej) => {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', url);

//         xhr.onload = () => {
//             const resp = JSON.parse(xhr.responseText);

//             res(resp.secure_url);
//         }

//         xhr.onerror = (evt) => rej(evt);

//         xhr.upload.onprogress = (event) => {
//             if (event.lengthComputable) {
//                 const precentage = (event.loaded / event.total) * 100;
//                 // console.log(precentage)
//                 onProgress(Math.round(precentage));
//             }
//         };

//         const formData = new FormData();
//         Object.keys(data).map((item, index)=>{
//             formData.set(item, data[item])
//         })
//         dropedFile.map((item, index)=>{
//             formData.append("uploaded_files[]", item)
//         })
//         console.log(file)
//         formData.append('uploaded_files[]', file);
       
//         // console.log(formData)
//         xhr.send(formData)
//        let axiosData =  axios.post(url, formData, {
//         onUploadProgress: (event) => {
//             if (event.lengthComputable) {
//                 const precentage = (event.loaded / event.total) * 100;
//                 console.log(precentage)
//                 onProgress(Math.round(precentage));
//             }
//         }
//        })
//        console.log(axiosData)
//        axiosData.then((response)=>{
//         console.log(response.data)
//         if(response.data.status  === "success" ){
//             console.log(response.data.document_ids.toString())
//              setAnswer({...answer, "document_ids": response.data.document_ids.toString()})
//              setDropedFile([])
           
//         }
//        })

//     })
// }