import axios from "axios";
import { BASE_URL } from "./flowReviewApis/global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

export const getContactInformation = () => {

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/personal_information"


    let newData = {
        'uuid': userData.uuid,
        'access_token': userData.access_token,
    }

    console.log(newData)
    formData = new FormData()
    Object.keys(newData).map((item, index) => {
        formData.set(item, newData[item])
    })
    return whitney.post(willFlowUrl, formData)

}
export const getContactInformationEdit = () => {

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/personal_information/edit"


    let newData = {
        'uuid': userData.uuid,
        'access_token': userData.access_token,
    }

    console.log(newData)
    formData = new FormData()
    Object.keys(newData).map((item, index) => {
        formData.set(item, newData[item])
    })
    return whitney.post(willFlowUrl, formData)

}

export const updateContactInfo = (data) => {

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/personal_information/edit"


    let newData = {
        'uuid': userData.uuid,
        'access_token': userData.access_token,
        ...data
    }

    console.log(newData)
    formData = new FormData()
    Object.keys(newData).map((item, index) => {
        formData.set(item, newData[item])
    })
    return whitney.post(willFlowUrl, formData)

}