import { Avatar, Badge, Box, Button, Card, Center, Grid, Image, Loader, LoadingOverlay, Text, useMantineTheme } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../apis/flowReviewApis/global";
import { inviteDetails } from "../apis/loginApi";
import FlowTitle from "../Component/flowTitle";
import { BreadCurmbText, InputLabel, LoginTitle, MyRolesCardTitle, PageDiscription, TextDim } from "../Component/textLabel";
import '../Custom.css';

const MyRoles = ({ navigate, setLoggedIn }) => {
    const theme = useMantineTheme("");
    const [viewMyRole, setviewMyRole] = useState(true);
    const [viewExecutor, setViewExecutor] = useState(false);
    const [myRole, setMyRole] = useState();
    const [myInfoBinder, setmyInfoBinder] = useState(null);
    const [pendingInvitation, setSendingInvitation] = useState(null)
    const [acceptedInvitation, setAcceptedInvitation] = useState(null)
    const [loading, setLoading] = useState(true)
    const [inviteDetail, setInviteDetail] = useState()
    const [profileImage, setProfileImage] = useState(null)
    const [showInvetationData, setShowInvetationData] = useState(false)
    const [userToken, setUserToken] = useState()

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));

    useEffect(() => {
        const url = BASE_URL + "/whitney/public/api/v1/role";

        //  console.log(userData)
        let data = {}
        // data['uuid'] = "53E1EF7F2D7EACBA1767E2C7C78BAAF2"
        // data['access_token'] = "eyJraWQiOiJQQ0tQZ3QxRVBGRHN4ZlIybVQ3bE5NM0laaUZFejczRUdtVkRjTXIrMmZJPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5NDYyZWQ2Yi0yMGQyLTQ5MWMtOTBhZC1hZGUwNTc0YmE5N2YiLCJkZXZpY2Vfa2V5IjoidXMtZWFzdC0xXzUwZWU1ZGI3LWI3ZDEtNDEyNC04Yjg2LTI0Y2NiNDAxN2I2ZiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1pwNkRRaVFsUCIsImNsaWVudF9pZCI6IjVyMTlnbnRpZGthZjRqbzFjOHIyMzBobGk5Iiwib3JpZ2luX2p0aSI6Ijk1MzJjZmU2LTZlNTEtNGZiMi1iYzJjLWVhMjI5M2E1YTZhZCIsImV2ZW50X2lkIjoiZmIzY2QxNDQtNjk0Ny00NWI4LWFkNTgtNGE1YzQ0ZTEzMzI1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY2MTUwODY2NywiZXhwIjoxNjYxNTEyMjY3LCJpYXQiOjE2NjE1MDg2NjcsImp0aSI6ImEzZjY3YjBhLWU5YzEtNGYxNy1iNjVlLTcxNzkwZTc4ZTg1NCIsInVzZXJuYW1lIjoiOTQ2MmVkNmItMjBkMi00OTFjLTkwYWQtYWRlMDU3NGJhOTdmIn0.k6TwXsZthymEcSkSa8nMFb6i83C61m2yio1A9m-99H0jQvC3LcJqHgskVRGy5FK22UTCufp9uIFIhnceZh31K-9ZiYvblHEC4_7GwX7hmOvtSJnH_-7jfk20KniX5Wp1it1lPHSgIu5oeDXCc9-dzerw51YL2rqZ8ii_c0Z5dHJMXYnVpZ1C70Kub1r7ndegc_C3iFeDukHFn0WfM2lJ-qRRFeORhkzBntbugi6_lC-gNnczS5pJ6Z_gOnARiQJEV6PqTza2XxskND0LHh1ZcjSluuhDBCdWMlhEBZ6h_BRWTnORaREQkuQelTuCSsL0cEoqI0NKlkT7bKc4iH896A"
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        console.log(data)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios({
            method: "post",
            url:   url, 
            data: formData, 
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "success") {
                console.log(response.data)
                setMyRole(response.data)
                setmyInfoBinder(response.data.my_information_binder)
                setSendingInvitation(response.data.pending_invitations)
                setAcceptedInvitation(response.data.accepted_invitations)
                setLoading(false)
            } else if (response.data.status === "error") {
                console.log(response)
                // showNotification({
                //     id: 'load-data',
                //     color: 'red',
                //     title: response.data.status,
                //     message: response.data.message,
                //     icon: <IconCircleX size={16} />,
                //     autoClose: 2000,
                // })
                setMyRole(response.data)
                setmyInfoBinder(response.data.my_information_binder)
                setSendingInvitation(response.data.pending_invitations)
                setAcceptedInvitation(response.data.accepted_invitations)
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })
    }, [loading])

    function takeMeThere(userId) {
        navigate('/essentialDocs')
    }

    function becomeExecutor(token) {
        console.log("token", token)
        setUserToken(token)
        // setLoading(true)
        setviewMyRole(false)
        setViewExecutor(true)
        let invite_token = { 'invitation_code': token }
        inviteDetails(invite_token).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "success") {
                console.log(response.data)
                setInviteDetail(response.data)
                setProfileImage(response.data.invitation_details[0].image)
                setShowInvetationData(true)
                setLoading(false)
            } else if (response.data.status === "error") {
                setLoading(false)
            }

        }, (error) => {
            console.log("Login API Failur: ", error.message)
            setLoading(false)
        })
    }

    function becomeExecutorAccept() {
        setLoading(true)
        const url = BASE_URL + "/whitney/public/api/v1/invitation-user-response";
        //  console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['invitation_code'] = userToken
        data['user_reponse'] = "accepted"

        console.log(data)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios({
            method:"post",
            url:url, 
            data:formData, 
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === "success") {

                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })



                setviewMyRole(true)
                setViewExecutor(false)
                setLoading(false)
            } else if (response.data.status === "error") {
                console.log(response)
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCircleX size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })

    }

    function declineInvetation() {
        const url = BASE_URL + "/whitney/public/api/v1/invitation-user-response";
        //  console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['invitation_code'] = userToken
        data['user_reponse'] = "Rejected"

        //  console.log(data)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios({
            method:"post",
            url:url, 
            data:formData, 
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === "success") {
                //  console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            } else if (response.data.status === "error") {
                //  console.log(response)
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCircleX size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })
    }

    function review(testatorId, testatorImage, testatorName) {
        navigate('/contactInfoBinder', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
    }


    function reportAsDeceased(testatorId, testatorName, testatorMname, testatorLame) {
        // setLoading(true)
        navigate('/reportDeceased', { state: { testatorId: testatorId, testatorName: testatorName, middleName: testatorMname, lastName: testatorLame } });
        // const data = {
        //     'uuid': userData.uuid,
        //     'access_token': userData.access_token,
        //     'deceased_testator_id': testatorId,
        // }
        // const url = BASE_URL + "/whitney/public/api/v1/user-deceased";
        // const formData = new FormData();
        // Object.keys(data).map((item, index) => {
        //     formData.set(item, data[item])
        // })
        // axios.post(url, formData, {
        //     headers: { "Content-Type": "multipart/form-data" }
        // }).then((response) => {
        //     console.log(response)
        //     if (response.data.message === "Invalid user") {
        //         localStorage.removeItem("project_whiteney_email")
        //         localStorage.removeItem("project_whiteney_user_data")
        //         setLoading(false)
        //         setLoggedIn(false)
        //     } else if (response.data.status === "success") {
        //         console.log(response.data)
        //         showNotification({
        //             id: 'load-data',
        //             color: 'teal',
        //             title: response.data.status,
        //             message: response.data.message,
        //             icon: <IconCheck size={16} />,
        //             autoClose: 2000,
        //         })
        //         navigate('/reportDeceased', { state: { testatorId: testatorId, testatorName: testatorName, middleName: testatorMname, lastName: testatorLame } });
        //         setLoading(false)
        //     } else if (response.data.status === "error") {
        //         console.log(response)
        //         showNotification({
        //             id: 'load-data',
        //             color: 'red',
        //             title: response.data.status,
        //             message: response.data.message,
        //             icon: <IconCircleX size={16} />,
        //             autoClose: 2000,
        //         })
        //         setLoading(false)
        //     }

        // }).catch((error) => {
        //     alert(error)
        //     setLoading(false)
        // })

    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    return (
        <>
            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>

                {!loading && viewMyRole && <Grid columns={24}>
                    <Grid.Col span={24}>
                        <div style={{ 'minWidth': '100%', paddingLeft: 5 }}>
                            {/* <FlowTitle title={myRole.title} description={myRole.description} /> */}
                            <FlowTitle title={"My Roles"} description={myRole.description} />
                        </div>
                    </Grid.Col>

                    {/*My Information Binder Section*/}
                    {myInfoBinder && myInfoBinder.map((val, index) => {
                        return (

                            <Grid.Col xs={24} sm={12} md={8} lg={8} key={index}>
                                <Box
                                // style={{ cursor: "pointer" }}
                                // onClick={() => {
                                //     takeMeThere(val.user_id)
                                // }}
                                >
                                    <Card className="cardBorderRad2" shadow="sm" radius="xxs" sx={(theme) => ({
                                        border: '1px solid #E9ECEF', backgroundColor: '#FEFEFE', padding: "20px 28px 20px 28px", boxShadow: theme.shadows.sm, minHeight:300,
                                        '@media (max-width: 1280px)': {
                                            padding: "20px"
                                        },
                                        '@media (max-width: 1024px)': {
                                            padding: "20px"
                                        },
                                    })}>
                                        <Card.Section>
                                            <Grid columns={12} >

                                                <Grid.Col xs={12} sm={0} md={0} lg={5} p={0} >

                                                </Grid.Col>
                                                <Grid.Col xs={12} sm={5} md={5} lg={2} sx={(theme) => ({
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: 20,
                                                    '@media (max-width: 1280px)': {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingRight: 10,
                                                        paddingTop: 10
                                                    },
                                                    '@media (max-width: 1024px)': {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingRight: 10,
                                                        paddingTop: 10
                                                    },
                                                })}>
                                                    <div className="profile-image">
                                                        <div sx={(theme) => ({
                                                            paddingLeft: 10, paddingTop: 10,
                                                        })}>
                                                            {val.user_image ? <Image
                                                                src={val.user_image}
                                                                alt="Random unsplash image"
                                                                width={64}
                                                                height={64}
                                                            /> : <Avatar radius="50%" size={64} />}
                                                        </div>

                                                    </div>
                                                </Grid.Col>
                                                <Grid.Col xs={12} sm={7} md={7} lg={5} sx={(theme) => ({
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    paddingTop: 25,
                                                    '@media (max-width: 1280px)': {
                                                        display: "flex",
                                                        // alignItems: "flex-start",
                                                        justifyContent: "center",
                                                        paddingRight: 15,
                                                        paddingTop: 15,
                                                    },
                                                    '@media (max-width: 1024px)': {
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        paddingLeft: 10

                                                    },
                                                })}>
                                                    <Badge size="xl" radius="xxs" color="teal" sx={(theme) => ({
                                                        fontSize: 20, padding: "6px 8px 6px 8px", fontWeight: 600,
                                                        '@media (max-width: 1280px)': {
                                                            marginTop: 5,
                                                            fontSize: 16,
                                                        },
                                                        '@media (max-width: 1024px)': {
                                                            marginTop: 5,
                                                            fontSize: 16,
                                                        },
                                                    })}>
                                                        Testator
                                                    </Badge>
                                                </Grid.Col>

                                            </Grid>

                                        </Card.Section>

                                        <Center>
                                            <MyRolesCardTitle title="My Information Binder" />
                                        </Center>
                                        <Center>
                                            <Text size="sm" color="dimmed" pt={20} pb={20} style={{ maxWidth: "75%", textAlign: "Center" }}>
                                                View {val.user_name}'s Information Binder.
                                            </Text>
                                        </Center>
                                        <Box style={{ paddingTop: 20 }}>
                                            <Button
                                                fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 50,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}

                                                onClick={() => {
                                                    takeMeThere(val.user_id)
                                                }}
                                            >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Take me there"}</Button>
                                        </Box>

                                    </Card>
                                </Box>
                            </Grid.Col>

                        )
                    })
                    }




                    {/*Pending Invitation Section*/}
                    {pendingInvitation && pendingInvitation.map((val, index) => {
                        //  console.log(val)
                        return (
                            <Grid.Col xs={24} sm={12} md={8} lg={8} key={index}>
                                <Box
                                // style={{ cursor: "pointer" }}
                                // onClick={() => {

                                //     becomeExecutor(val.invitation_code)
                                // }}
                                >
                                    <Card className="cardBorderRad2" shadow="sm" radius="xxs" sx={(theme) => ({
                                        border: '1px solid #E9ECEF', backgroundColor: '#FEFEFE', padding: "20px 28px 20px 28px", boxShadow: theme.shadows.sm,
                                        '@media (max-width: 1280px)': {
                                            padding: "20px"
                                        },
                                        '@media (max-width: 1024px)': {
                                            padding: "20px"
                                        },
                                    })}>
                                        <Card.Section>
                                            <Grid columns={12}>
                                                <Grid.Col xs={12} sm={0} md={0} lg={5}></Grid.Col>
                                                <Grid.Col xs={12} sm={5} md={5} lg={2} sx={(theme) => ({
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: 20,
                                                    '@media (max-width: 1280px)': {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingRight: 10,
                                                        paddingTop: 10
                                                    },
                                                    '@media (max-width: 1024px)': {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingRight: 10,
                                                        paddingTop: 10
                                                    },
                                                })}>
                                                    <div className="profile-image">
                                                        <div sx={(theme) => ({
                                                            paddingLeft: 10, paddingTop: 10,
                                                        })}>
                                                            {val.testator_image ? <Image
                                                                src={val.testator_image}
                                                                alt="Random unsplash image"
                                                                width={64}
                                                                height={64}
                                                            /> : <Avatar radius="50%" size={64} />}
                                                        </div>

                                                    </div>
                                                </Grid.Col>

                                                <Grid.Col xs={12} sm={7} md={7} lg={5} sx={(theme) => ({
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    paddingTop: 25,
                                                    '@media (max-width: 1280px)': {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingRight: 15,
                                                        paddingTop: 15,
                                                    },
                                                    '@media (max-width: 1024px)': {
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        paddingLeft: 10

                                                    },
                                                })}>
                                                    <Badge size="xl" radius="xxs" color="teal" sx={(theme) => ({
                                                        fontSize: 20, padding: "6px 8px 6px 8px", fontWeight: 600,
                                                        '@media (max-width: 1280px)': {
                                                            marginTop: 5,
                                                            fontSize: 16,
                                                        },
                                                        '@media (max-width: 1024px)': {
                                                            marginTop: 5,
                                                            fontSize: 16,
                                                        },
                                                    })}>
                                                        New Invite
                                                    </Badge>

                                                </Grid.Col>
                                            </Grid>

                                        </Card.Section>

                                        <Center>
                                            <MyRolesCardTitle title={val.testator_name} />
                                        </Center>

                                        <Center>
                                            <Text size="sm" color="dimmed" pt={20} pb={20} style={{ maxWidth: "70%", textAlign: "Center" }}>
                                                View your Testator and Executor information binders.
                                            </Text>
                                        </Center>
                                        <Box style={{ paddingTop: 20 }}>
                                            <Button
                                                fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 50,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}

                                                onClick={() => {

                                                    becomeExecutor(val.invitation_code)
                                                }}
                                            >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Become Trusted Person"}</Button>
                                        </Box>

                                    </Card>
                                </Box>
                            </Grid.Col>
                        )
                    })
                    }
                    {/*Accepted Invitation Section*/}
                    {acceptedInvitation && acceptedInvitation.map((val, index) => {
                        console.log(acceptedInvitation)
                        return (
                            <Grid.Col xs={24} sm={12} md={8} lg={8} key={index}>
                                <Card className="cardBorderRad2" shadow="sm" radius="xxs" sx={(theme) => ({
                                    border: '1px solid #E9ECEF', backgroundColor: '#FEFEFE', padding: "20px 28px 20px 28px", boxShadow: theme.shadows.sm,
                                    minHeight:300,
                                    '@media (max-width: 1280px)': {
                                        padding: "20px"
                                    },
                                    '@media (max-width: 1024px)': {
                                        padding: "20px"
                                    },
                                })}>
                                    <Card.Section>
                                        <Grid columns={12}>
                                            <Grid.Col xs={12} sm={0} md={0} lg={5} p={0}>

                                            </Grid.Col>
                                            <Grid.Col xs={12} sm={5} md={5} lg={2} sx={(theme) => ({
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: 20,
                                                '@media (max-width: 1280px)': {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingRight: 10,
                                                    paddingTop: 10
                                                },
                                                '@media (max-width: 1024px)': {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingRight: 10,
                                                    paddingTop: 10
                                                },
                                            })}>
                                                <div className="profile-image">
                                                    <div sx={(theme) => ({
                                                        paddingLeft: 10, paddingTop: 10,
                                                    })}>
                                                        {val.testator_image ? <Image
                                                            src={val.testator_image}
                                                            alt="Random unsplash image"
                                                            width={64}
                                                            height={64}
                                                        /> : <Avatar radius="50%" size={64} />}
                                                    </div>

                                                </div>
                                            </Grid.Col>
                                            <Grid.Col xs={12} sm={7} md={7} lg={5} sx={(theme) => ({
                                                display: "flex",
                                                alignItems: "flex-start",
                                                paddingTop: 25,
                                                '@media (max-width: 1280px)': {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingRight: 15,
                                                    paddingTop: 15,
                                                },
                                                '@media (max-width: 1024px)': {
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    paddingLeft: 10

                                                },
                                            })}>
                                                <Badge size="xl" radius="xxs" color="teal" sx={(theme) => ({
                                                    fontSize: 20, padding: "6px 8px 6px 8px", fontWeight: 600,
                                                    height: 60,
                                                    '@media (max-width: 1280px)': {
                                                        marginTop: 5,
                                                        fontSize: 16,
                                                    },
                                                    '@media (max-width: 1024px)': {
                                                        marginTop: 5,
                                                        fontSize: 16,
                                                    },
                                                })}>
                                                    Trusted <br /> Person
                                                </Badge>
                                            </Grid.Col>

                                        </Grid>

                                    </Card.Section>
                                    <Center>
                                        <MyRolesCardTitle title={val.testator_name + " " + "Information Binder"} />
                                    </Center>
                                    <Center>
                                        <Text size="sm" color="dimmed" pt={20} pb={20} style={{ maxWidth: "70%", textAlign: "Center" }}>
                                            View {val.testator_name} {val.testator_mname} {val.testator_lname}'s Information Binder.
                                        </Text>
                                    </Center>
                                    {val.deceased_status === "reported" && val.isAlive === "yes" &&
                                        <Box style={{ paddingTop: 20 }}>
                                            <Button
                                                fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 50,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                onClick={() => {
                                                    review(val.testator_id, val.testator_image, val.testator_name)
                                                    // becomeExecutor()
                                                }}
                                            >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Under review"}</Button>
                                        </Box>
                                    }
                                    {val.deceased_status === "reported" && val.isAlive === "no" &&
                                        <>
                                            <Box style={{ paddingTop: 20 }}>
                                                <Button
                                                    fullWidth
                                                    radius="xxs"
                                                    sx={(theme) => ({
                                                        backgroundColor: theme.colors.primary[0],
                                                        height: 50,
                                                        fontSize: theme.fontSizes.xxl,
                                                        fontWeight: 300,
                                                        fontFamily: theme.fontFamily[0],
                                                        '&:hover': {
                                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                        }
                                                    })}
                                                    onClick={() => {
                                                        review(val.testator_id, val.testator_image, val.testator_name)
                                                        // becomeExecutor()
                                                    }}
                                                >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Review"}</Button>
                                            </Box>
                                            <Box style={{ paddingTop: 20 }}>
                                                <Button fullWidth
                                                    radius="xxs"
                                                    styles={(theme) => ({
                                                        root: {
                                                            backgroundColor: theme.colors.buttonColor[9], color: theme.colors.buttonColor[0], height: 45, fontSize: theme.fontSizes.xxl, fontWeight: 600, border: "1px solid #E9ECEF", '&:hover': {
                                                                backgroundColor: theme.fn.darken(theme.colors.buttonColor[9], 0.0),
                                                            },
                                                        }
                                                    })}
                                                // onClick={(e) => {
                                                //     reportAsDeceased(val.testator_id, val.testator_name)
                                                // }}
                                                >
                                                    Deceased
                                                </Button>
                                            </Box>
                                        </>
                                    }
                                    {val.deceased_status !== "reported" && <Box>
                                        <Box style={{ paddingTop: 20 }}>
                                            <Button
                                                fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 50,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}

                                                onClick={() => {
                                                    review(val.testator_id, val.testator_image, val.testator_name)
                                                    // becomeExecutor()
                                                }}
                                            >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Review"}</Button>
                                        </Box>
                                        <Box style={{ paddingTop: 20 }}>
                                            <Button fullWidth
                                                radius="xxs"
                                                styles={(theme) => ({
                                                    root: {
                                                        backgroundColor: theme.colors.buttonColor[9], color: theme.colors.buttonColor[0], height: 45, fontSize: theme.fontSizes.xxl, fontWeight: 600, border: "1px solid #E9ECEF", '&:hover': {
                                                            backgroundColor: theme.fn.darken(theme.colors.buttonColor[9], 0.0),
                                                        },
                                                    }
                                                })}
                                                onClick={(e) => {
                                                    reportAsDeceased(val.testator_id, val.testator_name, val.testator_mname, val.testator_lame)
                                                }}
                                            >
                                                Report as deceased
                                            </Button>
                                        </Box>
                                    </Box>
                                    }

                                </Card>
                            </Grid.Col>
                        )
                    })
                    }
                </Grid>
                }

                {/*Invite Section*/}
                {!loading && viewExecutor && showInvetationData && <Grid columns={24}>
                    <Grid.Col span={6}></Grid.Col>
                    <Grid.Col span={12}>
                        <Box p={0} m={0}>
                            <MyRolesCardTitle title="You have been invited by" />
                        </Box>

                        <Grid columns={12}>
                            <Grid.Col span={1}>
                                <div className="profile-image">
                                    {profileImage ? <Image

                                        src={profileImage}
                                        alt="Random unsplash image"
                                        width={64}
                                        height={64}
                                    /> : <Avatar radius="50%" size={64} />}

                                </div>
                            </Grid.Col>
                            <Grid.Col span={11} style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ display: "flex" }}>
                                    <Box p={0} m={0}>
                                        <></>
                                    </Box>
                                    <TextDim title={inviteDetail.invitation_details[0].firstname} />
                                    <TextDim pl={10} title={inviteDetail.invitation_details[0].lastname} />
                                </Box>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <LoginTitle title="To become his Trusted Person." />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <InputLabel title="his message to  you:" />
                                <PageDiscription description={inviteDetail.invitation_details[0].invitation_message} />
                            </Grid.Col>


                            <Grid.Col span={12} style={{ display: "flex", flexDirection: "column" }}>
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <BreadCurmbText title="Yours truly" />
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <BreadCurmbText title={inviteDetail.invitation_details[0].firstname + " " + inviteDetail.invitation_details[0].middlename + " " + inviteDetail.invitation_details[0].lastname} />
                                    {/* <BreadCurmbText style={{ paddingLeft: 10 }} title={} /> */}
                                </Box>
                            </Grid.Col>
                        </Grid>
                        <Grid columns={12} >
                            <Grid.Col span={12}>
                                <Button
                                    fullWidth
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 45,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}

                                    onClick={() => {
                                        becomeExecutorAccept()
                                    }}
                                >{loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : "Become" + " " + inviteDetail.invitation_details[0].firstname + " " + "Trusted Person"}</Button>
                            </Grid.Col>
                            <Grid.Col>
                                <Button fullWidth
                                    radius="xxs"
                                    styles={(theme) => ({
                                        root: {
                                            backgroundColor: theme.colors.buttonColor[9], color: theme.colors.buttonColor[0], height: 45, fontSize: theme.fontSizes.xxl, fontWeight: 600, border: "1px solid #E9ECEF", '&:hover': {
                                                backgroundColor: theme.fn.darken(theme.colors.buttonColor[9], 0.0),
                                            },
                                        }
                                    })}
                                    onClick={(e) => {
                                        declineInvetation()
                                    }}
                                >
                                    Decline
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    <Grid.Col span={6}></Grid.Col>
                </Grid>
                }
            </div>

        </>
    )
}

export default MyRoles