import { TextInput, MantineProvider, PasswordInput, Button, Loader, useMantineTheme, Box } from "@mantine/core";
import { IconEyeCheck, IconEyeOff } from "@tabler/icons";
import React from "react";

/** For text Input**/
export const TextInputType = ({ placeholder, label, error, loginDetails, setLoginDetails }) => {
    return (
        <>
            <TextInput
                placeholder={placeholder}
                label={label}
                error={error}
                onChange={(e) => {
                    let temp = { ...loginDetails, "email": e.target.value }
                    setLoginDetails(temp)
                }}
                required
            />
        </>
    )
}
/** For Password  Input**/
export const PasswordInputType = ({ placeholder, label, error, loginDetails, setLoginDetails, setLoginPasswordError }) => {
    const theme = useMantineTheme("");
    return (
        <>
            <PasswordInput
                placeholder={placeholder}
                label={label}
                error={error}
                visibilityToggleIcon={({ reveal, size }) =>
                    reveal ? <IconEyeOff size={20} /> : <IconEyeCheck size={20} />
                }

                onChange={(e) => {
                    if (e.target.value.length > 0) {
                        setLoginPasswordError()
                    } else {
                        setLoginPasswordError("Password is required")
                    }
                    let temp = { ...loginDetails, "password": e.target.value }
                    setLoginDetails(temp)
                }}
                required

                sx={(theme) => ({
                    input: {
                        'height': 45, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1],
                        '@media (max-width: 1200px)': {
                            height: 30, fontSize: 18
                        },
                    },
                })}
            />
        </>
    )
}

/** For Password  Input**/
export const ButtonForm = ({ btnAction, loading, label }) => {
    return (
        <>
            <Button
                radius="xxs"
                fullWidth
                sx={(theme) => ({
                    backgroundColor: theme.colors.primary[0],
                    height: 40,
                    fontSize: theme.fontSizes.xxl,
                    fontWeight: 300,
                    textTransform: 'capitalize',
                    marginRight: '1.0vw',
                    fontFamily: theme.fontFamily[0],
                    '&:hover': {
                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                    }
                })}
                onClick={() => {
                    btnAction()
                }}
            >
                {loading ? <Loader variant="dots" style={{ "fill": "#FFFFFF" }} /> : label}
            </Button>
        </>
    )
}

/** For Password  Input**/
export const ButtonNoBg = ({ btnAction, className, actionVariable,label }) => {
    return (
        <>

            <Box
                className={className}
                sx={(theme) => ({
                    color: "#203047",
                    fontSize: 18,
                    lineHeight:"18px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: 'Source Sans Pro',
                    fontWeight: 500,
                    '&:hover': {
                        backgroundColor: theme.fn.darken('#fff', 0.0),
                        cursor:"pointer",
                        textDecoration: "underline"
                    },
                    '@media (max-width: 1600px)': {
                        fontSize: 18,
                        lineHeight: "18px"
                      },
                      '@media (max-width: 1280px)': {
                        fontSize: 16,
                        lineHeight: "16px"
                      },
                      '@media (max-width: 1200px)': {
                        fontSize: 16,
                        lineHeight: "16px"
                      },
                      '@media (max-width: 992px)': {
                        fontSize: 12,
                        lineHeight: "12px"
                      },
                      '@media (max-width: 768px)': {
                        fontSize: 18,
                        lineHeight: "18px"
                      },
                })}
                onClick={(e) => { btnAction(!actionVariable) }}


            >{label}</Box>


        </>
    )
}

