import { Affix, Button, Center, Text } from "@mantine/core";
import { IconMailForward } from "@tabler/icons";
import React from "react";
import FooterWill from "../Component/footer2";

const InvetationMessage = ({ navigate }) => {
    return (
        <> <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
            <Center style={{  'height': window.innerHeight }}>
                <div style={{ color: 'teal', display: 'flex' }}>
                    <IconMailForward />  <Text pl="xs">   Your invitation has been sent. We recommend calling your Trusted Person on the phone to confirm that they have received the email and to let them know that you’re sharing your Information Binder with them.</Text>
                </div>
            </Center>

            <>
                <Affix
                    sx={(theme) => ({
                        "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                            'left': 300
                        },
                        [`@media (max-width:1280px)`]: {
                            'left': 270
                        },
                        [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                            'left': 270
                        },
                        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                            'left': 270
                        },
                        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                            'height': '110px',
                            left: "0px"
                        },
                    })}
                >
                    <Affix position={{}} sx={(theme) => ({
                        bottom: 70, left: 330, zIndex: 2,
                        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                            left: "60px"
                        },
                    })}>
                        {/* <Button
                            sx={(theme) => ({
                                '&:hover': {
                                    backgroundColor: "#ffffff"
                                }
                            })}

                            className="buttonMediumBack textColor1 fontSize1 fontFamily1"
                            leftIcon={<BiChevronLeft />}
                            onClick={() => {
                                navigate('/accessControl');
                            }}
                        >
                            Back
                        </Button> */}
                    </Affix>

                    <Affix position={{ bottom: 70, right: 80, zIndex: 2 }}>
                        <Button
                            radius="xxs"
                            sx={(theme) => ({
                                backgroundColor: theme.colors.primary[0],
                                height: 50,
                                fontSize: theme.fontSizes.xxl,
                                fontWeight: 300,
                                fontFamily: theme.fontFamily[0],
                                '&:hover': {
                                    backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                }
                            })}
                            onClick={() => {

                                navigate('/accessControl');

                            }}
                        >
                            Continue
                        </Button>
                    </Affix>

                    <FooterWill navigate={navigate}/>
                </Affix>

            </>
        </div>
        </>
    )
}

export default InvetationMessage;