import { Avatar, Box, Button, Divider, FileButton, Grid, Image, LoadingOverlay, Modal, UnstyledButton } from "@mantine/core"
import { IconCamera, IconCheck, IconX } from "@tabler/icons";
import { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { BASE_URL } from "../apis/flowReviewApis/global";
import FlowTitle from "../Component/flowTitle";
import { getContactInformationEdit, updateContactInfo } from "../apis/keycontacatApis";
import InputControlPersonalInfo from '../Component/inputControlPersonalInfo'
import { showNotification } from "@mantine/notifications";




const EditPersonalInfo = ({ setLoggedIn, navigate }) => {
    // console.log(profileImage)
    const initialValues = {
        first_name: "",
        middle_name: "",
        last_name: "",
        nick_name: "",
        gender: "",
        ssn: "",
        home_phone_c_code: "",
        home_phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        county: "",
        country_id: "",
        zip_code: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [loading, setLoading] = useState(true)
    const [keyContactInfo, setKeyContactInfo] = useState({})
    const [prefill, setPrefill] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState({})
    //regex for different validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;  // for email
    const onlyAzSpace = /^[a-zA-Z ]*$/;                // for first name (with space in case of middle name)
    const onlyAz = /^[A-Za-z]+$/;                    // last name (no space)
    const onlyNumbers = /^[0-9]*$/;                  // only numberd
    const alphNumeric = /^[a-z0-9]+$/i;              // alpha numeric
    const checkPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;  //for password

    useEffect(() => {
        setLoading(true);
        getContactInformationEdit().then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                let ansObj = {}
                let prefillObj = {}
                response.data.inputList.map((item, index) => {

                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }

                })
                setAnswer(ansObj)
                setPrefill(prefillObj)
                setKeyContactInfo(response.data)
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }, [])

    function saveContactInfo() {


    }

    // sign up form
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(answer));
        setIsSubmit(true);
    };

    // validate signup form inputs
    const validate = (values) => {
        const errors = {};

        //validate first Name
        if (!values.first_name) {
            errors.first_name = "First name is required!";
        } else if (!onlyAzSpace.test(values.first_name)) {
            errors.first_name = "Enter a valid name!";
        } else if (values.first_name.length < 2) {
            errors.first_name = "Enter minimum 2 characters!";
        }

        //validate Last Name
        if (!values.last_name) {
            errors.last_name = "Last name is required!";
        } else if (!onlyAzSpace.test(values.last_name)) {
            errors.last_name = "Enter a valid name!";
        } else if (values.last_name.length < 2) {
            errors.first_name = "Enter minimum 2 characters!";
        }
        if (!onlyAzSpace.test(values.middle_name)) {
            errors.middle_name = "Enter a valid middle name!";
        }
        if (!onlyAzSpace.test(values.nick_name)) {
            errors.nick_name = "Enter a valid nick name!";
        }

        //validate user Name
        // if (values.gender == "male" || values.gender == "female") {
        //     errors.gender = "Select a valid gender";
        // }
        if(values.ssn.length < 9  || values.ssn.length > 9){
            errors.ssn = "Enter 9 digit social security number!";
        }
        //validate Phone Number
        if (values.home_phone.length > 0) {
            if (!onlyNumbers.test(values.home_phone)) {
                errors.home_phone = "Enter a valid phone number!";
            } else if (values.home_phone.length < 10) {
                errors.home_phone = "Enter minimum 10 digit phone number!";
            } else if (values.home_phone.length > 10) {
                errors.home_phone = "Maximum 10 digit allowed!";
            }
        }
        return errors;
    };

    useEffect(() => {
        console.log(formErrors);

        if (Object.keys(formErrors).length === 0 && isSubmit === true) {
            let data = {
                "Save": "yes",
                ...answer

            }
            console.log("data", data)
            setLoading(true)
            updateContactInfo(data).then((response) => {
                console.log("Response of get willflow object: ", response.data)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)

                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                } else if (response.data.status === 'error') {
                    setApiErrorMsg(response.data.message)
                    setLoading(false)
                } else if (response.data.status === 'success') {
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                    setKeyContactInfo(response.data)
                    setLoading(false)
                    setApiErrorMsg("")
                    navigate('/personalInfo')
                }

            }, (error) => {
                console.log("Error in getting willflow object: ", error)
            })
        }
    }, [formErrors])
    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    console.log(answer)
    // console.log(prefill)
    return (
        <>
            <Grid columns={24}>
                <Grid.Col span={6}></Grid.Col>
                <Grid.Col span={6}></Grid.Col>

                <Grid.Col span={24}>
                    <FlowTitle title="Update personal information" />
                    <Divider my="sm" variant="dotted" />
                </Grid.Col>

            </Grid>
            <form onSubmit={handleSubmit}>
                {!loading &&

                    <Grid columns={24} >
                        {keyContactInfo?.inputList.map((item, index) => {
                            return (

                                <InputControlPersonalInfo
                                    key={index}
                                    data={item}
                                    setAnswer={setAnswer}
                                    answer={answer}
                                    setPrefill={setPrefill}
                                    formErrors={formErrors}
                                    setFormErrors={setFormErrors}
                                    onlyAzSpace={onlyAzSpace}
                                    onlyNumbers={onlyNumbers}

                                />

                            )
                        })}
                    </Grid>

                }

                <Grid columns={24} >
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Button
                            radius="xxs"
                            sx={(theme) => ({
                                color: theme.colors.textColor[0],
                                fontSize: theme.fontSizes.xxl,
                                fontFamily: theme.fontFamily[0],
                                fontWeight: 300,
                                height: 50,
                                backgroundColor: "transparent",

                                '&:hover': {
                                    backgroundColor: theme.colors.buttonColor[9],
                                    borderWidth: 1,
                                    BorderStyle: "solid",
                                    borderColor: theme.colors.primary[0],
                                }
                            })}
                            onClick={() => {
                                navigate('/personalInfo')
                            }}

                        >Back</Button>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            radius="xxs"
                            sx={(theme) => ({
                                backgroundColor: theme.colors.primary[0],
                                height: 50,
                                fontSize: theme.fontSizes.xxl,
                                fontWeight: 300,
                                fontFamily: theme.fontFamily[0],
                                '&:hover': {
                                    backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                }
                            })}
                            type="submit"

                        >Update</Button>
                    </Grid.Col>
                </Grid>
            </form>
        </>
    )
}

export default EditPersonalInfo