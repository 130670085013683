import "./App.css";
import { MantineProvider } from "@mantine/core";
import Home from "./Pages/home"
import Login from "./Pages/Login";
import { BrowserRouter } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import { NotificationsProvider } from '@mantine/notifications';
// import { AuthProvider } from './context/AuthProvider';

function App() {
  let [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    let temp = localStorage.getItem("project_whiteney_email")
    if (temp !== null) {
      setLoggedIn(true)
    }
  })

  return (
    <BrowserRouter>
      <MantineProvider
        theme={{
          loader: "dots",
          fontFamily: "Roboto",
          headings: {
            fontFamily: "Source Sans Pro",
            sizes: {
              h1: { fontSize: 100 },
            },
          },
          colors: {
            // primaryColor: ["#023047", "#505664", "#62697B", "#ACB0B9", "#045C87"], 
            // gray: ["#F6F6F6", "#ACB0B9", "#62697B", "#595F70", "#505664"],
            primary: ["#023047", "#505664", "#62697B", "#ACB0B9", "#045C87", "#919497", "#239E69", "#ECF9F3", "#239e6926"],
            textColor: ["#023047", "#505664", "#62697B", "#ACB0B9", "#045C87", "#919497", "#239E69", "#ECF9F3", "#239e6926", "#ffffff","#f6fbfd"],
            buttonColor: ["#023047", "#505664", "#62697B", "#ACB0B9", "#045C87", "#919497", "#239E69", "#ECF9F3", "#239e6926", "#ffffff", "#D22B2B", "#FF3131"],
            iconColor: ["#023047", "#505664", "#62697B", "#ACB0B9", "#045C87", "#919497", "#239E69", "#ECF9F3", "#239e6926"],
            border: ["#ACB0B9"],
            bgColor: ["#DEF1FD","#023047", "#ffffff",],
            dark: ["#C1C2C5", "#A6A7AB", "#909296", "#5c5f66", "#373A40", "#2C2E33", "#25262b", "#1A1B1E", "#141517", "#101113",],
            gray: ["#F6F6F6", "#ACB0B9", "#62697B", "#595F70", "#505664", "#adb5bd", "#868e96", "#495057", "#343a40", "#212529",],
            red: ["#fff5f5", "#ffe3e3", "#ffc9c9", "#ffa8a8", "#ff8787", "#ff6b6b", "#fa5252", "#f03e3e", "#e03131", "#c92a2a",],
            pink: ["#fff0f6", "#ffdeeb", "#fcc2d7", "#faa2c1", "#f783ac", "#f06595", "#e64980", "#d6336c", "#c2255c", "#a61e4d",],
            grape: ["#f8f0fc", "#f3d9fa", "#eebefa", "#e599f7", "#da77f2", "#cc5de8", "#be4bdb", "#ae3ec9", "#9c36b5", "#862e9c",],
            violet: ["#f3f0ff", "#e5dbff", "#d0bfff", "#b197fc", "#9775fa", "#845ef7", "#7950f2", "#7048e8", "#6741d9", "#5f3dc4",],
            indigo: ["#edf2ff", "#dbe4ff", "#bac8ff", "#91a7ff", "#748ffc", "#5c7cfa", "#4c6ef5", "#4263eb", "#3b5bdb", "#364fc7",],
            blue: ["#e7f5ff", "#d0ebff", "#a5d8ff", "#74c0fc", "#4dabf7", "#339af0", "#228be6", "#1c7ed6", "#1971c2", "#1864ab",],
            cyan: ["#e3fafc", "#c5f6fa", "#99e9f2", "#66d9e8", "#3bc9db", "#22b8cf", "#15aabf", "#1098ad", "#0c8599", "#0b7285",],
            teal: ["#e6fcf5", "#c3fae8", "#96f2d7", "#63e6be", "#38d9a9", "#20c997", "#12b886", "#0ca678", "#099268", "#087f5b",],
            green: ["#ebfbee", "#d3f9d8", "#b2f2bb", "#8ce99a", "#69db7c", "#51cf66", "#40c057", "#37b24d", "#2f9e44", "#2b8a3e",],
            lime: ["#f4fce3", "#e9fac8", "#d8f5a2", "#c0eb75", "#a9e34b", "#94d82d", "#82c91e", "#74b816", "#66a80f", "#5c940d",],
            yellow: ["#fff9db", "#fff3bf", "#ffec99", "#ffe066", "#ffd43b", "#fcc419", "#fab005", "#f59f00", "#f08c00", "#e67700",],
            orange: ["#fff4e6", "#ffe8cc", "#ffd8a8", "#ffc078", "#ffa94d", "#ff922b", "#fd7e14", "#f76707", "#e8590c", "#d9480f",],
            custom: ["#239e6926", "#239E69", "#239e6940", "#3a7c60"],
            customViolet: ["#8F00FF15", "#8104e5f7", "#5c129769", "#8104e5f7"] 
          },
          fontSizes: {
            xxs:9,xs: 14, sm: 16, md: 18, lg: 20, xl: 22, xxl: 25, lxl: 28, lxxl: 30 
          },
          spacing: {zx:0, xxs: 5, xxs_input:6, xs: 10, sm: 12, md: 16, lg: 20, xl: 24 },
          radius: { xxs: 4, xs: 5, sm: 10, md: 15, lg: 20, xl: 25 },
          heights: { height: [45] },
          shadows: { xs: "1px 1px 5px 2px rgba(0, 0, 0, 0.1)", sm: "0px 4px 7px 7px rgba(28, 39, 60, 0.1)" },
          fontFamily: ["Source Sans Pro", "Roboto"],
          lineHeight: ["15px", "20px", "25px", "30px", "35px", "40px", "45px", "50px", "55px", "60px"],
          components: {
            InputWrapper: {
              styles: (theme) => ({
                label: {
                  color: "#023047", fontSize: 22, fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: 600,
                  '@media (max-width: 1600px)': {
                    fontSize: 22,
                    lineHeight: "22px"
                  },
                  '@media (max-width: 1280px)': {
                    fontSize: 16,
                    lineHeight: "16px"
                  },
                  '@media (max-width: 1200px)': {
                    fontSize: 16,
                    lineHeight: "16px"
                  },
                  '@media (max-width: 992px)': {
                    fontSize: 14,
                    lineHeight: "14px"
                  },
                  '@media (max-width: 768px)': {
                    fontSize: 18,
                    lineHeight: "18px"
                  },
                },
              }),
            },
            Input: {
              styles: (theme) => ({
                input: {
                  height: 45, border: 'none', fontSize: 20, 'color': '#023047', fontFamily: 'Roboto', border: '1px solid #ACB0B9', borderRadius: 4,
                  '@media (max-width: 1440px)': {
                    minHeight: 35,height: 35, fontSize: 18,

                  },
                  '@media (max-width: 1280px)': {
                    minHeight: 30,height:30, fontSize: 16,
                  },
                  '@media (max-width: 1200px)': {
                    minHeight: 30,height: 30, fontSize: 16,
                  },
                  '@media (max-width: 992px)': {
                    fontSize: 12,
                    height: 25,
                    minHeight: 25,
                 
                  },
                  '@media (max-width: 768px)': {
                    fontSize: 18,
                    height: 45,
                    minHeight: 45,
                   
                  },
                },
              }),
            },

            Button: {
              // Subscribe to theme and component params
              styles: (theme) => ({
                root: {
                  height: 45,
                  padding: '0 10px',
                  // backgroundColor: "#023047",
                  fontSize: 25,
                  // maxWidth: 200,
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 300,
                  '&:hover': {
                    // backgroundColor: theme.fn.darken('#023047', 0.2),
                  },
                  

                },
            
              }),
            },
          
          },
          breakpoints: {
            xxs:319,
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1400,
            xxl:1600,
            xxxl:3840
          },

        }}
      >
        <NotificationsProvider position="top-right" zIndex={2077}>
          {loggedIn ? <Home setLoggedIn={setLoggedIn} /> : <Login setLoggedIn={setLoggedIn} />}
        </NotificationsProvider>

      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;
