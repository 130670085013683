import { ActionIcon, Button, Grid, Text, Tooltip } from "@mantine/core";
import React from "react";
import { BiPlus } from "react-icons/bi";
import { BsFillEyeFill, BsInfoCircle } from "react-icons/bs";
import { HiArrowRight } from "react-icons/hi";

export const TableHeader = ({ leftTitle, rightTitle }) => {
    return (
        <>
            <Grid sx={(theme) => ({
                maxHeight: 50, backgroundColor: '#def1fd', borderTopRightRadius: 5, borderTopLeftRadius: 5, '@media (max-width: 1280px)': {
                    maxHeight: 40,
                }, '@media (max-width: 1024px)': {
                    maxHeight: 40,
                },
            })} className="cardWidth">
                <Grid.Col span={8}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.primary[0],
                            fontWeight: 700,
                            alignItems: "center",
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                        })}

                    >{leftTitle}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.primary[0],
                            fontWeight: 700,
                            alignItems: "center",
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                        })}
                        style={{ float: 'right', paddingRight: '2.5vw' }}>{rightTitle}</Text>
                </Grid.Col>
            </Grid>
        </>
    )
}
export const TableHeader2 = ({ leftTitle, midTitle, rightTitle }) => {
    return (
        <>
            <Grid style={{ maxHeight: 60, backgroundColor: '#def1fd', borderTopRightRadius: 5, borderTopLeftRadius: 5 }} >
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", paddingLeft: "40px", alignItems: "center" })}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.primary[0],
                            fontWeight: 700,
                            marginRight: 10,
                            alignItems: "center",
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                        })}

                    >{leftTitle}</Text>

                </Grid.Col>
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.primary[0],
                            fontWeight: 700,
                            alignItems: "center",
                            paddingRight: "10px",
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                        })}
                    >{midTitle}</Text>
                    <Tooltip
                        multiline
                        width={200}
                        radius="xxs"
                        transition="fade"
                        label="Now items will be visible to this Trusted Person when you invite them to access.
                            ">
                        <ActionIcon variant="subtle"
                            sx={(theme) => ({
                                '&:hover': {
                                    backgroundColor: theme.colors.buttonColor[2],
                                    color: theme.colors.buttonColor[9]
                                },
                            })}


                        >
                            <BsInfoCircle size={16} />
                        </ActionIcon>
                    </Tooltip>
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.primary[0],
                            fontWeight: 700,
                            alignItems: "center",
                            paddingRight: "10px",
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                        })}
                    >{rightTitle}</Text>
                     <Tooltip
                        multiline
                        width={200}
                        radius="xxs"
                        transition="fade"
                        label="After Death items will only be visible to this Trusted Person after you have passed.">
                        <ActionIcon variant="subtle"
                            sx={(theme) => ({
                                '&:hover': {
                                    backgroundColor: theme.colors.buttonColor[2],
                                    color: theme.colors.buttonColor[9]
                                },
                            })}


                        >
                            <BsInfoCircle size={16} />
                        </ActionIcon>
                    </Tooltip>
                </Grid.Col>
            </Grid>
        </>
    )
}

export const CardLeftText = ({ name, content, company }) => {
    return (
        <>
            <Grid columns={24} style={{ fontSize: 20, fontWeight: 900, }} p={0} m={0}>
                <Grid.Col span={24} p={0} m={0}>
                    <Text
                        sx={(theme) => ({
                            fontSize: 24,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.textColor[0],
                            fontWeight: 600,
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.md,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.md,
                            },
                        })}
                    >{name}</Text>
                    <Text

                        sx={(theme) => ({
                            fontSize: 24,
                            fontFamily: theme.fontFamily[0],
                            color: theme.colors.textColor[0],
                            fontWeight: 500,
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.md,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.md,
                            },
                        })}
                    >{company}</Text>
                </Grid.Col>
                <Grid.Col span={24} p={0} m={0}>
                    <Text
                        sx={(theme) => ({
                            fontSize: theme.fontSizes.sm,
                            fontFamily: theme.fontFamily[1],
                            color: theme.colors.primary[1],
                            fontWeight: 400,
                        })}
                    >{content}</Text>
                </Grid.Col>

            </Grid>
        </>
    )
}

export const CardButton = ({ btnAction, btnText, funArgs }) => {
    return (
        <>
            {btnText === "Start" && <Button
                // fullWidth
                radius="xxs"
                sx={(theme) => ({
                    backgroundColor: theme.colors.primary[0],
                    height: 40,
                    fontSize: theme.fontSizes.xl,
                    fontWeight: 300,
                    fontFamily: theme.fontFamily[0],
                    '&:hover': {
                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                    }
                })}
                rightIcon={<HiArrowRight size={20} />}

                onClick={() => {
                    btnAction(funArgs)
                }}

            >
                {btnText}
            </Button>
            }
            {btnText === "Review" && <Button
                // fullWidth
                radius="xxs"
                sx={(theme) => ({

                    backgroundColor: theme.colors.primary[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 300,

                    '&:hover': {
                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2)
                    },

                    maxWidth: 200,
                })}
                rightIcon={<BsFillEyeFill size={20} />}

                onClick={() => {
                    btnAction(funArgs)
                }}

            >
                {btnText}
            </Button>
            }

            {btnText === "Add" && <Button
                // fullWidth
                variant="outline"
                radius="xxs"

                sx={(theme) => ({
                    color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                    borderColor: theme.colors.buttonColor[0],
                    '&:hover': {
                        backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                    }
                })}
                rightIcon={<BiPlus size={20} />}

                onClick={() => {
                    btnAction(funArgs)
                }}

            >
                {btnText}
            </Button>
            }
        </>
    )
}


export const IconButton = ({ btnAction, funArgs, btnIcon }) => {
    return (
        <>
            <ActionIcon variant="subtle"
                onClick={() => {
                    btnAction(funArgs)
                }}
            >
                {/* <Trash size={20} style={{ color: "#023047" }} /> */}
                {btnIcon}
            </ActionIcon>
        </>
    )
}


export const CustomButton = () => {
    return (
        <>
            <Button
                sx={(theme) => ({
                    backgroundColor: theme.colors.primary[0],
                    height: 45,
                    fontSize: theme.fontSizes.xxl,
                    fontWeight: 300,
                    '&:hover': {
                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                    }
                })}
            >

            </Button>

        </>
    )
}