import React, { useState } from "react";
import { Box, Text} from '@mantine/core'
import '../Custom.css';
import { HelpModal } from "./modal";

const Footer = ({navigate}) => {
    const [helpOpened, setHelpOpened] = useState(false);
    return (
        <>
           <HelpModal
            helpOpened={helpOpened}
            setHelpOpened={setHelpOpened}
         />
            <Box
                sx={(theme) => ({
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    backgroundColor: theme.colors.textColor[10],
                    color: 'white',
                    textAlign: 'center',


                })}

            >
                <div className="setFooterText">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={(theme) => ({ fontSize: theme.fontSizes.sm, color: theme.colors.textColor[2], display: "flex", flexDirection: "row", gap: 10 })} >
                            <Box
                                className="footerLink"
                                onClick={(e) => {
                                    console.log("licence Agrement")
                                    navigate('/licenceAgreement')
                                }}
                            >License Agreement</Box>  |
                            <Box
                                className="footerLink"
                                onClick={(e) => {
                                    console.log("Privacy")
                                    navigate('/privacy')
                                }}
                            > Privacy </Box> |
                            <Box
                                className="footerLink"
                                onClick={(e) => {
                                    console.log("Security")
                                    navigate('/security')
                                }}
                            >Security </Box> |
                            <Box
                                className="footerLink"
                                onClick={(e) => {
                                    setHelpOpened(true)
   
                                 }}
                            >Give Feedback </Box>
                        </Box>
                    </div>
                    <div>
                        <Text sx={(theme) => ({ fontSize: theme.fontSizes.xs, color: theme.colors.textColor[3] })} > 2022 Project Whitney Inc. All Rights reserved </Text>
                    </div>
                </div>

            </Box>
        </>
    )
}

export default Footer