import React from "react";
import axios from "axios";
import { BASE_URL } from "./flowReviewApis/global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    onUploadProgress: function (progressEvent) {
        // console.log("Pregress event: ", progressEvent)
    },
    
})

export const login = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/sign-in"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const signUp = (data) => {
    console.log(data)
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/sign-up"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const verifyOtp = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/sign-up-verification"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const forgotPasswordVerifyOtp = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL+ "/whitney/public/api/v1/forgot-password-verify"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const resendOTPReq = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/sign-in-otp-resend"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}


export const veryFyEmailReq = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/forgot-password"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const setNewPasswordFun = (data) => {
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/forgot-password-update"
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}


export const checkDuplicateEmail = (data)=>{
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/email-username-verification "
    // console.log("Data in api call: ", data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)
}

export const inviteDetails = (data)=>{
    let formData = {}
    let inviteUrl = BASE_URL + '/whitney/public/api/v1/invitation-details';
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    console.log("Form data", data)
    return whitney.post(inviteUrl, formData)
}

export const inviteDecline = (data)=>{
    let formData = {}
    let inviteUrl = BASE_URL + '/whitney/public/api/v1/invitation-reject';
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    // console.log("Form data", formData)
    return whitney.post(inviteUrl, formData)
}