import { Accordion, ActionIcon, Affix, Box, Button, Checkbox, Grid, LoadingOverlay, Radio, Text, Tooltip, useMantineTheme } from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import FlowTitle from "../Component/flowTitle";
import '../Custom.css';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { BiChevronLeft } from "react-icons/bi";
import FooterWill from "../Component/footer2";
import { useListState, randomId } from '@mantine/hooks';
import { BASE_URL } from "../apis/flowReviewApis/global";
import { PermissionHeading, PermissionText } from "../Component/textLabel";
import { TableHeader2 } from "../Component/TableComponent";
import { WarningModal } from "../Component/modal";
import { BsInfo, BsInfoCircle, BsQuestionCircle } from "react-icons/bs";
import { FullScreenModal } from "../Component/modal";

const EditAccessPermission = ({ navigate, setLoggedIn }) => {
    // const theme = useMantineTheme("");
    const { state } = useLocation();
    const { key_contact_id, apiData, name, write_permission, selectedKeyContact } = state;

    const [loading, setLoading] = useState(true)
    const [keyContactData, setKeyContactData] = useState(null);
    const [writtenPermission, setWrittenPermission] = useState(write_permission === false ? "no" : "yes");
    const [disabledNow, setDisabledNow] = useState(write_permission === true ? true : false)
    const [disabledAfter, setDisabledAfter] = useState(write_permission === true ? true : false)
    const [writtenPermissionStatus, setWrittenPermissionStatus] = useState(write_permission === true ? true : false)
    const [warnOpned, setWarnOpened] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState({})
    const [fullOpened, setFullOpened] = useState(false);
    let setQuestionData = []
    console.log(apiData)
    apiData.topic_data.map((item) => {
        if (item.topic_id === '200000001') {
            item.questions[0].will_access_now.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_now_" + ele.question_id, "question": ele.question })
            })
        } else if (item.topic_id === '200000002') {
            item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_now_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000004') {
            item.questions[0].living_will_access_now.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_now_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000005') {
            item.questions[0].healthcare_proxy_access_now.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_now_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000003') {
            item.questions[0].trusts_access_now.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_now_" + ele.question_id, "question": ele.question })
            })

        }

        if (item.topic_id === '200000001') {
            item.questions[0].will_after_death.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_afterdeath_" + ele.question_id, "question": ele.question })
            })
        } else if (item.topic_id === '200000002') {
            item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_afterdeath_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000004') {
            item.questions[0].living_will_after_death.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_afterdeath_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000005') {
            item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_afterdeath_" + ele.question_id, "question": ele.question })
            })

        } else if (item.topic_id === '200000003') {
            item.questions[0].trusts_after_death.map((ele, key) => {
                setQuestionData.push({ "question_id": "at_" + ele.topic_id + "_afterdeath_" + ele.question_id, "question": ele.question })
            })

        }
    })

    useEffect(() => {
        if (writtenPermissionStatus === true) {
            // console.log("writtenPermissionStatus" + writtenPermissionStatus)
            writtenPermissionCall("yes")

        }

    }, [writtenPermissionStatus])

    function writtenPermissionPopupModal(event) {
        if (event === "yes") {
            let temp = {
                title: "Are you sure that you want to give full write permissions?",
                content: "A person with full write permissions, can write, edit and delete your notes and your uploaded documents. We recommend such permissions only for your most trusted persons, and even then for a limited time period, while you are creating your Information Binder.",
                content2: "For everyone else, we recommend not giving write permissions. They will still be able to view your notes and documents.                ",
                args: event,
                setPermissions: writtenPermissionCall
            }
            setConfirmMessage(temp)
            setWarnOpened(!warnOpned)
        } else {
            writtenPermissionCall(event)
        }
    }
    function writtenPermissionCall(event) {
        console.log("function called", event)
        // console.log(event)
        setWrittenPermission(event)
        if (event === "yes") {

            setDisabledNow(true)
            setDisabledAfter(true)

            let val = true
            handlerAllAfter.setState((current) =>
                current.map((value) => ({ ...value, checked: val }))
            )
            handlerAllNow.setState((current) =>
                current.map((value) => ({ ...value, checked: val }))
            )
            //Will Section
            handlers.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Power of Attorny Section
            handlersP.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Living Will Section
            handlersL.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Health  Section
            handlersH.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Trust  Section
            handlersT.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));


            //Will Section AfterDeath
            handlers1.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Power of Attorny Section AfterDeath
            handlers1P.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Living Will Section AfterDeath
            handlers1L.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Health Section AfterDeath
            handlers1H.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Trust Section AfterDeath
            handlers1T.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));

            /*set req Answer Values*/
            // Will Section
            apiData.topic_data.map((item) => {
                if (item.topic_id === '200000001') {
                    item.questions[0].will_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })
                } else if (item.topic_id === '200000002') {
                    item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000004') {
                    item.questions[0].living_will_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000005') {
                    item.questions[0].healthcare_proxy_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000003') {
                    item.questions[0].trusts_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                }
            })

            /*set Req Answer */
            //After Death
            apiData.topic_data.map((item) => {
                if (item.topic_id === '200000001') {
                    item.questions[0].will_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })
                } else if (item.topic_id === '200000002') {
                    item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000004') {
                    item.questions[0].living_will_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000005') {
                    item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000003') {
                    item.questions[0].trusts_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                }
            })


        }
        if (event === "no") {
            setDisabledNow(false)
            setDisabledAfter(false)
            let val = false
            handlerAllAfter.setState((current) =>
                current.map((value) => ({ ...value, checked: val }))
            )
            handlerAllNow.setState((current) =>
                current.map((value) => ({ ...value, checked: val }))
            )
            //Will Section
            handlers.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Power of Attorny Section
            handlersP.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Living Will Section
            handlersL.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Health  Section
            handlersH.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
            //Trust  Section
            handlersT.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));


            //Will Section AfterDeath
            handlers1.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Power of Attorny Section AfterDeath
            handlers1P.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Living Will Section AfterDeath
            handlers1L.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Health Section AfterDeath
            handlers1H.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));
            //Trust Section AfterDeath
            handlers1T.setState((current) =>
                current.map((value) => ({ ...value, checked: val })));

            /*set req Answer Values*/
            // Will Section
            apiData.topic_data.map((item) => {
                if (item.topic_id === '200000001') {
                    item.questions[0].will_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })
                } else if (item.topic_id === '200000002') {
                    item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000004') {
                    item.questions[0].living_will_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000005') {
                    item.questions[0].healthcare_proxy_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000003') {
                    item.questions[0].trusts_access_now.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                        }))
                    })

                }
            })

            /*set Req Answer */
            //After Death
            apiData.topic_data.map((item) => {
                if (item.topic_id === '200000001') {
                    item.questions[0].will_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })
                } else if (item.topic_id === '200000002') {
                    item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000004') {
                    item.questions[0].living_will_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000005') {
                    item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                } else if (item.topic_id === '200000003') {
                    item.questions[0].trusts_after_death.map((ele, key) => {
                        setAnswer((state) => ({
                            ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                        }))
                    })

                }
            })


        }
    }

    let ansTemp = {}
    apiData.topic_data.map((item) => {
        if (item.topic_id === '200000001') {
            item.questions.map((index) => {
                index.will_access_now.map((data) => {
                    // ansTemp = { ...ansTemp, ["access_now_" + data.question_id]: data.checked }
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_now_" + data.question_id]: data.checked }
                })

                index.will_after_death.map((data) => {
                    // ansTemp = { ...ansTemp, ["access_after_death_" + data.question_id]: data.checked }
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_afterdeath_" + data.question_id]: data.checked }
                })
            })
        } else if (item.topic_id === '200000002') {
            item.questions.map((index) => {
                index.power_of_attorney_access_now.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_now_" + data.question_id]: data.checked }
                })

                index.power_of_attorney_after_death.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_afterdeath_" + data.question_id]: data.checked }
                })
            })
        } else if (item.topic_id === '200000004') {
            item.questions.map((index) => {
                index.living_will_access_now.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_now_" + data.question_id]: data.checked }
                })

                index.living_will_after_death.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_afterdeath_" + data.question_id]: data.checked }
                })
            })
        } else if (item.topic_id === '200000005') {
            item.questions.map((index) => {
                index.healthcare_proxy_access_now.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_now_" + data.question_id]: data.checked }
                })

                index.healthcare_proxy_after_death.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_afterdeath_" + data.question_id]: data.checked }
                })
            })
        } else if (item.topic_id === '200000003') {
            item.questions.map((index) => {
                index.trusts_access_now.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_now_" + data.question_id]: data.checked }
                })

                index.trusts_after_death.map((data) => {
                    ansTemp = { ...ansTemp, ["at_" + data.topic_id + "_afterdeath_" + data.question_id]: data.checked }
                })
            })
        }
    })


    // console.log(ansTemp)






    const [answer, setAnswer] = useState(ansTemp);
    let topicArr = []
    apiData.topic_data.map((ele, key) => {
        topicArr.push(ele.topic_id)
    })
    // console.log(topicArr)
    let willTitleIdNow, willTitleIdAfter, powerTitleIdNow, powerTitleIdAfter, livingTitleIdNow, livingTitleIdAfter, healthTitleIdNow, healthTitleIdAfter, trustTitleIdNow, trustTitleIdAfter;
    apiData.topic_data.map((item) => {
        // console.log(item)
        if (item.topic_id === '200000001') {
            let checkStatusNow = false;
            let checkStatusAfter = false;
            item.questions.map((val, index) => {
                checkStatusNow = val.will_access_now.every((value) => { return value.checked })
            })
            item.questions.map((val, index) => {
                checkStatusAfter = val.will_after_death.every((value) => { return value.checked })
            })
            // console.log(checkStatusNow)
            willTitleIdNow = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusNow, after: { checked: checkStatusNow, key: randomId() } }
            willTitleIdAfter = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusAfter }
        }
        else if (item.topic_id === '200000002') {
            let checkStatusNow = false;
            let checkStatusAfter = false;
            item.questions.map((val, index) => {
                checkStatusNow = val.power_of_attorney_access_now.every((value) => { return value.checked })
            })
            item.questions.map((val, index) => {
                checkStatusAfter = val.power_of_attorney_after_death.every((value) => { return value.checked })
            })
            // console.log(checkStatusNow)
            powerTitleIdNow = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusNow, after: { checked: checkStatusNow, key: randomId() } }
            powerTitleIdAfter = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusAfter }
            // powerTitleId = { title: item.title, topic_id: item.topic_id, key: randomId() }
        } else if (item.topic_id === '200000004') {
            let checkStatusNow = false;
            let checkStatusAfter = false;
            item.questions.map((val, index) => {
                checkStatusNow = val.living_will_access_now.every((value) => { return value.checked })
            })
            item.questions.map((val, index) => {
                checkStatusAfter = val.living_will_after_death.every((value) => { return value.checked })
            })
            // console.log(checkStatusNow)
            livingTitleIdNow = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusNow, after: { checked: checkStatusNow, key: randomId() } }
            livingTitleIdAfter = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusAfter }
        } else if (item.topic_id === '200000005') {
            let checkStatusNow = false;
            let checkStatusAfter = false;
            item.questions.map((val, index) => {
                checkStatusNow = val.healthcare_proxy_access_now.every((value) => { return value.checked })
            })
            item.questions.map((val, index) => {
                checkStatusAfter = val.healthcare_proxy_after_death.every((value) => { return value.checked })
            })
            // console.log(checkStatusNow)
            healthTitleIdNow = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusNow, after: { checked: checkStatusNow, key: randomId() } }
            healthTitleIdAfter = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusAfter }
        } else if (item.topic_id === '200000003') {
            let checkStatusNow = false;
            let checkStatusAfter = false;
            item.questions.map((val, index) => {
                checkStatusNow = val.trusts_access_now.every((value) => { return value.checked })
            })
            item.questions.map((val, index) => {
                checkStatusAfter = val.trusts_after_death.every((value) => { return value.checked })
            })
            // console.log(checkStatusNow)
            trustTitleIdNow = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusNow, after: { checked: checkStatusNow, key: randomId() } }
            trustTitleIdAfter = { title: item.title, topic_id: item.topic_id, key: randomId(), checked: checkStatusAfter }
        }
    })

    const [will, setWill] = useState(topicArr.includes("200000001"));
    const [power, setPower] = useState(topicArr.includes("200000002"));
    const [living, setLiving] = useState(topicArr.includes("200000004"));
    const [health, setHealth] = useState(topicArr.includes("200000005"));
    const [trust, setTrust] = useState(topicArr.includes("200000003"));

    const allTopicParentNow = [willTitleIdNow, powerTitleIdNow, livingTitleIdNow, healthTitleIdNow, trustTitleIdNow]
    const allTopicParentAfter = [willTitleIdAfter, powerTitleIdAfter, livingTitleIdAfter, healthTitleIdAfter, trustTitleIdAfter]
    const allTopicParentNowModified = allTopicParentNow.filter(function (element) {
        return element !== undefined;
    });
    const allTopicParentAfterModified = allTopicParentAfter.filter(function (element) {
        return element !== undefined;
    });
    console.log(allTopicParentNowModified)
    console.log(allTopicParentNowModified)
    const [allAccessNow, handlerAllNow] = useListState(allTopicParentNowModified);
    const [allAccessAfter, handlerAllAfter] = useListState(allTopicParentAfterModified);

    const mainParentAllCheckedNow = allAccessNow.every((value) => {
        // console.log(value)
        return value.checked
    }
    );

    const mainParentAllIndeterminateNow = allAccessNow.some((value) => value.checked) && !mainParentAllCheckedNow;

    const mainParentAllCheckedAfter = allAccessAfter.every((value) => {
        // console.log(value)
        return value.checked
    }
    );

    const mainParentAllIndeterminateAfter = allAccessAfter.some((value) => value.checked) && !mainParentAllCheckedAfter;

    //Will Section
    let will_access_now, will_after_death;
    // console.log(apiData)
    if (will) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                will_access_now = item.questions[0].will_access_now.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })

        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                will_after_death = item.questions[0].will_after_death.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })
    }
    // console.log(will_access_now)
    // console.log(will_after_death)
    const [values, handlers] = useListState(will_access_now);

    const [values1, handlers1] = useListState(will_after_death);

    const allChecked = values.every((value) => {
        // console.log(value)
        return value.checked
    }
    );
    const indeterminate = values.some((value) => value.checked) && !allChecked;


    const allChecked1 = values1.every((value) => value.checked);
    const indeterminate1 = values1.some((value) => value.checked) && !allChecked1;



    const itemsTestWill = values.map((value, index) => (


        <Box key={index} style={{ border: "1px solid #e0dfdfa6", marginBottom: 5, borderRadius: 4, }}>
            <Grid columns={12}>
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", alignItems: "center" })}>
                    <PermissionText title={value.question} />
                </Grid.Col>
                <Grid.Col span={2}>
                    <input type="hidden" value={value.question_id} />
                </Grid.Col>

                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={value.key}
                        checked={value.checked}
                        disabled={disabledNow}
                        ml={0}
                        name={"at_" + value.topic_id + "_now_" + value.question_id}
                        onChange={
                            (event) => {
                                handlers.setItemProp(index, 'checked', event.currentTarget.checked);
                                // handlersAllCheckBoxNow.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_now_" + value.question_id]: event.currentTarget.checked,
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)

                                handlers1.setItemProp(index, 'checked', event.currentTarget.checked)

                                // setAllHandlersNow(event)
                                // handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler Now
                                // handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler After

                            }
                        }
                    />
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={values1[index].key}
                        checked={values1[index].checked}
                        disabled={disabledAfter}
                        ml={0}
                        name={"at_" + values1[index].topic_id + "_afterdeath_" + values1[index].question_id}
                        onChange={
                            (event) => {
                                handlers1.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxAfter.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer({ ...answer, ...temp })
                                // setAllHandlersAfter(event)
                                // handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler After
                            }
                        }
                    />
                </Grid.Col>
            </Grid>
        </Box>


    ));


    //power_of_attorney Section
    let power_of_attorney_access_now, power_of_attorney_after_death;
    if (power) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000002') {
                power_of_attorney_access_now = item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })

        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000002') {
                power_of_attorney_after_death = item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })
    }

    const [valuesP, handlersP] = useListState(power_of_attorney_access_now);

    const [values1P, handlers1P] = useListState(power_of_attorney_after_death);

    const allCheckedP = valuesP.every((value) => {
        // console.log(value)
        return value.checked
    }
    );
    const indeterminateP = valuesP.some((value) => value.checked) && !allCheckedP;

    const allChecked1P = values1P.every((value) => value.checked);
    const indeterminate1P = values1P.some((value) => value.checked) && !allChecked1P;

    // console.log(valuesP, values1P)

    const itemsPoweroFAttorny = valuesP.map((value, index) => (


        <Box key={index} style={{ border: "1px solid #e0dfdfa6", marginBottom: 5, borderRadius: 4, }}>
            <Grid columns={12}>
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", alignItems: "center" })}>
                    <PermissionText title={value.question} />
                </Grid.Col>
                <Grid.Col span={2}>
                    <input type="hidden" value={value.question_id} />
                </Grid.Col>

                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={value.key}
                        checked={value.checked}
                        disabled={disabledNow}
                        ml={0}
                        name={"at_" + value.topic_id + "_now_" + value.question_id}
                        onChange={
                            (event) => {
                                handlersP.setItemProp(index, 'checked', event.currentTarget.checked);
                                // handlersAllCheckBoxNow.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_now_" + value.question_id]: event.currentTarget.checked,
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                                handlers1P.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler Now
                                // handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler After
                            }
                        }
                    />
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={values1P[index].key}
                        checked={values1P[index].checked}
                        disabled={disabledAfter}
                        ml={0}
                        name={"at_" + value.topic_id + "_afterdeath_" + values1P[index].question_id}
                        onChange={
                            (event) => {
                                handlers1P.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxAfter.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer({ ...answer, ...temp })

                                //   handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler After
                            }
                        }

                    />
                </Grid.Col>
            </Grid>
        </Box>


    ));


    //Living Will
    let living_will_access_now, living_will_after_death;
    if (living) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000004') {
                living_will_access_now = item.questions[0].living_will_access_now.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })

        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000004') {
                living_will_after_death = item.questions[0].living_will_after_death.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })
    }

    const [valuesL, handlersL] = useListState(living_will_access_now);

    const [values1L, handlers1L] = useListState(living_will_after_death);
    // console.log(valuesL)
    const allCheckedL = valuesL.every((value) => {
        // console.log(value)
        return value.checked
    }
    );
    const indeterminateL = valuesL.some((value) => value.checked) && !allCheckedL;

    const allChecked1L = values1L.every((value) => value.checked);
    const indeterminate1L = values1L.some((value) => value.checked) && !allChecked1L;

    // console.log(valuesL, values1L)

    const itemsLivingWill = valuesL.map((value, index) => (


        <Box key={index} style={{ border: "1px solid #e0dfdfa6", marginBottom: 5, borderRadius: 4, }}>
            <Grid columns={12}>
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", alignItems: "center" })}>
                    <PermissionText title={value.question} />
                </Grid.Col>
                <Grid.Col span={2}>
                    <input type="hidden" value={value.question_id} />
                </Grid.Col>

                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={value.key}
                        checked={value.checked}
                        disabled={disabledNow}
                        ml={0}
                        name={"at_" + value.topic_id + "_now_" + value.question_id}
                        onChange={
                            (event) => {
                                handlersL.setItemProp(index, 'checked', event.currentTarget.checked);
                                handlers1L.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxNow.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_now_" + value.question_id]: event.currentTarget.checked,
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                            }
                        }
                    />
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={values1L[index].key}
                        checked={values1L[index].checked}
                        disabled={disabledAfter}
                        ml={0}
                        name={"at_" + value.topic_id + "_afterdeath_" + values1L[index].question_id}
                        onChange={
                            (event) => {
                                handlers1L.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxAfter.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer({ ...answer, ...temp })
                            }
                        }

                    />
                </Grid.Col>
            </Grid>
        </Box>


    ));

    //Health Care Proxy Section
    let healthcare_proxy_access_now, healthcare_proxy_after_death
    if (health) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000005') {
                healthcare_proxy_access_now = item.questions[0].healthcare_proxy_access_now.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })

        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000005') {
                healthcare_proxy_after_death = item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })
    }

    const [valuesH, handlersH] = useListState(healthcare_proxy_access_now);

    const [values1H, handlers1H] = useListState(healthcare_proxy_after_death);



    const allCheckedH = valuesH.every((value) => {
        // console.log(value)
        return value.checked
    }
    );
    const indeterminateH = valuesH.some((value) => value.checked) && !allCheckedH;

    const allChecked1H = values1H.every((value) => value.checked);
    const indeterminate1H = values1H.some((value) => value.checked) && !allChecked1H;

    const itemsHealth = valuesH.map((value, index) => (


        <Box key={index} style={{ border: "1px solid #e0dfdfa6", marginBottom: 5, borderRadius: 4, }}>
            <Grid columns={12}>
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", alignItems: "center" })}>
                    <PermissionText title={value.question} />
                </Grid.Col>
                <Grid.Col span={2}>
                    <input type="hidden" value={value.question_id} />
                </Grid.Col>

                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={value.key}
                        checked={value.checked}
                        disabled={disabledNow}
                        ml={0}
                        name={"at_" + value.topic_id + "_now_" + value.question_id}
                        onChange={
                            (event) => {
                                handlersH.setItemProp(index, 'checked', event.currentTarget.checked);
                                handlers1H.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxNow.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_now_" + value.question_id]: event.currentTarget.checked,
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                            }
                        }


                    />
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={values1H[index].key}
                        checked={values1H[index].checked}
                        disabled={disabledAfter}
                        ml={0}
                        name={"at_" + value.topic_id + "_afterdeath_" + values1H[index].question_id}
                        onChange={
                            (event) => {
                                handlers1H.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxAfter.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer({ ...answer, ...temp })
                            }
                        }

                    />
                </Grid.Col>
            </Grid>
        </Box>


    ));




    //Trust Section
    let trusts_access_now, trusts_after_death
    if (trust) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000003') {
                trusts_access_now = item.questions[0].trusts_access_now.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })

        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000003') {
                trusts_after_death = item.questions[0].trusts_after_death.map((ele, key) => {
                    return ({ ...ele, key: randomId() })
                })
            }
        })
    }
    // console.log(trusts_access_now)
    const [valuesT, handlersT] = useListState(trusts_access_now);

    const [values1T, handlers1T] = useListState(trusts_after_death);

    const allCheckedT = valuesT.every((value) => {
        // console.log(value)
        return value.checked
    }
    );
    const indeterminateT = valuesT.some((value) => value.checked) && !allCheckedT;

    const allChecked1T = values1T.every((value) => value.checked);
    const indeterminate1T = values1T.some((value) => value.checked) && !allChecked1T;

    const itemsTrust = valuesT.map((value, index) => (


        <Box key={index} style={{ border: "1px solid #e0dfdfa6", marginBottom: 5, borderRadius: 4, }}>
            <Grid columns={12}>
                <Grid.Col span={6} sx={(theme) => ({ display: "flex", justifyContent: "left", alignItems: "center" })}>
                    <PermissionText title={value.question} />
                </Grid.Col>
                <Grid.Col span={2}>
                    <input type="hidden" value={value.question_id} />
                </Grid.Col>

                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={value.key}
                        checked={value.checked}
                        disabled={disabledNow}
                        ml={0}
                        name={"at_" + value.topic_id + "_now_" + value.question_id}
                        onChange={
                            (event) => {
                                handlersT.setItemProp(index, 'checked', event.currentTarget.checked);
                                handlers1T.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxNow.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_now_" + value.question_id]: event.currentTarget.checked,
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                            }
                        }


                    />
                </Grid.Col>
                <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                    <Checkbox
                        radius="xxs"
                        pt={5}
                        pb={5}
                        key={values1T[index].key}
                        checked={values1T[index].checked}
                        disabled={disabledAfter}
                        ml={0}
                        name={"at_" + value.topic_id + "_afterdeath_" + values1T[index].question_id}
                        onChange={
                            (event) => {
                                handlers1T.setItemProp(index, 'checked', event.currentTarget.checked)
                                // handlersAllCheckBoxAfter.setItemProp(index, 'checked', event.currentTarget.checked);
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    ["at_" + value.topic_id + "_afterdeath_" + value.question_id]: event.currentTarget.checked
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer({ ...answer, ...temp })
                            }
                        }

                    />
                </Grid.Col>
            </Grid>
        </Box>


    ));




    const allTopicItems = allAccessNow.map((value, index) => {
        // console.log(value.topic_id)

        if (value.topic_id === "200000001") {
            return (<Accordion.Item value={value.title} style={{ borderRadius: "4px" }}>
                <div className="accordianHeader">
                    <Grid columns={12} pl={16} pr={16}>
                        <Grid.Col span={6} pl={0}>
                            <Accordion.Control>
                                <PermissionHeading title={value.title} />
                            </Accordion.Control>
                        </Grid.Col>
                        <Grid.Col span={2}>{<input type="hidden" value={value.topic_id} />}</Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box >
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked}
                                    indeterminate={indeterminate}
                                    disabled={disabledNow}
                                    key={value.key}

                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {

                                        setAllCheckWillNow(event.currentTarget.checked) // set result now
                                        setAllCheckWillAfterHere(event)
                                        handlers.setState((current) =>                      //will handlers now
                                            current.map((value) => {
                                                return ({ ...value, checked: !allChecked })
                                            })
                                        )

                                        handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked) //Group Handler Now
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)

                                    }

                                    }
                                />
                            </Box>

                        </Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box>
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked1}
                                    indeterminate={indeterminate1}
                                    disabled={disabledAfter}
                                    // ref={refWillAfter}
                                    key={value.after.key}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckWillAfter(event.currentTarget.checked)
                                        handlers1.setState((current) =>
                                            current.map((value) => ({ ...value, checked: !allChecked1 }))
                                        )

                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)

                                    }

                                    }
                                />
                            </Box>
                        </Grid.Col>
                    </Grid>
                </div>
                <Accordion.Panel>
                    <div style={{ backgroundColor: "#fff", }}>
                        {itemsTestWill}
                    </div>

                </Accordion.Panel>
            </Accordion.Item>
            )
        } else if (value.topic_id === "200000002") {
            return (<Accordion.Item value={value.title} style={{ borderRadius: "4px" }}>
                <div className="accordianHeader">
                    <Grid columns={12} pl={16} pr={16}>
                        <Grid.Col span={6} pl={0}>
                            <Accordion.Control>
                                <PermissionHeading title={value.title} />
                            </Accordion.Control>
                        </Grid.Col>
                        <Grid.Col span={2}>{<input type="hidden" value={value.topic_id} />}</Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box >
                                <Checkbox
                                    radius="xxs"
                                    checked={allCheckedP}
                                    indeterminate={indeterminateP}
                                    disabled={disabledNow}
                                    key={value.key}
                                    // ref={refWillNow}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckPowerNow(event.currentTarget.checked)
                                        setAllCheckPOAAfterHere(event)
                                        handlersP.setState((current) =>
                                            current.map((value) => {
                                                return ({ ...value, checked: !allCheckedP })
                                            })
                                        )
                                        handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked)
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>

                        </Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box>
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked1P}
                                    indeterminate={indeterminate1P}
                                    disabled={disabledAfter}
                                    // ref={refWillAfter}
                                    key={value.after.key}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckPowerAfter(event.currentTarget.checked)
                                        handlers1P.setState((current) =>
                                            current.map((value) => ({ ...value, checked: !allChecked1P }))
                                        )
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>
                        </Grid.Col>
                    </Grid>
                </div>
                <Accordion.Panel>
                    <div style={{ backgroundColor: "#fff", }}>
                        {itemsPoweroFAttorny}
                    </div>

                </Accordion.Panel>
            </Accordion.Item>
            )
        } else if (value.topic_id === "200000004") {
            return (<Accordion.Item value={value.title} style={{ borderRadius: "4px" }}>
                <div className="accordianHeader">
                    <Grid columns={12} pl={16} pr={16}>
                        <Grid.Col span={6} pl={0}>
                            <Accordion.Control>
                                <PermissionHeading title={value.title} />
                            </Accordion.Control>
                        </Grid.Col>
                        <Grid.Col span={2}>{<input type="hidden" value={value.topic_id} />}</Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box >
                                <Checkbox
                                    radius="xxs"
                                    checked={allCheckedL}
                                    indeterminate={indeterminateL}
                                    disabled={disabledNow}
                                    key={value.key}
                                    // ref={refWillNow}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckLivingWillNow(event.currentTarget.checked)
                                        setAllCheckLivingWillAfterHere(event)
                                        handlersL.setState((current) =>
                                            current.map((value) => {
                                                return ({ ...value, checked: !allCheckedL })
                                            })
                                        )
                                        handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked)
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>

                        </Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box>
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked1L}
                                    indeterminate={indeterminate1L}
                                    disabled={disabledAfter}
                                    // ref={refWillAfter}
                                    key={value.after.key}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckLivingWillAfter(event.currentTarget.checked)
                                        handlers1L.setState((current) =>
                                            current.map((value) => ({ ...value, checked: !allChecked1L }))
                                        )
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>
                        </Grid.Col>
                    </Grid>
                </div>
                <Accordion.Panel>
                    <div style={{ backgroundColor: "#fff", }}>
                        {itemsLivingWill}
                    </div>

                </Accordion.Panel>
            </Accordion.Item>)
        } else if (value.topic_id === "200000005") {
            return (<Accordion.Item value={value.title} style={{ borderRadius: "4px" }}>
                <div className="accordianHeader">
                    <Grid columns={12} pl={16} pr={16}>
                        <Grid.Col span={6} pl={0}>
                            <Accordion.Control>
                                <PermissionHeading title={value.title} />
                            </Accordion.Control>
                        </Grid.Col>
                        <Grid.Col span={2}>{<input type="hidden" value={value.topic_id} />}</Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box >
                                <Checkbox
                                    radius="xxs"
                                    checked={allCheckedH}
                                    indeterminate={indeterminateH}
                                    disabled={disabledNow}
                                    key={value.key}
                                    // ref={refWillNow}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckHealthNow(event.currentTarget.checked)
                                        setAllCheckHealthAfterHere(event)
                                        handlersH.setState((current) =>
                                            current.map((value) => {
                                                return ({ ...value, checked: !allCheckedH })
                                            })
                                        )
                                        handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked)
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>

                        </Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box>
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked1H}
                                    indeterminate={indeterminate1H}
                                    disabled={disabledAfter}
                                    // ref={refWillAfter}
                                    key={value.after.key}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckHealthAfter(event.currentTarget.checked)
                                        handlers1H.setState((current) =>
                                            current.map((value) => ({ ...value, checked: !allChecked1H }))
                                        )
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>
                        </Grid.Col>
                    </Grid>
                </div>
                <Accordion.Panel>
                    <div style={{ backgroundColor: "#fff", }}>
                        {itemsHealth}
                    </div>

                </Accordion.Panel>
            </Accordion.Item>
            )
        } else if (value.topic_id === "200000003") {
            return (<Accordion.Item value={value.title} style={{ borderRadius: "4px" }}>
                <div className="accordianHeader">
                    <Grid columns={12} pl={16} pr={16}>
                        <Grid.Col span={6} pl={0}>
                            <Accordion.Control>
                                <PermissionHeading title={value.title} />
                            </Accordion.Control>
                        </Grid.Col>
                        <Grid.Col span={2}>{<input type="hidden" value={value.topic_id} />}</Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box >
                                <Checkbox
                                    radius="xxs"
                                    checked={allCheckedT}
                                    indeterminate={indeterminateT}
                                    disabled={disabledNow}
                                    key={value.key}
                                    // ref={refWillNow}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckTrustNow(event.currentTarget.checked)
                                        setAllCheckTrustAfterHere(event)
                                        handlersT.setState((current) =>
                                            current.map((value) => {
                                                return ({ ...value, checked: !allCheckedT })
                                            })
                                        )

                                        handlerAllNow.setItemProp(index, 'checked', event.currentTarget.checked)
                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>

                        </Grid.Col>
                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })} >
                            <Box>
                                <Checkbox
                                    radius="xxs"
                                    checked={allChecked1T}
                                    indeterminate={indeterminate1T}
                                    disabled={disabledAfter}
                                    // ref={refWillAfter}
                                    key={value.after.key}
                                    // label="Receive all notifications"
                                    transitionDuration={0}
                                    onChange={(event) => {
                                        setAllCheckTrustAfter(event.currentTarget.checked)
                                        handlers1T.setState((current) =>
                                            current.map((value) => ({ ...value, checked: !allChecked1T }))
                                        )

                                        handlerAllAfter.setItemProp(index, 'checked', event.currentTarget.checked)
                                    }

                                    }
                                />
                            </Box>
                        </Grid.Col>
                    </Grid>
                </div>
                <Accordion.Panel>
                    <div style={{ backgroundColor: "#fff", }}>
                        {itemsTrust}
                    </div>

                </Accordion.Panel>
            </Accordion.Item>)
        }

    })



    let data = {
        title: "Set permission for " + name[0].firstName + " " + name[0].lastName,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        label: "Manage access for",
        placeholder: "select one",
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
    useEffect(() => {
        const url = BASE_URL + "/whitney/public/api/v3/set-permission";

        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        // console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['key_contact_id'] = key_contact_id
        // console.log(data)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else if (response.data.status === "success") {
                // console.log(response.data.table_data[0])
                setKeyContactData(response.data.table_data[0])
                setLoading(false)
            } else {
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })
    }, [])



    function getFormValue() {
        const url = BASE_URL + "/whitney/public/api/v3/set-permission";
        let data = {}
        data = {
            'uuid': userData.uuid,
            "access_token": userData.access_token,
            "key_contact_id": key_contact_id,
            "UpdatePermissions": "Update",
            "write_permission": writtenPermission,
            ...answer
        }
        console.log(data)
        setLoading(true)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            // console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else if (response.data.status === "success") {
                // console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
                // navigate('/accessControl');
                navigate('/editKeyContact', { state: { key_contact_id: key_contact_id, selectedKeyContact: selectedKeyContact } });

            } else if (response.data.status === "error") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCircleX size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
            }

        }).catch((error) => {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: error.status,
                message: error.message,
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })
    }
    //will Section
    function setAllCheckWillNow(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                item.questions[0].will_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    //
    function setAllCheckWillAfterHere(event) {
        console.log(event)
        let val = event.currentTarget.checked
        handlers1.setState((current) => current.map((value) => ({ ...value, checked: val })))  //after Will
        // handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))
        setAllCheckWillAfter(val)
    }
    function setAllCheckWillAfter(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                item.questions[0].will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    //Power Of Attorny Section
    function setAllCheckPowerNow(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000002') {
                item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    function setAllCheckPOAAfterHere(event) {
        console.log(event)
        let val = event.currentTarget.checked
        handlers1P.setState((current) => current.map((value) => ({ ...value, checked: val })))  //after Will
        // handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))
        setAllCheckPowerAfter(val)
    }
    function setAllCheckPowerAfter(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000002') {
                item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    //Living Will Section
    function setAllCheckLivingWillNow(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000004') {
                item.questions[0].living_will_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            }
        })

    }
    function setAllCheckLivingWillAfterHere(event) {
        console.log(event)
        let val = event.currentTarget.checked
        handlers1L.setState((current) => current.map((value) => ({ ...value, checked: val })))  //after Will
        // handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))
        setAllCheckLivingWillAfter(val)
    }
    function setAllCheckLivingWillAfter(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000004') {
                item.questions[0].living_will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    //Health Section
    function setAllCheckHealthNow(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000005') {
                item.questions[0].healthcare_proxy_access_now.map((ele, key) => {


                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    function setAllCheckHealthAfterHere(event) {
        console.log(event)
        let val = event.currentTarget.checked
        handlers1H.setState((current) => current.map((value) => ({ ...value, checked: val })))  //after Will
        // handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))
        setAllCheckHealthAfter(val)
    }
    function setAllCheckHealthAfter(val) {
        apiData.topic_data.map((item) => {
            // console.log(item)
            if (item.topic_id === '200000005') {
                item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            }
        })
    }

    //Trust Section
    function setAllCheckTrustNow(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000003') {
                item.questions[0].trusts_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            }
        })
    }
    function setAllCheckTrustAfterHere(event) {
        console.log(event)
        let val = event.currentTarget.checked
        handlers1T.setState((current) => current.map((value) => ({ ...value, checked: val })))  //after Will
        // handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))
        setAllCheckTrustAfter(val)
    }
    function setAllCheckTrustAfter(val) {
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000003') {
                item.questions[0].trusts_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            }
        })
    }

    function selectAllCheckNow(event) {
        // console.log(event)
        let val = event.currentTarget.checked
        handlerAllAfter.setState((current) =>
            current.map((value) => ({ ...value, checked: val }))
        )
        // Will Section
        handlers.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
        //Power of Attorny Section
        handlersP.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
        //Living Will Section
        handlersL.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
        //Health  Section
        handlersH.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));
        //Trust  Section
        handlersT.setState((current) => current.map((value) => { return ({ ...value, checked: val }) }));


        //Will Section AfterDeath
        handlers1.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Power of Attorny Section AfterDeath
        handlers1P.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Living Will Section AfterDeath
        handlers1L.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Health Section AfterDeath
        handlers1H.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Trust Section AfterDeath
        handlers1T.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));

        /*set req Answer Values*/
        // Will Section
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                item.questions[0].will_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })
            } else if (item.topic_id === '200000002') {
                item.questions[0].power_of_attorney_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000004') {
                item.questions[0].living_will_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000005') {
                item.questions[0].healthcare_proxy_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000003') {
                item.questions[0].trusts_access_now.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_now_" + ele.question_id]: val
                    }))
                })

            }
        })

        /*set Req Answer */
        //After Death
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                item.questions[0].will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            } else if (item.topic_id === '200000002') {
                item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000004') {
                item.questions[0].living_will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000005') {
                item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000003') {
                item.questions[0].trusts_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            }
        })

    }
    function selectAllCheckAfter(event) {
        // console.log(allChecked1)
        let val = event.currentTarget.checked
        // console.log(val)
        //Will Section
        handlers1.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Power of Attorny Section
        handlers1P.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Living Will Section
        handlers1L.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Health Section
        handlers1H.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));
        //Trust Section
        handlers1T.setState((current) =>
            current.map((value) => ({ ...value, checked: val })));

        /*set Req Answer */
        //Will
        apiData.topic_data.map((item) => {
            if (item.topic_id === '200000001') {
                item.questions[0].will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })
            } else if (item.topic_id === '200000002') {
                item.questions[0].power_of_attorney_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000004') {
                item.questions[0].living_will_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000005') {
                item.questions[0].healthcare_proxy_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            } else if (item.topic_id === '200000003') {
                item.questions[0].trusts_after_death.map((ele, key) => {
                    setAnswer((state) => ({
                        ...state, ["at_" + ele.topic_id + "_afterdeath_" + ele.question_id]: val
                    }))
                })

            }
        })

    }
    function setAllHandlersNow(event) {

        let val = event.currentTarget.checked
        // console.log(val)
        handlerAllNow.setState((current) => current.map((value) => ({ ...value, checked: val })))
        console.log(allAccessNow)
        console.log(answer)
    }
    function setAllHandlersAfter(event) {

        let val = event.currentTarget.checked
        console.log(allAccessAfter)
        console.log(answer)
        handlerAllAfter.setState((current) => current.map((value) => ({ ...value, checked: val })))

    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    return (
        <>
            <FullScreenModal fullOpened={fullOpened} setFullOpened={setFullOpened} questionData={setQuestionData} getFormValue={getFormValue} answer={answer} />
            <WarningModal modalMessage={confirmMessage} warnOpned={warnOpned} setWarnOpened={setWarnOpened} />
            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
                {/* <FlowTitle title={data.title} description={data.description} /> */}
                <FlowTitle title={data.title} description={"Manage what information this Trusted Person can see and when they can see it by placing checks on the column. For items you do not check, the Trusted Person will never be able to see them."} />
                <Grid columns={12} pt={20}>
                    <Grid.Col span={8} pl={0} style={{ display: "flex" }}>
                        <Text
                            sx={(theme) => ({
                                fontSize: theme.fontSizes.lg,
                                fontFamily: theme.fontFamily[1],
                                color: theme.colors.primary[0],
                                fontWeight: 700,
                                marginRight: 10,
                                [`@media (max-width: 1280px)`]: {
                                    fontSize: theme.fontSizes.sm,
                                },
                                [`@media (max-width: 1024px)`]: {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}
                        >Do you want to give write permission to this trusted person for the selected section(s)/topic(s) below?
                        </Text>
                        <Tooltip
                            multiline
                            width={300}
                            radius="xxs"
                            transition="fade"
                            label="Be very selective to whom you are giving write permission. A Trusted Person with write permissions can change anything you have written or delete documents. Only provide these permissions if you are unable to fill the Information Binder yourself.">
                            <ActionIcon variant="subtle"
                                sx={(theme) => ({
                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[2],
                                        color: theme.colors.buttonColor[9]
                                    },
                                })}


                            >
                                <BsInfoCircle size={16} />
                            </ActionIcon>
                        </Tooltip>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Box style={{ paddingLeft: 8, paddingRight: 20 }}>
                            <Radio.Group
                                value={writtenPermission}
                                // onChange={setWrittenPermission}
                                onChange={(event) => {
                                    writtenPermissionPopupModal(event)
                                }}
                                // style={{display:"flex" ,justifyContent:"space-around"}}
                                className="setRadioBtn"
                            >

                                <Radio
                                    value="yes"
                                    label="Yes"
                                />

                                <Radio
                                    value="no"
                                    label="No"
                                />


                            </Radio.Group>
                        </Box>
                    </Grid.Col>

                </Grid>
                <Box pt="md">
                    <TableHeader2 leftTitle="Category" midTitle="Now" rightTitle="After death" />
                </Box>

                {keyContactData &&
                    <>

                        <Accordion variant="separated" chevronPosition="left" defaultValue={keyContactData.category_name} transitionDuration={1000} className="accordianBody" >
                            <Accordion.Item value={keyContactData.category_name} style={{ borderRadius: "0px 0px 4px 4px" }}>
                                <div className="mainTopic" style={{ paddingLeft: 24, paddingRight: 32 }}>
                                    <Grid columns={12}>
                                        <Grid.Col span={6} pl={0}>
                                            <Accordion.Control><PermissionHeading title={keyContactData.category_name} /></Accordion.Control>
                                        </Grid.Col>
                                        <Grid.Col span={2}>
                                            <input type="hidden" value={keyContactData.category_id} />
                                        </Grid.Col>
                                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "3.5%" })}>

                                            <Checkbox
                                                radius="xxs"

                                                checked={mainParentAllCheckedNow}
                                                indeterminate={mainParentAllIndeterminateNow}
                                                disabled={disabledNow}
                                                transitionDuration={0}
                                                // ref={setAllCheckBoxNow}
                                                onChange={(event) => {
                                                    console.log(event)
                                                    selectAllCheckNow(event)
                                                    handlerAllNow.setState((current) =>
                                                        current.map((value) => ({ ...value, checked: !mainParentAllCheckedNow }))
                                                    )


                                                }} />
                                            <Box style={{paddingLeft:10}}>
                                                <Tooltip
                                                    multiline
                                                    width={300}
                                                    radius="xxs"
                                                    transition="fade"
                                                    label="click this box to toggle through options">
                                                    <ActionIcon variant="subtle"
                                                        sx={(theme) => ({
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[2],
                                                                color: theme.colors.buttonColor[9]
                                                            },
                                                        })}


                                                    >
                                                        <BsInfoCircle size={16} />
                                                    </ActionIcon>
                                                </Tooltip>
                                            </Box>
                                        </Grid.Col>
                                        <Grid.Col span={2} sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}>
                                            <Checkbox
                                                disabled={disabledAfter}
                                                radius="xxs"
                                                checked={mainParentAllCheckedAfter}
                                                indeterminate={mainParentAllIndeterminateAfter}
                                                // ref={setAllCheckBoxAfter}
                                                transitionDuration={0}
                                                onChange={(event) => {
                                                    selectAllCheckAfter(event)
                                                    handlerAllAfter.setState((current) =>
                                                        current.map((value) => ({ ...value, checked: !mainParentAllCheckedAfter }))
                                                    )

                                                }} />
                                        </Grid.Col>
                                    </Grid>
                                </div>

                                <Accordion.Panel>
                                    <Accordion variant="separated" chevronPosition="left" defaultValue={keyContactData.topic_data[0].title} transitionDuration={1000} >
                                        {keyContactData &&
                                            <>

                                                {allTopicItems}
                                            </>



                                        }
                                    </Accordion>

                                </Accordion.Panel>

                            </Accordion.Item>
                        </Accordion>
                    </>
                }

                <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>
                {/* </div> */}
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    navigate('/accessControl');
                                }}
                            >
                                Back
                            </Button>
                        </Affix>

                        <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: theme.colors.primary[0],
                                    height: 50,
                                    fontSize: theme.fontSizes.xxl,
                                    fontWeight: 300,
                                    fontFamily: theme.fontFamily[0],
                                    '&:hover': {
                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                    }
                                })}
                                onClick={() => {

                                    // getFormValue()
                                    setFullOpened(true)

                                }}
                            >
                                Set access for {name[0].firstName + " " + name[0].lastName}
                            </Button>
                        </Affix>

                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            </div>
        </>
    )
}

export default EditAccessPermission;