import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Image, NavLink, Text } from "@mantine/core";
import { IconBooks, IconChevronRight, IconFingerprint, IconGauge, IconHome, IconUserPlus } from "@tabler/icons";
import { BiBook, BiBookOpen, BiUser, BiUserCheck } from "react-icons/bi";
import { BsCircleFill, BsHandIndex } from "react-icons/bs";
import { Users } from "tabler-icons-react";
import { UserBadge, UserName, WelcomeText } from "./textLabel";


const SideBar = ({ navigate, setLoggedIn, userLoginData, profileImage, opened, setOpened, dynamicMenu, setDynamicMenu }) => {
    // console.log(userLoginData)
    const location = useLocation();

    // console.log(location)
    const [active, setActive] = useState("");
    const data = [


        {
            icon: BiUser,
            label: 'Estate Planning',
            to: "/essentialDocs",
            component: Link
        },
        {
            icon: BiBook,
            label: 'Personal Info',
            to: "/personalInfo",
            component: Link
        },
        {
            icon: BiUserCheck,
            label: 'Key Contacts',
            to: "/keyContact",
            component: Link

        },
        {
            icon: BsHandIndex,
            label: 'Manage Access',
            to: "/accessControl",
            component: Link

        },
        {
            icon: BsHandIndex,
            label: 'My Roles',
            to: "/myRoles",
            component: Link

        },

    ];

    useEffect(() => {
        console.log(location)
        const activeLabel = data.filter(function (el) {
            if (el.to === location.pathname) {
                return el.label
            }
        }
        );
        if (location?.pathname === '/infoBinder' || location?.pathname === '/') {
            setActive("Home")
        } else if (location.pathname === "/accept-invitation/") {
            console.log(location)
            // setActive("My Roles")
            navigate('/myRoles')
            setActive("My Roles")
        } else if (location?.pathname === "/userTopicReview") {
            console.log("line72", location?.pathname)
            setActive("Estate Planning")
        }
        else if (activeLabel.length > 0) {
            setActive(activeLabel[0].label)
        } else {
            setActive("Home")
        }

    }, [location])

    const items = data.map((item, index) => (
        <NavLink
            key={item.label}
            active={active === item.label ? true : false}
            label={item.label}
            description={item.description}
            rightSection={item.rightSection}
            icon={<item.icon size={16} stroke={1.5} className="menuButtonText" />}
            onClick={
                () => {
                    setActive(item.label)
                    setOpened((o) => !o)
                }
            }
            to={item.to}
            component={item.component}
            sx={(theme) => ({
                fontWeight: 300,
                fontFamily: theme.fontFamily[1],
                fontSize: theme.fontSizes.md,
                [`@media (max-width: 1280px)`]: {
                    fontSize: theme.fontSizes.sm,
                    paddingTop: 6,
                    paddingBottom: 6
                },
            })}

        />
    ));

    return (
        <>
            <Box style={{ paddingBottom: 10, borderBottom: "1px solid #def1fd" }} className="userInfo">

                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ paddingRight: 8, paddingLeft: 20 }}>
                        <Box
                            sx={(theme) => ({
                                cursor: "pointer",
                                '&:hover': {

                                }

                            })}
                            onClick={() => {
                                //  console.log("click")
                                navigate('/personalInfo')
                            }}
                        >
                            <div className="profile-image">
                                {profileImage ? <Image

                                    src={userLoginData.profile_image}
                                    alt="image"
                                    width={64}
                                    height={64}
                                /> : <Avatar radius="50%" size={64} />}
                            </div>
                        </Box>
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <WelcomeText title="WELCOME" />
                        <UserName firstName={userLoginData.first_name} lastName={userLoginData.last_name} />
                    </div>
                </div>
                <div style={{ paddingLeft: 24, marginTop: 10 }}>
                    {dynamicMenu.menuStatus === false && <UserBadge title="Testator" />}
                    {dynamicMenu.menuStatus === true && <UserBadge title="Trusted person view" />}
                </div>


            </Box>
            <Box style={{ paddingTop: 5 }}>

                <NavLink
                    sx={(theme) => ({
                        fontWeight: 300,
                        fontFamily: theme.fontFamily[1],
                        fontSize: theme.fontSizes.md,
                        [`@media (max-width: 1280px)`]: {
                            fontSize: theme.fontSizes.sm,
                            paddingTop: 6,
                            paddingBottom: 6
                        },
                    })}
                    label="Home"
                    icon={<IconHome size={24} stroke={1.5} className="menuButtonText" />}
                    component={Link}
                    // variant="link"
                    to="/infoBinder"
                    childrenOffset={28}
                    active={active === "Home" ? true : false}
                    onClick={() => setActive("Home")}
                />
                {dynamicMenu.menuStatus === true &&
                    <NavLink
                        sx={(theme) => ({
                            fontWeight: 300,
                            fontFamily: theme.fontFamily[1],
                            fontSize: theme.fontSizes.md,
                            [`@media (max-width: 1280px)`]: {
                                fontSize: theme.fontSizes.sm,
                                paddingTop: 6,
                                paddingBottom: 6
                            },
                        })}
                        label={dynamicMenu.testatorName + "'s Information Binder"}
                        icon={<BiBookOpen size={24} stroke={1.5} className="menuButtonText" />}
                        // component={Link}
                        // variant="link"
                        // to="/myRoles"
                        childrenOffset={28}
                        // active={active === dynamicMenu.testatorName + "'s Information Binder" ? true : false}
                        // onClick={() => setActive(dynamicMenu.testatorName + "'s Information Binder")}
                        defaultOpened={true}
                    >
                        <NavLink
                            label={"Estate Planning"}
                            icon={<BiUser size={16} stroke={1.5} className="menuButtonText" />}
                            active={active === "Estate Planning" ? true : false}
                            onClick={() => {
                                console.log(dynamicMenu)
                                navigate('/userTopicReview', { state: location.state  })
                                setActive("Estate Planning")
                            }}

                        />
                    </NavLink>
                }
                {dynamicMenu.menuStatus === false && <NavLink
                    label="My Information Binder"
                    icon={<BiBookOpen size={24} stroke={1.5} className="menuButtonText" />}
                    childrenOffset={28}
                    sx={(theme) => ({
                        fontWeight: 300,
                        fontFamily: theme.fontFamily[1],
                        fontSize: theme.fontSizes.md,
                        [`@media (max-width: 1280px)`]: {
                            fontSize: theme.fontSizes.sm,
                            paddingTop: 6,
                            paddingBottom: 6
                        },
                    })}
                    defaultOpened={true}
                >
                    {items}
                </NavLink>
                }
            </Box>
        </>
    )
}

export default SideBar; 