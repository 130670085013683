import { Affix, Button, Grid, LoadingOverlay, Text } from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import FlowTitle from "../Component/flowTitle";
import FooterWill from "../Component/footer2";
import InputControlAccess from "../Component/inputControlAccess";
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { BASE_URL } from "../apis/flowReviewApis/global";

const EditKeyContact = ({ navigate, setLoggedIn }) => {
    const { state } = useLocation();
    const { key_contact_id, selectedKeyContact, keyContactEditOnly, keyContactAllData } = state;
    // console.log(key_contact_id, selectedKeyContact, keyContactEditOnly)
    const [loading, setLoading] = useState(true)
    const [keyContactData, setKeyContactData] = useState({})
    const [checkApiCall, setCheckApiCall] = useState(false)
    const [prefill, setPrefill] = useState()
    const [answer, setAnswer] = useState()
    const [editMode, setEditMode] = useState(false);
    const [editKeyContactOnly, SetEditKeyContactOnly] = useState(keyContactEditOnly ? true : false)
    const [formError, setFormError] = useState();



    let data = {
        title: "Invite your trusted person",
        description: "Please confirm the Trusted Person’s contact information, especially their email address, before inviting them to access your documents.",
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));

    useEffect(() => {
        const url = BASE_URL + "/whitney/public/api/v1/my-key-contacts/view";


        // console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['key_contact_id'] = key_contact_id

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === "success") {
                console.log(response.data)
                setKeyContactData(response.data)
                setCheckApiCall(true);
                setEditMode(true)
                let temp = response.data
                let prefillObj = {}
                let ansObj = {}
                temp.inputList.map((item, index) => {
                    if (item?.prefill != undefined) {
                        prefillObj = { ...prefillObj, [item.label]: item.prefill }
                        ansObj = { ...ansObj, [item?.id]: item.prefill }
                    }
                })
                // console.log("answer Object: ", ansObj, prefillObj)
                setPrefill(prefillObj)
                setAnswer(ansObj)
                setLoading(false)
            } else {

            }

        }).catch((error) => {
            alert(error)
        })
    }, [])



    //update Key Contact 
    function updateKeyContact() {
        setLoading(true)
        const url = BASE_URL + "/whitney/public/api/v1/my-key-contacts/view";
        // console.log(answer)
        let data = {}
        data = {
            'uuid': userData.uuid,
            "access_token": userData.access_token,
            "key_contact_id": key_contact_id,
            "Save": "yes",
            ...answer
        }
        console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "success") {
                console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
                editKeyContactOnly ? navigate('/accessControl')
                    : navigate('/messageToKeyContact', { state: { key_contact_id: key_contact_id } });

            } else if (response.data.status === "error") {
             
                setFormError(response.data.message)
                setLoading(false)
            }

        }).catch((error) => {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: error.status,
                message: error.message,
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })


    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    return (
        <>
            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
                {!loading &&
                    <div>
                        <Grid columns={24}>
                            <Grid.Col span={24} pl={0}>
                                <div style={{ 'minWidth': '100%' }}>
                                    <FlowTitle title={data.title} description={data.description} />
                                </div>
                            </Grid.Col>
                        </Grid>
                        {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}
                        <Grid columns={24}>
                           {editMode && checkApiCall && keyContactData.inputList.map((item, key) => {
                                return (
                                    <InputControlAccess
                                        data={item}
                                        keyContactData={{ get: () => { return keyContactData }, set: selectedKeyContact }}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                        prefill={prefill}
                                        setPrefill={setPrefill}
                                        key={key}
                                        editFileUpload={true}
                                        selectedKeyContact={selectedKeyContact}


                                        setLoggedIn={setLoggedIn}
                                        navigate={setLoggedIn}
                                    // editMode={editMode}    // for benif and executor files
                                    // handleButtonClick={updateKeyContact}
                                    />)
                            })
                            }
                            <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>
                        </Grid>
                    </div>
                }
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    navigate('/accessControl');
                                }}
                            >
                                Back
                            </Button>
                        </Affix>

                        <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: theme.colors.primary[0],
                                    height: 50,
                                    fontSize: theme.fontSizes.xxl,
                                    fontWeight: 300,
                                    fontFamily: theme.fontFamily[0],
                                    '&:hover': {
                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                    }
                                })}
                                onClick={() => {

                                    updateKeyContact()

                                }}
                            >
                                Continue
                            </Button>
                        </Affix>

                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>
            </div>
        </>
    )
}

export default EditKeyContact;