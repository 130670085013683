import React, { useState, useEffect } from "react";
import axios from "axios";
import { ActionIcon} from '@mantine/core';
import {
    Box,
    LoadingOverlay,
    useMantineTheme,
    Grid
} from '@mantine/core';



import Footer from "../Component/footer";
import '../Custom.css';
import { ConfirmationModal } from "../Component/modal";
import { BASE_URL } from "../apis/flowReviewApis/global";
import FlowTitle from "../Component/flowTitle";
import { CardButton, CardLeftText, TableHeader } from "../Component/TableComponent";
import { EditIcon, TrashIcon } from "../Component/IconComponent";
import { IconCheck, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";


const EssentialDocs = ({ navigate, setLoggedIn }) => {
    const [pageHeader, setPageHeader] = useState("")
    const [mytopics, setMytopics] = useState("");
    const [othertopics, setOthertopics] = useState("");
    const [loading, setLoading] = useState(true)

    const [modalStatus, setModalStatus] = useState(false)

    const [confirmMessage, setConfirmMessage] = useState({})
    const [confirmModalStatus, setConfirmModalStatus] = useState(false)
    const theme = useMantineTheme();
   



    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    var bodyFormData = new FormData();
    bodyFormData.append('uuid', userData.uuid);
    bodyFormData.append('access_token', userData.access_token);

    //click on add button and add process into Essential Documents list
    const actionAdd = (topicId) => {
        // console.log(topicId)
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopic/add/" + topicId,
            data: bodyFormData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                console.log(response)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                } else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                }else {
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                    setMytopics(response.data.mytopics);
                    setOthertopics(response.data.othertopics);
                }

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    const deleteTopic = (topicId) => {
        setModalStatus(!modalStatus)
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopic/delete/" + topicId,
            data: bodyFormData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                } else {
                    console.log(response)
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                    setMytopics(response.data.mytopics);
                    setOthertopics(response.data.othertopics);
                    // setCounter(0)
                }

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }
    //delete item  from Essential Documents list and add it to choose more option list
    const actionDelete = (topicId, topicName) => {

        let temp = {
            title: "Are you sure you want to delete topic " + " " + '"' + topicName + '"',
            content: "Deleting this will result in permanent loss of data topic " + '"' + topicName + '"',
            args: [topicId],
            deleteAction: deleteTopic

        }
        setModalStatus(true)
        setConfirmMessage(temp)
        setConfirmModalStatus(!confirmModalStatus)

    }


    // action start on click start button

    const actionStartWill = (topicId) => {
        // console.log("Start btn clicked with ID: ", topicId)
        if (topicId == "200000001") {
            navigate('/essentialDocs/willFlow')
        } else if (topicId == "200000002") {
            navigate('/essentialDocs/powerOfAttorney')
        } else if (topicId == "200000003") {
            navigate('/essentialDocs/trust')
        } else if (topicId == "200000004") {
            navigate('/essentialDocs/livingWill')
        } else if (topicId == "200000005") {
            navigate('/essentialDocs/healthcareProxy')
        } else {
            console.log("Default case, unrecognized id passed")
        }
    }


    // fetch api data
    useEffect(() => {
        setLoading(true)
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopics",
            data: bodyFormData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                console.log(response)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)

                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                } else {
                    setPageHeader(response.data)
                    setMytopics(response.data.mytopics);
                    setOthertopics(response.data.othertopics);
                    setLoading(false)
                }


            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }, [])

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }





    return (
        <Box >
            <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />

            <div >
                <FlowTitle title={pageHeader.title} description={pageHeader.description} />
                <TableHeader leftTitle="Topic" rightTitle="" />
                {/* {display myTopic data} */}
                <div className="cardBorder" >
                    {mytopics && mytopics.map((item, index) => {
                        return (
                            <Grid columns={24} className="cardBorderRad cardWidth" key={index}  >
                                <Grid.Col xs={22} sm={23} md={23} lg={23} xl={23} className="innerCardWidth" >
                                    <Box
                                        onClick={() => {
                                            actionStartWill(item.topic_id)
                                        }}

                                        sx={(theme) => ({
                                            cursor: 'pointer',

                                            '&:hover': {

                                            },
                                        })}
                                    >
                                        <Grid columns={24}>
                                            <Grid.Col xs={16} sm={18} md={18} lg={18} xl={20} pl="zx" className="textWidth" sx={(theme) => ({
                                                paddingTop: 0,
                                                '@media (max-width: 1280px)': {
                                                    padding: "0px",
                                                },
                                                '@media (max-width: 1024px)': {
                                                    padding: "0px",
                                                },
                                            })}>
                                                <CardLeftText name={item.name} content={item.content} />
                                            </Grid.Col>
                                            <Grid.Col xs={8} sm={6} md={6} lg={6} xl={4} className="actionBtnWidth">
                                                <Box style={{ display: 'flex', alignItems: "center", justifyContent: "right", height: '100%' }}>
                                                    <CardButton

                                                        btnAction={actionStartWill}
                                                        btnText={item.action[0].text}
                                                        funArgs={item.topic_id}
                                                    />

                                                </Box>
                                            </Grid.Col>
                                        </Grid>


                                    </Box>
                                </Grid.Col>
                                <Grid.Col xs={2} sm={1} md={1} lg={1} xl={1} className="trashBtnWidth">
                                    <Box style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                        <ActionIcon
                                            onClick={() => actionDelete(item.topic_id, item.name)}

                                        >
                                            <TrashIcon />
                                        </ActionIcon>
                                    </Box>
                                </Grid.Col>
                            </Grid>

                        )
                    })}
                </div>
                <div className="cardBorder">
                    {mytopics && mytopics.length === 0 &&

                        <Grid className="cardBorderRad " >

                            <Grid.Col span={12}>
                                <Box sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center" })}>
                                    No topic added yet, click below on add button.
                                </Box>
                            </Grid.Col>
                        </Grid>


                    }
                </div>



                <Grid >
                    <Grid.Col span={12} pl="zx" sx={(theme) => ({
                        marginTop: 10,
                        '@media (max-width: 1280px)': {
                            marginTop: 10,
                        },
                        '@media (max-width: 1024px)': {
                            marginTop: 10,
                        },
                    })}>
                        <FlowTitle title={pageHeader.other_title} description={pageHeader.other_description} />
                    </Grid.Col>
                </Grid>
                <TableHeader leftTitle="Topic" rightTitle="" />
                <div className="cardBorder">
                    {othertopics && othertopics.map((item, index) => {
                        return (

                            <Grid className="cardBorderRad cardWidth" key={index} columns={24}>
                                <Box
                                    onClick={() => {
                                        actionAdd(item.topic_id)
                                    }}

                                    sx={(theme) => ({
                                        cursor: 'pointer',
                                        width: "100%",
                                        display: "flex",
                                        '&:hover': {

                                        },
                                    })}
                                >
                                    <Grid.Col xs={22} sm={23} md={23} lg={23} xl={23} className="innerCardWidth">

                                        <Grid columns={24}>
                                            <Grid.Col xs={12} sm={12} md={16} lg={18} xl={20} pl="zx" className="textWidth">
                                                <CardLeftText name={item.name} content={item.content} />
                                            </Grid.Col>
                                            <Grid.Col xs={12} sm={12} md={8} lg={6} xl={4} style={{ margin: 0 }} className="actionBtnWidth">
                                                <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                                    <CardButton
                                                        btnIcon={<EditIcon />}
                                                        btnAction={actionAdd}
                                                        btnText={item.action.text}
                                                        funArgs={item.topic_id}
                                                    />
                                                </Box>
                                            </Grid.Col>
                                        </Grid>

                                    </Grid.Col>
                                    <Grid.Col xs={2} sm={1} md={1} lg={1} xl={1} className="trashBtnWidth" >

                                    </Grid.Col>
                                </Box>
                            </Grid>

                        )
                    })}
                </div>
                <div className="cardBorder">
                    {othertopics && othertopics.length === 0 &&

                        <Grid className="cardBorderRad cardWidth" >

                            <Grid.Col span={12}>
                                <Box sx={(theme) => ({
                                    color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center", '@media (max-width: 1280px)': {
                                        fontSize: theme.fontSizes.md, 
                                    },
                                    '@media (max-width: 1024px)': {
                                        fontSize: theme.fontSizes.md, 
                                    },
                                })}>
                                    No more topic to add.
                                </Box>
                            </Grid.Col>
                        </Grid>


                    }
                </div>

                <Grid>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}>1</div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}>1</div>
                    </Grid.Col>
                </Grid>

                <Footer navigate={navigate}/>



            </div>
        </Box>
    )
}


export default EssentialDocs;