import { Grid, LoadingOverlay, Text, Box, Button, Group } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsExclamationCircle, BsFillEyeFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../apis/flowReviewApis/global";
import FlowTitle from "../Component/flowTitle";
import Footer from "../Component/footer";
import { CardLeftText, TableHeader } from "../Component/TableComponent";
import '../Custom.css'

const UserTopicReview = ({ setCounter, navigate, setLoggedIn }) => {
    const { state } = useLocation();
    // const { contactDetail } = state;
    // console.log(contactDetail)
    const [loading, setLoading] = useState(true);
    const [mytopics, setMytopics] = useState("");
    const [pageHeader, setPageHeader] = useState("")
    const [topBar, SetTopBar] = useState(state.orangeBar ? true : false)
    const [topTitleDesc, SetTopTitleDesc] = useState(state.orangeBar ? false : true)


    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let data = {
        'uuid': userData.uuid,
        'access_token': userData.access_token,
        'testator_id': state.testatorId
    }
    console.log(data)
    var formData = new FormData();
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })

    // fetch api data
    useEffect(() => {
        setLoading(true)
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopics_review",
            data: formData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                // console.log(response)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)

                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                } else {
                    setPageHeader(response.data)
                    setMytopics(response.data.mytopics);
                    setLoading(false)
                }


            })
            .catch(function (response) {
                //handle error
                // console.log(response);
            });
    }, [])


    // action start on click start button

    const actionStartWill = (topicId, testatorId, testatorImage, testatorName) => {
        // console.log(typeof (topicId))
        // console.log("Start btn clicked with ID: ", topicId)
        if (topicId === 200000001) {
            // console.log("true")
            navigate('/userTopicReview/willFlowReview', { state: { topicId: topicId, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName  } })
        }
        else if (topicId === 200000002) {
            navigate('/userTopicReview/powerOfAttorneyReview', { state: { topicId: topicId, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } })
        }
        else if (topicId === 200000003) {
            navigate('/userTopicReview/trustReview', { state: { topicId: topicId, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } })
        }
        else if (topicId === 200000004) {
            navigate('/userTopicReview/livingWillReview', { state: { topicId: topicId, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } })
        }
        else if (topicId === 200000005) {
            navigate('/userTopicReview/healthCareReview', { state: { topicId: topicId, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } })
        }
        else {
            // console.log("Default case, unrecognized id passed")
        }
    }


    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    return ( 
        <>
            <div style={{ 'height': window.innerHeight - 148 }}>
                <Grid columns={12}>
                    {/*Orange Top Bar */}
                    {topBar && <><Grid.Col span={12} pl="zx">
                        <Box className="viewBarOrange">
                            <Group>
                                <BsExclamationCircle size={22}  /><Text sx={(theme)=>({color: theme.colors.textColor[0], fontWeight:700 , fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[1]})}>You are viewing this page as a {state.testatorName}</Text>
                            </Group>
                        </Box>
                    </Grid.Col>
                        <Grid.Col span={12} pl="zx">
                            <FlowTitle title={pageHeader.title} description={pageHeader.description} />
                        </Grid.Col>
                    </>
                    }

                    {/*Review Title Bar Top Bar */}
                    {topTitleDesc && <Grid.Col span={12} pl="zx">
                        <Box>
                            <Text sx={(theme)=>({color: theme.colors.textColor[0], fontWeight:700, fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.lxl})}  > {state.testatorName}'s Information Binder</Text>
                            {/* <Text sx={(theme)=>({color: theme.colors.textColor[1], fontSize: theme.fontSizes.md })}> Lorem ipsum began as scrambled, nonsensical Latin derived from Cicero's 1st-century BC text De Finibus Bonorum et Malorum.</Text> */}
                        </Box>
                    </Grid.Col>
                    }

                </Grid>
                <TableHeader leftTitle="Topic" rightTitle="" />

                {/* {display myTopic data} */}
                <div className="cardBorder">
                    {mytopics && mytopics.map((item, index) => {
                        return (
                            <Box
                                onClick={() => {
                                    actionStartWill(item.topic_id, state.testatorId, state.testatorImage, state.testatorName)
                                }}
                                sx={(theme) => ({
                                    cursor: 'pointer',

                                    '&:hover': {

                                    },
                                })}

                                key={index}
                            >
                                <Grid className="cardBorderRad" >
                                    <Grid.Col span={8}>
                                        <CardLeftText name={item.name} content={item.content} />
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                            <Button fullWidth
                                               radius="xxs"
                                               sx={(theme) => ({
                                                   backgroundColor: theme.colors.primary[0],
                                                   height: 40,
                                                   fontSize: theme.fontSizes.xl,
                                                   fontWeight: 300,
                                                   fontFamily: theme.fontFamily[0],
                                                   '&:hover': {
                                                       backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                   }
                                               })}
                                               rightIcon={<BsFillEyeFill size={20} />}

                                                onClick={() => {
                                                    actionStartWill(item.topic_id, state.testatorId)
                                                }}

                                            >
                                                {item.action[0].text}
                                            </Button>
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                            </Box>
                        )
                    })}
                </div>

                <Grid columns={12}>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}>1</div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}>1</div>
                    </Grid.Col>
                </Grid>

                <Footer navigate={navigate}/>
            </div>
        </>
    )
}

export default UserTopicReview