import React, { useState } from "react";
import {
   Header,
   Text,
   MediaQuery,
   Burger,
   useMantineTheme,
   Group,
   Button,
   ActionIcon,
   Grid,
   TextInput,
   Tooltip,
   Center,
   Box,
   Modal,

} from '@mantine/core';
import { BiBell, BiEdit, BiSearch } from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import "../Custom.css"
import { HelpModal } from "./modal";

const MenuHeader = ({ opened, setOpened, navigate }) => {
   const [helpOpened, setHelpOpened] = useState(false);
   const theme = useMantineTheme();
   const [searchBox, setSearchBox] = useState(false)


   return (
      <>
         <HelpModal
            helpOpened={helpOpened}
            setHelpOpened={setHelpOpened}
         />


         <Header height={70} p="md" sx={(theme) => ({ backgroundColor: '#F6FBFD', border: 'none', 
               [`@media (max-width: 1280px)`]: {
                       paddingTop:8,
                  paddingBottom: 8
                 },
            })}>
            <Grid columns={12}>
               <Grid.Col xs={1} sm={1} md={0} lg={0} xl={0} className="headerBurger"  sx={(theme) => ({
                     display:"none",
                    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                        
                    }, display:"none",
                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                     display:"none",
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                     display:"none",
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                     display:"block",
                    },
                    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                     display:"block",
                     
                    }

                })}>
                  <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                     <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                     />
                  </MediaQuery>
               </Grid.Col>


               <Grid.Col xs={3} sm={3} md={3} lg={3} xl={3}  className="headerText">
                  {/* <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                        <Burger
                           opened={opened}
                           onClick={() => setOpened((o) => !o)}
                           size="sm"
                           color={theme.colors.gray[6]}
                           mr="xl"
                        />
                     </MediaQuery> */}
                     <Box 
                     className="headerLogo"
                     onClick={(e)=>{
                        console.log("clicked header text")
                        navigate('/infoBinder')
                     }}
                     >
                  <Text pt={0}
                     sx={(theme) => ({
                        fontSize: theme.fontSizes.lxxl,
                        fontFamily: theme.fontFamily[1],
                        color: theme.colors.primary[0],
                        fontWeight: 600,
                        [`@media (max-width: 1280px)`]: {
                           fontSize: theme.fontSizes.xxl,
                          
                        },
                        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                           fontSize: theme.fontSizes.xl,
                        },
                        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                           paddingTop: "10px"
                        }
                     })}
                  >
                     Project Whitney
                  </Text>
                  </Box>
               </Grid.Col>

               <Grid.Col xs={5} sm={6} md={6} lg={6} xl={6}  p="zx" className="headerSearch">
                  {searchBox && <TextInput
                     placeholder="Search (coming soon)"
                     type="search"
                     sx={(theme) => ({ input: { height: 40 } })}
                  />}
               </Grid.Col>

               <Grid.Col xs={3} sm={3} md={3} lg={3} xl={3}  style={{ right: 0 }} className="headerIcons">
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                     <Group style={{ right: 0 }}>
                        <Tooltip radius="xxs" label="Search" >
                           <ActionIcon variant="search"
                              onClick={() => {
                                 setSearchBox(!searchBox)
                              }}
                              // color="#868e96"
                              // className="iconBg"
                              sx={(theme) => ({
                                 backgroundColor: "#f6fbfd ",
                                 color: "#868e96",

                                 '&:hover': {
                                    backgroundColor: theme.colors.buttonColor[2],
                                    color: theme.colors.buttonColor[9]
                                 },
                              })}
                           >
                              <BiSearch size={22} />
                           </ActionIcon>
                        </Tooltip>
                        <Tooltip radius="xxs" label="Edit (coming soon)"><ActionIcon variant="subtle"
                           sx={(theme) => ({
                              '&:hover': {
                                 backgroundColor: theme.colors.buttonColor[2],
                                 color: theme.colors.buttonColor[9]
                              },
                           })}
                        ><BiEdit size={22} /></ActionIcon></Tooltip>
                        <Tooltip radius="xxs" label="Notification (coming soon)"><ActionIcon variant="subtle"
                           sx={(theme) => ({
                              '&:hover': {
                                 backgroundColor: theme.colors.buttonColor[2],
                                 color: theme.colors.buttonColor[9]
                              },
                           })}

                        ><BiBell size={22} /></ActionIcon></Tooltip>
                        <Tooltip radius="xxs" label="Help">
                           <ActionIcon variant="subtle"
                              sx={(theme) => ({
                                 '&:hover': {
                                    backgroundColor: theme.colors.buttonColor[2],
                                    color: theme.colors.buttonColor[9]
                                 },
                              })}

                              onClick={(e) => {
                                 setHelpOpened(true)

                              }}
                           >
                              <BsQuestionCircle size={22} />
                           </ActionIcon>
                        </Tooltip>
                     </Group>
                  </Box>

               </Grid.Col>
            </Grid>





         </Header>

      </>
   )
}

export default MenuHeader;