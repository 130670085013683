import React from "react";
import axios from "axios";
import { BASE_URL } from "./global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    // onUploadProgress: function (progressEvent) {
    //     console.log("Pregress event: ", progressEvent) 
    // },

})

export const getPowerOfAttorneyReview = (topicId, testatorId, submiData) => {
    // console.log("binder id from: ", topicId, testatorId)
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow")
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
       
    if(submiData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'testator_id': testatorId,
            ...submiData

        }
        // console.log(data)
        Object.keys(data).map((item, index)=>{
            formData.set(item, data[item])
        })
    }else{
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'testator_id': testatorId,
        }
        // console.log(data)
        Object.keys(data).map((item, index) => {
         formData.set(item, data[item])
        })
    }
    return whitney.post(willFlowUrl, formData)
    
}

export const getPowerOfAttorneyReviewAs = (topicId, testatorId, keyContactId,live, mode, submiData) => {
    // console.log("binder id from: ", topicId, testatorId, mode, submiData)
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow")
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
    if(live === "no"){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'userId': testatorId,
            "mode": mode,
            "permitted_contact_id": keyContactId,
            "isAlive": live,
            ...submiData

        }
    }else
    if(submiData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'userId': testatorId,
            "mode": mode,
            "isAlive": live,
            "permitted_contact_id": keyContactId,
            ...submiData

        }
  
    }else{
        data = { 
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'userId': testatorId,
            "permitted_contact_id": keyContactId,
            "mode": mode,
            "isAlive": live,
        }
  
    }
    // console.log(data)
    Object.keys(data).map((item, index) => {
     formData.set(item, data[item])
    })
    return whitney.post(willFlowUrl, formData)
    
}

export const removePOA = (flowId, submitData) => {
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let data = {}
    let formData = new FormData()
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId

    if (!flowId) {
        return
    }
    if (submitData) {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            ...submitData

        }
       
    }
     console.log(data)
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    return whitney.post(willFlowUrl, formData)
}