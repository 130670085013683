import React from "react";
import axios from "axios";
import { BASE_URL } from "./flowReviewApis/global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    onUploadProgress: function (progressEvent) {
        // console.log("Pregress event: ", progressEvent)
    },

})



export const getKeyContacts = ( data, formdata,) => {
    // console.log("get Key Contacts from: ",  data, formdata, )
 
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/my-key-contacts"
    if (!data) {
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
    
        formData = new FormData()
        Object.keys(data).map((item, index) => {

            Object.keys(data).map((item, index) => {

                if(Array.isArray(data[item])){
                    Array.data[item].forEach(element => {
                                    formData.append("uploaded_files[]", element)

                                })
                }
                else {
                    formData.set(item, data[item])
                }

            })
            // formData.set(item, data[item])
        })
        return whitney.post(willFlowUrl, formData)
    } else {
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
    
        // console.log("Data test >>>>", data)
        if (!formdata) {
            formData = new FormData()
            Object.keys(data).map((item, index) => {
                if(Array.isArray(data[item])){
                    data[item].forEach(element => {
                                    formData.append("uploaded_files[]", element)

                                })
                }
                else {
                    formData.set(item, data[item])
                }

            })
        } else {
            formData = new FormData(data)
        }
        // console.log("Form data", formData)
        return whitney.post(willFlowUrl, formData)
    }
}

