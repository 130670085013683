import React from "react";
import { BiPencil } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";

export const EditIcon = ({ }) => {
    return (
        <>
            <BiPencil size={20} />
        </>
    )
}


export const TrashIcon = ({size, color }) => {
    return (
        <>
            <BsTrashFill size={22} style={{ color: "#023047" }} />
        </>
    )
}

export const TrashIconRed = ({size, color }) => {
    return (
        <>
            <BsTrashFill size={22}  />
        </>
    )
}


