import React, {useState } from "react";
import { Button, Textarea, Text, useMantineTheme, Select, TextInput, InputBase, Grid, Box, ActionIcon, Anchor } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { HiArrowRight, HiOutlineLightBulb } from 'react-icons/hi'
import { IoPlayOutline } from 'react-icons/io5'
import { BiPencil, BiPlus } from 'react-icons/bi'
import { getWillFlow } from "../apis/willFlowApis";
import { getHealthCareFlow } from "../apis/healthcareProxyApis"
import { removeTrust , removeKeyContact} from "../apis/trustApis";
import { ConfirmationModal } from "./modal";
import { removeHealth } from "../apis/healthcareProxyApis";
import '../Custom.css';
import { MultipleFileUploadField } from './uploads/MultipleFileUploadField';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';
import { removePOA } from "../apis/powerOfAttorneyApis";
import { CardLeftText, TableHeader } from "./TableComponent";
import { BsFillEyeFill, BsTrashFill } from "react-icons/bs";
import phoneData from "../Pages/phoneCode.json"
import { TrashIconRed } from "./IconComponent";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";



const InputControl = ({
    data, handleButtonClick, step,
    modalMessage, setModalMessage, modalStatus,
    setModalStatus, setAnswer, answer,
    prefill, setPrefill, btnClick, flowId, willFlow,
    editMode, setEditMode, addAnother, questionId, binderId,
    trustEditClick, poaEditClick, healthEditClick,
    setLoggedIn, AddContat, setLoading, setLoadingMock, selectPOAagent, selectHCAgent,
    keyContactId
}) => {
    // console.log(data)
    // console.log(answer)
    const theme = useMantineTheme("");
    const [dateError, setDateError] = useState("")
    const [textInputError, setTextInputError] = useState("")
    const [confirmMessage, setConfirmMessage] = useState({})
    const [confirmModalStatus, setConfirmModalStatus] = useState(false)

    const [birthDate, setBirthDate] = useState(new Date());

    // const [textAreaInput, setTextAreaInput] = useState("");
    const [tableText, ] = useState({
        willBeneficiariesList: "No beneficiary added. Click below to add a new beneficiary to the list",
        willExecutorList: "No trusted person(s) added. Click below to add a new prusted person(s) to the list",
        poaList: "No power of attorney added. Click below to add a new power of attorney to the list",
        poaAgentList: "No agent added. Click below to add a new agent to the list",
        healthProxiesList: "No health care added. Click below to add a new health care to the list",
        healthCareProxyList: "No health care proxy added. Click below to add a new health care proxy to the list",
        trustList: "No trust added. Click below to add a new trust to the list",
        trusteesList: "No trustee added. Click below to add a new trustee to the list",
        trustBeneficiariesList: "No trust beneficiary added. Click below to add a new beneficiary to the list"
    })



    switch (data.type) {
        case "yesNoButton":
            return (
                <div style={{ 'display': 'flex', 'flexDirection': 'row', 'maxWidth': '50%', "gap": "3.63vw", marginBottom: 18, marginTop: 15 }}>
                    <Button
                        className="buttonMediumYesNo   yesNoBtn  "
                        sx={(theme) => ({
                            color: theme.colors.primary[0],
                            fontSize: theme.fontSizes.xl,
                            fontFamily: theme.fontFamily[0],
                            fontWeight: 900,
                            borderRadius: theme.radius.xxs
                        })}
                        // style={{ "display": "flex", "fontSize": "2.03vw", "color": "#023047", "flexDirection": "row", "justifyContent": "center", "alignItems": "center", "padding": "28px 128px", "gap": "10px", "height": "103px", "background": "#FEFEFE", "boxShadow": "1px 1px 13px 12px rgba(2, 48, 71, 0.1)", "borderRadius": "4px", "flex": "none", "order": "0", "flexGrow": "0" }}
                        onClick={(e) => { handleButtonClick(step, "primary") }}
                    >
                        {data.text1}
                    </Button>
                    <Button
                        className="buttonMediumYesNo   yesNoBtn  "
                        sx={(theme) => ({
                            color: theme.colors.primary[0],
                            fontSize: theme.fontSizes.xl,
                            fontFamily: theme.fontFamily[0],
                            fontWeight: 900,
                            borderRadius: theme.radius.xxs
                        })}
                        onClick={(e) => { handleButtonClick(step, "secondery") }}
                    >
                        {data.text2}
                    </Button>
                </div>
            )
        case "textarea":
     
            let textAreaSize;
            if (data.id === "short_desc") {
                textAreaSize = "50%"
            } else {
                textAreaSize = "100%"
            }
           return (
                <Grid.Col span={24} pl="zx" pr="zx">
                    <Textarea placeholder={data.placeholder} label={data.label}
                        value={answer[data.id]}
                        radius="xxs"
                        autosize
                        minRows={6}
                        maxRows={8}
                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': textAreaSize },
                            input: { 'color': theme.colors.textColor[0], 'fontSize': theme.fontSizes.lg, marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            setPrefill({ ...prefill, [data.id]: e.target.value })
                            let temp = answer
                            let key = data.id.toLowerCase()
                            temp[key] = e.target.value
                            console.log("Temp Var: ", temp)
                            setAnswer(temp)
                        }}
                    />
                </Grid.Col>

            )
        case "hint":
            return (
                <Text style={{ 'display': 'flex', "cursor": 'pointer', alignItems: 'center', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.5vw', }}

                    sx={(theme) => ({
                        color: theme.colors.primary[4],
                        fontSize: theme.fontSizes.md,
                        fontFamily: theme.fontFamily[1],
                        fontWeight: 400,
                        '@media (max-width: 1280px)': {
                            fontSize: theme.fontSizes.sm,
                        },
                        '@media (max-width: 1024px)': {
                            fontSize: theme.fontSizes.sm,
                        },
                    })}
                    onClick={() => {
                        let temp = {
                            title: data.title,
                            content: data.text
                        }
                        setModalMessage(temp)
                        setModalStatus(!modalStatus)
                    }}
                >
                    <HiOutlineLightBulb size={30} /> {data.title}
                </Text>
            )
        case "videoHint":
            return (
                <Text style={{ 'display': 'flex', "cursor": 'pointer', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginBottom': '2vw', 'color': '#045C87', 'fontSize': '1.3vw' }}

                    sx={(theme) => ({
                        color: theme.colors.primary[4],
                        fontSize: theme.fontSizes.md,
                        fontFamily: theme.fontFamily[1],
                    })}
                    onClick={() => {
                        let temp = {
                            title: data.title,
                            content: data.text,
                            url: data.videoUrl
                        }
                        setModalMessage(temp)
                        setModalStatus(!modalStatus)
                    }}
                >
                    <IoPlayOutline size={30} /> {data.title}
                </Text>
            )
        case 'fileUploader':

            const deleteFilesTestator = (documentId, questionId) => {
                let data = {}
                if (flowId === "200000001") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteTestatorDocument": 'yes'
                    }
                } else if (flowId === "200000002") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteTestatorDocument": 'yes',
                        "main_binder_id": binderId,
                        "key_contact_id": keyContactId,
                    }
                } else if (flowId === "200000005") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteTestatorDocument": 'yes',
                        "main_binder_id": binderId,
                        "key_contact_id": keyContactId,
                    }
                } else if (flowId === "200000003") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteTestatorDocument": 'yes',
                        "main_binder_id": binderId,

                    }
                } else if (flowId === "200000004") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteTestatorDocument": 'yes'
                    }
                }
                console.log(data)
                setLoadingMock(true)
                // console.log("Input Control Folow ID:", flowId)   
                getWillFlow(flowId, data).then((response) => {
                    console.log("Delete document response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoadingMock(false)
                        setLoggedIn(false)
                    }else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    } else if (response.data.status === 'success') {
                        willFlow.set(response.data)
                        setLoadingMock(false)
                    } else {
                        setLoadingMock(false)
                    }
                }, (error) => {
                    console.log("Delete document error: ", error)
                })
            }
            const deleteFilesKeyContact = (documentId, questionId, key_contact_id) => {
                console.log("questionId", questionId)
                let data = {}
                if (flowId === "200000001") {
                    data = {
                        "DeleteDocument": "Delete",
                        "document_id": documentId,
                        "question_id": questionId,
                        "key_contact_id": key_contact_id
                    }
                } else if (flowId === "200000002") {
                    data = {
                        "DeleteDocument": "Delete",
                        "document_id": documentId,
                        "question_id": questionId,
                        "key_contact_id": key_contact_id,
                        "main_binder_id": binderId

                    }
                } else if (flowId === "200000005") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteDocument": 'yes',
                        "main_binder_id": binderId,
                        "key_contact_id": key_contact_id,
                    }
                } else if (flowId === "200000003") {
                    data = {
                        "document_id": documentId,
                        "question_id": questionId,
                        "DeleteDocument": 'yes',
                        "main_binder_id": binderId,
                        "key_contact_id": key_contact_id,
                    }
                }
                console.log(data)
                setLoadingMock(true)
                getWillFlow(flowId, data).then((response) => {
                    console.log("Delete document response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoadingMock(false)
                        setLoggedIn(false)
                    }else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    } else if (response.data.status === 'success') {
                        console.log(response.data.steps[step.step].table_data[0])
                        willFlow.set(response.data)
                        setEditMode({ value: true, data: response.data.steps[step.step].table_data[0] })
                        setLoadingMock(false)
                    } else {
                        setLoadingMock(false)
                    }
                }, (error) => {
                    console.log("Delete document error: ", error)
                })
            }

            console.log(step)
            console.log(data)
            console.log(editMode)
            console.log(answer)
            return (
                <Grid.Col span={24} pt="xs" pl="zx" pr="zx">
                    <div className="container" >
                        <Text
                            sx={(theme) => ({
                                color: theme.colors.textColor[0],
                                fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0],
                                fontWeight: 600,
                                '@media (max-width: 1280px)': {
                                    fontSize: 16,
                                    lineHeight: "16px"
                                },
                                '@media (max-width: 1200px)': {
                                    fontSize: 16,
                                    lineHeight: "16px"
                                },
                                '@media (max-width: 992px)': {
                                    fontSize: 14,
                                    lineHeight: "14px"
                                },
                            })}
                            pb="xxs_input"
                        >Upload documents</Text>
                        <Formik initialValues={{ files: [] }} onSubmit={() => { }}>
                            {({ values, errors }) => (
                                <Form>
                                    <Grid columns={24}>
                                        <Grid.Col span={24} p="zx">
                                            {/* <h1>Hello Dropzone</h1> */}
                                            <MultipleFileUploadField
                                                name="files"
                                                setAnswer={setAnswer}
                                                answer={answer}
                                                data={data}
                                                questionId={questionId}
                                                binderId={binderId}
                                                setLoggedIn={setLoggedIn}
                                                navigate={setLoggedIn}



                                            />
                                        </Grid.Col>
                                    </Grid>

                                </Form>
                            )}

                        </Formik>


                    </div>




                    {editMode?.data?.documents?.length > 0 &&
                        <div>
                            {editMode.data.documents.map((item, index) => {
                                return (
                                    <Grid key={index}>
                                        <Grid.Col span={11}>
                                            <Anchor href={item.fileUrl} target="_blank">
                                                <Text
                                                    sx={(theme) => ({
                                                        color: theme.colors.textColor[6],
                                                        fontSize: theme.fontSizes.xl,
                                                        fontFamily: theme.fontFamily[1],
                                                        fontWeight: 300
                                                    })}
                                                >{item.originalname}</Text>
                                            </Anchor>
                                        </Grid.Col>
                                        <Grid.Col span={1} >
                                            <Box sx={(theme) => ({
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            })}>
                                                <ActionIcon variant="subtle"

                                                    sx={(theme) => ({
                                                        color: theme.colors.red[9],
                                                    })}
                                                    onClick={(e) => {
                                                        deleteFilesKeyContact(item.document_id, item.question_id, editMode.data.key_contact_id)
                                                    }}>
                                                    <TrashIconRed />
                                                </ActionIcon></Box>
                                        </Grid.Col>
                                    </Grid>
                                )
                            })}
                        </div>
                    }
                    {step?.documents?.length > 0 &&
                        <div>
                            {step.documents.map((item, index) => {
                                // console.log("items in documents: ", item)
                                return (
                                    <Grid key={index}>
                                        <Grid.Col span={11}>
                                            <Anchor href={item.fileUrl} target="_blank" className="customLink">
                                                <Text
                                                    sx={(theme) => ({
                                                        color: theme.colors.textColor[6],
                                                        fontSize: theme.fontSizes.xl,
                                                        fontFamily: theme.fontFamily[1],
                                                        fontWeight: 300
                                                    })}
                                                >{item.originalname}</Text>
                                            </Anchor>
                                        </Grid.Col>
                                        <Grid.Col span={1} >
                                            <Box sx={(theme) => ({
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            })}>
                                                <ActionIcon variant="subtle"

                                                    sx={(theme) => ({
                                                        color: theme.colors.red[9],
                                                    })}
                                                    onClick={(e) => {
                                                        deleteFilesTestator(item.document_id, item.question_id)
                                                    }}>
                                                    <TrashIconRed />
                                                </ActionIcon></Box>
                                        </Grid.Col>

                                    </Grid>
                                )
                            })}
                        </div>
                    }

                    {/* </div> */}
                </Grid.Col>
            )
        case "dropDown":
            //dropDownInput , setDropDownInput
            let options = []
            // console.log(data.options)
            data.options.map((item) => {
                let temp = { value: item.value, label: item.label, key: item.value + item.label }   // make it array as per mantine select
                options.push(temp)
            })
            // console.log("Options array for drop down: ", options)
            let dropdownSize;
            let dropDownWidth;
            if (data.label === "Relationship") {
                dropdownSize = 24;
                dropDownWidth = '50%';
            } else {
                dropdownSize = 12;
                dropDownWidth = '100%';
            }


            // console.log(data.prefill)
            let dropdownFlag = false;
            if (data.prefill) {
                dropdownFlag = true
            }

            return (

                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.7vw' }} >
                <Grid.Col span={dropdownSize} pt="xs"
                    pr={data.id === "country_id"


                        ? "zx" : 10}
                    pl={data.id === "category" ? "zx" : 10}
                >
                    <Select
                        label={data.label}
                        placeholder={data.placeholder}
                        // value={String(prefill[data.label])}
                        // defaultValue={dropdownFlag ? String(data.prefill) : dropDownInput}
                        defaultValue={data.prefill.length > 0 ? data.prefill : answer[data.id]}
                        data={options}
                        id={data.id}
                        styles={{
                            label: {
                                'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0], 'fontWeight': 600
                            },
                            root: { 'width': dropDownWidth },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                            dropdown: { 'border': 'none', 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                            item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                            hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                        }}
                        clearable
                        searchable
                        onChange={(e) => {
                            setPrefill({ ...prefill, [data.label]: e })
                            if (step.step === 5 || step.step === 8 || step.step === 3 || step.step === 6 || step.step === 9) {
                                let temp = {
                                    ...answer,
                                    // [data.label.toLowerCase().replace(" ", "_")]: e
                                    [data.id.toLowerCase().replace(" ", "_")]: e
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)

                            }
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "smallDropDown":
            //smallDropDownInput , setSmallDropDownInput
            // let smallOptions = []
            // // console.log(data.options)
            // data.options.map((item) => {
            //     let temp = { value: item.value, label: item.label, key: item.code + item.value }   // make it array as per mantine select
            //     smallOptions.push(temp)
            // })
            let smallOptions = []
            // // console.log(data.options)

            phoneData.options.map((item) => {
                let temp = { value: item.dial_code + item.code, label: item.name, key: item.code + item.dial_code }   // make it array as per mantine select
                smallOptions.push(temp)
            })

            // console.log("Options array for drop down: ", options)
            let smallDropdownSize;
            let smalldropDownWidth;
            if (data.label === "Relationship") {
                smallDropdownSize = 4;
                smalldropDownWidth = '10%';
            } else {
                smallDropdownSize = 4;
                smalldropDownWidth = '10';
            }
            let checkSmallDDInput = false;
            if (data.prefill) {
                checkSmallDDInput = true;
            }


            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', "gap": "1.63vw", 'marginTop': '1.7vw' }} >
                <Grid.Col span={smallDropdownSize} pt="xs">
                    <Select
                        label={"Phone No"}
                        placeholder={data.placeholder}
                        // value={String(prefill[data.label])}
                        // defaultValue={checkSmallDDInput ? data.prefill : smallDropDownInput}
                        defaultValue={data.prefill.length > 0 ? data.prefill : answer[data.id]}
                        data={smallOptions}
                        id={data.id}
                        styles={{
                            label: {
                                'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                fontFamily: theme.fontFamily[0], 'fontWeight': 600
                            },
                            root: { 'width': smalldropDownWidth },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                            dropdown: { 'border': 'none', 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                            item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                            hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                        }}
                        clearable
                        searchable
                        onChange={(e) => {
                            console.log(answer)
                            setPrefill({ ...prefill, [data.label]: e })
                            if (step.step === 5 || step.step === 8 || step.step === 3 || step.step === 6 || step.step === 9) {

                                let temp = {
                                    ...answer,
                                    [data.id]: e
                                }
                                console.log(temp)
                                setAnswer(temp)
                            }
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "textDate":
            let checkDateInput = false;
            if (data.prefill) {
                checkDateInput = true
            }
            let changeDate;
            function format(inputDate) {
                let changeDate = inputDate.split('-')
                let newDate = changeDate[1] + "/" + changeDate[2] + "/" + changeDate[0]
                return newDate;
            }
            // console.log(data.prefill)
            // changeDate = format(data.prefill)
            return (
                <Grid.Col span={12} pt="xs">
                    <InputBase
                        label={data.label}
                        placeholder={data.placeholder}
                        // value={prefill[data.label]}
                        defaultValue={checkDateInput ? changeDate : answer[data.id]}
                        mask="99/99/9999"
                        component={InputMask}
                        error={dateError}
                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': '100%' },
                            wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, 'border': 'none', fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] }
                        }}
                        onChange={(e) => {
                            // console.log(e.target.value)
                            // change date into YYYY-MM-DD
                            let input = e.target.value;
                            let output = input.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
                            // console.log(output)

                            //Check Changed Date is Valid Date Or Not
                            let ValidDate;
                            function validateDate(date) {
                                var regex = new RegExp("([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})");
                                var dateOk = regex.test(date);
                                if (dateOk) {
                                    ValidDate = dateOk;

                                } else {
                                    console.log("date Error")

                                }
                            }
                            validateDate(output)
                            // console.log(e.target.value)

                            setPrefill({ ...prefill, [data.id]: output })

                            if (output != null) {
                                let diff = new Date().getFullYear() - new Date(output).getFullYear()
                                let checkValidDate = new Date(ValidDate);

                                if (checkValidDate == "Invalid Date" && e.target.value != "__/__/____") {
                                    setDateError("Please Enter Valid Date")
                                } else
                                    if (diff < 18) {
                                        setDateError("Age must be 18 or above")
                                    } else {
                                        setDateError()
                                    }
                            }
                            if (step.step === 5 || step.step === 8 || step.step === 6 || step.step === 9) {
                                let temp = {
                                    ...answer,
                                    [data.id.toLowerCase().replace(" ", "_")]: output
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                                setBirthDate(output)
                            } else {
                                setAnswer(output)
                            }
                        }}
                    />
                </Grid.Col>
            )
        case "textInput":
            let setRequired;
            if (flowId === "200000003" && data.id === "item_name") {
                setRequired = true
            } else {
                setRequired = false
            }
            // textInputs , setTextInputs
            let checkTestInput = false;
            if (data.prefill) {
                checkTestInput = true
            }
            // check size in case of relationship
            let testInputSize;
            let testInputWidth;
            if (data.label === "Relationship") {
                testInputSize = 12;
                testInputWidth = '100%';
            } else {
                testInputSize = 12;
                testInputWidth = '100%';
            }
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={testInputSize} pt="xs"
                    pl={data.id === "relationship"
                        || data.id === "first_name"
                        || data.id === "last_name"
                        || data.id === "email"
                        || data.id === "address_line2"
                        || data.id === "state"
                        || data.id === "zip_code"
                        || data.id === "category"
                        || data.id === "item_name"

                        ? "zx" : 10}

                    pr={data.id === "company_name"
                        || data.id === "middle_name"
                        || data.id === "suffix"
                        || data.id === "address_line1"
                        || data.id === "city"
                        || data.id === "phone_no"
                        || data.id === "category"

                        ? "zx" : 10}
                >
                    <TextInput
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        error={textInputError}
                        required={setRequired}
                        styles={{
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            setPrefill({ ...prefill, [data.label]: e.target.value })
                            if (e.target.value.length > 0) {
                                if (data.label === "Email") {
                                    if (String(e.target.value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct email address")
                                    }
                                } else if (data.label === "Phone No") {
                                    let phone = e.target.value;
                                    let phoneNum = phone.replace(/[^\d]/g, '');
                                    if (phoneNum.length > 6 && phoneNum.length < 10) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct phone number")
                                    }
                                }
                            } else {
                                setTextInputError()
                            }
                            console.log(step)
                            if (step.step === 5 || step.step === 8 || step.step === 1 || step.step === 2 || step.step === 6 || step.step === 9 || step.step === 4 || step.step === 3) {
                                let temp = {
                                    ...answer,
                                    [data.id]: e.target.value
                                }
                                console.log(answer)
                                console.log(temp)
                                setAnswer(temp)
                            }
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "mediumTextInput":
            //mediumTextInputs , setTmediumTextInputs
            let checkMediumText = false;
            if (data.prefill) {
                checkMediumText = true
            }
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={8} pt="xs"
                    pr={data.id === "phone_no" ? "zx" : 10}
                >
                    <TextInput
                        label={"."}
                        placeholder={data.placeholder}
                        // value={prefill[data.label]}
                        value={answer[data.id]}
                        error={textInputError}
                        styles={{
                            label: { 'color': "#ffffff", fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            // root: { 'width': '100%' },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            setPrefill({ ...prefill, [data.label]: e.target.value })
                            if (e.target.value.length > 0) {
                                if (data.label === "Email") {
                                    if (String(e.target.value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct email address")
                                    }
                                } else if (data.label === "Phone No") {
                                    let phone = e.target.value;
                                    let phoneNum = phone.replace(/[^\d]/g, '');
                                    if (phoneNum.length > 6 && phoneNum.length < 11) {
                                        setTextInputError()
                                    } else {
                                        setTextInputError("Please enter correct phone number")
                                    }
                                }
                            } else {
                                setTextInputError()
                            }
                            if (step.step === 5 || step.step === 8 || step.step === 3 || step.step === 6 || step.step === 9) {
                                console.log(answer)
                                let temp = {
                                    ...answer,
                                    [data.id]: e.target.value
                                }
                                console.log("Temp Var: ", temp)
                                setAnswer(temp)

                            }
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case "smallTextInput":
            //smallTextInputs, setSmallTextInputs
            let checkSmallText = false;
            if (data.prefill) {
                checkSmallText = true;
            }
            return (
                // <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '49%', "gap": "1.63vw", 'marginTop': '1.7vw', 'paddingRight': '1%' }}>
                <Grid.Col span={12} pt="xs">
                    <TextInput
                        label={data.label}
                        placeholder={data.placeholder}
                        value={answer[data.id]}
                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': '80px' },
                            // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input }
                        }}
                        onChange={(e) => {
                            setPrefill({ ...prefill, [data.label]: e.target.value })
                            if (step.step === 5 || step.step === 8 || step.step === 3 || step.step === 6 || step.step === 9) {
                                let temp = {
                                    ...answer,
                                    [data.id]: e.target.value
                                }
                                setAnswer(temp)
                            }
                        }}
                    />
                </Grid.Col>

                // </div>
            )
        case 'date':
            // console.log(data.prefill)
            var checkDate = false;

            function isValidDate(dateString) {
                var regEx = /^\d{4}-\d{2}-\d{2}$/;
                if (!dateString.match(regEx)) return false;  // Invalid format
                var d = new Date(dateString);
                var dNum = d.getTime();
                if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
                return d.toISOString().slice(0, 10) === dateString;
            }
            if (isValidDate(data.prefill)) {
                // console.log("0000-00-00 true")
                checkDate = true
            }
            return (
                <Grid.Col span={12} pt="xs">

                    <DatePicker
                        label={data.label}
                        placeholder={data.placeholder}
                        error={dateError}
                        defaultValue={new Date(checkDate ? new Date(data.prefill) : birthDate)}

                        styles={{
                            label: { 'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl, 'fontWeight': 600 },
                            root: { 'width': '100%' },
                            wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                            input: { 'height': 50, 'border': 'none', fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] }
                        }}
                        onChange={(e) => {

                            // change date yyyy-mm-dd
                            function formatDate(date) {
                                var d = new Date(date),
                                    month = '' + (d.getMonth() + 1),
                                    day = '' + d.getDate(),
                                    year = d.getFullYear();

                                if (month.length < 2)
                                    month = '0' + month;
                                if (day.length < 2)
                                    day = '0' + day;

                                return [year, month, day].join('-');
                            }
                            // console.log(formatDate(e.toDateString())) // check date
                            setPrefill({ ...prefill, [data.id]: formatDate(e?.toDateString()) })
                            if (e != null) {
                                let diff = new Date().getFullYear() - e.getFullYear()
                                if (diff < 18) {
                                    setDateError("Age must be 18 or above")
                                } else {
                                    setDateError()
                                }
                            }
                            if (step.step === 5 || step.step === 8) {
                                let temp = {
                                    ...answer,
                                    [data.id.toLowerCase().replace(" ", "_")]: formatDate(e.toDateString())
                                }
                                // console.log("Temp Var: ", temp)
                                setAnswer(temp)
                                setBirthDate(formatDate(e.toDateString()))
                            } else {
                                setAnswer(e.target.value)
                            }
                        }}
                    />
                    {/* </div> */}
                </Grid.Col>
            )
        case 'table':
            const deleteKeyContact = (binderId, contactTypeId, questionId, keyContactId) => {
                setModalStatus(!modalMessage)
                let data = {
                    "DeleteKeyContact": "Delete",
                    "contact_type_id": contactTypeId,
                    "question_id": questionId,
                    "key_contact_id": keyContactId,
                    "main_binder_id": binderId
                }
                // console.log("Executinon to this point")
                console.log(data)
                console.log(typeof (flowId))
                if (flowId === '200000005') {
                    setLoading(true)
                    getHealthCareFlow(flowId, data).then((response) => {
                        console.log("Delete contact success response: ", response.data)
                        if (response.data.message === "Invalid user") {
                            localStorage.removeItem("project_whiteney_email")
                            localStorage.removeItem("project_whiteney_user_data")
                            setLoading(false)
                            setLoggedIn(false)
                        }else if(response.data.message === "Session timeout."){
                            showNotification({
                                id: 'load-data',
                                color: 'red',
                                title: response.data.status,
                                message: response.data.message,
                                icon: <IconX size={16} />,
                                autoClose: 2000,
                            })
                            localStorage.removeItem("project_whiteney_email")
                            localStorage.removeItem("project_whiteney_user_data")
                            setLoading(false)
                            setLoggedIn(false)
                        
                        } else {
                            showNotification({
                                id: 'load-data',
                                color: 'teal',
                                title: response.data.status,
                                message: response.data.message,
                                icon: <IconCheck size={16} />,
                                autoClose: 2000,
                            })
                            willFlow.set(response.data)
                            setLoading(false)
                        }
                    }, (error) => {
                        // console.log("Delete contact error: ", error)
                    })
                } else {
                    setLoading(true)
                    getWillFlow(flowId, data).then((response) => {
                        console.log("Delete contact success response: ", response.data)
                        if (response.data.message === "Invalid user") {
                            localStorage.removeItem("project_whiteney_email")
                            localStorage.removeItem("project_whiteney_user_data")
                            setLoading(false)
                            setLoggedIn(false)
                        }else if(response.data.message === "Session timeout."){
                            showNotification({
                                id: 'load-data',
                                color: 'red',
                                title: response.data.status,
                                message: response.data.message,
                                icon: <IconX size={16} />,
                                autoClose: 2000,
                            })
                            localStorage.removeItem("project_whiteney_email")
                            localStorage.removeItem("project_whiteney_user_data")
                            setLoading(false)
                            setLoggedIn(false)
                        
                        }else {
                            showNotification({
                                id: 'load-data',
                                color: 'teal',
                                title: response.data.status,
                                message: response.data.message,
                                icon: <IconCheck size={16} />,
                                autoClose: 2000,
                            })
                            willFlow.set(response.data)
                            setLoading(false)
                        }
                    }, (error) => {
                        // console.log("Delete contact error: ", error)
                    })
                }


            }
            const showConfirmModal = (binderId, contactTypeId, questionId, keyContactId, name, relation) => {
                console.log(binderId, contactTypeId, questionId, keyContactId, name, relation)
                let temp = {
                    title: "Are you sure you want to delete " + name,
                    content: "Deleting this will result in permanent loss of data regarding " + name,
                    args: [binderId, contactTypeId, questionId, keyContactId],
                    deleteAction: deleteKeyContact
                }
                console.log(temp)
                // setModalStatus(!modalStatus)
                setConfirmMessage(temp)
                setConfirmModalStatus(!confirmModalStatus)
            }
            console.log(data)
            console.log(step)
            return (
                <div className="tableMainContainer">
                    <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />
                    <TableHeader leftTitle={data.titles[0]} rightTitle={data.titles[1]} />
                    <div className="cardBorder">
                        {data && step.table_data.map((item, index) => {
                            console.log(item)
                            return (


                                <Grid className="cardBorderRad cardWidth" key={index}>
                                    <Grid.Col span={8}>
                                        <CardLeftText name={data.rows[index].details[0].value}
                                            content={data.rows[index].details[1].value}
                                        // company={data.rows[index].details.length > 2 ? data.rows[index].details[2].value : ""}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>

                                            {flowId === "200000005" && <Button fullWidth
                                                radius="xxs"
                                                mr="xl"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<HiArrowRight size={20} />}

                                                onClick={() => {
                                                    selectHCAgent(item, item.key_contact_id, step)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}>{"Continue"}</Text>
                                            </Button>}
                                            {flowId === "200000002" && <Button fullWidth
                                                radius="xxs"
                                                mr="xl"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<HiArrowRight size={20} />}

                                                onClick={() => {
                                                    selectPOAagent(item, item.key_contact_id, step)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}>{"Continue"}</Text>
                                            </Button>}
                                            <Button fullWidth
                                                radius="xxs"
                                                mr="xl"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<BiPencil size={20} />}

                                                onClick={() => {
                                                    btnClick(item, item.key_contact_id)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}>{data.rows[index].buttons[0]}</Text>
                                            </Button>

                                            <ActionIcon variant="subtle"
                                                radius="xxs"

                                                onClick={() => {
                                                    showConfirmModal(binderId, item.contact_type_id, item.question_id, item.key_contact_id, data.rows[index].details[0].value, data.rows[index].details[1].value)
                                                }}
                                            >
                                                <BsTrashFill size={20} style={{ color: "#023047", }} />
                                            </ActionIcon>
                                        </Box>
                                    </Grid.Col>
                                </Grid>


                            )
                        })}
                    </div>
                    {data && step.table_data.length === 0 && <Grid className="cardBorderRad" >

                        <Grid.Col span={12}>
                            <Box sx={(theme) => ({
                                color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center",
                                '@media (max-width: 1280px)': {
                                    fontSize: theme.fontSizes.md,
                                }, '@media (max-width: 1024px)': {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}>
                                {step.step === 1 && step.activBreadCrumb === "Power of Attorney" && tableText.poaList}
                                {step.step === 1 && step.activBreadCrumb === "Trust" && tableText.trustList}
                                {step.step === 6 && step.activBreadCrumb === "Beneficiaries" && tableText.willBeneficiariesList}
                                {step.step === 6 && step.activBreadCrumb === "Healthcare proxy" && tableText.healthProxiesList}
                                {step.step === 7 && step.activBreadCrumb === "Healthcare proxy" && tableText.healthCareProxyList}
                                {step.step === 9 && tableText.willExecutorList}
                                {step.step === 7 && step.activBreadCrumb === "Trustee" && tableText.trusteesList}
                                {step.step === 10 && step.activBreadCrumb === "Trust Beneficiary" && tableText.trustBeneficiariesList}
                                {step.step === 4 && tableText.poaAgentList}

                            </Box>
                        </Grid.Col>
                    </Grid>
                    }
                </div>
            )

        case 'trustTable':
            const deleteKeyContactTrust = (binderId) => {
                // console.log("Binder Id:" + binderId)
                setModalStatus(!modalMessage)
                let data = {
                    "delete": "yes",
                    "trust_id": binderId
                }
                // console.log("Executinon to this point")
                // console.log("flow id:" + flowId)
                setLoading(true)
                removeTrust(flowId, data).then((response) => {
                    // console.log("Delete contact success response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    }else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    } else {
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                        willFlow.set(response.data)
                        setLoading(false)
                    }
                }, (error) => {
                    console.log("Delete contact error: ", error)
                })
            }
            const showConfirmModalTrust = (binderId, name, relation) => {
                let temp = {
                    title: "Are you sure you want to delete " + name,
                    content: "Deleting this will result in permanent loss of data regarding " + name,
                    args: [binderId],
                    deleteAction: deleteKeyContactTrust
                }
                // setModalStatus(!modalStatus)
                setConfirmMessage(temp)
                setConfirmModalStatus(!confirmModalStatus)
            }
            // console.log(data)
            // console.log(step)
            return (
                <div className="tableMainContainer">
                    <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />

                    <TableHeader leftTitle={data.titles[0]} rightTitle={data.titles[1]} />

                    <div className="cardBorder">
                        {data && step.table_data.map((item, index) => {
                            // console.log(item)
                            return (
                                <Grid className="cardBorderRad cardWidth" key={index}>
                                    <Grid.Col span={8}>
                                        <CardLeftText name={data.rows[index].details[1].value}
                                        //  content={data.rows[index].details[1].value}
                                        // company={data.rows[index].details.length > 2 ? data.rows[index].details[2].value : ""}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                            <Button fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    marginRight: '1.0vw',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<BsFillEyeFill size={20} />}

                                                onClick={() => {
                                                    trustEditClick(item, item.main_binder_id)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}> {data.rows[index].buttons[0]}</Text>
                                            </Button>

                                            <ActionIcon variant="subtle"
                                                radius="xxs"
                                                onClick={() => {
                                                    showConfirmModalTrust(item.main_binder_id, data.rows[index].details[0].value, data.rows[index].details[1].value)
                                                }}
                                            >
                                                <BsTrashFill size={20} style={{ color: "#023047" }} />
                                            </ActionIcon>
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                            )
                        })}
                    </div>
                    {data && step.table_data.length === 0 && <Grid className="cardBorderRad" >

                        <Grid.Col span={12}>
                            <Box sx={(theme) => ({
                                color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center", '@media (max-width: 1280px)': {
                                    fontSize: theme.fontSizes.md,
                                }, '@media (max-width: 1024px)': {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}>
                                {step.step === 1 && tableText.trustList}
                                {step.step === 7 && tableText.trusteesList}
                                {step.step === 10 && tableText.trustBeneficiariesList}
                            </Box>
                        </Grid.Col>
                    </Grid>
                    }
                </div>
            )
        case 'healthcareTable':
            const deleteKeyContactHealth = (binderId) => {
                console.log("Binder Id:" + binderId)
                setModalStatus(!modalMessage)
                let data = {
                    "delete": "yes",
                    "healthcare_id": binderId
                }
                // console.log("Executinon to this point")
                setLoading(true)
                console.log("flow id:" + flowId)
                console.log(data)
                removeHealth(flowId, data).then((response) => {
                    console.log("Delete contact success response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    }else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    } else {
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                        willFlow.set(response.data)
                        setLoading(false)
                    }
                }, (error) => {
                    console.log("Delete contact error: ", error)
                })
            }
            const showConfirmModalHealth = (binderId, name) => {
                console.log(binderId, name)
                let temp = {
                    title: "Are you sure you want to delete " + name,
                    content: "Deleting this will result in permanent loss of data regarding " + name,
                    args: [binderId],
                    deleteAction: deleteKeyContactHealth
                }
                console.log(temp)
                // setModalStatus(!modalStatus)
                setConfirmMessage(temp)
                setConfirmModalStatus(!confirmModalStatus)
            }
            console.log(data)
            console.log(step)
            return (
                <div className="tableMainContainer">
                    <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />

                    <TableHeader leftTitle={data.titles[0]} rightTitle={data.titles[1]} />

                    <div className="cardBorder">
                        {data && step.table_data.map((item, index) => {
                            // console.log(item)
                            return (
                                <Grid className="cardBorderRad cardWidth" key={index}>
                                    <Grid.Col span={8}>
                                        <CardLeftText name={data?.rows[index]?.details[0]?.value} content={data?.rows[index]?.details[1]?.value}
                                            company={data?.rows[index]?.details?.length > 2 ? data?.rows[index]?.details[2]?.value : ""}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                            <Button fullWidth
                                                radius="xxs"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    marginRight: '1.0vw',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<BsFillEyeFill size={20} />}

                                                onClick={() => {
                                                    healthEditClick(item, item.main_binder_id, step)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}> {data.rows[index].buttons[0]}</Text>
                                            </Button>

                                            <ActionIcon variant="subtle"
                                                radius="xxs"
                                                onClick={() => {
                                                    showConfirmModalHealth(item.main_binder_id, data.rows[index].details[0].value)
                                                }}
                                            >
                                                <BsTrashFill size={20} style={{ color: "#023047" }} />
                                            </ActionIcon>
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                            )
                        })}
                    </div>
                    {data && step.table_data.length === 0 && <Grid className="cardBorderRad" >

                        <Grid.Col span={12}>
                            <Box sx={(theme) => ({
                                color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center", '@media (max-width: 1280px)': {
                                    fontSize: theme.fontSizes.md,
                                }, '@media (max-width: 1024px)': {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}>
                                {step.step === 1 && tableText.healthProxiesList}
                                {step.step === 7 && tableText.healthCareProxyList}
                            </Box>
                        </Grid.Col>
                    </Grid>
                    }
                </div>
            )
        case 'poaTable':
            const deleteKeyContactPoa = (binderId) => {
                // console.log("Binder Id:" + binderId)
                setModalStatus(!modalMessage)
                let data = {
                    "delete": "yes",
                    "poa_id": binderId
                }
                // console.log("Executinon to this point")
                // console.log("flow id:" + flowId)
                setLoading(true)
                removePOA(flowId, data).then((response) => {
                    console.log("Delete contact success response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    }else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    } else {
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                        willFlow.set(response.data)
                        setLoading(false)
                    }
                }, (error) => {
                    console.log("Delete contact error: ", error)
                })
            }
            const showConfirmModalPoa = (binderId, name, relation) => {
                let temp = {
                    title: "Are you sure you want to delete " + name,
                    content: "Deleting this will result in permanent loss of data regarding " + name,
                    args: [binderId],
                    deleteAction: deleteKeyContactPoa
                }
                // setModalStatus(!modalStatus)
                setConfirmMessage(temp)
                setConfirmModalStatus(!confirmModalStatus)
            }
            // console.log(data)
            // console.log(step)
            return (
                <div className="tableMainContainer">
                    <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />
                    <TableHeader leftTitle={data.titles[0]} rightTitle={data.titles[1]} />

                    <div className="cardBorder">
                        {data && step.table_data.map((item, index) => {
                            // console.log(item)
                            return (
                                <Grid className="cardBorderRad cardWidth" key={index}>
                                    <Grid.Col span={8}>
                                        <CardLeftText name={data.rows[index].details[0].value} content={data.rows[index].details[1].value}
                                            company={data.rows[index].details.length > 2 ? data.rows[index].details[2].value : ""}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                            <Button fullWidth
                                                radius="xxs"
                                                mr="xl"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    marginRight: '1.0vw',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<BsFillEyeFill size={20} />}

                                                onClick={() => {
                                                    poaEditClick(item, item.main_binder_id, step)
                                                }}

                                            >
                                                <Text style={{ textTransform: "capitalize" }}>{data.rows[index].buttons[0]}</Text>
                                            </Button>
                                            <ActionIcon variant="subtle"
                                                radius="xxs"
                                                onClick={() => {
                                                    showConfirmModalPoa(item.main_binder_id, data.rows[index].details[0].value, data.rows[index].details[1].value)
                                                }}
                                            >
                                                <BsTrashFill size={20} style={{ color: "#023047" }} />
                                            </ActionIcon>
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                            )
                        })}
                    </div>
                    {data && step.table_data.length === 0 && <Grid className="cardBorderRad" >

                        <Grid.Col span={12}>
                            <Box sx={(theme) => ({
                                color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center", '@media (max-width: 1280px)': {
                                    fontSize: theme.fontSizes.md,
                                }, '@media (max-width: 1024px)': {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}>
                                {step.step === 1 && tableText.poaList}
                                {step.step === 4 && tableText.poaAgentList}
                            </Box>
                        </Grid.Col>
                    </Grid>
                    }
                </div>
            )

        case 'selectTable':
            const deleteKeyContactSelect = (contactId) => {
                console.log("Binder Id:" + contactId)
                let data ={}
                setModalStatus(!modalMessage)
                if (flowId === "200000001") {
                    data = {
                        "RemoveContact": "yes",
                        "contact_id": contactId,
    
                    }
                } else if (flowId === "200000002") {
                    data = {
                        "RemoveContact": "yes",
                        "contact_id": contactId,
                        "main_binder_id": binderId,
                    }
                } else if (flowId === "200000005") {
                    data = {
                        "RemoveContact": "yes",
                        "contact_id": contactId,
                        "main_binder_id": binderId,
                    }
                } else if (flowId === "200000003") {
                    data = {
                        "RemoveContact": "yes",
                        "contact_id": contactId,
                        "main_binder_id": binderId,
                    }
                } 
               
                // console.log("Executinon to this point")
                // console.log("flow id:" + flowId)
                setLoading(true)
                removeKeyContact(flowId, data).then((response) => {
                    console.log("Delete contact success response: ", response.data)
                    if (response.data.message === "Invalid user") {
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    } else if(response.data.message === "Session timeout."){
                        showNotification({
                            id: 'load-data',
                            color: 'red',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconX size={16} />,
                            autoClose: 2000,
                        })
                        localStorage.removeItem("project_whiteney_email")
                        localStorage.removeItem("project_whiteney_user_data")
                        setLoading(false)
                        setLoggedIn(false)
                    
                    }else {
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                        willFlow.set(response.data)
                        setLoading(false)
                    }
                }, (error) => {
                    console.log("Delete contact error: ", error)
                })
            }

            const showConfirmModalContact = (contactId, firstName, middleName, lastName, componayName) => {
                let temp = {
                    title: "Are you sure you want to delete " + (firstName ? (firstName + " "+ middleName + " " + lastName):componayName),
                    content: "Deleting this will result in permanent loss of data regarding " + (firstName ? (firstName + " "+ middleName + " " + lastName):componayName),
                    args: [contactId],
                    deleteAction: deleteKeyContactSelect
                }
                console.log(temp)
                console.log(confirmModalStatus)
                // setModalStatus(!modalStatus)
                setConfirmMessage(temp)
                setConfirmModalStatus(!confirmModalStatus)
            }

            // console.log(data)
            // console.log(step)
            return (
                <div className="tableMainContainer">
                     <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />
                    <TableHeader leftTitle={data.titles[0]} rightTitle={data.titles[1]} />
                    <div className="cardBorder">
                        {data && data.rows.map((item, index) => {
                            // console.log(item)
                            return (
                                <Grid className="cardBorderRad cardWidth" key={index}>
                                    <Grid.Col span={8}>
                                    <Text
                                                    sx={(theme) => ({
                                                        fontSize: 24,
                                                        fontFamily: theme.fontFamily[0],
                                                        color: theme.colors.textColor[0],
                                                        fontWeight: 600,
                                                        '@media (max-width: 1280px)': {
                                                            fontSize: theme.fontSizes.md,
                                                        },
                                                        '@media (max-width: 1024px)': {
                                                            fontSize: theme.fontSizes.md,
                                                        },
                                                    })}
                                                >{item.first_name || item.last_name ? (item.first_name + " " + item.middle_name + " " + item.last_name) : item.company_name}</Text>
                                    </Grid.Col>
                                    <Grid.Col span={4} style={{ margin: 0 }} >
                                        <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                            <Button fullWidth
                                                radius="xxs"
                                                mr="xl"
                                                sx={(theme) => ({
                                                    backgroundColor: theme.colors.primary[0],
                                                    height: 40,
                                                    fontSize: theme.fontSizes.xxl,
                                                    fontWeight: 300,
                                                    textTransform: 'capitalize',
                                                    marginRight: '1.0vw',
                                                    fontFamily: theme.fontFamily[0],
                                                    '&:hover': {
                                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                    }
                                                })}
                                                rightIcon={<BiPlus size={20} />}

                                                onClick={() => {
                                                    AddContat(item.contact_id, item.question_id)
                                                }}

                                            >
                                                {"Add"}
                                            </Button>
                                            <ActionIcon variant="subtle"
                                                radius="xxs"
                                                onClick={() => {
                                                    showConfirmModalContact(item.contact_id, item.first_name, item.middle_name, item.last_name, item.company_name )
                                                }}
                                            >
                                                <BsTrashFill size={20} style={{ color: "#023047" }} />
                                            </ActionIcon>
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                               
                            )
                        })}
                    </div>
                    {data && step.table_data.length === 0 && <Grid className="cardBorderRad" >

                        <Grid.Col span={12}>
                            <Box sx={(theme) => ({
                                color: theme.colors.textColor[0], fontSize: theme.fontSizes.lg, fontWeight: 600, fontFamily: theme.fontFamily[1], display: "flex", justifyContent: "center", '@media (max-width: 1280px)': {
                                    fontSize: theme.fontSizes.md,
                                }, '@media (max-width: 1024px)': {
                                    fontSize: theme.fontSizes.sm,
                                },
                            })}>
                                {step.step === 1 && tableText.poaList}
                                {step.step === 4 && tableText.poaAgentList}
                            </Box>
                        </Grid.Col>
                    </Grid>
                    }
                </div>
            )
        case 'button':
            // console.log(step)
            return (
                <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', justifyContent: "flex-end", marginBottom: "2vw" }}>
                    <Button
                        rightIcon={<BiPlus />}
                        className="buttonMediumYesNo yesNoBtn tableAddAnotherBtn"
                        sx={(theme) => ({
                            color: theme.colors.primary[0],
                            fontSize: theme.fontSizes.xl,
                            fontFamily: theme.fontFamily[0],
                            fontWeight: 900,
                            borderRadius: theme.radius.xxs
                        })}
                        onClick={() => {
                            addAnother(data, step);
                            // console.log("Btn click called")  
                        }}
                    >
                        {data && step.table_data.length === 0 ? "Add New" : data.text}
                    </Button>
                </div>
            )
        default:
            return (
                <div>Default Case</div>
            )
    }
}

export default InputControl