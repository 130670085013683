import { Grid, Text, Affix, Button } from "@mantine/core";
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import FooterWill from "../../Component/footer2";
import '../../Custom.css';

const ReportDeceased = ({ navigate }) => {
    const { state } = useLocation();
    const { testatorId, testatorName, middleName, lastName } = state;
    // console.log(testatorId)

    return (
        <>
            <Grid columns={24}>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 600, fontSize: theme.fontSizes.xxl, color: theme.colors.textColor[2], fontFamily: theme.fontFamily[0] })} >Report as deceased</Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 700, fontSize: theme.fontSizes.lxl, color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })} > What happens when the Testator dies?</Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > We’re sad to hear about the passing of a loved one. It is a difficult time. We are here to help.</Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > It may give you some comfort to know that {testatorName} {middleName}  {lastName} was thinking of his/her family and friends when he/she created this binder that has information which you may find useful after their passing.
                        .</Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > {testatorName} {middleName}  {lastName} choose what to disclose once they have passed. We can make this information available to you, once we have verified {testatorName}’s passing.
                    </Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > The next page will guide you to report {testatorName} {middleName}  {lastName}’s death to us and send us a death certificate. Once we have reviewed the materials, you will be able to see the information [ ] wanted you to see.                </Text>
                </Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > If you have any questions, please reach out to us at [ ]. </Text>
                </Grid.Col>
                <Grid.Col span={24} style={{ height: 40 }}></Grid.Col>
                <Grid.Col span={24}>
                    <Text sx={(theme) => ({ fontWeight: 400, fontSize: theme.fontSizes.md, color: theme.colors.textColor[1], fontFamily: theme.fontFamily[1] })} > We are sorry for your loss.</Text>
                    <Text>[Company name]</Text>
                </Grid.Col>
            </Grid>

            <Affix
                sx={(theme) => ({
                    "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                        'left': 300
                    },
                    [`@media (max-width:1280px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        'left': 270
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        'height': '110px',
                        left: "0px"
                    },
                })}
            >
                <Affix position={{}} sx={(theme) => ({
                    bottom: 70, left: 330, zIndex: 2,
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        left: "45px"
                    },
                })}>
                    <Button
                        radius="xxs"
                        sx={(theme) => ({
                            color: theme.colors.textColor[0],
                            fontSize: theme.fontSizes.xxl,
                            fontFamily: theme.fontFamily[0],
                            fontWeight: 300,
                            height: 50,
                            backgroundColor: "transparent",

                            '&:hover': {
                                backgroundColor: theme.colors.buttonColor[9],
                                borderWidth: 1,
                                BorderStyle: "solid",
                                borderColor: theme.colors.primary[0],
                            }
                        })}
                        leftIcon={<BiChevronLeft />}
                        onClick={() => {
                            navigate('/myRoles');
                        }}
                    >
                        Back
                    </Button>
                </Affix>
                <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                    <Button
                        radius="xxs"
                        sx={(theme) => ({
                            backgroundColor: theme.colors.primary[0],
                            height: 50,
                            fontSize: theme.fontSizes.xxl,
                            fontWeight: 300,
                            fontFamily: theme.fontFamily[0],
                            '&:hover': {
                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                            }
                        })}
                        onClick={() => {
                            navigate('/uploadDeceDocs', { state: { testatorId: testatorId } });
                        }}
                    >
                        Continue
                    </Button>
                </Affix>

                <FooterWill navigate={navigate}/>
            </Affix>

        </>

    )
}

export default ReportDeceased