import { Grid,Progress, Text } from '@mantine/core'

export function FileHeader({ file,fileSize, onDelete, progress }) {
    console.log(file)
   return (
        <Grid columns={24} style={{ alignItems: 'center' }}>
            <Grid.Col span={16}
                sx={(theme) => ({
                    color: theme.colors.textColor[4],
                    ontSize: theme.fontSizes.sm,
                    fontFamily: theme.fontFamily[1],
                    fontWeight: 400,
                    display: 'flex',
                    justifyContent: 'left'
                })}
            >{file.name}</ Grid.Col>
            <Grid.Col span={1} pr={0} style={{ textAlign: "left", color: '#023047', fontWeight: 500 }}>
                <Text >{((file.size)/(1024*1024)).toFixed(2)}MB</Text>
            </Grid.Col>
            <Grid.Col span={1} pr={0} style={{ textAlign: "left", color: '#023047', fontWeight: 500 }}>
                <Text >{""}</Text>
            </Grid.Col>
            <Grid.Col span={5} >
                <Progress
                    // value={progress} 
                    sections={[
                        { value: progress, color: '#023047' }
                    ]}
                />
            </Grid.Col>
            <Grid.Col span={1} pr={0} style={{ textAlign: "right", color: '#023047', fontWeight: 500 }}>
                <Text >{progress}%</Text>
            </Grid.Col>
            {/* <Grid.Col span={1} style={{display: 'flex', justifyContent: 'right'}}> */}
            {/* <Button color="red" onClick={() => onDelete(file)}>Delete</Button> */}
            {/* <CloseButton aria-label="Close modal" color="red" onClick={() => onDelete(file)}/> */}
            {/* </Grid.Col> */}
        </Grid>
    )
}