
import { Affix, Button, Grid, LoadingOverlay, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import FlowTitle from "../Component/flowTitle";
import InputControlAccess from "../Component/inputControlAccess";
// import executorForm from './executorForm.json';
import '../Custom.css';
import FooterWill from "../Component/footer2";
import axios from "axios";
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from "@tabler/icons";
import { BASE_URL } from "../apis/flowReviewApis/global";

const AddNewExecutor = ({ navigate, setLoggedIn }) => {
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [prefill, setPrefill] = useState()
    const [loading, setLoading] = useState(false)
    const [executorForm, setExecutorForm] = useState();
    const [formError, setFormError] = useState();

    let data = {
        title: "Enter Trusted Person information",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
    function addNew() {
        // console.log("add new Executor Clicked")
        // console.log(answer)
        const url = BASE_URL + "/whitney/public/api/v1/my-key-contacts/add";
        // console.log(answer)
        let data = {}
        data = {
            'uuid': userData.uuid,
            "access_token": userData.access_token,
            "Save": "yes",
            ...answer
        }
        console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        setLoading(true)
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } if (response.data.status === 'error') {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            }
            else if (response.data.status === "success") {
                // console.log(response.data)
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setLoading(false)
                navigate('/accessControl');

            }

        }).catch((error) => {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: error.status,
                message: error.message,
                icon: <IconCheck size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        const url = BASE_URL + "/whitney/public/api/v1/my-key-contacts/add";

        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token

        // console.log(userData.uuid)
        // console.log(userData.access_token)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        setLoading(true)
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "success") {
                setExecutorForm(response.data)
                setLoading(false)

            } else if (response.data.status === "error" && response.data.message === "Invalid request") {
                setExecutorForm(response.data)
                setLoading(false)
            }

        }).catch((error) => {
            alert(error)
        })
    }, [])
    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    console.log(executorForm)
    return (
        <>
            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
                <Grid columns={24}>
                    <Grid.Col span={24} p="zx">
                        <div style={{ 'minWidth': '100%' }}>
                            {!loading && executorForm && <FlowTitle title={data.title} description={data.description} />}
                        </div>
                    </Grid.Col>
                </Grid>
                {formError ? <Text style={{ color: 'red', marginTop:30 }}>{formError}</Text> : ""}
                {!loading && executorForm && <Grid columns={24} sx={(theme) => ({ padding: theme.spacing.zx })} justify="space-between">
                
                    {executorForm?.inputList.map((item, index) => {
                        return (
                            <InputControlAccess
                                data={item}
                                setAnswer={setAnswer}
                                answer={answer}
                                prefill={prefill}
                                setPrefill={setPrefill}
                                key={index}


                                setLoggedIn={setLoggedIn}
                                navigate={setLoggedIn}
                            />
                        )
                    })

                    }

                    <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>
                </Grid>
                }

                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            {!loading && <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    navigate('/accessControl');
                                }}
                            >
                                Back
                            </Button>
                            }
                        </Affix>

                        <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                            {!loading && <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: theme.colors.primary[0],
                                    height: 50,
                                    fontSize: theme.fontSizes.xxl,
                                    fontWeight: 300,
                                    fontFamily: theme.fontFamily[0],
                                    '&:hover': {
                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                    }
                                })}
                                onClick={() => {
                                    addNew()
                                }}
                            >
                                Continue
                            </Button>
                            }
                        </Affix>

                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>
            </div>
        </>
    )
}

export default AddNewExecutor;