import { Upload, Photo, X, Icon as TablerIcon } from 'tabler-icons-react';
import { Dropzone, DropzoneStatus, MIME_TYPES } from '@mantine/dropzone';
import { Text, useMantineTheme, Group, Grid } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import { useState, useEffect } from 'react';
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
import { useField } from "formik";
import { UploadError } from './UploadError';
import { centerCrop } from 'react-image-crop';


export function MultipleFileUploadField({ navigate, setLoggedIn, name, answer, setAnswer, data, questionId, binderId, keyContactUpload, editFileUpload, deseDocs, testatorId }) {
    const theme = useMantineTheme();
    const [_, __, helpers] = useField(name);
    const [files, setFiles] = useState([]);
    const [dropedFile, setDropedFile] = useState([]);
    // console.log(answer)


    useEffect(() => {
        helpers.setValue(files);
        // helpers.setTouched(true);
    }, [files])

    function onDelete(file, getKey) {
        console.log(file)
        console.log(getKey)
        console.log(answer)

        setFiles((curr) => curr.filter((fw) => fw.file !== file));
        let ansArr = answer.document_ids.split(',');
        var filteredArray = ansArr.filter(e => e !== getKey)
        // console.log(filteredArray)
        setAnswer({ "document_ids": filteredArray.toString() })


    }

    function onUpload(file, url) {
        // console.log(file)
        // console.log(url)
        setFiles((curr) =>
            curr.map((fw) => {
                if (fw.file === file) {
                    return { ...fw, url };
                }
                return fw;
            })
        );
    }
    // console.log(data)
    return (
        <>
            {/* <Dropzone
                onDrop={(accFiles) => {
                    console.log('accepted files', accFiles)
                    const mappedAcc = accFiles.map(file => ({ file, errors: [] }));
                    setFiles(curr => [...curr, ...mappedAcc]);
                    setAnswer({ ...answer, [data.id]: accFiles })
                }}
                onReject={(rejFiles) => {
                    console.log('rejected files', rejFiles)
                    const mappedRej = rejFiles.map(file => ({ file }));
                    setFiles(curr => [...curr, ...rejFiles]);
                }}
                maxSize={3 * 1024 ** 2}
                accept={[MIME_TYPES.png,MIME_TYPES.jpeg, MIME_TYPES.svg, MIME_TYPES.gif, MIME_TYPES.doc, MIME_TYPES.docx, MIME_TYPES.pdf, MIME_TYPES.xls, MIME_TYPES.xlsx]}
            >
                {(status) => dropzoneChildren(status, theme)}

            </Dropzone> */}
            <Dropzone
                onDrop={(accFiles) => {
                    // console.log('accepted files', accFiles)
                    const mappedAcc = accFiles.map(file => ({ file, errors: [] }));
                    setFiles(curr => [...curr, ...mappedAcc]);
                    // setAnswer({ ...answer, [data.id]: uploadedFilesData.toString() })
                    // setDropedFile(accFiles)
                    setDropedFile((prev) => [...prev, accFiles])

                }}
                onReject={(rejFiles) => {
                    // console.log('rejected files', rejFiles)
                    const mappedRej = rejFiles.map(file => ({ file }));
                    setFiles(curr => [...curr, ...rejFiles]);
                }}
                maxSize={20000000}
                accept={[MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.gif, MIME_TYPES.doc, MIME_TYPES.docx,  MIME_TYPES.xls, MIME_TYPES.xlsx]}
                multiple ={true}

            >
                <Group position="center" spacing="xl" sx={(theme) => ({
                    minHeight: 80, pointerEvents: 'none', '@media (max-width: 1280px)': {
                        minHeight:80,
                    },
                    '@media (max-width: 1024px)': {
                        minHeight: 80,
                    },
                })}>
                    <Dropzone.Accept>
                        <IconUpload
                            size={50}
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size={50}
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconPhoto size={50} stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                        <Text inline sx={(theme) => ({
                            fontSize: theme.fontSizes.xxl, color: theme.colors.primary[0], fontWeight: 300, fontFamily: theme.fontFamily[0],
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.lg,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.md,
                            },
                        })}>
                            Drag images here or click to select files
                        </Text>
                        <Text color="dimmed" inline mt={7} sx={(theme) => ({
                            fontSize: theme.fontSizes.sm, color: theme.colors.primary[1], fontWeight: 100, fontFamily: theme.fontFamily[0],
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.xs,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.xs,
                            },
                        })}>
                            Attach as many files as you like, each file should not exceed 20mb 
                        </Text>
                        <Text color="dimmed" inline mt={7} sx={(theme) => ({
                            fontSize: theme.fontSizes.sm, color: theme.colors.primary[1], fontWeight: 100, fontFamily: theme.fontFamily[0], textAlign: 'center',
                            '@media (max-width: 1280px)': {
                                fontSize: theme.fontSizes.xs,
                            },
                            '@media (max-width: 1024px)': {
                                fontSize: theme.fontSizes.xs,
                            },
                        })}>(pdf, jpeg, png, gif, doc, docx, xls, xlsx)</Text>
                    </div>
                </Group>
            </Dropzone>
            {/* <div>{JSON.stringify(files)}</div> */}
            {files.map((fileWrapper, key) => (
                <Grid columns={12} key={key}>
                    {fileWrapper.errors.length ? (
                        <Grid.Col>
                            <UploadError
                                file={fileWrapper.file}
                                onDelete={onDelete}
                                errors={fileWrapper.errors}
                            />
                        </Grid.Col>
                    ) : (
                        <Grid.Col>
                            <SingleFileUploadWithProgress
                                onDelete={onDelete}
                                onUpload={onUpload}
                                file={fileWrapper.file}
                                question_id={questionId}
                                binderId={binderId}
                                dropedFile={dropedFile}
                                setDropedFile={setDropedFile}
                                setAnswer={setAnswer}
                                answer={answer}
                                keyContactUpload={keyContactUpload}
                                editFileUpload={editFileUpload}

                                deseDocs={deseDocs}
                                testatorId={testatorId}
                                navigate={navigate}
                                setLoggedIn={setLoggedIn}


                            />
                        </Grid.Col>
                    )}
                </Grid>
            ))}
        </>
    )
}

