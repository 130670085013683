import React, { useEffect, useState } from "react";
import FlowTitle from '../Component/flowTitle'
import InputControl from '../Component/inputControl'
import { Affix, Button, Text, LoadingOverlay, Grid, Box, Stepper } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import TipModal from '../Component/modal'
import { getLivingWillFlow, listBinderDetails } from '../apis/livingWillApis'
import FooterWill from "../Component/footer2";
import '../Custom.css';
import { IoDocumentTextOutline } from "react-icons/io5";
import { livingWillStepperBack, livingWillStepperNext } from "../apis/stepperFlow";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

const LivingWill = ({ navigate, setLoggedIn }) => {
    const initialValues = {
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [active, setActive] = useState(1);  // for stepper

    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    // const willFlow = require("../willFlow.json")

    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [handleSkips, setHandleSkips] = useState(false);
    const [binderId, setBinderId] = useState();

    //stepper status
    const [stepTwo, setStepTwo] = useState()

    const handleClick = (step, btnClick) => {
        /*Set breadcrumb*/
        // setActive((current) => (current < 3 ? current + 1 : current));
        livingWillStepperNext(step, setActive)
        /*End Set breadcrumb*/
        // console.log("step from handleClick: ", step)
        // console.log(btnClick)
        setHandleSkips(false)
        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (btnClick === "continue with api") {
            if (step.step === 4 || step.step === 2) {
                submitNotes(step)
            } else if (step.step === 3) {
                submitForm(step)            // call step -3 
            }
            else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (!step.lastStep) {
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            navigate('/essentialDocs')
            setCounter(0)
        }
    }



    const submitNotes = (step) => {
        // console.log("Submit Notes called")
        let data = {}
        // console.log(answer)
        // console.log(answer.answer)
        // check Answer type and set it
        if (!answer) {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/essentialDocs')
                setCounter(0)
            }
            return
        }

        if (step.step == 2) {
            // console.log("step-2 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        if (step.step == 4) {
            // console.log("step-4 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        setLoading(true)
        getLivingWillFlow("200000004", data).then((response) => {
            // console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/essentialDocs')
                    // setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
            }

        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    // submitForm (upload files)
    const submitForm = (step) => {
        
        let data = {}
        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/essentialDocs')
                // setCounter(0)
            }
            return
        }

        if (step.step === 3) {                   //upload will step -3 
            // console.log(questionId)
            // console.log(answer)
            data = {
                "SaveDocument": "Submit",    // 
                "question_id": questionId,
                ...answer
            }
        }
        console.log(data)
        setLoading(true)
        getLivingWillFlow("200000004", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {

                if (response.data.status === 'error') {
                    // console.log("error")
                    // console.log(response.data.message)
                    // setFormError(response.data.message);
                    setPrevStep(counter)
                    setCounter(counter + 1)
                    setLoading(false);

                } else if (response.data.status === 'success') {
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                    // console.log("sucess")
                    if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(counter + 1)
                    } else {
                        navigate('/essentialDocs')
                        // setCounter(0)
                    }
                    setWillFlow(response.data)
                    setLoading(false)
                    // setAnswer()
                    setEditMode(false)
                    setFormError("");
                }

            }



        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const backBtnClick = (step) => {
        /*Set breadcrumb*/
        // setActive((current) => (current > 0 ? current - 1 : current));
        livingWillStepperBack(step, setActive)
        /*End Set breadcrumb*/
        setHandleSkips(true)
        setFormError("");
        // console.log(willFlow.steps[0])
        if (counter != 0) {

            if (willFlow.steps[0].visible === false && step.step === 2) {
                // console.log("step 2 true")
                navigate('/essentialDocs')
            }
            else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/essentialDocs')
        }
    }



    useEffect(() => {
        if (!loading) {
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [editMode])

    useEffect(() => {
        setLoading(true)
        listBinderDetails("200000004").then((response) => {
            // console.log("Binder List for topic Id 200000004: ", response.data)
        }, (err) => {
            // console.log("Error in getting binder List")
        })
        getLivingWillFlow("200000004").then((response) => {
            console.log("Response of get willflow object: ", response.data)
            // console.log(response.data.main_binder_id)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setWillFlow(response.data)
                setBinderId(200000004)
            }
        }, (error) => {
            // console.log("Error in getting willflow object: ", error)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(willFlow).length > 0) {
            setLoading(false)
        }
        // console.log(willFlow)
    }, [willFlow])

    // useEffect(() => {
    //     // console.log("Loading changed: ", loading, willFlow)
    // }, [loading])

    useEffect(() => {
        // console.log(handleSkips)
        // console.log(counter)
        if (!loading && handleSkips === false) {   // backbutton case
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 4 is false")
                setCounter(counter + 1)
            }


            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
        if (!loading && handleSkips === true) {   // backbutton case
           let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [counter, loading])
    

    function setBreadCrumb(stepIndex) {
        setFormError("")
        setEditMode(false)
        switch (stepIndex) {
            case 0:
                if (willFlow.steps[0].visible === true) {
                    setCounter(0)
                } else {
                    setCounter(1)
                }

                break;
            case 1:
                setCounter(2)
                break;
            case 2:
                setCounter(3)
                break;
            default:
                setCounter(0)
        }
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }
    if(Object.keys(willFlow).length === 0){
        console.log(willFlow)
        navigate('/essentialDocs')
    }
    // // console.log(modalStatus)
    return (
        <div className="mainContainer" >
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} p="zx">
                        <Box >
                            <Stepper active={active}

                                onStepClick={(stepIndex) => {
                                    console.log(stepIndex)
                                    setActive(stepIndex)

                                    setBreadCrumb(stepIndex)
                                }} breakpoint="xxs" size="sm">
                                {willFlow.breadCrumbsList.map((breadCrumb, index) => {
                                    // setActiveBreadCrumb(breadCrumb)

                                    return (
                                        <Stepper.Step
                                            label={breadCrumb.name}
                                            key={index}
                                            sx={(theme) => ({
                                                color: theme.colors.textColor[0],
                                            })}
                                            color={breadCrumb.status === "not_done" ? "red" : breadCrumb.status === "start" ? "green" : ""}
                                            completedIcon={breadCrumb.status === "not_done" ? <IconCircleX /> : ""}
                                        >
                                            {/* Step {index + 1} content: {breadCrumb} */}
                                        </Stepper.Step>
                                    )
                                })}
                            </Stepper>
                        </Box>
                        {/* <Box style={{ "display": 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between' }}>
                            {willFlow.breadCrumbsList.map((breadCrumb, index) => {
                                // // console.log("Breadcrumb List: ", willFlow.steps[counter].activBreadCrumb)

                                return (
                                    <div
                                        className="breadCrumbMain"
                                        onClick={(e) => {
                                            console.log("Click on breadCrumb", breadCrumb)
                                            for (let i = 0; i < willFlow.steps.length; i++) {
                                                if (willFlow.steps[i].activBreadCrumb == breadCrumb) {
                                                    console.log("Navigate to step: ", willFlow.steps[i].step)
                                                    setCounter(willFlow.steps[i].step - 1)
                                                    break;
                                                }
                                            }
                                        }}

                                        key={index}
                                    >
                                        <RiRadioButtonFill color={breadCrumb == willFlow.steps[counter].activBreadCrumb ? "#023047" : '#ACB0B9'} size={14} />
                                        {breadCrumb == willFlow.steps[counter].activBreadCrumb ?
                                            <BreadCurmbText title={breadCrumb} />
                                            :
                                            <BreadCurmbText2 title={breadCrumb} />
                                        }
                                    </div>
                                )
                            })}
                        </Box> */}
                    </Grid.Col>
                </Grid>
            }
            {!loading &&
            <Grid columns={24} >
            <Grid.Col span={24} pl="zx" pr="zx" pt={22} pb={10}>
            <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                    <IoDocumentTextOutline />
                    <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}>  Living Will</Text>
                </Box>
                </Grid.Col>
                </Grid>
            }
            <Grid columns={24}>
                <Grid.Col span={24} pl="zx" pt={5}>
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>

            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24}>
                {willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControl
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            setEditMode ={setEditMode}
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            loading = {loading}
                            setLoading = {setLoading}
                            setLoadingMock = {setLoadingMock}

                            setPrefill={setPrefill}

                            flowId="200000004"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
            </Grid>}

            {/* </div>} */}
            {!modalStatus && !loading &&
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 4) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>
                            </Affix>
                        }
                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>
            }
        </div>
    )
}

export default LivingWill