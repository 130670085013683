import React, { useState } from "react";
import { Box, Text, useMantineTheme } from '@mantine/core'
import '../Custom.css';
import { HelpModal } from "./modal";

const FooterWill = ({navigate}) => {
    const theme = useMantineTheme();
    const [helpOpened, setHelpOpened] = useState(false);
    return (
        <>
         <HelpModal
            helpOpened={helpOpened}
            setHelpOpened={setHelpOpened}
         />
            <div className="setFooterText2">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={(theme) => ({ fontSize: theme.fontSizes.sm, color: theme.colors.textColor[2], display: "flex", flexDirection: "row", gap: 10 })} >
                        <Box
                            className="footerLink"
                            onClick={(e) => {
                                console.log("licence Agrement")
                                navigate('/licenceAgreement')
                            }}
                        >License Agreement</Box>  |
                        <Box
                            className="footerLink"
                            onClick={(e) => {
                                console.log("Privacy")
                                navigate('/privacy')
                            }}
                        > Privacy </Box> |
                        <Box
                            className="footerLink"
                            onClick={(e) => {
                                console.log("Security")
                                navigate('/security')
                            }}
                        >Security </Box> |
                        <Box
                            className="footerLink"
                            onClick={(e) => {
                                setHelpOpened(true)

                            }}
                        >Give Feedback </Box>
                    </Box>
                </div>
                <div>
                    <Text className="fontSize6 textColor4" > 2022 Project Whitney Inc. All Rights reserved </Text>
                </div>
            </div>
        </>
    )
}

export default FooterWill