import { Avatar, Box, Grid, Image, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {InfoCard2} from "../Component/infoCard";
import '../Custom.css'
import { ReactComponent as AuditTrail1 } from '../assets/Icons/auditTrail1.svg' 
import { ReactComponent as AuditTrail } from '../assets/Icons/auditTrail.svg'

const ContactInfoBinder = ({ navigate, setDynamicMenu }) => {
    const { state } = useLocation();
    const { testatorId, testatorImage, testatorName } = state;
    const [contactDetail, setContactDetails] = useState({ testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName })
    useEffect(()=>{
        setDynamicMenu({menuStatus: true, testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName})
    },[])
    return (
        <>
            {/* <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}> */}
            <div className="mainContainer">
                <Grid columns={12}>
                    <Grid.Col span={12}>
                        <Box style={{ display: "flex" }}>
                            <Box>
                                <div className="profile-image">
                                    <div style={{ display: "flex", }}>
                                        {contactDetail.testatorImage ? <Image
                                            src={contactDetail.testatorImage}
                                            alt="Random unsplash image"
                                            width={70}
                                            height={70}
                                        /> : <Avatar radius="50%" size={70} />}
                                    </div>

                                </div>
                            </Box>
                            <Box style={{ paddingLeft: 10 }}>
                                <Text sx={(theme)=>({color: theme.colors.textColor[0], fontWeight:700 , fontSize: theme.fontSizes.lxl, fontFamily: theme.fontFamily[0]})} > {contactDetail.testatorName}'s Information Binder</Text>
                                {/* <Text sx={(theme)=>({color: theme.colors.textColor[1], fontWeight:400 , fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[0]})} > Lorem ipsum began as scrambled, nonsensical Latin derived from Cicero's 1st-century BC text De Finibus Bonorum et Malorum.</Text> */}
                            </Box>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={4}>
                        <Box
                            className="cardHover"
                            onClick={() => {
                                console.log("click")
                                navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } })
                            }}
                        >
                            <InfoCard2 title='Estate planning' description='Your estate planning documents, including the Will' Icon={<AuditTrail1 />}
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='Assets' description='Your possessions, income, inheritance and insurance' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='Debts/Liabilties' description='Monies you woe and payment you have to make others' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='For the Home' description='List of service providers of your home(s)' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='Key Contacts' description='Your family friends, attorneys, doctors and others' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='Special Items' description='Your dependent pets, and funeral arrangements' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <InfoCard2 title='Important Documents' description='Documents your Executor would need' Icon={<AuditTrail />} color="#7a7d7b" />
                    </Grid.Col>



                    <Grid.Col span={3}>
                        <div style={{ height: 40, color: '#ffffff' }}> </div>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <div style={{ height: 40, color: '#ffffff' }}> </div>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <div style={{ height: 40, color: '#ffffff' }}> </div>
                    </Grid.Col>



                </Grid>
                <></>
            </div>
        </>
    )
}

export default ContactInfoBinder;