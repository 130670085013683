export const monthName = 
    {
        "01": "Jan",
         "02": "Feb", 
         "03": "March", 
         "04": "April",
         "05": "May",
         "06": "June",
         "07": "July",
         "08": "Aug",
         "09": "Sep",
         "10": "Oct",
         "11": "Nov",
         "12": "Dec",
        }
