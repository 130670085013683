import React from "react";
import axios from "axios";
import { BASE_URL } from "./global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    // onUploadProgress: function (progressEvent) {
    //     console.log("Pregress event: ", progressEvent)
    // },

})

export const gethealthCareFlowReview = (topicId, testatorId, submiData) => {
    // console.log("binder id from: ", topicId, testatorId)
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow")
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
       
    if(submiData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'testator_id': testatorId,
            ...submiData

        }
        // console.log(data)
        Object.keys(data).map((item, index)=>{
            formData.set(item, data[item])
        })
    }else{
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'testator_id': testatorId,
        }
        // console.log(data)
        Object.keys(data).map((item, index) => {
         formData.set(item, data[item])
        })
    }
    console.log(data)

    return whitney.post(willFlowUrl, formData)
    
}


export const gethealthCareFlowReviewAs = (topicId, testatorId, keyContactId ,live, mode, submiData) => {
    // console.log("binder id from: ", topicId, testatorId)
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow") 
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
    if(live === "no"){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'userId': testatorId,
            "mode": mode,
            "permitted_contact_id": keyContactId,
            "isAlive": live,
            ...submiData

        }
    }else
    if(submiData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'userId': testatorId,
            "mode": mode,
            "permitted_contact_id": keyContactId,
            "isAlive": live,
            ...submiData

        }
  
    }else{
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            'userId': testatorId,
            "mode": mode,
            "isAlive": live,
            "permitted_contact_id": keyContactId,
        }   
    }
    console.log(data)
    Object.keys(data).map((item, index)=>{
        formData.set(item, data[item])
    })
    return whitney.post(willFlowUrl, formData)
    
}

export const removeHealthReviewAs = (flowId, data, formdata, binderId) => {
    // console.log("flowId: ", flowId)
    // console.log("Data: ", data)
    // console.log("Form Data: ", formdata)
    // console.log("binder id from: ", binderId)
    if (!flowId) {
        // console.log("Error in will flow please send flowid with will flow")

        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId
                                
    // console.log(willFlowUrl)
    if (!data) {
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        if (binderId) {
            // console.log("Binder id added: ", binderId)
            data["main_binder_id"] = binderId
        }
        formData = new FormData()
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        return whitney.post(willFlowUrl, formData)
    } else {
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        if (binderId?.length > 0) {
            data["main_binder_id"] = binderId
        }
        // console.log("Data test >>>>", data)
        if (!formdata) {
            formData = new FormData()
            Object.keys(data).map((item, index) => {
                formData.set(item, data[item])
            })
        } else {
            formData = new FormData(data)
        }
        // console.log("Form data", formData)
        return whitney.post(willFlowUrl, formData)
    }



}


export const  deleteKeyContactFromList = (topicId,submitData)=>{
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow")
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
       
    if(submitData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            ...submitData

        }
        // console.log(data)
        Object.keys(data).map((item, index)=>{
            formData.set(item, data[item])
        })
    }
    console.log(data)

    return whitney.post(willFlowUrl, formData)
}

export const  deleteFileFromList = (topicId,submitData)=>{
    let data = {}
    let formData = new FormData()
    if (!topicId) {
        // console.log("Error in will flow please send flowid with will flow")
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/update-access-permission-info/category/10001/topic/" + topicId
       
    if(submitData){
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token, 
            ...submitData

        }
        // console.log(data)
        Object.keys(data).map((item, index)=>{
            formData.set(item, data[item])
        })
    }
    console.log(data)

    return whitney.post(willFlowUrl, formData)
}