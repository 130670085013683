import React, { useEffect, useState } from "react";
import FlowTitle from '../Component/flowTitle'
import InputControl from '../Component/inputControl'
import { Affix, Button, Text, LoadingOverlay, Grid, Box, Stepper } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import TipModal from '../Component/modal'
import { getTrustWillFlow, listBinderDetails } from '../apis/trustApis';
import FooterWill from "../Component/footer2";
import '../Custom.css';
import { HeartHandshake } from "tabler-icons-react";
import { trustStepperBack, trustStepperNext } from "../apis/stepperFlow";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

const Trust = ({ navigate, setLoggedIn }) => {
    // debugger 
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [active, setActive] = useState(1);  // for stepper
    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [beneficiaries, setBeneficiaries] = useState(true)
    const [executors, setExecutors] = useState(true)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    // const willFlow = require("../willFlow.json")

    const [listPage, setListPage] = useState(false)
    const [binderList, setBinderList] = useState({})
    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [binderId, setBinderId] = useState();
    const [handleSkips, setHandleSkips] = useState(false)

    //set adding new trust us
    const [addNewTrust, setAddNewTrust] = useState(false)
    const [addAnotherBtn, setAddAnotherBtn] = useState(false)

    const handleClick = (step, btnClick) => {
        /*Set breadcrumb*/
        // setActive((current) => (current < 5 ? current + 1 : current)); 
        trustStepperNext(step, setActive)
        /*End Set breadcrumb*/
        console.log("step from handleClick: ", step)
        console.log("btn click ", btnClick)
        setHandleSkips(false)
        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 4 && btnClick === "secondery") {
            setPrevStep(counter)
            setBeneficiaries(false)
            setCounter(6)
        } else if (step.step === 5 && btnClick === "secondery") {
            // console.log(step, btnClick)
            setPrevStep(counter)
            setExecutors(false)
            setCounter(7)
            // let temp = {
            //     title: "Modal title",
            //     content: "Modal description"
            // }
            // setModalMessage(temp)
            // setModalStatus(!modalStatus)
        } else if (step.step === 8 && btnClick === "secondery") {
            setPrevStep(counter)
            setExecutors(false)
            setCounter(10)
            // let temp = {
            //     title: "Modal title",
            //     content: "Modal description"
            // }
            // setModalMessage(temp)
            // setModalStatus(!modalStatus)
        } else if (btnClick === "continue with api") {
            if (step.step === 3 || step.step === 11) {
                submitNotes(step)
            } else if (step.step === 5 || step.step === 4 || step.step === 6 || step.step === 9) {
                //  // console.log("step true", step.step)
                submitForm(step)
            } else if (step.step === 2) {
                setAddNewTrust(true)
                submitForm(step);
            } else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (step.step === 5 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (step.step === 8 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (!step.lastStep) {
            console.log("step 5 true")
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            // navigate('/essentialDocs')
            setCounter(0)
            setPrevStep(-1)
        }
    }

    const submitForm = (step) => {
        console.log("submit form call", step)
        // console.log(answer)
        let data = {}

        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                // navigate('/essentialDocs')
                setCounter(0)
                setPrevStep(-1)
            }
            return
        }
        if (step.step === 1) {
            //  // console.log("step 1")
            data = {
                "SaveBinder": "yes",
                ...answer
            }
        } else if (step.step === 2 && addNewTrust === false) {
            if (binderId === "undefined") {
                data = {
                    "SaveBinder": "yes",
                    ...answer
                }
            } else {
                data = {
                    "SaveBinder": "yes",
                    "main_binder_id": binderId,
                    ...answer
                }
            }

        } else if (step.step === 2 && addNewTrust === true) {
            data = {
                "SaveBinder": "yes",
                ...answer
            }
        } else if (step.step === 4 && addNewTrust === true) {
            data = {
                "SaveDocument": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                ...answer
            }
        } else if (step.step === 6 && addNewTrust === true) {
            data = {
                "SaveKeyContact": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "contact_type_id": 104,
                ...answer
            }
        } else if (step.step === 9 && addNewTrust === true) {
            data = {
                "SaveKeyContact": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "contact_type_id": 105,
                ...answer
            }
        }
        else if (step.step === 4 && addNewTrust === false) {
            data = {
                "SaveDocument": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                ...answer
            }
        }
        else if (step.step === 6 && addNewTrust === false) {
            data = {
                "SaveKeyContact": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "contact_type_id": 104,
                ...answer
            }
        }
        else if (step.step === 9 && addNewTrust === false) {
            data = {
                "SaveKeyContact": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "contact_type_id": 105,
                ...answer
            }
        }
        console.log("data before api call", data)
        if (editMode) {
            data.question_id = editMode?.data?.question_id
            data.contact_type_id = editMode?.data?.contact_type_id
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        setLoading(true)
        getTrustWillFlow("200000003", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === 'error' && step.step === 2) {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'error' && step.step === 6) {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'error' && step.step === 9) {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                console.log(response.data)
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
                setBinderId(response.data.main_binder_id)
            } else {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setBinderId(response.data.main_binder_id)
            }

        }, (error) => {
            //  // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const submitNotes = (step) => {
        //  // console.log("Submit Notes called")
        let data = {}
        if (!answer) {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                // navigate('/essentialDocs')
                setCounter(0)
                setPrevStep(-1)
            }
            return
        }
        if (step.step == 3) {

            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "main_binder_id": binderId,
                "answer": answer.answer
            }
        }
        if (step.step == 11) {
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "main_binder_id": binderId,
                "answer": answer.answer
            }
        }
        console.log(data)
        setLoading(true)
        getTrustWillFlow("200000003", data).then((response) => {
            //  // console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
            }

        }, (error) => {
            //  // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    //AddContat to benifi and agent
    const AddContat = (keyContactIds, questionIds) => {
        console.log(keyContactIds)
        console.log(questionIds)

        let data = {}
        data = {
            "AddContact": "yes",
            "question_id": questionIds,
            "main_binder_id": binderId,
            "contact_id": keyContactIds,
        }
        console.log(data)
        setLoading(true)
        getTrustWillFlow("200000003", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === 'success') {
                setWillFlow(response.data)
                setLoading(false)
            }
        })
    }


    const backBtnClick = (step) => {
        setEditMode(false)
        setAddAnotherBtn(false)
        /*Set breadcrumb*/
        // setActive((current) => (current > 0 ? current - 1 : current));
        trustStepperBack(step, setActive)
        /*End Set breadcrumb*/
        setHandleSkips(true)
        setFormError("");
        //  // console.log(counter)
        //  // console.log(step)
        //  // console.log(willFlow.steps[7].visible)
        if (counter) {
            //  // console.log("true")
            if (willFlow.steps[4].visible === false && step.step === 7) {
                //  // console.log("step 7 true")
                setEditMode(false)
                setCounter(3)
                setPrevStep(2)

            } else if (willFlow.steps[4].visible === true && step.step === 7) {
                //  // console.log("step 7 true")
                setEditMode(false)
                setCounter(4)
                setPrevStep(3)

            } else if (step.step === 7) {
                //  // console.log("step 7 true")
                setEditMode(false)
                setCounter(3)
                setPrevStep(2)

            } else if (willFlow.steps[7].visible === false && step.step === 10) {
                //  // console.log("step 10 true")
                setEditMode(false)
                setCounter(6)
                setPrevStep(5)

            } else if (willFlow.steps[7].visible === true && step.step === 10) {
                //  // console.log("step 10 true")
                setEditMode(false)
                setCounter(7)
                setPrevStep(6)

            } else if (step.step === 10) {
                //  // console.log("step 10 true")
                setEditMode(false)
                setCounter(5)
                setPrevStep(3)

            } else if (step.step === 3) {
                //  // console.log("step 3 true")
                setEditMode(false)
                setCounter(0)
            } else if (step.step === 2) {
                //  // console.log("step 2 true")
                // console.log(willFlow.steps[1])
                let temp = willFlow
                temp.steps[1].inputList.map((item, index) => {
                    item.prefill = ""
                })
                setWillFlow(temp)
                setEditMode(false)
                setCounter(0)
                setBinderId()
                setAddNewTrust(true)
                setWillFlow(willFlow)
                // setAnswer()
            }

            else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            // navigate('/essentialDocs')
            setCounter(0)
            setPrevStep(-1)
        }
    }
    // add another record
    const addAnother = (data, step) => {
        console.log(data)
        console.log(step.step)
        console.log(counter)
        // console.log(answer)
        setAddAnotherBtn(true)
        setAnswer(initialValues)
        if (counter === 0 || counter === 6 || counter === 9) {
            if (step.step === 1) {
                trustStepperNext(step, setActive)
                setCounter(1)
                setPrevStep(0)
                setAddNewTrust(true)
                setPrefill()
            } else if (step.step === 7) {
                //  // console.log("step 7 true")
                setCounter(5)
                setPrevStep(4)
            } else if (step.step === 10) {
                // console.log("step 10 true")
                setCounter(8)
                setPrevStep(7)
            } else {
                console.log("step 1")
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {

            if (step.step === 1) {

                setCounter(1)
                setPrevStep(0)
                setAddNewTrust(true)
                setPrefill()
            } else {
                // navigate('/essentialDocs') 
                setCounter(0)
                setPrevStep(-1)
            }


        }

    }
    const btnClick = (data) => {
        console.log(data)
        setAnswer(initialValues)
        setCounter(counter - 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = willFlow
            //  // console.log("Data from btn click: ", data)
            //  // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter - 1].inputList.map((item, index) => {
                //  // console.log("Match see: ", item.id, data[item.id])
                item.prefill = data[item.id]
            })
            //  // console.log("Temp Object from btnCLick: ", temp.steps[counter - 1].inputList)
            setWillFlow(temp)
        }
    }

    const trustEditClick = (data, main_binder_id) => {
        console.log(data)
        console.log(main_binder_id)
        console.log(counter)
        setBinderId(main_binder_id)
        setCounter(counter + 1)
        setEditMode({ value: true, data: data })
        setBinderId(main_binder_id)
        setAddNewTrust(false)
        //  // console.log("binder id is:" + main_binder_id)


        if (data) {
            let temp = willFlow
            //  // console.log("Data from btn click: ", data)
            //  // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter + 1].inputList.map((item, index) => {
                //  // console.log("Match see: ", item, item.id, data[item.id])
                item.prefill = data[item.id]
            })
            //  // console.log("Temp Object from btnCLick: ", temp.steps[counter + 1].inputList)
            setWillFlow(temp)
        }
    }

    useEffect(() => {
        setLoading(true)
        listBinderDetails("200000003").then((response) => {
            //  // console.log("Binder List for topic Id 200000002: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {


                let temp = response.data
                temp.inputList = response.data.binder_list
                //  // console.log("Finale temp here: ", temp)
                setBinderList(temp)
                setListPage(true)
            }
        }, (err) => {
            // console.log("Error in getting binder List")
        })
        getTrustWillFlow("200000003").then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {
                setWillFlow(response.data)
            }
        }, (error) => {
            // console.log("Error in getting willflow object: ", error)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(binderList).length > 0) {
            setLoading(false)
        }
    }, [willFlow])



    useEffect(() => {
        //  // console.log(handleSkips)
        //  // console.log(counter)
        if (!loading && handleSkips === false) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 5) {
                // console.log("step 5 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 8) {
                // console.log("step 8 is false")
                setCounter(counter + 2)
            } else {
                setCounter(counter)
            }

            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                //  // console.log(item)
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        } if (!loading && handleSkips === true) {   // backbutton case

            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                //  // console.log(item)
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        }
    }, [counter])

    useEffect(() => {
        //  // console.log(handleSkips)
        //  // console.log(counter)
        if (!loading) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 5) {
                // console.log("step 5 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 8) {
                // console.log("step 8 is false")
                setCounter(counter + 2)
            } else {
                setCounter(counter)
            }
        }
    }, [loading])


    function setBreadCrumb(stepIndex) {
        console.log("setBreadCrumb", stepIndex)
        let temp = {
            title: "Mandatory",
            content: "You do not have 'select' OR 'create' trust yet, 'select' OR 'create' a trust."
        }
        setFormError("");
        setEditMode(false)
        switch (stepIndex) {
            case 0:
                setCounter(0)
                break;
            case 1:
                if (binderId === undefined) {
                    setLoading(false)
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(1)
                }

                break;
            case 2:
                if (binderId === undefined) {
                    setLoading(false)
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(3)
                }
                break;
            case 3:
                if (binderId === undefined) {
                    setLoading(false)
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    if (willFlow.steps[4].visible === true) {
                        setCounter(4)
                    } else {
                        setCounter(6)
                    }
                }

                break;
            case 4:
                if (binderId === undefined) {
                    setLoading(false)
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    if (willFlow.steps[7].visible === true) {
                        setCounter(7)
                    } else {
                        setCounter(9)
                    }
                }

                break;
            case 5:

                if (binderId === undefined) {
                    console.log("stepIndex", stepIndex)
                    setLoading(false)
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(10)
                }

                break;
            default:
                setCounter(0)
        }
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }
    if (Object.keys(willFlow).length === 0) {
        console.log(willFlow)
        navigate('/essentialDocs')
    }
    // console.log(willFlow)
    return (
        <div className="mainContainer" >
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} p="zx">
                        <Box >
                            <Stepper active={active}

                                onStepClick={(stepIndex) => {
                                    console.log(stepIndex)
                                    setActive(stepIndex)

                                    setBreadCrumb(stepIndex)
                                }} breakpoint="xxs" size="sm">
                                {willFlow.breadCrumbsList.map((breadCrumb, index) => {
                                    return (
                                        <Stepper.Step
                                            label={breadCrumb.name}
                                            key={index}
                                            sx={(theme) => ({
                                                color: theme.colors.textColor[0],
                                            })}
                                            color={breadCrumb.status === "not_done" ? "red" : breadCrumb.status === "start" ? "green" : ""}
                                            completedIcon={breadCrumb.status === "not_done" ? <IconCircleX /> : ""}
                                        >

                                        </Stepper.Step>
                                    )
                                })}
                            </Stepper>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx" pt={22} pb={10}>
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <HeartHandshake />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}>Trust</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            <Grid columns={24} >
                <Grid.Col span={24} pl="zx" pr="zx" pt={5}>
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>

            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24}>
                {willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControl
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            setEditMode={setEditMode}
                            addAnother={addAnother}  // func for add another record
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            AddContat={AddContat}
                            loading={loading}
                            setLoading={setLoading}
                            setLoadingMock={setLoadingMock}

                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            trustEditClick={trustEditClick}
                            flowId="200000003"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
            </Grid>}

            {/* </div>} */}
            {!loading &&
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 1 || willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 4 || willFlow?.steps[counter].step === 6 || willFlow?.steps[counter].step === 9 || willFlow?.steps[counter].step === 11) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>
                            </Affix>
                        }
                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            }
        </div>
    )
}

export default Trust