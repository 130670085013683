//Will Flow Stepper Next
export function willStepperNext(step, setActive){
    if(step.step === 1 || step.step === 2){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 3){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 4 || step.step === 5  || step.step === 6){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 7 || step.step === 8  || step.step === 9){
        console.log(step.step, "true")
        setActive(4)
    }else if(step.step === 10){
        console.log(step.step, "true")
        setActive(5)
    }
}
//Will Flow Stepper Back
export function willStepperBack(step, setActive){
    console.log(step)
    if(step.step === 1 || step.step === 2){
        console.log(step.step, "true")
        setActive(0)
    }else if(step.step === 3){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 4 || step.step === 5  || step.step === 6){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 7 || step.step === 8  || step.step === 9){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 10){
        console.log(step.step, "true")
        setActive(4)
    }
}
//Power of Attorny Flow Stepper Next
export function powerOfAttornyStepperNext(step, setActive){
    console.log(step)
    if(step.step === 1 ){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 2 || step.step === 3  || step.step === 4){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 5){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 6 ){
        console.log(step.step, "true")
        setActive(4)
    }
    else if(step.step === 7 ){
        console.log(step.step, "true")
        setActive(5)
    }else if(step.step === 8){
        console.log(step.step, "true")
        setActive(6)
    }
}
//Power of Attorny Flow Stepper Back
export function powerOfAttornyStepperBack(step, setActive){
    console.log(step)
    if(step.step === 1){
        console.log(step.step, "true")
        setActive(0)
    }else if(step.step === 2 || step.step === 3  || step.step === 4){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 5){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 7 ){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 8){
        console.log(step.step, "true")
        setActive(4)
    }
}

//livingWill Flow Stepper Next
export function livingWillStepperNext(step, setActive){
    if(step.step === 1 || step.step === 2){
        console.log(step.step, "true")
        setActive(1)
    }else if( step.step === 3 ){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 4){
        console.log(step.step, "true")
        setActive(3)
    }
}
//livingWill Flow Stepper Back
export function livingWillStepperBack(step, setActive){
    console.log(step)
    if(step.step === 1 || step.step === 2){
        console.log(step.step, "true")
        setActive(0)
    }else if( step.step === 3 ){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 4){
        console.log(step.step, "true")
        setActive(2)
    }
}

//healthCareProxy Flow Stepper Next
export function healthCareProxyStepperNext(step, setActive){
    console.log(step)
    if(step.step === 1){
        console.log(step.step, "true")
        setActive(1)
    }else if( step.step === 2 ||  step.step === 3 || step.step === 4 ){
        console.log(step.step, "true")
        setActive(2)
    }else if( step.step === 5 ){
        console.log(step.step, "true")
        setActive(3)
    }else if (step.step === 6){
        setActive(4)
    }
    else if(step.step === 7){
        console.log(step.step, "true")
        setActive(5)
    }
}
//healthCareProxy Flow Stepper Back
export function healthCareProxyStepperBack(step, setActive){
    console.log(step)
    if(step.step === 1 ){
        console.log(step.step, "true")
        setActive(0)
    }else if( step.step === 2 ||  step.step === 3 || step.step === 4  ){
        console.log(step.step, "true")
        setActive(0)
    }else if(step.step === 5){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 6){
        console.log(step.step, "true")
        setActive(2)
    } else if(step.step === 7){
        console.log(step.step, "true")
        setActive(3)
    }
}

//trust Flow Stepper Next
export function trustStepperNext(step, setActive){
    if(step.step === 1){
        console.log(step.step, "true")
        setActive(1)
    } if(step.step === 2 || step.step === 3  ){
        console.log(step.step, "true")
        setActive(2)
    }
    else if( step.step === 4 ){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 5 || step.step === 6 || step.step === 7){
        console.log(step.step, "true")
        setActive(4)
    }else if(step.step === 8 || step.step === 9 || step.step === 10){
        console.log(step.step, "true")
        setActive(5)
    }else if(step.step === 11){
        console.log(step.step, "true")
        setActive(6)
    }
}
//trust Flow Stepper Back
export function trustStepperBack(step, setActive){
    console.log(step)
    if(step.step === 1 || step.step === 2 || step.step === 3){
        console.log(step.step, "true")
        setActive(0)
    }else if( step.step === 4 ){
        console.log(step.step, "true")
        setActive(1)
    }else if(step.step === 5 || step.step === 6 || step.step === 7){
        console.log(step.step, "true")
        setActive(2)
    }else if(step.step === 8 || step.step === 9 || step.step === 10){
        console.log(step.step, "true")
        setActive(3)
    }else if(step.step === 11){
        console.log(step.step, "true")
        setActive(4)
    }
}