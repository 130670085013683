import React, { useEffect, useState } from "react";
import FlowTitle from '../Component/flowTitle'
import InputControl from '../Component/inputControl'
import { Affix, Button, Text, LoadingOverlay, Grid, Box, Stepper } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiPagesLine } from 'react-icons/ri'
import TipModal from '../Component/modal'
import { getWillFlow, listBinderDetails } from '../apis/willFlowApis'
import FooterWill from "../Component/footer2";
import '../Custom.css';
import { willStepperBack, willStepperNext } from "../apis/stepperFlow";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";


const WillFlow = ({ navigate, setLoggedIn }) => {
    // for Add Executor and beneficiaries update
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [active, setActive] = useState(1);  // for stepper
    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [beneficiaries, setBeneficiaries] = useState(true)
    const [executors, setExecutors] = useState(true)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    const [addAnotherBtn, setAddAnotherBtn] = useState(false)

    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [handleSkips, setHandleSkips] = useState(false)
    const [binderId, setBinderId] = useState();



    const handleClick = (step, btnClick) => {
        // console.log("step from handleClick: ")
        // console.log("btn click:")
        /*Set breadcrumb*/
        console.log(step)
        console.log(btnClick)
        willStepperNext(step, setActive)

        // setActive((current) => (current < 4 ? current + 1 : current));
        /*End Set breadcrumb*/


        setHandleSkips(false)
        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Do Not Have Will ?",
                content: "Will is a crucial component of your essential doc please refer to our help section to get more information about will."
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 4 && btnClick === "secondery") {
            setPrevStep(counter)
            setBeneficiaries(false)
            setCounter(6)
            // let temp = {
            //     title: "Modal title",
            //     content: "Modal description"
            // }
            // setModalMessage(temp)
            // setModalStatus(!modalStatus)
        } else if (step.step === 7 && btnClick === "secondery") {
            setPrevStep(counter)
            setExecutors(false)
            setCounter(9)
            // let temp = {
            //     title: "Modal title",
            //     content: "Modal description"
            // }
            // setModalMessage(temp)
            // setModalStatus(!modalStatus)
        } else if (btnClick === "continue with api") {
            if (step.step === 10 || step.step === 2) {
                submitNotes(step)
            } else if (step.step === 5 || step.step === 8) {
                submitForm(step)
            } else if (step.step === 3) {      // for step-3
                submitForm(step)            // call step -3 
            }
            else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        }else if(step.step === 4 && btnClick === "primary"){
            setPrevStep(counter)
            setCounter(counter + 2)
        }else if(step.step === 7 && btnClick === "primary"){
            setPrevStep(counter)
            setCounter(counter + 2)
        }  else if (!step.lastStep) {
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            navigate('/essentialDocs')
            setCounter(0)
        }
    }

    const submitForm = (step) => {
        console.log(answer)
        // setAnswer(answer)
        let data = {}
        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/essentialDocs')
                setCounter(0)
            }
            return
        }
        if (step.step === 5) {
            // console.log(questionId)
            // console.log(answer)

            data = {
                "SaveKeyContact": "Submit",
                "contact_type_id": 101,
                "question_id": questionId,
                ...answer
            }
        } else {
            data = {
                "SaveKeyContact": "Submit",
                "contact_type_id": 102,
                "question_id": questionId,
                ...answer
            }
        }
        if (step.step === 3) {                   //upload will step -3
            // // console.log(questionId)
            // console.log(answer)
            data = {
                "SaveDocument": "Submit",    // 
                "question_id": questionId,
                ...answer
            }
        }
        console.log(data);

        if (editMode?.value) {
            data.question_id = editMode?.data?.question_id
            data.contact_type_id = editMode?.data?.contact_type_id
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        setLoading(true)
        getWillFlow("200000001", data).then((response) => {
            console.log("Api call success: ", response.data)

            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {
                if (step.step === 5 || step.step === 8) {
                    if (response.data.status === 'error') {
                        console.log("error")
                        console.log(response.data.message)
                        setFormError(response.data.message);
                        setLoading(false);

                    } else if (response.data.status === 'success') {
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                        console.log(response.data)
                        if (!step.lastStep) {
                            setPrevStep(counter)
                            setCounter(counter + 1)
                        } else {
                            navigate('/essentialDocs')
                            setCounter(0)
                        }
                        setWillFlow(response.data)
                        setLoading(false)
                        // setAnswer()
                        setEditMode(false)
                        setFormError("");
                    }
                } else {
                    // console.log("sucess")
                    if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(counter + 1)
                    } else {
                        navigate('/essentialDocs')
                        setCounter(0)
                    }
                    setWillFlow(response.data)
                    setLoading(false)
                    // setAnswer()
                    setEditMode(false)
                    setFormError("");
                }

            }




        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const submitNotes = (step) => {
        console.log(answer)
        let data = {}
        // console.log(answer.answer)
        if (!answer) {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/essentialDocs')
                // setCounter(0)
            }
            return
        }


        if (step.step == 2) {
            // console.log("step-2 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        if (step.step == 10) {
            // console.log("step-10 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        console.log(data)
        setLoading(true)
        getWillFlow("200000001", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                // console.log("last step", step.lastStep)
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else if (step.lastStep) {
                    // console.log("last step check")
                    navigate('/essentialDocs')
                    // setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
            }

        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const backBtnClick = (step) => {
        setEditMode(false)
        setAddAnotherBtn(false)
        console.log(step)
        /*for stepper */
        // setActive((current) => (current > 0 ? current - 1 : current));
        willStepperBack(step, setActive)
        /*for stepper end*/
        setHandleSkips(true)
        setFormError("");
        // console.log(willFlow.steps[0])
        if (counter != 0) {

            if (beneficiaries === false && step.step === 7) {
                // console.log("step 7 true")
                setCounter(3)
                setPrevStep(2)
            } else if (executors === false && step.step === 10) {
                // console.log("step 10 true")
                setCounter(6)
                setPrevStep(5)
            } else if (willFlow.steps[3].visible === false && step.step === 6) {
                // console.log("step 6 true")
                setEditMode(false)
                setCounter(2)
                setPrevStep(1)

            }else if (step.step === 6) {
                // console.log("step 5 true")
                setEditMode(false)
                setCounter(3)
                setPrevStep(2)
            }
             else if (willFlow.steps[3].visible === false && step.step === 5) {
                // console.log("step 5 true")
                setEditMode(false)
                setCounter(5)
                setPrevStep(5)
            }
            else if (willFlow.steps[6].visible === false && step.step === 9) {
                // console.log("step 9 true")
                setEditMode(false)
                setCounter(5)
                setPrevStep(4)
            } else if (step.step === 9) {
                // console.log("step 9 true")
                setEditMode(false)
                setCounter(6)
                setPrevStep(5)
            } else if (willFlow.steps[6].visible === false && step.step === 8) {
                // console.log("step  8 true")
                setEditMode(false)
                setCounter(8)
                setPrevStep(7)
            }
            else if (willFlow.steps[0].visible === false && step.step === 2) {
                // console.log("step 2 true")
                navigate('/essentialDocs')
            }
            else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/essentialDocs')
        }
    }
    //AddContat to benifi and agent
    const AddContat = (keyContactIds, questionIds) => {
        console.log(keyContactIds)
        console.log(questionIds)

        let data = {}
        data = {
            "AddContact": "yes",
            "question_id": questionIds,
            "main_binder_id": binderId,
            "contact_id": keyContactIds,
        }
        console.log(data)
        setLoading(true)
        getWillFlow("200000001", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                setWillFlow(response.data)
                setLoading(false)
            }
        })
    }


    const btnClick = (data, key_contact_id) => {
        // console.log(key_contact_id)
        console.log(data)
        setCounter(counter - 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = willFlow
            // console.log("Data from btn click: ", data)
            // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter - 1].inputList.map((item, index) => {
                // console.log("Match see: ", item, item.id, data[item.id])
                item.prefill = data[item.id]
            })
            // console.log("Temp Object from btnCLick: ", temp.steps[counter - 1].inputList)
            // console.log(temp)
            setWillFlow(temp)
        }
    }

    // useEffect(() => {
    //     console.log("run edit mode use effect")
    //     if (!loading) {
    //         // console.log(willFlow.steps[counter])
    //         let temp = willFlow.steps[counter]
    //         let prefillObj = {}
    //         let ansObj = {}
    //         temp.inputList.map((item, index) => {
    //             // console.log(item)
    //             // console.log(item.label)
    //             // console.log(item.prefill)
    //             if (item?.prefill != undefined) {
    //                 prefillObj = { ...prefillObj, [item.label]: item.prefill }
    //                 ansObj = { ...ansObj, [item?.id]: item.prefill }
    //             }
    //         })
    //         console.log("answer Object: ", ansObj, prefillObj)
    //         setQuestionId(willFlow.steps[counter].question_id)
    //         setPrefill(prefillObj)
    //         setAnswer(ansObj)
    //         setPrevStep(counter - 1)
    //     }
    // }, [editMode])
    // add another record
    const addAnother = (data, step) => {
        // console.log(step)
        // console.log(counter)
        setAddAnotherBtn(true)
        setAnswer(initialValues)
        if (counter != 0) {
            // console.log("true")
            if (step.step === 6) {
                // console.log(true)
                setCounter(4)
                setPrevStep(3)
            } else if (executors === false && step.step === 8) {
                setCounter(7)
                setPrevStep(5)
            } else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/essentialDocs')
        }

    }
    useEffect(() => {
        // console.log("run page load use effect ")
        setLoading(true)
        listBinderDetails("200000001").then((response) => {
            // console.log("Binder List for topic Id 200000002: ", response.data)
        }, (err) => {
            // console.log("Error in getting binder List")
        })
        getWillFlow("200000001").then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setWillFlow(response.data)
                setBinderId(response.data.main_binder_id)
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }, [])

    useEffect(() => {
        // console.log("run willFlow use effect ")
        if (Object.keys(willFlow).length > 0) {
            setLoading(false)
        }

    }, [willFlow])

    // useEffect(() => {
    //     // console.log("Loading changed: ", loading, willFlow)
    // }, [loading])

    useEffect(() => {
        // console.log("run counter use effect ")
        // console.log(handleSkips)
        // console.log(counter)
        // console.log(willFlow)
        // console.log(loading)
        if (!loading && handleSkips === false) {
            console.log("Inside handle skips false", loading)
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 4) {
                // console.log("step 4 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 7) {
                // console.log("step 7 is false")
                setCounter(counter + 2)
            }
            else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 1 false")
                setCounter(counter + 1)
            }
            else {
                setCounter(counter)
            }
            // console.log(counter)
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        } else if (!loading && handleSkips === true) {   // backbutton case
            console.log("Inside handle skips true", loading)
            // console.log(counter)
            // console.log(willFlow)
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [counter])
    useEffect(() => {
        // console.log(counter)
        // console.log("run loading use effect ")
        if (!loading) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 4) {
                // console.log("step 4 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 7) {
                // console.log("step 7 is false")
                setCounter(counter + 2)
            }
            else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 1 false")
                setCounter(counter + 1)
            }
            else {
                setCounter(counter)
            }
        }
    }, [loading])

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }

    function setBreadCrumb(stepIndex) {
        // setCounter(stepIndex + 1)
        setEditMode(false)
        setFormError("")
        setLoading(true)
        switch (stepIndex) {
            case 0:
                if (willFlow.steps[0].visible === true) {
                    setCounter(0)
                } else {
                    setCounter(1)
                }
                setLoading(false)
                break;
            case 1:
                setCounter(2)
                setLoading(false)
                break;
            case 2:
                if (willFlow.steps[3].visible === true) {
                    setCounter(3)
                } else {
                    setCounter(5)
                }

                setLoading(false)
                break;
            case 3:
                if (willFlow.steps[6].visible === true) {
                    setCounter(6)
                } else {
                    setCounter(8)
                }

                setLoading(false)
                break;
            case 4:
                setCounter(9)
                setLoading(false)
                break;
            default:
                setCounter(0)
                setLoading(false)
        }
    }
    if (Object.keys(willFlow).length === 0) {
        console.log(willFlow)
        navigate('/essentialDocs')
    }
    return (
        <div className="mainContainer"  >
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            {!loading &&
                //  <div >
                <Grid columns={24} >
                    <Grid.Col span={24} p="zx">
                        <Box >
                            <Stepper active={active}

                                onStepClick={(stepIndex) => {
                                    console.log(stepIndex)
                                    setActive(stepIndex)

                                    setBreadCrumb(stepIndex)
                                }} breakpoint="xxs" size="sm">
                                {willFlow.breadCrumbsList.map((breadCrumb, index) => {
                             

                                    return (
                                        <Stepper.Step
                                            label={breadCrumb.name}
                                            key={index}
                                            sx={(theme) => ({
                                                color: theme.colors.textColor[0],
                                            })}
                                            color={breadCrumb.status === "not_done" ? "red" : breadCrumb.status === "start" ? "green" : ""}
                                            completedIcon={breadCrumb.status === "not_done" ? <IconCircleX /> : ""}
                                        >
                                            {/* Step {index + 1} content: {breadCrumb} */}
                                        </Stepper.Step>
                                    )
                                })}
                            </Stepper>
                        </Box>
                    </Grid.Col>
                </Grid>

            }
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx" pt={22} pb={10}>
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <RiPagesLine />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs, [`@media (max-width:3840px)`]: { fontSize:theme.fontSizes.lg } })}>Will</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            <Grid columns={24} >
                <Grid.Col span={24} pl="zx" pr="zx" pt={5}>
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>

            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24} sx={(theme) => ({ padding: theme.spacing.zx })} justify="space-between">
                {willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControl
                            data={item}
                            answer={answer}
                            prefill={prefill}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}

                            editMode={editMode}    // for benif and executor files
                            setEditMode ={setEditMode}
                            addAnother={addAnother}  // func for add another record 
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            AddContat={AddContat}
                            loading = {loading}
                            setLoading = {setLoading}
                            setLoadingMock = {setLoadingMock}

                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            flowId="200000001"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
            </Grid>}

            {/* </div>} */}
            {/* {!modalStatus && !loading && */}
            { !loading &&
                <>
                    <Affix

                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 5 || willFlow?.steps[counter].step === 8 || willFlow?.steps[counter].step === 10) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>
                            </Affix>
                        }
                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>
            }
        </div>
    )
}

export default WillFlow