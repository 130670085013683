import React, { useEffect, useState } from "react";
import FlowTitle from '../../Component/flowTitle'
import InputControlReview from '../../Component/Review/inputControlReview'
import { Affix, Button, Text, LoadingOverlay, Grid, Box } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiPagesLine } from 'react-icons/ri'

import TipModal from '../../Component/modal'
import { modifiedJson } from '../../apis/willFlowApis'
import { getWillFlowReview } from '../../apis/flowReviewApis/willFlowReviewApis'
import FooterWill from "../../Component/footer2";
import '../../Custom.css';
import { useLocation } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";

const WillFlowReview = ({ navigate, setLoggedIn }) => {
    const { state } = useLocation();
    const { topicId, testatorId, testatorImage, testatorName } = state;

    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [active, setActive] = useState(1);  // for stepper s
    // console.log(testatorId)
    //// console.log(counter)
    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [beneficiaries, setBeneficiaries] = useState(true)
    const [executors, setExecutors] = useState(true)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [handleSkips, setHandleSkips] = useState(false)
    const [binderId, setBinderId] = useState();
    const [countRandomStep, setCountRandomStep] = useState("")
    const [addAnotherBtn, setAddAnotherBtn] = useState(false)
    const [writePermission, setWritePermission] = useState("")

    const handleClick = (step, btnClick) => {
        console.log(counter)
        console.log(step)
        console.log(btnClick)
        setHandleSkips(false)

        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Do Not Have Will ?",
                content: "Will is a crucial component of your essential doc please refer to our help section to get more information about will."
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 4 && btnClick === "secondery") {
            if (countRandomStep.includes(7) && (willFlow.steps[countRandomStep.indexOf(7)].visible) === false) {
                setPrevStep(counter)
                setCounter(counter + 5)
            } else if (countRandomStep.includes(7) && (willFlow.steps[countRandomStep.indexOf(7)].visible) === true) {
                setPrevStep(counter)
                setCounter(counter + 3)
            } else if (countRandomStep.includes(10)) {
                setCounter(counter + 3)
            } else if (willFlow.steps[countRandomStep.indexOf(6)].lastStep) {
                navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                setCounter(0)
            }
        }
        else if (step.step === 7 && btnClick === "secondery") {
            if (countRandomStep.includes(10)) {
                setCounter(counter + 3)
            } else if (willFlow.steps[countRandomStep.indexOf(9)].lastStep) {
                navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                setCounter(0)
            }
        }
        else if (btnClick === "continue with api") {
            if (step.lastStep && step.step === 2) {
                console.log("last step found")
                submitNotes(step)
            } if (step.lastStep && step.step === 3) {
                console.log("last step found")
                submitForm(step)
            }
            else if (step.step === 10 || step.step === 2) {
                submitNotes(step)
            } else if (step.step === 5 || step.step === 8) {
                submitForm(step)
            } else if (step.step === 3) {      // for step-3
                submitForm(step)            // call step -3 
            }
            else {
                console.log("comes here")
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (step.step === 4 && writePermission === "no") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (step.step === 4 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (step.step === 7 && writePermission === "no") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (step.step === 7 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (!step.lastStep) {
            console.log("comes here 2")
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
            setCounter(0)
        }
    }

    const submitForm = (step) => {
        let data = {}
        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                setCounter(0)
            }
            return
        }
        if (step.step === 5) {
            // console.log(questionId)
            // console.log(answer)

            data = {
                "SaveKeyContact": "Submit",
                "contact_type_id": 101,
                "question_id": questionId,
                ...answer
            }
        } else {
            data = {
                "SaveKeyContact": "Submit",
                "contact_type_id": 102,
                "question_id": questionId,
                ...answer
            }
        }
        if (step.step === 3) {                   //upload will step -3
            //// console.log(questionId)
            // console.log(answer)
            data = {
                "SaveDocument": "Submit",    // 
                "question_id": questionId,
                ...answer
            }
        }

        if (editMode?.value) {
            data.question_id = editMode?.data?.question_id
            data.contact_type_id = editMode?.data?.contact_type_id
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        setLoading(true)
        getWillFlowReview(topicId, testatorId, data).then((response) => {
            console.log("Api call success: ", response.data)

            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (step.lastStep) {
                console.log("last step found line 283")
                navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                setLoading(false)
            } else {
                if (step.step === 5 || step.step === 8) {
                    console.log("inside 5 and 8")
                    if (response.data.status === 'error') {
                        console.log("error")
                        console.log(response.data.message)
                        setFormError(response.data.message);
                        setLoading(false);

                    } else if (response.data.status === 'success') {
                        setWillFlow(modifiedJson(response.data))
                        setLoading(false)
                        // setAnswer()
                        setEditMode(false)
                        setFormError("");
                        if (step.lastStep) {
                            console.log("last step found line 283")
                            setLoading(false);
                            navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                        }
                        if (!step.lastStep) {
                            console.log(counter)
                            console.log("not last step")
                            setPrevStep(counter)
                            setCounter(counter + 1)
                        } else {
                            navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                            setCounter(0)
                        }


                    }
                } else {
                    if (step.lastStep) {
                        console.log("last step found line 283")
                        navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                    } else if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(counter + 1)
                    } else {
                        navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                        setCounter(0)
                    }
                    setWillFlow(modifiedJson(response.data))
                    setLoading(false)
                    // setAnswer()
                    setEditMode(false)
                    setFormError("");
                }
            }
        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const submitNotes = (step) => {
        // console.log("Submit Notes called")
        let data = {}
        console.log(answer.answer)
        if (!answer || typeof (answer) != 'string') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                setCounter(0)
            }
            return
        }



        // check Answer type and set it
        if (step.step == 2) {
            // console.log("step-2 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        if (step.step == 10) {
            // console.log("step-10 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        setLoading(true)
        getWillFlowReview(topicId, testatorId, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "error" && response.data.write_permission === "no") {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    setLoggedIn(false)
                }
                // setWillFlow(response.data)
                setWillFlow(modifiedJson(response.data))
                setBinderId(response.data.main_binder_id)
                setLoading(false)
            } else {
                if (step.lastStep) {
                    console.log("last step found line 283")
                    // setLoading(false)
                    navigate('/userTopicReview', { state:{ testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    setLoggedIn(false)
                } else if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    setLoggedIn(false)
                }
                // setWillFlow(response.data)
                setWillFlow(modifiedJson(response.data))
                setBinderId(response.data.main_binder_id)
                setLoading(false)
            }

        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    //AddContat to benifi and agent
    const AddContat = (keyContactIds, questionIds) => {
        console.log(keyContactIds)
        console.log(questionIds)

        let data = {}
        data = {
            "AddContact": "yes",
            "question_id": questionIds,
            "main_binder_id": binderId,
            "contact_id": keyContactIds,
        }
        console.log(data)
        setLoading(true)
        getWillFlowReview(topicId, testatorId, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'success') {
                setWillFlow(modifiedJson(response.data))
                setBinderId(response.data.main_binder_id)
                setLoading(false)
            }
        })
    }



    const backBtnClick = (step) => {
        setEditMode(false)
        console.log(step)
        console.log(countRandomStep)
        setAddAnotherBtn(false)
        setHandleSkips(true)
        setFormError("");

        if (counter != 0) {

            if (beneficiaries === false && step.step === 7) {
                //console.log("step 7 true")
                setCounter(3)
                setPrevStep(2)
            } else if (executors === false && step.step === 10) {
                //console.log("step 10 true")
                setCounter(6)
                setPrevStep(5)
            } else if (countRandomStep.includes(1) && step.step === 2) {
                if ((willFlow.steps[countRandomStep.indexOf(1)].visible) === false) {
                    console.log("con true")
                    navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    // setLoading(false)
                } else {
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                }
            } else if (countRandomStep.includes(6) && step.step === 9) {

                if ((willFlow.steps[countRandomStep.indexOf(7)].visible) === false) {
                    console.log("True")
                    console.log(counter)
                    setCounter(counter - 3)
                    setPrevStep(counter - 4)
                } else {
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                }
            } else if (countRandomStep.includes(3) && step.step === 9) {
                console.log("True")
                console.log(counter)
                // setCounter(counter - 3)
                // setPrevStep(counter - 4)
            } else if (countRandomStep.includes(2) && step.step === 9) {
                console.log("True")
                console.log(counter)
                setCounter(1)
                setPrevStep(0)
            } else if (countRandomStep.includes(3) && step.step === 6) {
                console.log(counter)
                if ((willFlow.steps[countRandomStep.indexOf(4)].visible) === false) {
                    console.log(counter)
                    console.log(prevStep)
                    setCounter(counter - 3)
                    setPrevStep(counter - 4)
                } else {
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                }
            } else if (countRandomStep.includes(2) && step.step === 6) {
                console.log(counter)
                if ((willFlow.steps[countRandomStep.indexOf(4)].visible) === false) {
                    console.log(counter)
                    setCounter(counter - 3)
                    setPrevStep(counter - 4)
                }
            } else if (!countRandomStep.includes(3) && !countRandomStep.includes(2) && step.step === 6) {
                navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
            } else if (countRandomStep.includes(3) && step.step === 3) {
                if (countRandomStep.includes(2)) {
                    console.log("con true")
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                } else {
                    navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                    // setLoading(false)
                }
            }
            else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
        }
    }

    const btnClick = (data, key_contact_id) => {
        // console.log(key_contact_id)
        // console.log(data)
        setCounter(counter - 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = willFlow
            // console.log("Data from btn click: ", data)
            // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter - 1].inputList.map((item, index) => {
                // console.log("Match see: ", item, item.id, data[item.id])
                item.prefill = data[item.id]
            })
            // console.log("Temp Object from btnCLick: ", temp.steps[counter - 1].inputList)
            // console.log(temp)
            setWillFlow(temp)
        }
    }

    // useEffect(() => {
    //     if (!loading) {
    //         // console.log(willFlow.steps[counter])
    //         let temp = willFlow.steps[counter]
    //         let prefillObj = {}
    //         let ansObj = {}
    //         temp.inputList.map((item, index) => {
    //             if (item?.prefill != undefined) {
    //                 prefillObj = { ...prefillObj, [item.label]: item.prefill }
    //                 ansObj = { ...ansObj, [item?.id]: item.prefill }
    //             }
    //         })
    //         // console.log("answer Object: ", ansObj, prefillObj)
    //         setQuestionId(willFlow.steps[counter].question_id)
    //         setPrefill(prefillObj)
    //         setAnswer(ansObj)
    //         setPrevStep(counter - 1)
    //     }
    // }, [editMode])
    // add another record
    const addAnother = (data, step) => {
        // console.log(step)
        // console.log(counter)
        if (counter != 0) {
            // console.log("true")
            if (step.step === 6) {
                // console.log(true)
                setCounter(4)
                setPrevStep(3)
            } else if (executors === false && step.step === 8) {
                setCounter(7)
                setPrevStep(5)
            } else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
        }

    }
    useEffect(() => {
        setLoading(true)
        // listBinderDetails("200000001").then((response) => {
        //     // console.log("Binder List for topic Id 200000002: ", response.data)
        // }, (err) => {
        //     // console.log("Error in getting binder List")
        // })
        console.log("Run fist useEffect")
        getWillFlowReview(topicId, testatorId).then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                let responseData = response.data
                responseData.steps = response.data.steps.filter((val) => {
                    return val.permissionStatus === true
                })
                setCountRandomStep(countRandomSteps(response.data))
                setWillFlow(modifiedJson(response.data))
                setBinderId(response.data.main_binder_id)
                setWritePermission(response.data.write_permission)
                setLoading(false)


            }

        }, (error) => {
            // console.log("Error in getting willflowReview object: ", error)
        })
    }, [])

    function countRandomSteps(willData) {
        let willRandomStepsArr = []
        willData.steps.map((item, index) => {
            console.log(item)
            willRandomStepsArr.push(item.step)
        })

        console.log(willRandomStepsArr)
        return willRandomStepsArr
    }

    useEffect(() => {
        if (Object.keys(willFlow).length > 0) {
            setLoading(false)
        }
    }, [willFlow])

    // useEffect(() => {
    //    // console.log("Loading changed: ", loading, willFlow)
    // }, [loading])

    useEffect(() => {
        // console.log(handleSkips)
        // console.log(counter)
        if (!loading && handleSkips === false) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 4) {
                // console.log("step 4 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 7) {
                // console.log("step 7 is false")
                setCounter(counter + 2)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1 && willFlow.steps[counter].lastStep === true) {
                // console.log("step 1 false")
                setCounter(counter)
            }
            else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 1 false")
                setCounter(counter + 1)
            }
            else {
                setCounter(counter)
            }
            // console.log(counter)
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            // console.log(temp)
            if (temp.visible === true) {
                temp.inputList.map((item, index) => {
                    if (item?.prefill != undefined) {
                        prefillObj = { ...prefillObj, [item.label]: item.prefill }
                        ansObj = { ...ansObj, [item?.id]: item.prefill }
                    }
                })
            }

            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        } else if (!loading && handleSkips === true) {   // backbutton case
            // console.log(counter)
            // console.log(willFlow)
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [counter, loading])


    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    // console.log(willFlow.steps[0])
    return (

        <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            <Grid columns={24} >
                <Grid.Col span={12} pl="zx" pt={0}>
                    <Box>
                        <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.lxl })}  > {testatorName}'s Information Binder</Text>
                        {/* <Text sx={(theme)=>({color: theme.colors.textColor[1], fontSize: theme.fontSizes.md })}> Lorem ipsum began as scrambled, nonsensical Latin derived from Cicero's 1st-century BC text De Finibus Bonorum et Malorum.</Text> */}
                    </Box>
                </Grid.Col>
            </Grid>
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx" pt={10} pb={10}>
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <RiPagesLine />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}>Will</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            <Grid columns={24}>
                <Grid.Col span={24} pl="zx" pr="zx" pt={5}>
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>

            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}
            {!loading && <Grid columns={24}>

                {willFlow?.steps[counter].visible === true && willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControlReview
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            setEditMode={setEditMode}
                            addAnother={addAnother}  // func for add another record
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            testatorId={testatorId}
                            writePermission={writePermission}

                            setLoading={setLoading}
                            loading={setLoading}
                            AddContat={AddContat}
                            setLoadingMock={setLoadingMock}
                            loadingMock={loadingMock}

                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            flowId="200000001"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
            </Grid>}

            {/* </div>} */}
            {!modalStatus && !loading &&
                <>
                    <Affix

                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 5 || willFlow?.steps[counter].step === 8 || willFlow?.steps[counter].step === 10) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>
                            </Affix>
                        }
                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            }
        </div>
    )
}

export default WillFlowReview