import React from "react";
import { Card, Text,  Group, Box, Button } from '@mantine/core';
import { CardTitle} from "./textLabel";
import { Butterfly } from "tabler-icons-react";

export const InfoCard = ({ title, description, Icon, color }) => {
    return (

        <Card className="cardBorderRad2" radius="xxs" sx={(theme) => ({
            border: '1px solid #E9ECEF', backgroundColor: '#FEFEFE',
            boxShadow: theme.shadows.sm, height: 250,
            '@media (max-width: 1600px)': {
                height: 220,
            },
            '@media (max-width: 1366px)': {
                height: 190,
            },
            '@media (max-width: 1280px)': {
                height: 165,
            }, '@media (max-width: 1176px)': {
                height: 135,
            },
             '@media (max-width: 1024px)': {
                height: 180,
            },

        })}>
            <Card.Section>
                <Box className="cardIcons" sx={(theme) => ({
                    paddingTop: theme.spacing.md, paddingLeft: theme.spacing.md, paddingBottom: theme.spacing.xs,
                    '@media (max-width: 1600px)': {
                        paddingBottom: 0,
                    },  
                    '@media (max-width: 1366px)': {
                        paddingBottom: 0,
                    },                    
                    '@media (max-width: 1280px)': {
                        paddingBottom: 0,
                    },'@media (max-width: 1176px)': {
                        paddingBottom: 0,
                    },
                    '@media (max-width: 1024px)': {
                        paddingBottom: 0,
                    },
                })} >
                    {Icon}
                </Box>
            </Card.Section>

            <Group position="apart" sx={(theme) => ({
                marginTop: 20, 
                '@media (max-width: 1600px)': {
                    marginTop: 5
                },
                '@media (max-width: 1366px)': {
                    marginTop: 2
                },
                '@media (max-width: 1280px)': {
                    marginTop: 0
                },'@media (max-width: 1176px)': {
                    paddingBottom: 0,
                },
                '@media (max-width: 1024px)': {
                    marginTop: 0
                },
            })}>
                <CardTitle style={{ color: color }} title={title} />

            </Group>


            {/* <PermissionText style={{ color: color}} title={description} /> */}
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.md,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.textColor[0],
                    fontWeight: 400,
                    color: color,
                    marginTop: 11,
                    // textAlign:"center"
                    '@media (max-width: 1600px)': {
                        marginTop: 4,
                        fontSize: theme.fontSizes.md,
                        color: color
                    },
                    '@media (max-width: 1366px)': {
                        marginTop: 4,
                        fontSize: theme.fontSizes.sm,
                        color: color
                    },
                    '@media (max-width: 1280px)': {
                        marginTop: 4,
                        fontSize: theme.fontSizes.xs,
                        color: color
                    },'@media (max-width: 1176px)': {
                        fontSize: 10
                    },
                     '@media (max-width: 1024px)': {
                        fontSize: theme.fontSizes.xs,
                        color: color
                    },

                })}
            >{description} </Text>

            {/* <CardTitle style={{ color: color, fontWeight: 400, position: 'absolute', bottom: 0, paddingBottom: 10 , marginTop: 20}}  title="Take me there" /> */}
            <Button
                fullWidth
                sx={(theme) => ({
                    fontSize: theme.fontSizes.md,
                    fontFamily: theme.fontFamily[1],
                    backgroundColor: color,
                    fontWeight: 400,
                    bottom: 15,
                    position: "absolute",
                    color: "#ffffff",
                    width: "94%" ,
                    '&:hover': {
                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                    },
                    '@media (max-width: 1176px)': {
                        fontSize: 12
                    }

                })}
            >Take me there  </Button>
        </Card>
    )
}

export const InfoCard2 = ({ title, description, Icon, color }) => {
    return (

        <Card className="cardBorderRad2" shadow="sm" radius="xxs" style={{ border: '1px solid #E9ECEF', backgroundColor: '#FEFEFE' }}>
            <Card.Section>
                <Box style={{ color: "red" }} sx={(theme) => ({ paddingTop: theme.spacing.md, paddingLeft: theme.spacing.md, paddingBottom: theme.spacing.xs })} >
                    {Icon}
                </Box>
            </Card.Section>

            <Group position="apart">
                <Text className="fontSize8 textColor1 fontFamily1" style={{ fontWeight: 600, color: color }}>{title}</Text>
            </Group>

            <Text className="fontSize2 textColor2 fontFamily2" style={{ fontWeight: 400, color: color }}>
                {description}
            </Text>
        </Card>
    )
}