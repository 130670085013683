import React, { useEffect, useState } from "react";
import FlowTitle from '../../Component/flowTitle'
import { Affix, Button, Text, LoadingOverlay, Grid, Box } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiPagesLine } from 'react-icons/ri'

import TipModal from '../../Component/modal'
import { getPowerOfAttorneyReview } from '../../apis/flowReviewApis/powerOfAttorneyReviewApis';
import FooterWill from "../../Component/footer2";

import '../../Custom.css';
import { useLocation } from "react-router-dom";
import InputControlReview from "../../Component/Review/inputControlReview";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";


const PowerOfAttornyReview = ({ navigate, setLoggedIn }) => {
    const { state } = useLocation(); 
    const { topicId, testatorId,  testatorImage, testatorName } = state;
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    
    //  console.log(testatorId)
    const [active, setActive] = useState(1);  // for stepper
    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [powerOfAttorney, setPowerOfAttorney] = useState({})
    const [loading, setLoading] = useState(true)
    const [prevStep, setPrevStep] = useState(-1)
    const [executors, setExecutors] = useState(true)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    // const willFlow = require("../willFlow.json")
    const [listPage, setListPage] = useState(false)
    const [binderList, setBinderList] = useState({})
    const [questionId, setQuestionId] = useState(false);

    const [formError, setFormError] = useState();
    const [binderId, setBinderId] = useState();
    const [keyContactIds, setKeyContactIds] = useState("");


    //set adding new POA us
    const [addAnotherBtn, setAddAnotherBtn] = useState(false)
    const [writePermission, setWritePermission] = useState("")
    const [agentEdit, setAgentEdit] = useState(false)   // for skip agent list step when edit

    const handleClick = (step, btnClick) => {
        console.log("step from handleClick: ", step)
        console.log("continue button ", btnClick)
        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 2 && btnClick === "secondery") {
            console.log("step 2")
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 4 && btnClick === "secondery") {
            console.log("step 4")
            // setPrevStep(counter)
            // setBeneficiaries(false)
            // setCounter(6)
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 7 && btnClick === "secondery") {
            console.log("step 7")
            // setPrevStep(counter)
            // setExecutors(false)
            // setCounter(7) 
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        }
        else if (btnClick === "continue with api") {
            if (step.step === 5 || step.step === 6 || step.step === 8) {
                console.log("step ", step.step)
                submitNotes(step)
            } else if (step.step === 3 || step.step === 7) {
                console.log("step ", step.step)
                // console.log("step: " + step.step + " true")
                submitForm(step)
            } if (step.step === 4 && keyContactIds.length === 0) {
                let temp = {
                    title: "Mandatory",
                    content: "Add a new agent using Add New button OR update existing from agent list using edit button."
                }
                setModalMessage(temp)
                setModalStatus(!modalStatus)
            } else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (step.step === 2 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (!step.lastStep) {
            setPrevStep(counter)
            setCounter(counter + 1)

        } else {
            navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
            setCounter(0)
        }
    }

    const submitForm = (step) => {
        console.log(step)
        console.log(answer)
        setAddAnotherBtn(false)
        console.log(keyContactIds)

        let data = {}
        if (step.step === 3) {
            // console.log("step: " + step.step + " true")
            data = {
                "SaveKeyContact": "Submit",
                "contact_type_id": 106,
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactIds,
                ...answer
            }
        } else if (step.step === 7) {
            // console.log("step: " + step.step + " true")
            data = {
                "SaveDocument": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactIds,
                ...answer
            }
        }
        console.log(data)
        if (editMode) {
            data.question_id = editMode?.data?.question_id
            data.contact_type_id = editMode?.data?.contact_type_id
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        setLoading(true)
        getPowerOfAttorneyReview(topicId, testatorId, data).then((response) => {
            //  console.log("Api call success: ", response.data)
            console.log("agentEdit", agentEdit)
            console.log("addAnotherBtn", addAnotherBtn)
            console.log("Api call success: ", response.data)

            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } if (response.data.status === 'error' && step.step === 3) {
                console.log("error")
                console.log(response.data.message)
                // setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                if (step.step === 3) {
                    console.log("true line 209")
                    setKeyContactIds(response.data.key_contact_id)
                }
                console.log(response.data)
                if (!step.lastStep && step.step === 3 && agentEdit === true) {
                    console.log("true line 214")
                    setPrevStep(counter + 1)
                    setCounter(counter + 2)
                    setAgentEdit(false)

                } else if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
            }
            else {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
                    setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
            }
        }, (error) => {
            //  console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const submitNotes = (step) => {
        setAddAnotherBtn(false)
        let data = {}
        if (!answer) {
            // console.log("true")
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                setCounter(0)
            }
            return
        }
        if (step.step === 5 || step.step === 6 || step.step === 8) {

            data = {
                "SaveData": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactIds,
                "answer": answer.answer
            }
        }
        console.log(data)
        setLoading(true)
        getPowerOfAttorneyReview(topicId, testatorId, data).then((response) => {
            console.log("agentEdit", agentEdit)
            console.log("addAnotherBtn", addAnotherBtn)
            console.log("Api call success: ", response.data)

            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } if (response.data.status === 'error' && step.step === 3) {
                console.log("error")
                console.log(response.data.message)
                // setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                if (step.step === 3) {
                    console.log("true line 209")
                    setKeyContactIds(response.data.key_contact_id)
                }
                console.log(response.data)
                if (!step.lastStep && step.step === 3 && agentEdit === true) {
                    console.log("true line 214")
                    setPrevStep(counter + 1)
                    setCounter(counter + 2)
                    setAgentEdit(false)

                } else if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                    setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
            }
            else {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
                    setCounter(0)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
            }
        }, (error) => {
            //  console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    //selectPOA Agent
    const selectPOAagent = (item, contactId) => {
        console.log(item)
        console.log(contactId)
        let data = {}
        data = {
            "key_contact_id": contactId,
            "main_binder_id": binderId,
        }
        console.log(data)
        setLoading(true)
        getPowerOfAttorneyReview(topicId, testatorId, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'success') {
                setWillFlow(response.data)
                setKeyContactIds(response.data.key_contact_id)
                setBinderId(response.data.main_binder_id)
                setPrevStep(counter)
                setCounter(counter + 1)
                setLoading(false)
            }
        })
    }
    const backBtnClick = (step) => {
        setEditMode(false)
        setAddAnotherBtn(false)
        setFormError("");

        console.log(counter)

        console.log(counter)
        console.log(step.step)
        console.log(willFlow.steps[1])
        if (counter != 0) {
            if (willFlow.steps[1].visible === false && step.step === 4) {
                console.log("line 196")
                setEditMode(false)
                setCounter(0)
                setPrevStep(1)
                setBinderId("")
            } else if (willFlow.steps[1].visible === true && step.step === 4) {
                console.log("line 301")
                setEditMode(false)
                setCounter(0)
                setPrevStep(1)
                setBinderId("")
            } else if (step.step === 4) {
                console.log("line 301")
                setEditMode(false)
                setAgentEdit(false)
                setCounter(0)
                setPrevStep(1)
                setBinderId("")
            } else if (step.step === 2) {
                console.log("line 301")
                setEditMode(false)
                setAgentEdit(false)
                setCounter(0)
                setPrevStep(1)
                setBinderId("")
            } else if (step.step === 5) {
                console.log("step 5")
                setEditMode(false)
                setAgentEdit(false)
                setCounter(3)
                setPrevStep(1)
                setAgentEdit(false)
                setKeyContactIds("")
            }
            else if (executors === false && step.step === 10) {
                setCounter(6)
                setPrevStep(5)
            } else {
                // console.log("true")
                // console.log(prevStep)
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/userTopicReview', { state:{ testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName } });
        }
    }
    const keyContactData = (item, key_contact_id) => {
        console.log(item, key_contact_id)
        console.log(keyContactIds)
        let data = {}
        data = {
            "key_contact_id": key_contact_id,
            "main_binder_id": binderId,
        }
        setLoading(true)
        getPowerOfAttorneyReview(topicId, testatorId, data).then((response) => {
            console.log(response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setWillFlow(response.data)
                setBinderId(response.data.main_binder_id)
                setKeyContactIds(response.data.key_contact_id)
                setLoading(false)
                // setAnswer()
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }

    const AddContat = (keyContactIds, questionIds) => {
        console.log(keyContactIds)
        console.log(questionIds)

        let data = {}
        data = {
            "AddContact": "yes",
            "question_id": questionIds,
            "main_binder_id": binderId,
            "contact_id": keyContactIds,
        }
        console.log(data)
        setLoading(true)
        getPowerOfAttorneyReview(topicId, testatorId, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'success') {
                setWillFlow(response.data)
                setLoading(false)
            }
        })
    }

    const btnClick = (data) => {
        setAgentEdit(true)
        setKeyContactIds(data.key_contact_id)
        setCounter(counter - 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = willFlow
            // console.log("Data from btn click: ", data)
            // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter - 1].inputList.map((item, index) => {
                // console.log("Match see: ", item.id, data[item.id]) 
                item.prefill = data[item.id]
            })
            // console.log("Temp Object from btnCLick: ", temp.steps[counter - 1].inputList)
            setWillFlow(temp)
        }

    }

    const poaEditClick = (data, main_binder_id) => {
        //console.log(counter)
        console.log(data)
        console.log(main_binder_id)
        let dataSetAttr = {
            "main_binder_id": main_binder_id,
        }

        setLoading(true)
        setBinderId(main_binder_id)
        getPowerOfAttorneyReview(topicId, testatorId, dataSetAttr).then((response) => {
            //  console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setWillFlow(response.data)
                setBinderId(response.data.main_binder_id)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
            }
        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })


        if (data) {
            let temp = willFlow
            //  console.log("Data from btn click: ", data)
            //  console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter + 3].inputList.map((item, index) => {
                //  console.log("Match see: ", item, item.id, data[item.id])
                item.prefill = data[item.id]
            })
            console.log("Temp Object from btnCLick: ", temp.steps[counter + 3].inputList)
            setWillFlow(temp)
            setCounter(counter + 3)
        }
    }
    // add another record
    const addAnother = (data, step) => {
        if (writePermission === "no") {
            let temp = {
                title: "Mandatory",
                content: "You don't have permission to perform this action"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else {
            setWillFlow(powerOfAttorney)
            setAnswer(initialValues)
            setAddAnotherBtn(true)
            if (counter === 3 || counter === 1 || counter === 0) {
                if (step.step === 4) {
                    // console.log("step 4 true")
                    setCounter(2)
                    setPrevStep(1)
                    setPrefill()
                } else if (step.step === 2) {
                    setCounter(2)
                    setPrevStep(0)
                } else if (step.step === 1) {
                    setCounter(1)
                    setPrevStep(0)
                }
                else {
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                }
            } else {
                navigate('/userTopicReview', { state: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
            }
        }

    }
    useEffect(() => {
        setLoading(true)
        // listBinderDetails("200000002").then((response) => {
        //      console.log("Binder List for topic Id 200000002: ", response.data)
        //     let temp = response.data
        //     temp.inputList = response.data.binder_list
        //      console.log("Finale temp here: ", temp)
        //     setBinderList(temp)
        //     setListPage(true)
        // }, (err) => {
        //      console.log("Error in getting binder List")
        // })
        getPowerOfAttorneyReview(topicId, testatorId).then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "error") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                // setWillFlow(response.data)
                setWillFlow(response.data)
                setPowerOfAttorney(response.data)
                setBinderId(response.data.main_binder_id)
                setWritePermission(response.data.write_permission)
                setLoading(false)
            }
        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(binderList).length > 0) {
            setLoading(false)
        }
    }, [willFlow])

    useEffect(() => {
        //console.log("Loading changed: ", loading, binderList)
    }, [loading])

    useEffect(() => {
        console.log("run useeffect counter")
        if (!loading) {

            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            setQuestionId(willFlow.steps[counter].question_id)
            console.log("answer Object: ", ansObj, prefillObj)
            console.log(addAnotherBtn)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        }
    }, [counter, loading])

    useEffect(() => {
        if (!loading) {
            console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                console.log(item)
                console.log(item.label)
                console.log(item.prefill)
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [editMode])


    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    if (Object.keys(willFlow).length === 0) {
        console.log(willFlow)
        navigate('/userTopicReview', { state:  { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName }  });
    }

    return (
        <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            <Grid columns={24} >
                <Grid.Col span={12} pl="zx" pt={0}>
                    <Box>
                        <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.lxl })}  > {testatorName}'s Information Binder</Text>
                        {/* <Text sx={(theme)=>({color: theme.colors.textColor[1], fontSize: theme.fontSizes.md })}> Lorem ipsum began as scrambled, nonsensical Latin derived from Cicero's 1st-century BC text De Finibus Bonorum et Malorum.</Text> */}
                    </Box>
                </Grid.Col>
            </Grid>
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx" pt={10} pb={10}>
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <RiPagesLine />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}>  Power Of Attorney</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            <Grid columns={24}>
                <Grid.Col span={24} pl="zx" pr="zx" pt={5}>
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>
            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24}>
                {willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControlReview
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            setEditMode ={setEditMode}
                            addAnother={addAnother}  // func for add another record
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            testatorId={testatorId}
                            writePermission={writePermission}
                            selectPOAagent={selectPOAagent}
                            keyContactData={keyContactData}

                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            poaEditClick={poaEditClick}
                            flowId="200000002"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>
            </Grid>}

            {/* </div>} */}
            {!modalStatus && !loading &&
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "60px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 1 || willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 4 || willFlow?.steps[counter].step === 5 || willFlow?.steps[counter].step === 6 || willFlow?.steps[counter].step === 7 || willFlow?.steps[counter].step === 8) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>
                            </Affix>
                        }
                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            }
        </div>
    )
}

export default PowerOfAttornyReview