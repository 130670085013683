import { Affix, Button, Grid,  LoadingOverlay } from "@mantine/core";
import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import FlowTitle from "../Component/flowTitle";
import FooterWill from "../Component/footer2";
import InputControlAccess from "../Component/inputControlAccess";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { showNotification} from '@mantine/notifications';
import { IconCheck, IconCircleX, IconCrane, IconX } from '@tabler/icons';
import { BASE_URL } from "../apis/flowReviewApis/global";

const MessageToKeyContact = ({ navigate,setLoggedIn }) => {
    const { state } = useLocation();
    const { key_contact_id } = state;
    const initialValues = {
        mail_text: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [prefill, setPrefill] = useState()

    let data = {
        title: "Invite your trusted person ",
        description: "Hi there, I want to add you as a trusted person on this platform. As a trusted person you will be able to manage my information. Please accept my invite.",
        inputList: [{
            id: "mail_text",
            label: "Send a text message to your trusted person",
            placeholder: "Hi there, I want to add you as a trusted person on this platform. As a trusted person you will be able to manage my information. Please accept my invite.",
            prefill: "",
            type: "textarea",
            value: ""
        }]

    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));

    function sendMessageToExecutor() {
        const url = BASE_URL + "/whitney/public/api/v1/invite-key-contact";
        // console.log(answer)
        let data = {}
        if (answer === undefined) {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: "error",
                message: "message should't blank",
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            return
        } else {

            data = {
                'uuid': userData.uuid,
                "access_token": userData.access_token,
                "key_contact_id": key_contact_id,
                "Save": "yes",
                ...answer
            }
            // console.log(data)
            const formData = new FormData();
            Object.keys(data).map((item, index) => {
                formData.set(item, data[item])
            })
            axios.post(url, formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type':  "multipart/form-data",
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then((response) => {
                // console.log(response)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                }else if (response.data.status === "success") {
                    // console.log(response.data)

                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                    navigate('/invetationSuccess', { state: { key_contact_id: key_contact_id } });

                } else if (response.data.status === "error") {

                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCrane size={16} />,
                        autoClose: 2000,
                    })

                } else {

                }

            }).catch((error) => {
                alert(error)
            })
        }

    }
    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    return (
        <>
            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>



                <Grid columns={24}>
                    <Grid.Col span={24}>
                        <div style={{ 'minWidth': '100%' }}>
                            <FlowTitle title={data.title} description={""} />
                        </div>
                    </Grid.Col>
                </Grid>
                <Grid columns={24}>
                  
                    {data.inputList.map((item, index) => {
                        return (
                            <InputControlAccess
                                data={item}
                                answer={answer}
                                setAnswer={setAnswer}
                                prefill={prefill}
                                setPrefill={setPrefill}
                                key={index}

                                
                                setLoggedIn={setLoggedIn}
                                navigate={setLoggedIn}
                            />)
                    })}
                </Grid>


                <>
                    <Affix
                               sx={(theme) => ({
                                "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                                [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                    'left': 300
                                },
                                [`@media (max-width:1280px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    'height': '110px',
                                    left: "0px"
                                },
                            })}
                        >
                            <Affix position={{}} sx={(theme) => ({
                                bottom: 70, left: 330, zIndex: 2,
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    left: "45px"
                                },
                            })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    navigate('/editKeyContact', { state: { key_contact_id: key_contact_id } });
                                }}
                            >
                                Back
                            </Button>
                        </Affix>

                        <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: theme.colors.primary[0],
                                    height: 50,
                                    fontSize: theme.fontSizes.xxl,
                                    fontWeight: 300,
                                    fontFamily: theme.fontFamily[0],
                                    '&:hover': {
                                        backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                    }
                                })}
                                onClick={() => {

                                    sendMessageToExecutor()

                                }}
                            >
                                Continue
                            </Button>
                        </Affix>

                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>


            </div>
        </>
    )
}

export default MessageToKeyContact;