import React, { useEffect, useState } from "react";
import FlowTitle from '../Component/flowTitle'
import { Affix, Button, Text, LoadingOverlay, Grid, Box } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiPagesLine } from 'react-icons/ri'
import FooterWill from "../Component/footer2";
import '../Custom.css';
import { getKeyContacts } from "../apis/getKeyContacts";
import InputControlKeyContact from "../Component/inputControlKeyContact";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";

const KeyContacts = ({ navigate, setLoggedIn }) => {
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [counter, setCounter] = useState(0)
    const [loading, setLoading] = useState(true)
    const [keyContacts, setKeyContacts] = useState({})
    const [keyContactsCopy, setKeyContactsCopy] = useState({})
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [prevStep, setPrevStep] = useState(-1);
    const [prefill, setPrefill] = useState();

    const [editMode, setEditMode] = useState(false)
    const [formError, setFormError] = useState();
    const [addAnotherKeyContact, setAddAnotherKeyContact] = useState(false)


    const handleClick = (step, btnClick) => {
        // console.log("step from handleClick: ")
        // console.log("btn click:")

        if (btnClick === "continue with api") {
            if (step.step === 2) {      // for step-3
                submitForm(step)            // call step -3 
            }
            else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (!step.lastStep) {
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            navigate(-1)
            setCounter(0)
        }
    }

    const submitForm = (step) => {
        // console.log("submit form")
        // console.log(answer)
        // console.log(step)
        let data = {}
        if (!answer || typeof (answer) != 'object') {
            setPrevStep(counter)
            setCounter(0)
            return
        }

        if (step.step === 2) {
            // console.log(answer)

            data = {
                "Save": "yes",
                ...answer
            }

        }
        if (editMode?.value) {
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        console.log(data)
        setLoading(true)
        getKeyContacts(data).then((response) => {
            // console.log("Api call success: ", response.data)

            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'error' && step.step === 2) {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                if (step.step == 2 && addAnotherKeyContact === true) {
                    if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(0)
                    } else {
                        setPrevStep(counter)
                        setCounter(0)
                    }
                    setKeyContacts(response.data)
                    setLoading(false)
                    setEditMode(false)
                    setFormError("");

                } else {
                    // console.log("sucess")
                    if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(0)
                    } else {
                        setPrevStep(counter)
                        setCounter(0)
                    }
                    setKeyContacts(response.data)
                    setLoading(false)

                    setEditMode(false)
                    setFormError("");
                }
            }
        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const btnClick = (data, key_contact_id) => {
        console.log(key_contact_id)
        console.log(data)
        setCounter(counter + 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = keyContacts
            // console.log("Data from btn click: ", data)
            // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter + 1].inputList.map((item, index) => {
                // console.log("Match see: ", item, item.id, data[item.id])
                item.prefill = data[item.id]
            })
            // console.log("Temp Object from btnCLick: ", temp.steps[counter + 1].inputList)
            // console.log(temp)
            setKeyContacts(temp)
        }
    }

    const addAnother = (data, step) => {
        setLoading(true)
        // setCounter(counter + 1)
        // setAddAnotherKeyContact(true)
        console.log(keyContactsCopy)
        getKeyContacts().then((response) => {
            console.log("Response of get keyContacts object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                console.log(response.data)
                setKeyContacts(response.data)
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting keyContacts object: ", error)
        })


        if (counter === 0) {
            // console.log("true")
            if (step.step === 1) {
                // console.log(true)
                setCounter(1)
                setPrevStep(-1)
            } else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate(-1)
        }

    }

    const backBtnClick = (step) => {
        setFormError("");
        if (counter != 0) {
            setCounter(prevStep)
            setPrevStep(prevStep - 1)
        } else {
            navigate(-1)
        }

    }

    const getDropDownValue = (data) => {
        //// console.log(keyContacts.steps[0].inputList[1].rows)
        let arr1 = []
        let arr2 = []
        let arr3 = []

        let tableArr = keyContacts.steps[0].inputList[1].rows
        tableArr.map((ele, key) => {
            if (ele.details[1].value === data) {
                arr1.push(ele)
            } else {
                arr2.push(ele)
            }

        })

        arr3 = arr1.concat(arr2);
        // console.log(arr3)
        keyContacts.steps[0].inputList[1].rows = arr3;
        // console.log(keyContacts)


    }
    useEffect(() => {
        setLoading(true)

        getKeyContacts().then((response) => {
            console.log("Response of get keyContacts object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                console.log(response.data)
                setKeyContacts(response.data)
                setKeyContactsCopy(response.data)
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting keyContacts object: ", error)
        })
    }, [])
    useEffect(() => {
        if (!loading) {
            console.log(counter)
            console.log(keyContacts)
            let temp = keyContacts.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: "" }
                    ansObj = { ...ansObj, [item?.id]: "" }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            // setQuestionId(keyContacts.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [addAnotherKeyContact])

    useEffect(() => {
        if (!loading) {
            // console.log(keyContacts.steps[counter])
            let temp = keyContacts.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            // setQuestionId(keyContacts.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [editMode])

    useEffect(() => {
        // console.log(counter)
        if (!loading) {   // backbutton case
            // console.log(counter)
            // console.log(keyContacts)
            // console.log(keyContacts.steps[counter])
            let temp = keyContacts.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [counter])

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    return (
        <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx">
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <RiPagesLine />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs, [`@media (max-width:3840px)`]: { fontSize:theme.fontSizes.lg } })}>Key Contacts</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }

            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24}>
                {keyContacts?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControlKeyContact
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={keyContacts?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            addAnother={addAnother}  // func for add another record
                            questionId={keyContacts?.steps[counter].question_id}
                            getDropDownValue={getDropDownValue}


                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            keyContactUpload={true}
                            keyContacts={{ get: () => { return keyContacts }, set: setKeyContacts }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>
            </Grid>}

            {/* </div>} */}
            {!modalStatus && !loading &&
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            {keyContacts?.steps[counter]?.step === 1 ? "" : <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(keyContacts?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                            }
                        </Affix>
                        {keyContacts?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                {keyContacts?.steps[counter]?.step === 1 ? "" : <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (keyContacts?.steps[counter].step === 2) {
                                            handleClick(keyContacts?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(keyContacts?.steps[counter], "continue without api")
                                        }
                                    }}
                                >

                                    {keyContacts?.steps[counter]?.step === 1 ? "Add another" : "Continue"}

                                </Button>}
                            </Affix>
                        }
                        <FooterWill navigate={navigate}/>
                    </Affix>

                </>
            }
        </div>
    )
}

export default KeyContacts;