import React, { useEffect, useState } from "react";
import { Affix, Box, Button, Card, Grid, Group, Modal, Paper, SimpleGrid, Text, Textarea, TextInput, Title, Transition } from '@mantine/core'
import ReactPlayer from 'react-player/lazy'
import "../Custom.css"
import { IoWarning } from "react-icons/io5";
import parse from 'html-react-parser';
import { IconArrowUp } from "@tabler/icons";
import { BiCheck, BiCrosshair, BiDockRight, BiX } from "react-icons/bi";
import { PermissionHeading } from "./textLabel";

const TipModal = ({ modalMessage, status, setStatus }) => {
    let test = `This is a <a href="#">link</a>, for a test.`
    return (
        <Modal
            opened={status}
            onClose={() => { setStatus(false) }}
            title={modalMessage.title}
            overlayOpacity={0.55}
            overlayBlur={3}
            styles={{
                body: { 'color': '#505664', 'fontSize': '1.3vw', 'padding': '0px' },
                title: { 'color': '#023047', 'fontSize': '2.03vw', 'padding': '0px' },
                header: { 'padding': '1.05vw' },
                modal: { 'width': '50vw', 'padding': '0px' },
                close: { 'fontSize': '2.03vw' }
            }}
            centered
        >
            {modalMessage.content &&
                <div style={{ 'padding': '1.05vw' }}>
                    <Text>{parse(modalMessage.content)}</Text>

                </div>
            }
            {modalMessage.url &&
                <div style={{ 'padding': '1.05vw' }}>
                    <ReactPlayer url={modalMessage.url} width='100%' />
                </div>
            }
            <div style={{ 'padding': '1vw', 'marginTop': '4vw', 'display': 'flex', 'justifyContent': 'flex-end' }}>
                <Button
                    radius="xxs"
                    sx={(theme) => ({
                        backgroundColor: '#023047',
                        fontSize: 16,
                        width: 200,
                        height: 50,
                        fontSize: 20
                    })}
                    onClick={() => { setStatus(false) }}
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}
export default TipModal;

export const ConfirmationModal = ({ modalMessage, status, setStatus }) => {
    return (
        <Modal
            opened={status}
            onClose={() => { setStatus(false) }}
            title={modalMessage.title}
            overlayOpacity={0.55}
            overlayBlur={3}
            styles={{
                body: { 'color': '#505664', 'fontSize': '1.3vw', 'padding': '0px' },
                title: { 'color': '#023047', 'fontSize': '2.03vw', 'padding': '0px' },
                header: { 'padding': '1.05vw' },
                modal: { 'width': '50vw', 'padding': '0px' },
                close: { 'fontSize': 25 }
            }}
            centered
        >
            {modalMessage.content &&
                <div style={{ 'padding': '1.05vw' }}>
                    {modalMessage.content}
                </div>
            }
            {modalMessage.url &&
                <div style={{ 'padding': '1.05vw' }}>
                    <ReactPlayer url={modalMessage.url} width='100%' />
                </div>
            }
            <div style={{ 'padding': '1vw', 'marginTop': '4vw', 'display': 'flex', 'justifyContent': 'flex-end' }}>
                <Button
                    radius="xxs"
                    sx={(theme) => ({
                        backgroundColor: '#D22B2B',
                        fontSize: 16,
                        width: 200,
                        height: 50,
                        fontSize: 20,
                        marginRight: 20,
                        ":hover": { backgroundColor: "#FF3131" }
                    })}
                    onClick={() => {
                        let args = modalMessage.args
                        modalMessage.deleteAction(...args)
                        setStatus(false)
                    }}
                >
                    Delete
                </Button>
                <Button
                    radius="xxs"
                    sx={(theme) => ({
                        backgroundColor: '#023047',
                        fontSize: 16,
                        width: 200,
                        height: 50,
                        fontSize: 20
                    })}
                    onClick={() => { setStatus(false) }}
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}



export const MessageModal = ({ modalMessage, status, setStatus }) => {
    // console.log(modalMessage)

    return (
        <>
            <Modal
                onClose={() => { setStatus(false) }}
                opened={status}
                title="Status!"
                className="messageModal"

            >
                {modalMessage &&
                    <Box className="textColor1 fontSize4">
                        {modalMessage}
                    </Box>
                }
            </Modal>
        </>
    )
}


export const HelpModal = ({ helpOpened, setHelpOpened }) => {
    return (
        <>
            <Modal
                opened={helpOpened}
                onClose={() => setHelpOpened(false)}
                title="Write us!"
                size="lg"
            >
                <Grid>
                    <Grid.Col>
                        <TextInput
                            placeholder="Subject here"
                            label="Subject "
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Textarea
                            placeholder="text..."
                            label="Message "
                            autosize
                            minRows={4}
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                radius="xxs"
                            >
                                Submit
                            </Button>
                        </Box>

                    </Grid.Col>
                </Grid>

            </Modal>



        </>
    )
}

export const WarningModal = ({ modalMessage, warnOpned, setWarnOpened }) => {
    console.log(modalMessage)
    return (
        <>
            <Modal
                opened={warnOpned}
                onClose={() => setWarnOpened(false)}
                title={<Box style={{ display: "flex", flexDirection: "row" }}><IoWarning size={35} color="#D22B2B" /><Title sx={(theme) => ({ color: theme.colors.primary[0] })} order={2}>{modalMessage.title}</Title></Box>}
                size="lg"
            >
                <Grid>
                    <Grid.Col>
                        <Text sx={(theme) => ({ color: theme.colors.primary[0], fontWeight: 600, fontSize: theme.fontSizes.lg })} order={4}>{modalMessage.content}</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <Text sx={(theme) => ({ color: theme.colors.primary[0], fontWeight: 600, fontSize: theme.fontSizes.lg })} order={4}>{modalMessage.content2}</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <div style={{ 'padding': '1vw', 'marginTop': '4vw', 'display': 'flex', 'justifyContent': 'flex-end' }}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: '#D22B2B',
                                    fontSize: 16,
                                    width: 200,
                                    height: 50,
                                    fontSize: 20,
                                    marginRight: 20,
                                    ":hover": { backgroundColor: "#FF3131" }
                                })}
                                onClick={() => {
                                    console.log(modalMessage.args)
                                    // let args = modalMessage.args
                                    modalMessage.setPermissions(modalMessage.args)
                                    setWarnOpened(false)
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    backgroundColor: '#023047',
                                    fontSize: 16,
                                    width: 200,
                                    height: 50,
                                    fontSize: 20
                                })}
                                onClick={() => { setWarnOpened(false) }}
                            >
                                Close
                            </Button>
                        </div>

                    </Grid.Col>
                </Grid>

            </Modal>



        </>
    )
}

export const FullScreenModal = ({ fullOpened, setFullOpened, getFormValue, questionData, answer }) => {
    const [allTrueQuestions, setAllTrueQuestions] = useState("")
    const [newAnswer, setNewAnswer] = useState(answer)
    const [tempWill, setTempWill] = useState([])
    const [mainWill, setMainWill] = useState([])

    const [tempPoa, setTempPoa] = useState([])
    const [mainPoa, setMainPoa] = useState([])

    const [tempTrust, setTempTrust] = useState([])
    const [mainTrust, setMainTrust] = useState([])

    const [tempLivingWill, setTempLivingWill] = useState([])
    const [mainLivingWill, setMainLivingWill] = useState([])

    const [tempHealth, setTempHealth] = useState([])
    const [mainHealth, setMainTempHealth] = useState([])


    useEffect(() => {
        let trueQuestion = []
        let allAnswer = []
        Object.keys(answer).map(key => {
            allAnswer.push({ [key]: answer[key] })
        })
        console.log(allAnswer)
        console.log(questionData)

        questionData.map((item, index) => {
            // if (answer[item.question_id] == true) {
            //     console.log("question", item.question_id)
            trueQuestion.push({ question_title: item.question, question_id: item.question_id, question_base: item.question_id.split("_")[3], topic_id: item.question_id.split("_")[1], value: answer[item.question_id] })
            // }
        })
        console.log(trueQuestion)
        console.log(newAnswer)
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(trueQuestion.map(item =>
            [item[key], item])).values()];
        setAllTrueQuestions(arrayUniqueByKey)
        console.log(arrayUniqueByKey)
        arrayUniqueByKey.map((item, index) => {
            if (item.topic_id == 200000001) {
                setTempWill((prevState) => [...prevState, { question_title: item.question_title, nowValue: answer["at_" + item.topic_id + "_now_" + item.question_base], afterValue: answer["at_" + item.topic_id + "_afterdeath_" + item.question_base] }])
            } else if (item.topic_id == 200000002) {
                setTempPoa((prevState) => [...prevState, { question_title: item.question_title, nowValue: answer["at_" + item.topic_id + "_now_" + item.question_base], afterValue: answer["at_" + item.topic_id + "_afterdeath_" + item.question_base] }])
            } else if (item.topic_id == 200000003) {
                setTempTrust((prevState) => [...prevState, { question_title: item.question_title, nowValue: answer["at_" + item.topic_id + "_now_" + item.question_base], afterValue: answer["at_" + item.topic_id + "_afterdeath_" + item.question_base] }])
            } else if (item.topic_id == 200000004) {
                setTempLivingWill((prevState) => [...prevState, { question_title: item.question_title, nowValue: answer["at_" + item.topic_id + "_now_" + item.question_base], afterValue: answer["at_" + item.topic_id + "_afterdeath_" + item.question_base] }])
            }
            else if (item.topic_id == 200000005) {
                setTempHealth((prevState) => [...prevState, { question_title: item.question_title, nowValue: answer["at_" + item.topic_id + "_now_" + item.question_base], afterValue: answer["at_" + item.topic_id + "_afterdeath_" + item.question_base] }])
            }
        })

    }, [getFormValue, questionData])

    useEffect(() => {
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(tempWill.map(item =>
            [item[key], item])).values()];
        setMainWill(arrayUniqueByKey)
    }, [tempWill])

    useEffect(() => {
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(tempPoa.map(item =>
            [item[key], item])).values()];
        setMainPoa(arrayUniqueByKey)
    }, [tempPoa])

    useEffect(() => {
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(tempTrust.map(item =>
            [item[key], item])).values()];
        setMainTrust(arrayUniqueByKey)
    }, [tempTrust])

    useEffect(() => {
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(tempLivingWill.map(item =>
            [item[key], item])).values()];
        setMainLivingWill(arrayUniqueByKey)
    }, [tempLivingWill])

    useEffect(() => {
        const key = 'question_title';
        const arrayUniqueByKey = [...new Map(tempHealth.map(item =>
            [item[key], item])).values()];
        setMainTempHealth(arrayUniqueByKey)
    }, [tempHealth])

    console.log(tempWill)
    return (
        <>
            <Modal
                opened={fullOpened}
                onClose={() => setFullOpened(false)}
                title={<Box><Title order={2} sx={(theme) => ({ color: theme.colors.primary[0] })}>Are you sure that you want to give below permissions?</Title></Box>}
                size="calc(100vw - 87px)"
            // overflow="inside"
            >
                <Grid>
                    <Grid.Col>
                        <div style={{ overflowY: "auto", height: "calc(100vh - 330px)", paddingTop: 40, paddingBottom: 25, paddingLeft: 25, paddingRight: 25 }}>


                            {mainWill && <Card withBorder={false} shadow="sm" radius="md">
                                <Card.Section inheritPadding py="xs">

                                    <Grid>
                                        <Grid.Col span={8}>
                                            <Group position="apart">
                                            <PermissionHeading title="Will" />

                                            </Group>
                                        </Grid.Col>
                                        <Grid.Col span={2} style={{display:"flex", justifyContent: "center", paddingRight:30}}><PermissionHeading title="Now"/></Grid.Col>
                                        <Grid.Col span={2} style={{display:"flex", justifyContent: "center", paddingRight:30}}><PermissionHeading title="After Death" /></Grid.Col>
                                    </Grid>
                                </Card.Section>

                                <Card.Section inheritPadding mt="sm" pb="md">
                                    {mainWill && mainWill?.map((val, key) => (
                                        <Paper shadow="xs" p="md" key={key} mb={10} >
                                            <Grid>
                                                <Grid.Col span={8}>
                                                    <Text sx={(theme) => ({ color: theme.colors.primary[0], fontSize: 18 })}>
                                                        {val.question_title}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.nowValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.afterValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                            </Grid>
                                        </Paper>
                                    ))
                                    }
                                </Card.Section>
                            </Card>
                            }

                            {mainPoa && <Card withBorder={false} shadow="sm" radius="md" mt={15}>
                                <Card.Section inheritPadding py="xs">
                                    <Group position="apart">
                                    <PermissionHeading title="Power of attorney" />

                                    </Group>
                                </Card.Section>

                                <Card.Section inheritPadding mt="sm" pb="md">
                                    {mainPoa && mainPoa?.map((val, key) => (
                                        <Paper shadow="xs" p="md" key={key} mb={10}>
                                            <Grid>
                                                <Grid.Col span={8}>
                                                    <Text sx={(theme) => ({ color: theme.colors.primary[0], fontSize: 18 })}>
                                                        {val.question_title}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.nowValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.afterValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                            </Grid>
                                        </Paper>
                                    ))
                                    }
                                </Card.Section>
                            </Card>
                            }
                            {mainTrust && <Card withBorder={false} shadow="sm" radius="md" mt={15}>
                                <Card.Section inheritPadding py="xs">
                                    <Group position="apart">
                                    <PermissionHeading title="Trusts" />

                                    </Group>
                                </Card.Section>

                                <Card.Section inheritPadding mt="sm" pb="md">
                                    {mainTrust && mainTrust?.map((val, key) => (
                                        <Paper shadow="xs" p="md" key={key} mb={10}>
                                            <Grid>
                                                <Grid.Col span={8}>
                                                    <Text sx={(theme) => ({ color: theme.colors.primary[0], fontSize: 18 })}>
                                                        {val.question_title}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.nowValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.afterValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                            </Grid>
                                        </Paper>
                                    ))
                                    }
                                </Card.Section>
                            </Card>
                            }

                            {mainLivingWill && <Card withBorder={false} shadow="sm" radius="md" mt={15}>
                                <Card.Section inheritPadding py="xs">
                                    <Group position="apart">
                                    <PermissionHeading title="Living will" />

                                    </Group>
                                </Card.Section>

                                <Card.Section inheritPadding mt="sm" pb="md">
                                    {mainLivingWill && mainLivingWill?.map((val, key) => (
                                        <Paper shadow="xs" p="md" key={key} mb={10}>
                                           <Grid>
                                                <Grid.Col span={8}>
                                                    <Text sx={(theme) => ({ color: theme.colors.primary[0], fontSize: 18 })}>
                                                        {val.question_title}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.nowValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.afterValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                            </Grid>
                                        </Paper>
                                    ))
                                    }
                                </Card.Section>
                            </Card>
                            }

                            {mainHealth && <Card withBorder={false} shadow="sm" radius="md" mt={15}>
                                <Card.Section inheritPadding py="xs">
                                    <Group position="apart">
                                    <PermissionHeading title="Healthcare proxy" />

                                    </Group>
                                </Card.Section>

                                <Card.Section inheritPadding mt="sm" pb="md">
                                    {mainHealth && mainHealth?.map((val, key) => (
                                        <Paper shadow="xs" p="md" key={key} mb={10}>
                                          <Grid>
                                                <Grid.Col span={8}>
                                                    <Text sx={(theme) => ({ color: theme.colors.primary[0], fontSize: 18 })}>
                                                        {val.question_title}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.nowValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                                <Grid.Col span={2} style={{display:"flex", justifyContent: "center"}}>{val.afterValue === true ? <BiCheck size={24} color="green" /> : <BiX size={24} color="red" />}</Grid.Col>
                                            </Grid>
                                        </Paper>
                                    ))
                                    }
                                </Card.Section>
                            </Card>
                            }
                        </div>
                    </Grid.Col>
                    <Grid.Col>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <div>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => { setFullOpened(false) }}
                                >
                                    Edit
                                </Button>
                            </div>
                            <div>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: '#D22B2B',
                                        fontSize: 16,
                                        width: 200,
                                        height: 50,
                                        fontSize: 20,
                                        marginRight: 20,
                                        ":hover": { backgroundColor: "#FF3131" }
                                    })}
                                    onClick={() => {

                                        getFormValue()


                                    }}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>

                    </Grid.Col>
                </Grid>

            </Modal>
        </>
    )
}