import React, { useEffect, useState } from "react";
import { InfoCard } from '../Component/infoCard'
import { ReactComponent as UserRole } from '../assets/Icons/userRole.svg'
import { ReactComponent as PersonalInformation } from '../assets/Icons/personalInfo.svg'
import { ReactComponent as AuditTrail } from '../assets/Icons/auditTrail.svg'
import { ReactComponent as ManageAccess } from '../assets/Icons/manageAccess.svg'
import { ReactComponent as PeriodicReview } from '../assets/Icons/periodicReview.svg'
import { ReactComponent as Account } from '../assets/Icons/account.svg'
import Footer from "../Component/footer"
import { Grid,  Box, LoadingOverlay, useMantineTheme } from "@mantine/core";
import '../Custom.css'
import { Home } from "../apis/dashboardApi";
import FlowTitle from "../Component/flowTitle";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";

const InfoBinder = ({ navigate, setLoggedIn, setDynamicMenu }) => {
    const theme = useMantineTheme();
    const [cardData, setCardData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setDynamicMenu({menuStatus: false})
        setLoading(true)
        Home().then((response) => {
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === "success") {
                console.log(response.data) 
                setCardData(response.data)
                setLoading(false)
            }
            else if (response.data.status === "error") {
                  console.log(response.data)
                setCardData(response.data)
                // console.log(cardData)
                setLoading(false)
            }

        })
    }, [])

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    console.log(loading)
    return (
       
        <>
        {!loading  && <div  >
            
           <div style={{ paddingLeft: 10, paddingBottom: 15 }}>
                {/* <FlowTitle title={cardData.title}   description={cardData.description}/> */}
                <FlowTitle title={"Home"} description={"See a snapshot of all your modules here!"} />
            </div>



            <Grid>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <Box
                        sx={(theme) => ({
                            cursor: "pointer",
                            '&:hover': {

                            }

                        })}
                        onClick={() => {
                            //  console.log("click")
                            navigate('/myRoles')
                        }}
                    >
                        <InfoCard title={cardData.card_myrole_title} description={cardData.card_myrole_description} color="rgba(2, 48, 71)" Icon={<UserRole />}
                        />
                    </Box>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <Box
                        sx={(theme) => ({
                            cursor: "pointer",
                            '&:hover': {

                            }

                        })}
                        onClick={() => {
                            //  console.log("click")
                            navigate('/personalInfo')
                        }}
                    >
                        <InfoCard title={cardData.card_personal_information_title} description={cardData.card_personal_information_description} Icon={<PersonalInformation />} color="rgba(2, 48, 71)" />
                    </Box>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <InfoCard title={cardData.card_audit_trail_title} description={cardData.card_audit_trail_description} Icon={<AuditTrail />} color="rgba(2, 48, 71, 0.5)" />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <Box
                        sx={(theme) => ({
                            cursor: "pointer",
                            '&:hover': {

                            }

                        })}
                        onClick={() => {
                            //  console.log("click")
                            navigate('/accessControl')
                        }}
                    >
                        <InfoCard title={cardData.card_manage_access_title} description={cardData.card_manage_access_description} Icon={<ManageAccess />} color="rgba(2, 48, 71)" />
                    </Box>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <InfoCard title={cardData.card_periodic_review_title} description={cardData.card_periodic_review_description} Icon={<PeriodicReview />} color="rgba(2, 48, 71, 0.5)" />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <InfoCard title={cardData.card_account_title} description={cardData.card_account_description} Icon={<Account />} color="rgba(2, 48, 71, 0.5)" />
                </Grid.Col>




                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <div style={{ height: 40, color: '#ffffff' }}> </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <div style={{ height: 40, color: '#ffffff' }}> </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4} lg={4}>
                    <div style={{ height: 40, color: '#ffffff' }}> </div>
                </Grid.Col>

            </Grid>

            <Footer navigate={navigate}/>


        </div>
    }
    </>
    )
}

export default InfoBinder