import React, { useState } from "react";

import SideBar from "../Component/sidebar";

import { Routes, Route, useNavigate } from 'react-router-dom'
// import EssentialDocs from '../Pages/essentialDocs';
import EssentialDocs from '../Pages/EssentialDocs';
import InfoBinder from '../Pages/infoBinder'
import WillFlow from '../Pages/willFlow';
import LivingWill from "./livingWill";
import HealthcareProxy from "./healthcareProxy";
import Trust from "./trust";
import PowerOfAttorney from "./powerOfAttorney";
import AccessControl from './accessControl';
import { AppShell, Navbar, useMantineTheme} from '@mantine/core';
import { MainLinkSubIcon } from '../mantine/_mainLinkSub';
import MenuHeader from '../Component/MenuHeader';
import { useScrollIntoView, useViewportSize } from '@mantine/hooks';
import PersonalInfo from "./personalInfo";
import KeyContacts from "./keyContact";
import EditAccessPermission from "./editAccessPermission";
import EditKeyContact from "./editKeyContact";
import MessageToKeyContact from "./messageToKeyContact";
import AddNewExecutor from "./addNewExecutor";
import InvetationMessage from "./invetationMessage";
import MyRoles from "./myRoles";
import ContactInfoBinder from "./contactInfoBinder";
import UserTopicReview from "./userTopicReview";
import WillFlowReview from "./userFlow/willFlowReview";
import NotFound from "./notFound";
import PowerOfAttornyReview from "./userFlow/powerOfAttornyReview";
import TrustReview from "./userFlow/trustReview";
import LivingWillReview from "./userFlow/livingWillReview";
import HealthcareProxyReview from "./userFlow/healthCareProxyReview";
import UserTopicReviewAs from "./userTopicReviewAs";
import WillFlowReviewAs from "./userFlowAs/willFlowReviewAs";
import TrustReviewAs from "./userFlowAs/trustReviewAs";
import LivingWillReviewAs from "./userFlowAs/livingWillReviewAs";
import PowerOfAttornyReviewAs from "./userFlowAs/powerOfAttornyReviewAs";
import HealthcareProxyReviewAs from "./userFlowAs/healthCareProxyReviewAs";
import ReportDeceased from "./Deceased/reportDeceased";
import UploadDeceDocs from "./Deceased/uploadDeceDocs";
import Privacy from "./Privacy";
import Security from "./Security";
import LicenceAgreement from "./LicenceAgreement";
import EditPersonalInfo from "./editPersonalInfo"







const Home = ({ setLoggedIn }) => {
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    // console.log(userData.profile_image)
    const navigate = useNavigate()
    const [userLoginData, setUserLoginData] = useState(userData)
    const [profileImage, setProfileImage] = useState(userData.profile_image)
    const { scrollableRef } = useScrollIntoView();
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const [dynamicMenu, setDynamicMenu] = useState({menuStatus: false})

    return (
        <AppShell
            styles={{
                // main: {
                //   background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                // },
                backgrounColor: '#ffff',



            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"

            navbar={
                < Navbar width={{ base: 300 }}

                    hiddenBreakpoint="sm" hidden={!opened} p="xs" sx={(theme) => ({
                        backgroundColor: '#F6FBFD', border: 'none',
                        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                            width: 300,
                        },
                        [`@media (max-width: 1280px)`]: {
                            width: 270,
                           
                        },
                        [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                            width: 270,
                        },
                        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                            width: 270,
                        },
                        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                            width: "100%",
                        }

                    })}>
                    <Navbar.Section grow >
                        <SideBar userLoginData={userLoginData} opened={opened} setOpened={setOpened} profileImage={profileImage} navigate={navigate} setLoggedIn={setLoggedIn} dynamicMenu={dynamicMenu} setDynamicMenu={setDynamicMenu}/>
                    </Navbar.Section>
                    <Navbar.Section>
                        <div style={{ marginBottom: 8 }}>
                            <MainLinkSubIcon my={20} navigate={navigate} setLoggedIn={setLoggedIn} />
                        </div>
                    </Navbar.Section>
                </Navbar >
            }
            header={

                < MenuHeader opened={opened} setOpened={setOpened}  navigate={navigate}/>
            }

            ref={scrollableRef}
            style={{ overflow: "auto", flex: 1 }}


        >
            <Routes>
                <Route path="/" element={<InfoBinder navigate={navigate} setLoggedIn={setLoggedIn} setDynamicMenu={setDynamicMenu}/>} />
                <Route path="/infoBinder" element={<InfoBinder navigate={navigate} setLoggedIn={setLoggedIn} setDynamicMenu={setDynamicMenu}/>} />
                <Route path="/essentialDocs" element={<EssentialDocs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/essentialDocs/willFlow" element={<WillFlow navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/essentialDocs/livingWill" element={<LivingWill navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/essentialDocs/healthcareProxy" element={<HealthcareProxy navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/essentialDocs/trust" element={<Trust navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/essentialDocs/powerOfAttorney" element={<PowerOfAttorney navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path='/personalInfo' element={<PersonalInfo navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} setProfileImage={setProfileImage} userLoginData={userLoginData} setUserLoginData={setUserLoginData} setDynamicMenu={setDynamicMenu}/>} />
                <Route path='/editPersonalInfo' element={<EditPersonalInfo navigate={navigate} setLoggedIn={setLoggedIn}  />} />
                <Route path='/keyContact' element={<KeyContacts navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path='/accessControl' element={<AccessControl navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} setDynamicMenu={setDynamicMenu} />} />
                <Route path='/editAcesspermission' element={<EditAccessPermission navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/editKeyContact' element={<EditKeyContact navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/messageToKeyContact' element={<MessageToKeyContact navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/addExecutor' element={<AddNewExecutor navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/invetationSuccess' element={<InvetationMessage navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/myRoles' element={<MyRoles navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path='/contactInfoBinder' element={<ContactInfoBinder navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} setDynamicMenu={setDynamicMenu}/>} />
                <Route path='/userTopicReview' element={<UserTopicReview navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} setDynamicMenu={setDynamicMenu}/>

                <Route path="/userTopicReview/willFlowReview" element={<WillFlowReview navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReview/powerOfAttorneyReview" element={<PowerOfAttornyReview navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReview/trustReview" element={<TrustReview navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReview/livingWillReview" element={<LivingWillReview navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReview/healthCareReview" element={<HealthcareProxyReview navigate={navigate} setLoggedIn={setLoggedIn} />} />

                <Route path='/userTopicReviewAs' element={<UserTopicReviewAs navigate={navigate} setLoggedIn={setLoggedIn} profileImage={profileImage} userLoginData={userLoginData} />} />
                <Route path="/userTopicReviewAs/willFlowReviewAs" element={<WillFlowReviewAs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReviewAs/livingWillReviewAs" element={<LivingWillReviewAs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReviewAs/healthCareReviewAs" element={<HealthcareProxyReviewAs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReviewAs/trustReviewAs" element={<TrustReviewAs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/userTopicReviewAs/powerOfAttorneyReviewAs" element={<PowerOfAttornyReviewAs navigate={navigate} setLoggedIn={setLoggedIn} />} />

                <Route path="/reportDeceased" element={<ReportDeceased navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/uploadDeceDocs" element={<UploadDeceDocs navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/licenceAgreement" element={<LicenceAgreement navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/privacy" element={<Privacy navigate={navigate} setLoggedIn={setLoggedIn} />} />
                <Route path="/security" element={<Security navigate={navigate} setLoggedIn={setLoggedIn} />} />

                <Route path="*" element={<NotFound navigate={navigate} />} />
            </Routes>

        </AppShell >
    )
}

export default Home