import React, { useEffect, useState } from "react";
import FlowTitle from '../Component/flowTitle'
import InputControl from '../Component/inputControl'
import { Affix, Button, Text, LoadingOverlay, Grid, Box, Stepper } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiHeartPulseLine } from 'react-icons/ri'

import TipModal from '../Component/modal'
import { getHealthCareFlow, listBinderDetails } from '../apis/healthcareProxyApis'
import FooterWill from "../Component/footer2";
import '../Custom.css';
import { healthCareProxyStepperBack, healthCareProxyStepperNext } from "../apis/stepperFlow";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

const HealthcareProxy = ({ navigate, setLoggedIn }) => {
    // for Add Executor and beneficiaries
    const initialValues = {
        relationship: "",
        company_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country_id: "",
        zip_code: "",
        phone_c_code: "",
        phone_no: "",
        category: "",
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)

    const [active, setActive] = useState(1);  // for stepper
    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [healthCare, setHealthCare] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState(false)
    // const willFlow = require("../willFlow.json")


    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [binderId, setBinderId] = useState();
    const [handleSkips, setHandleSkips] = useState(false)
    const [addAnotherBtn, setAddAnotherBtn] = useState(false)
    const [keyContactId, setKeyContactId] = useState("");
    const [agentEdit, setAgentEdit] = useState(false)   // for skip agent list step when edit

    const handleClick = (step, btnClick) => {
        /*Set breadcrumb*/
        console.log(step)
        console.log("btn click", btnClick)

        healthCareProxyStepperNext(step, setActive)
        /*End Set breadcrumb*/

        console.log("step from handleClick: ", step)
        console.log(btnClick)
        setHandleSkips(false)
        if (step.step === 2 && btnClick === "secondery") {
            let temp = {
                title: "Mandatory",
                content: "You do not have create healthcare proxy yet, create a new health care proxy."
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 5 && btnClick === "secondery") {
            console.log("step 5 true")
            // console.log(counter)
            // setPrevStep(counter)
            // setBeneficiaries(false)
            // setCounter(6)
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (step.step === 7 && btnClick === "secondery") {
            console.log("step 7 true")
            console.log(counter)
            // setPrevStep(counter)
            // setExecutors(false)
            // setCounter(7)
            let temp = {
                title: "Modal title",
                content: "Modal description"
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (btnClick === "continue with api") {
            if (step.step === 8) {
                console.log("step", step.step)
                submitNotes(step)
            } else if (step.step === 5) {
                submitNotes(step)
            } else if (step.step === 3) {
                submitForm(step);
            } else if (step.step === 6) {
                submitForm(step);
            } else if (step.step === 7) {
                submitNotes(step);
            } else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (step.step === 2 && btnClick === "primary") {
            setPrevStep(counter)
            setCounter(counter + 2)
        } else if (!step.lastStep) {
            console.log(keyContactId.length)
            console.log(step)
            console.log(step.step, "true")
            if (step.step === 4 & keyContactId.length === 0) {
                let temp = {
                    title: "Mandatory",
                    content: "Add a new agent using Add New button OR update existing from agent list using edit button."
                }
                setModalMessage(temp)
                setModalStatus(!modalStatus)
            } else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }

        } else {
            // navigate('/essentialDocs')
            setCounter(0)
            setPrevStep(-1)
        }
    }

    const submitForm = (step) => {
        console.log(step)
        console.log(answer)
        let data = {}
        console.log(binderId)
        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                // navigate('/essentialDocs')
                setCounter(0)
                setPrevStep(-1)
            }
            return
        }

        if (step.step === 3) {
            data = {
                "SaveKeyContact": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactId,
                "contact_type_id": 103,
                ...answer
            }
        } else if (step.step === 6) {
            data = {
                "SaveDocument": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactId,
                ...answer
            }
        }
        console.log(data)
        if (editMode) {
            data.question_id = editMode?.data?.question_id
            data.contact_type_id = editMode?.data?.contact_type_id
            data.key_contact_id = editMode?.data?.key_contact_id
        }
        console.log(data)
        setLoading(true)
        getHealthCareFlow("200000005", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'error' && step.step === 3) {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                console.log(response.data)
                if (step.step === 3) {
                    setKeyContactId(response.data.key_contact_id)
                }
                if (!step.lastStep && step.step === 3 && agentEdit === true) {
                    setPrevStep(counter + 1)
                    setCounter(counter + 2)
                    setAgentEdit(false)
                } else if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }

                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
                setFormError("");
                setBinderId(response.data.main_binder_id)
                setAnswer(initialValues)
            } else {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }
                setWillFlow(response.data)
                setLoading(false)
                setAnswer(initialValues)
                setEditMode(false)
                setBinderId(response.data.main_binder_id)

            }

        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const submitNotes = (step) => {
        console.log("Submit Notes called")
        console.log(step)
        // debugger
        let data = {}
        if (!answer) {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                // navigate('/essentialDocs')
                setCounter(0)
                setPrevStep(-1)
            }
            return
        }
        if (step.step === 5) {
            data = {
                "SaveData": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactId,
                ...answer
            }
        } else if (step.step === 7) {
            data = {
                "SaveData": "yes",
                "question_id": questionId,
                "main_binder_id": binderId,
                "key_contact_id": keyContactId,
                ...answer
            }
        }
        console.log(data)
        setLoading(true)
        getHealthCareFlow("200000005", data).then((response) => {

            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }
            
            if (response.data.status === 'error') {
                console.log("error")
                console.log(response.data.message)
                setFormError(response.data.message);
                setLoading(false);

            } else if (response.data.status === 'success') {
                showNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                })
                if(step.step === 7){
                    setActive(1)
                }
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)

                }
                setWillFlow(response.data)
                setLoading(false)
                setAnswer(initialValues)
            } else {
                if(step.step === 8){
                    setActive(1)
                }
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    // navigate('/essentialDocs')
                    setCounter(0)
                    setPrevStep(-1)
                }
                setWillFlow(response.data)
                setLoading(false)
                // setAnswer()
                setFormError("");
            }

        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }
    //select Health care agent
    const selectHCAgent = (item, contactId, step) => {
        healthCareProxyStepperNext(step, setActive)
        console.log(item)
        console.log(contactId)
        let data = {}
        data = {
            "key_contact_id": contactId,
            "main_binder_id": binderId,
        }
        console.log(data)
        setLoading(true)
        getHealthCareFlow("200000005", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === 'success') {
                setWillFlow(response.data)
                setKeyContactId(response.data.key_contact_id)
                setBinderId(response.data.main_binder_id)
                setPrevStep(counter)
                setCounter(counter + 1)
                setLoading(false)
            }
        })
    }
    //AddContat to benifi and agent
    const AddContat = (keyContactIds, questionIds) => {

        let data = {}
        data = {
            "AddContact": "yes",
            "question_id": questionIds,
            "main_binder_id": binderId,
            "contact_id": keyContactIds,
        }
        console.log(data)
        setLoading(true)
        getHealthCareFlow("200000005", data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else if (response.data.status === 'success') {
                setWillFlow(response.data)
                setLoading(false)
            }
        })
    }
    // add another record
    const addAnother = (data, step) => {
        // console.log(data, step)
        // console.log(beneficiaries) 
        // console.log(counter)
        // setWillFlow(healthCare)
        console.log(willFlow)
        setAddAnotherBtn(true)
        setAnswer(initialValues)
        setLoading(true)
        getHealthCareFlow("200000005").then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (response.data.status === 'success') {
                setWillFlow(response.data)
                if (counter === 0 || counter === 3) {
                    console.log("inside counter")
                    if (step.step === 1) {
                        healthCareProxyStepperNext(step, setActive)
                        setCounter(1)
                        setPrevStep(0)
                    } else if (step.step === 4) {
                        setCounter(2)
                        setPrevStep(1)
                    }
                    else {
                        setCounter(prevStep)
                        setPrevStep(prevStep - 1)
                    }
                } else {

                    if (step.step === 1) {

                        setCounter(1)
                        setPrevStep(0)
                    } else {
                        console.log("skips counter")
                        navigate('/essentialDocs')
                    }
                }
                setLoading(false)
            }
        })

    }
    const backBtnClick = (step) => {
        setEditMode(false)
        setAddAnotherBtn(false)
        console.log(step)
        /*Set breadcrumb*/
        // setActive((current) => (current > 0 ? current - 1 : current));
        healthCareProxyStepperBack(step, setActive)
        /*End Set breadcrumb*/
        console.log(step)

        setHandleSkips(true)
        if (counter !== 0) {
            if (step.step === 7) {
                console.log("step 7 ")
                setCounter(5)
                setPrevStep(4)
            } else if (step.step === 6) {
                console.log("step 6 true,")
                setCounter(4)
                setPrevStep(3)
            } else if (step.step === 5) {
                console.log("step 5 true,")
                setCounter(3)
                setPrevStep(2)
                setKeyContactId("")
            } else if (step.step === 4) {
                console.log("step 5 true,")
                setCounter(0)
                setPrevStep(1)
                setAgentEdit(false)
                setBinderId("")
            }
            else if (step.step === 2) {
                console.log("step 2 true")
                navigate('/essentialDocs')
            } else {
                console.log("true line 390")
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            // console.log("true nevigate -1")
            navigate('/essentialDocs')
        }
    }

    const btnClick = (data) => {
        console.log(data)
        setAgentEdit(true)  // for skip agent list step when edit
        setKeyContactId(data.key_contact_id)
        setAddAnotherBtn(false)
        setCounter(counter - 1)
        setEditMode({ value: true, data: data })
        if (data) {
            let temp = willFlow
            // console.log("Data from btn click: ", data)
            // console.log("Inputlist on btnCLick: ", data)
            temp.steps[counter - 1].inputList.map((item, index) => {
                // console.log("Match see: ", item.id, data[item.id])
                item.prefill = data[item.id]
            })
            console.log("Temp Object from btnCLick: ", temp)
            setWillFlow(temp)
        }
    }

    const healthEditClick = (data, main_binder_id, step) => {
        // console.log(counter)
        healthCareProxyStepperNext(step, setActive)
        console.log(data)
        console.log(main_binder_id)
        let dataSetAttr = {
            "main_binder_id": main_binder_id,
        }

        setLoading(true)
        setBinderId(main_binder_id)
        getHealthCareFlow("200000005", dataSetAttr).then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setWillFlow(response.data)
                setBinderId(response.data.main_binder_id)
                setLoading(false)
                // setAnswer()
                setEditMode(false)
            }
        }, (error) => {
            // console.log("Error in getting willflow object: ", error)
        })


        if (data) {
            let temp = willFlow
            temp.steps[counter + 3].inputList.map((item, index) => {
                item.prefill = data[item.id]
            })
            setWillFlow(temp)
            setCounter(counter + 3)
        }
    }

    useEffect(() => {
        setLoading(true)
        listBinderDetails("200000005").then((response) => {
            // console.log("Binder List for topic Id 200000002: ", response.data)
        }, (err) => {
            // console.log("Error in getting binder List")
        })
        getHealthCareFlow("200000005").then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {
                setWillFlow(response.data)
                setHealthCare(response.data)
                setBinderId(response.data.main_binder_id)
            }

        }, (error) => {
            // console.log("Error in getting willflow object: ", error)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(willFlow).length > 0) {
            setLoading(false)
        }
    }, [willFlow])



    useEffect(() => {
        console.log(counter)
        if (!loading && handleSkips === false) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 2) {
                // console.log("step 4 is false")
                setCounter(counter + 1)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 1 false") 
                setCounter(counter)
            }
            else {
                setCounter(counter)
            }
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            console.log(addAnotherBtn)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        } else if (!loading && handleSkips === true) {
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            console.log("answer Object: ", ansObj, prefillObj)
            console.log(addAnotherBtn)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            if (addAnotherBtn === false) {
                setAnswer(ansObj)
            }
            setPrevStep(counter - 1)
        }
    }, [counter])

    useEffect(() => {
        if (!loading) {
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 2) {
                // console.log("step 4 is false")
                setCounter(counter + 1)
            } else if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 1 false")
                setCounter(counter)
            }
            else {
                setCounter(counter)
            }
        }
    }, [loading])



    function setBreadCrumb(stepIndex) {
        console.log(stepIndex)
        setEditMode(false)
        setLoading(true)
        setFormError("")
        switch (stepIndex) {
            case 0:
                setCounter(0)
                setLoading(false)
                break;
            case 1:
                if (willFlow.steps[1].visible === true) {
                    setCounter(1)
                } else {
                    setCounter(3)
                }
                setLoading(false)
                break;
            case 2:
                if (willFlow.steps[3].table_data.length < 1 || keyContactId.length === 0) {
                    setLoading(false)
                    let temp = {
                        title: "Mandatory",
                        content: "You do not have create healthcare proxy yet, create a new health care proxy."
                    }
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(4)
                    setLoading(false)
                }

                break;
            case 3:
                if (willFlow.steps[3].table_data.length < 1 || keyContactId.length === 0) {
                    setLoading(false)
                    let temp = {
                        title: "Mandatory",
                        content: "You do not have create healthcare proxy yet, create a new health care proxy."
                    }
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(5)
                    setLoading(false)
                }
                break;
            case 4:
                if (willFlow.steps[3].table_data.length < 1 || keyContactId.length === 0) {
                    setLoading(false)
                    let temp = {
                        title: "Mandatory",
                        content: "You do not have create healthcare proxy yet, create a new health care proxy."
                    }
                    setModalMessage(temp)
                    setModalStatus(!modalStatus)
                } else {
                    setCounter(6)
                    setLoading(false)
                }
                break;
            default:
                setCounter(0)
                setLoading(false)
        }
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }
    // // console.log(modalStatus)
    // if(Object.keys(willFlow).length === 0){
    //     console.log(willFlow)
    //     navigate('/essentialDocs')
    // }
    // console.log(counter)
    return (
        <div className="mainContainer" >
            <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />
            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} p="zx">
                        <Box >
                            <Stepper active={active}

                                onStepClick={(stepIndex) => {
                                    console.log(stepIndex)
                                    setActive(stepIndex)

                                    setBreadCrumb(stepIndex)
                                }} breakpoint="xxs" size="sm">
                                {willFlow.breadCrumbsList.map((breadCrumb, index) => {
                                    // setActiveBreadCrumb(breadCrumb)

                                    return (
                                        <Stepper.Step
                                            label={breadCrumb.name}
                                            key={index}
                                            sx={(theme) => ({
                                                color: theme.colors.textColor[0],
                                            })}
                                            color={breadCrumb.status === "not_done" ? "red" : breadCrumb.status === "start" ? "green" : ""}
                                            completedIcon={breadCrumb.status === "not_done" ? <IconCircleX /> : ""}
                                        >
                                            {/* Step {index + 1} content: {breadCrumb} */}
                                        </Stepper.Step>
                                    )
                                })}
                            </Stepper>
                        </Box>

                    </Grid.Col>
                </Grid>

            }

            {!loading &&
                <Grid columns={24} >
                    <Grid.Col span={24} pl="zx" pr="zx" pt={22} pb={10}>
                        <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                            <RiHeartPulseLine />
                            <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}> Healthcare Proxy</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            }
            <Grid columns={24} >
                <Grid.Col span={24} pl="zx" pr="zx" pt={5} >
                    {!loading && <div>
                        <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                    </div>}
                </Grid.Col>

            </Grid>



            {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}

            {!loading && <Grid columns={24}>
                {willFlow?.steps[counter]?.inputList.map((item, index) => {
                    return (

                        <InputControl
                            data={item}
                            key={index}
                            handleButtonClick={handleClick}
                            step={willFlow?.steps[counter]}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            setAnswer={setAnswer}
                            answer={answer}
                            prefill={prefill}
                            editMode={editMode}    // for benif and executor files
                            setEditMode={setEditMode}
                            addAnother={addAnother}  // func for add another record
                            questionId={willFlow?.steps[counter].question_id}
                            binderId={binderId}
                            AddContat={AddContat}
                            loading={loading}
                            setLoading={setLoading}
                            setLoadingMock={setLoadingMock}
                            selectHCAgent={selectHCAgent}
                            keyContactId={keyContactId}

                            healthEditClick={healthEditClick}

                            setPrefill={setPrefill}
                            btnClick={btnClick}
                            flowId="200000005"
                            willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                            setLoggedIn={setLoggedIn}
                            navigate={setLoggedIn}
                        />

                    )
                })}
                <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
            </Grid>}

            {/* {!modalStatus && !loading && */}
            {!loading &&
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    backBtnClick(willFlow?.steps[counter])
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        {willFlow?.steps[counter]?.continueBtn?.visible &&
                            <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        backgroundColor: theme.colors.primary[0],
                                        height: 50,
                                        fontSize: theme.fontSizes.xxl,
                                        fontWeight: 300,
                                        fontFamily: theme.fontFamily[0],
                                        '&:hover': {
                                            backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                        }
                                    })}
                                    onClick={() => {
                                        if (willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 5 || willFlow?.steps[counter].step === 6 || willFlow?.steps[counter].step === 7) {
                                            handleClick(willFlow?.steps[counter], "continue with api")
                                        } else {
                                            handleClick(willFlow?.steps[counter], "continue without api")
                                        }
                                    }}
                                >
                                    Continue
                                </Button>


                            </Affix>
                        }

                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            }
        </div>
    )
}

export default HealthcareProxy