import { Grid, LoadingOverlay, Text, Box, Button,  Group,  Badge, Affix } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { BsExclamationCircle, BsFillEyeFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../apis/flowReviewApis/global";
import FlowTitle from "../Component/flowTitle";
import Footer from "../Component/footer";
import { ConfirmationModal } from "../Component/modal";
import { CardLeftText, TableHeader } from "../Component/TableComponent";
import '../Custom.css'

const UserTopicReviewAs = ({ setCounter, navigate, setLoggedIn }) => {
    const { state } = useLocation();
    const { contactDetail } = state;
    console.log(contactDetail)
    const [loading, setLoading] = useState(true);
    const [mytopics, setMytopics] = useState("");
    const [pageHeader, setPageHeader] = useState("")
    const [topBar, SetTopBar] = useState(contactDetail.orangeBar ? true : false)
    const [bgMock, setBgMock] = useState(contactDetail.bgMock ? true : false)
    const [modalStatus, setModalStatus] = useState(false)
    const [othertopics, setOthertopics] = useState("");
    const [confirmMessage, setConfirmMessage] = useState({})
    const [confirmModalStatus, setConfirmModalStatus] = useState(false)
    const [mode, setMode] = useState("mockup");
    const [live, setIsLive] = useState(contactDetail.isAlive)
    const [bgColor, setBgColor] = useState(bgMock ? '#E5E5E5' : '#ffff')

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    // // console.log(userData)
    let data;
    if (contactDetail.isAlive) {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'userId': parseInt(contactDetail.testatorId),
            'mode': "mockup",
            'key_contact_id': parseInt(contactDetail.keyContactId),
            'isAlive': "no"
        }
    } else if (contactDetail.keyContactId) {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'userId': parseInt(contactDetail.testatorId),
            'mode': "mockup",
            'key_contact_id': parseInt(contactDetail.keyContactId)
        }

    } else {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            'userId': contactDetail.testatorId,
            'mode': "mockup"
        }
    }

    // console.log(data)
    // fetch api data
    useEffect(() => {
        setLoading(true)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopics_review",
            data: formData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                // console.log(response)
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)

                }else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                } else {
                    setMytopics(response.data.mytopics);
                    setLoading(false)
                }


            })
            .catch(function (response) {
                //handle error
                // console.log(response);
            });
    }, [])

    const deleteTopic = (topicId) => {
        setModalStatus(!modalStatus)
        let bodyFormData = new FormData();
        bodyFormData.append('uuid', userData.uuid);
        bodyFormData.append('access_token', userData.access_token);
        axios({
            method: "post",
            url: BASE_URL + "/whitney/public/api/v1/usertopic/delete/" + topicId,
            data: bodyFormData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(function (response) {
                //handle success
                if (response.data.message === "Invalid user") {
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                } else if(response.data.message === "Session timeout."){
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconX size={16} />,
                        autoClose: 2000,
                    })
                    localStorage.removeItem("project_whiteney_email")
                    localStorage.removeItem("project_whiteney_user_data")
                    setLoading(false)
                    setLoggedIn(false)
                
                }else {
                    // console.log(response)
                    setMytopics(response.data.mytopics);
                    setOthertopics(response.data.othertopics);
                    setCounter(0)
                }

            })
            .catch(function (response) {
                //handle error
                // console.log(response);
            });
    }

    //delete item  from Essential Documents list and add it to choose more option list
    // const actionDelete = (topicId) => {

    //     let temp = {
    //         title: "Are you sure you want to delete ",
    //         content: "Deleting this will result in permanent loss of data regarding ",
    //         args: [topicId],
    //         deleteAction: deleteTopic

    //     }
    //     setModalStatus(true)
    //     setConfirmMessage(temp)
    //     setConfirmModalStatus(!confirmModalStatus)

    // }

    //click on add button and add process into Essential Documents list
    // const actionAdd = (topicId) => {
    //     // console.log(topicId)
    //     let bodyFormData = new FormData();
    //     bodyFormData.append('uuid', userData.uuid);
    //     bodyFormData.append('access_token', userData.access_token);
    //     axios({
    //         method: "post",
    //         url: BASE_URL + "/whitney/public/api/v1/usertopic/add/" + topicId,
    //         data: bodyFormData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     })
    //         .then(function (response) {
    //             if (response.data.message === "Invalid user") {
    //                 localStorage.removeItem("project_whiteney_email")
    //                 localStorage.removeItem("project_whiteney_user_data")
    //                 setLoading(false)
    //                 setLoggedIn(false)
    //             } else {
    //                 //handle success
    //                 console.log(response)
    //                 setPageHeader(response.data)
    //                 setMytopics(response.data.mytopics);
    //                 setOthertopics(response.data.othertopics);
    //             }

    //         })
    //         .catch(function (response) {
    //             //handle error
    //             console.log(response);
    //         });
    // } 
    // action start on click start button

    const actionStartWill = (topicId, testatorId, testatorImage, testatorName, keyContactId, live, mode) => {
        // console.log(typeof (topicId))
        // console.log("Start btn clicked with ID: ", topicId, testatorId, live, mode)
        if (topicId === 200000001) {
            // console.log("true")
            navigate('/userTopicReviewAs/willFlowReviewAs', { state: { topicId: topicId, testatorId: testatorId, testatorImage:testatorImage,  testatorName: testatorName, keyContactId: keyContactId, mode: mode, live: live, mouckUp: true, bgMock, bgColor } })
        }
        else if (topicId === 200000002) {
            navigate('/userTopicReviewAs/powerOfAttorneyReviewAs', { state: { topicId: topicId, testatorId: testatorId, testatorImage:testatorImage, testatorName: testatorName, keyContactId: keyContactId, mode: mode, live: live, mouckUp: true, bgMock, bgColor } })
        }
        else if (topicId === 200000003) {
            navigate('/userTopicReviewAs/trustReviewAs', { state: { topicId: topicId, testatorId: testatorId,testatorImage:testatorImage,  testatorName: testatorName, keyContactId: keyContactId, mode: mode, live: live, mouckUp: true, bgMock, bgColor } })
        }
        else if (topicId === 200000004) {
            navigate('/userTopicReviewAs/livingWillReviewAs', { state: { topicId: topicId, testatorId: testatorId, testatorImage:testatorImage,  testatorName: testatorName, keyContactId: keyContactId, mode: mode, live: live, mouckUp: true, bgMock, bgColor } })
        }
        else if (topicId === 200000005) {
            navigate('/userTopicReviewAs/healthCareReviewAs', { state: { topicId: topicId, testatorId: testatorId, testatorImage:testatorImage, testatorName: testatorName, keyContactId: keyContactId, mode: mode, live: live, mouckUp: true, bgMock, bgColor } })
        }
        else {
            console.log("Default case, unrecognized id passed")
        }
    }


    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    return (
        <>
            <div style={{ 'minHeight': window.innerHeight - 148, background: bgColor }}>
                <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />
                <Grid columns={12}>
                    {/*Orange Top Bar */}
                    {topBar &&
                        <>
                            <Grid.Col span={12} style={{ padding: 0 }}>
                                <Box className={bgMock ? "ViewMock" : "viewAs"}>
                                    {bgMock === true &&
                                        <Grid columns={12}>
                                            <Grid.Col span={9}>
                                                <Group position="left">
                                                    <BsExclamationCircle size={22} /><Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[1] })} >You are viewing this  as {contactDetail.testatorName}  (post demise)</Text>
                                                </Group>
                                            </Grid.Col>
                                            <Grid.Col span={3}>
                                                <Group position="right">
                                                <Box  onClick={() => { navigate('/accessControl');}} sx={(theme) => ({  cursor: "pointer",backgroundColor: theme.colors.textColor[9], color: theme.colors.textColor[0], padding:5, borderRadius:20 })}>View as {userData.first_name} {userData.last_name}</Box>
                                                </Group>
                                            </Grid.Col>
                                        </Grid>}
                                    {bgMock === false && <Group>
                                        <BsExclamationCircle size={22} /><Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[1] })} >You are viewing this page as a {contactDetail.testatorName}</Text>
                                    </Group>}
                                </Box>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <FlowTitle title={pageHeader.title} description={pageHeader.description} />
                            </Grid.Col>
                        </>
                    }

                </Grid>

                <TableHeader leftTitle="Topic" rightTitle="" />

                {/* {display myTopic data} */}
                <div className="cardBorder" >
                    {mytopics && mytopics.map((item, index) => {
                        return (
                            <Grid columns={24} className="cardBorderRad cardWidth" key={index}  >
                                <Grid.Col xs={22} sm={23} md={23} lg={23} xl={23} className="innerCardWidth" >
                                    <Box
                                        onClick={() => {
                                            actionStartWill(item.topic_id, contactDetail.testatorId, contactDetail.testatorImage, contactDetail.testatorName, contactDetail.keyContactId, live, mode)
                                        }}
                                        sx={(theme) => ({
                                            cursor: 'pointer',

                                            '&:hover': {

                                            },
                                        })}
                                        key={index}
                                    >
                                        <Grid key={index} style={{ backgroundColor: '#fff' }}>
                                            <Grid.Col span={8}>
                                                <CardLeftText name={item.name} content={item.content} />
                                            </Grid.Col>
                                            <Grid.Col span={4} style={{ margin: 0 }} >
                                                <Box style={{ float: 'Right', display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                                    <Button fullWidth
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            backgroundColor: theme.colors.primary[0],
                                                            height: 40,
                                                            fontSize: theme.fontSizes.xl,
                                                            fontWeight: 300,
                                                            fontFamily: theme.fontFamily[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                                            }
                                                        })}
                                                        rightIcon={<BsFillEyeFill size={20} />}

                                                        onClick={() => {
                                                            actionStartWill(item.topic_id, contactDetail.testatorId, contactDetail.testatorName, contactDetail.keyContactId, live, mode)
                                                        }}

                                                    >
                                                        {item.action[0].text}
                                                    </Button>
                                                </Box>
                                            </Grid.Col>
                                        </Grid>
                                    </Box>
                                </Grid.Col>
                                <Grid.Col xs={2} sm={1} md={1} lg={1} xl={1} className="trashBtnWidth">
                                    <Box style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: '100%' }}>
                                        
                                    </Box>
                                </Grid.Col>
                            </Grid>

                        )
                    })}
                </div>
                <Grid columns={12}>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}></div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div style={{ minHeight: 50, color: "#ffff" }}></div>
                    </Grid.Col>
                </Grid>
                <Affix
                            sx={(theme) => ({
                                "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                                [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                    'left': 300
                                },
                                [`@media (max-width:1280px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    'height': '110px',
                                    left: "0px"
                                },
                            })}
                        >
                                             <Affix position={{}} sx={(theme) => ({
                                bottom: 70, left: 330, zIndex: 2,
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    left: "45px"
                                },
                            })}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        color: theme.colors.textColor[0],
                                        fontSize: theme.fontSizes.xxl,
                                        fontFamily: theme.fontFamily[0],
                                        fontWeight: 300,
                                        height: 50,
                                        backgroundColor: "transparent",

                                        '&:hover': {
                                            backgroundColor: theme.colors.buttonColor[9],
                                            borderWidth: 1,
                                            BorderStyle: "solid",
                                            borderColor: theme.colors.primary[0],
                                        }
                                    })}
                                    leftIcon={<BiChevronLeft />}
                                    onClick={() => {
                                        navigate("/accessControl")
                                    }}
                                >
                                    Back
                                </Button>
                            </Affix>
                            <Footer navigate={navigate}/>
                </Affix>
                
            </div>
        </>
    )
}

export default UserTopicReviewAs