import React from "react";
import { FileHeader } from "./FileHeader";
import { Grid, CloseButton, Progress,Text } from '@mantine/core'

export function UploadError({file, onDelete , errors}) {


    return (
    <>

    <Grid columns={24} style={{alignItems: 'center'}}>
        <Grid.Col span={18} style={{display: 'flex', justifyContent: 'left', color:'red'}}>{file.name}</ Grid.Col>
        <Grid.Col span={5} style={{display: 'flex', justifyContent: 'right'}}>
         <Text style={{color: "red"}}>Faild</Text>
        </ Grid.Col>
        <Grid.Col span={1} style={{display: 'flex', justifyContent: 'right'}}>
            {/* <Button color="red" onClick={() => onDelete(file)}>Delete</Button> */}
            <CloseButton aria-label="Close modal" color="red" onClick={() => onDelete(file)}/>
        </Grid.Col>
    </Grid>
      
     
    </>
    )
}