import { Affix, Avatar, Box, Button, Card, Grid, Image, LoadingOverlay, Select, Text, Title, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import FlowTitle from "../Component/flowTitle";
import '../Custom.css';
import axios from "axios";
import { IconCheck, IconCircleX, IconForbid2, IconPencil, IconUser, IconX, } from "@tabler/icons";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import FooterWill from "../Component/footer2";
import { showNotification } from '@mantine/notifications';
import { ConfirmationModal, MessageModal } from "../Component/modal";
import { BASE_URL } from "../apis/flowReviewApis/global";
import { Mail } from "tabler-icons-react";
import { IoClose } from "react-icons/io5";
import { monthName } from "../constant/month";

const AccessControl = ({ navigate, profileImage, setLoggedIn, setDynamicMenu }) => {
    const theme = useMantineTheme("");
    const [loading, setLoading] = useState(true)
    const [allKeyContactData, setAllKeyContactData] = useState(null);
    const [pageData, setPageData] = useState(null)
    const [dropDownData, setDropDownData] = useState(null);
    const [keyContactId, setKeyContactId] = useState(null)
    const [selectedKeyContact, setSelectedKeyContact] = useState(null)
    const [accessStatus, setAccessStatus] = useState(null)
    const [loginStatus, setLoginStatus] = useState(null)
    const [contactHasAccess, setContactHasAccess] = useState(null)
    const [contactPartialAccess, setContactPartialAccess] = useState(null)

    const [confirmMessage, setConfirmMessage] = useState({})
    const [confirmModalStatus, setConfirmModalStatus] = useState(false)

    const [message, setMessage] = useState({})
    const [messageModalStatus, setMessageModalStatus] = useState(false)




    let data = {
        title: "Manage access",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        label: "Manage access for",
        placeholder: "Choose person",
    }

    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));

    useEffect(() => {
        setDynamicMenu({ menuStatus: false })
        const url = BASE_URL + "/whitney/public/api/v1/key-contacts";


        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            } else if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else if (response.data.status === "success") {
                console.log(response)
                setPageData(response.data)
                // console.log(pageData)
                let options = []

                response.data.inputList[0].rows.map((item) => {
                    let temp = { value: item.key_contact_id, label: item.details[0].value, key: item.key_contact_id }   // make it array as per mantine select
                    options.push(temp)
                })
                // console.log(response)
                setDropDownData(options)
                setAllKeyContactData(response)
                setContactHasAccess(response.data.table_data_has_access)
                setContactPartialAccess(response.data.table_data_partial_access)
                setLoading(false)
            } else {
                setDropDownData([])
            }

        }).catch((error) => {
            console.log(error)
            // alert(error)
        })

    }, [])

    function getKeyContacts(e) {
        setKeyContactId(e)
        // console.log(allKeyContactData)
        const val = allKeyContactData.data.table_data.filter((key) => {
            return key.key_contact_id === e
        })
        // console.log(val)
        setSelectedKeyContact(val)


    }
    function editPermissionAndInvite(keyContactId) {
        // console.log(keyContactId)
        setLoading(true)
        const url = BASE_URL + "/whitney/public/api/v3/set-permission";

        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        // console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['key_contact_id'] = keyContactId
        // console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            console.log(response)
            // if (response.data.message === "Invalid user") {
            //     localStorage.removeItem("project_whiteney_email")
            //     localStorage.removeItem("project_whiteney_user_data")
            //     setLoading(false)
            //     setLoggedIn(false)
            // } else 
            if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else
                if (response.data.status === "success" && response.data.table_data.length === 0) {
                    console.log(response.data)
                    //  setKeyContactData(response.data.table_data[0])
                    // console.log(selectedKeyContact)
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: "error",
                        message: "You haven't set any topic yet. Please add topics first.",
                        icon: <IconCircleX size={16} />,
                        autoClose: 2000,
                    })
                    setLoading(false)


                } else if (response.data.status === "success") {
                    navigate('/editAcesspermission', { state: { key_contact_id: keyContactId, apiData: response.data.table_data[0], write_permission: response.data.write_permission, selectedKeyContact: selectedKeyContact, name: selectedKeyContact.map((item, key) => { return ({ firstName: item.first_name, lastName: item.last_name }) }) } });
                    setLoading(false)
                }
                else {
                    setLoading(false)
                }

        }).catch((error) => {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: error,
                message: "Key Contact not have permission yet",
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })

    }
    function editPermissionAndInviteHasAccess(conatctId) {
        console.log(conatctId)
        setLoading(true)
        const url = BASE_URL + "/whitney/public/api/v3/set-permission";

        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        // console.log(userData) 
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['key_contact_id'] = conatctId
        // console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            // if (response.data.message === "Invalid user") {
            //     localStorage.removeItem("project_whiteney_email")
            //     localStorage.removeItem("project_whiteney_user_data")
            //     setLoading(false)
            //     setLoggedIn(false)
            // } else 
            if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else
                if (response.data.status === "success" && response.data.table_data.length === 0) {
                    console.log(response.data)
                    //  setKeyContactData(response.data.table_data[0])
                    // console.log(selectedKeyContact)
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: "error",
                        message: "You haven't set any topic yet. Please add topics first.",
                        icon: <IconCircleX size={16} />,
                        autoClose: 2000,
                    })
                    setLoading(false)


                } else if (response.data.status === "success") {
                    console.log(response.data)
                    // console.log(response.data.table_data.length)
                    //  setKeyContactData(response.data.table_data[0])
                    // if(response.data.table_data.length)
                    // console.log(response.data.key_contact_details)
                    navigate('/editAcesspermission', { state: { key_contact_id: conatctId, apiData: response.data.table_data[0], write_permission: response.data.write_permission, selectedKeyContact: response.data.key_contact_details, name: [{ firstName: response.data.key_contact_details.first_name, lastName: response.data.key_contact_details.last_name }] } });
                    setLoading(false)
                } else {
                    setLoading(false)
                }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })

    }


    function editUserDetails(keyContactId) {
        console.log(selectedKeyContact)
        console.log(keyContactId)
        // console.log(selectedKeyContact.map((item, key) => { return item }))
        navigate('/editKeyContact', { state: { key_contact_id: keyContactId, keyContactEditOnly: true, selectedKeyContact: selectedKeyContact, keyContactAllData: selectedKeyContact } });
    }
    function editUserDetailsHasAccess(conatctId) {
        console.log(conatctId)
        setLoading(true)
        const url = BASE_URL + "/whitney/public/api/v3/set-permission";

        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        // console.log(userData)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['key_contact_id'] = conatctId
        // console.log(conatctId)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            // if (response.data.message === "Invalid user") {
            //     localStorage.removeItem("project_whiteney_email")
            //     localStorage.removeItem("project_whiteney_user_data")
            //     setLoading(false)
            //     setLoggedIn(false)
            // } else
            if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else
                if (response.data.status === "success") {
                    // console.log(response.data.table_data[0])
                    //  setKeyContactData(response.data.table_data[0])
                    // console.log(response.data.key_contact_details)
                    navigate('/editKeyContact', { state: { key_contact_id: conatctId, keyContactEditOnly: true, selectedKeyContact: response.data.key_contact_details, keyContactAllData: response.data.key_contact_details } });
                    setLoading(false)
                } else {
                    setLoading(false)
                }

        }).catch((error) => {
            alert(error)
            setLoading(false)
        })


    }
    function addAnother() {
        navigate('/addExecutor');
    }

    function showConfirmModal(keycontact, firstName, lastName) {
        let temp = {
            title: "Are you sure you want to revoke access of " + firstName + " " + lastName,
            content: "Deleting this will result in all assigned permissions revoked",
            args: [keycontact],
            deleteAction: revokeAccess
        }
        // console.log(temp)
        // setModalStatus(!modalStatus)
        setConfirmMessage(temp)
        setConfirmModalStatus(!confirmModalStatus)
    }

    function revokeAccess(keycontact) {
        const url = BASE_URL + "/whitney/public/api/v1/revoke-access";
        let data = {}
        data = {
            'uuid': userData.uuid,
            "access_token": userData.access_token,
            "key_contact_id": keycontact,
        }
        // console.log(data)
        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            // console.log(response)
            // if (response.data.message === "Invalid user") {
            //     localStorage.removeItem("project_whiteney_email")
            //     localStorage.removeItem("project_whiteney_user_data")
            //     setLoading(false)
            //     setLoggedIn(false)
            // } else
            if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else
                if (response.data.status === "success") {
                    // console.log(response.data)
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })

                    setLoading(false)
                    resetPage()
                    navigate('/accessControl');

                } else if (response.data.status === "error") {
                    showNotification({
                        id: 'load-data',
                        color: 'red',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCircleX size={16} />,
                        autoClose: 2000,
                    })
                    setLoading(false)
                }

        }).catch((error) => {
            showNotification({
                id: 'load-data',
                color: 'red',
                title: error.status,
                message: error.message,
                icon: <IconCircleX size={16} />,
                autoClose: 2000,
            })
            setLoading(false)
        })
    }
    function resetPage() {
        const url = BASE_URL + "/whitney/public/api/v1/key-contacts";

        console.log(url)
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        // console.log(userData.uuid)
        // console.log(userData.access_token)

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })
        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            // if (response.data.message === "Invalid user") {
            //     localStorage.removeItem("project_whiteney_email")
            //     localStorage.removeItem("project_whiteney_user_data")
            //     setLoading(false)
            //     setLoggedIn(false)
            // } else
            if (response.data.message === "Session timeout.") {
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            } else
                if (response.data.status === "success") {
                    let options = []
                    response.data.inputList[0].rows.map((item) => {
                        let temp = { value: item.key_contact_id, label: item.details[0].value, key: item.key_contact_id }   // make it array as per mantine select
                        options.push(temp)
                    })
                    // console.log(response)
                    setDropDownData(options)
                    setAllKeyContactData(response)
                    setContactHasAccess(response.data.table_data_has_access)
                    setContactPartialAccess(response.data.table_data_partial_access)
                    setLoading(false)
                } else {
                    setDropDownData([])
                }

        }).catch((error) => {
            alert(error)
        })
    }

    function review(userId, testatorImage, firstName, lastName, keyContactId) {
        console.log("388")
        navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: userId, testatorImage: testatorImage, testatorName: firstName + " " + lastName, isAlive: "yes", keyContactId: keyContactId, orangeBar: true } } });
    }


    function reviewMock(userId, testatorImage, firstName, lastName, keyContactId) {
        console.log("394")
        navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: userId, testatorImage: testatorImage, testatorName: firstName + " " + lastName, keyContactId, isAlive: "no", orangeBar: true, bgMock: true } } });
    }
    function showPopUp(firstName, lastName) {
        // console.log(firstName, lastName)
        MessageModal(firstName, lastName);
        let temp = firstName + " " + lastName + " has not accepted your invitation yet. Please contact him"

        setMessage(temp)
        // console.log(message)
        setMessageModalStatus(!messageModalStatus)
    }
    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    // console.log(selectedKeyContact)
    return (
        <>

            <div className="mainContainer" style={{ 'height': window.innerHeight - 148 }}>
                <MessageModal modalMessage={message} status={messageModalStatus} setStatus={setMessageModalStatus} />
                <ConfirmationModal modalMessage={confirmMessage} status={confirmModalStatus} setStatus={setConfirmModalStatus} />
                {!loading &&
                    <>
                        <Grid columns={24}>
                            <Grid.Col span={24}>
                                <div style={{ 'minWidth': '100%' }}>
                                    {/* <FlowTitle title={pageData.title} description={pageData.description} /> */}
                                    <FlowTitle title={pageData.title} description={"Select those with whom you would like to share information. You can 'Set Permissions' to control what they can access. When you click 'Invite', an email invitation will go to them."} />
                                </div>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                {dropDownData && <Select
                                    label={data.label}
                                    placeholder={data.placeholder}
                                    defaultValue={""}
                                    data={dropDownData}
                                    id={data.id}
                                    styles={{
                                        label: {
                                            'color': theme.colors.textColor[0], fontSize: theme.fontSizes.xl,
                                            fontFamily: theme.fontFamily[0], 'fontWeight': 600
                                        },
                                        root: { 'width': '100%' },
                                        // wrapper: { borderColor: theme.colors.border[0], borderStyle: "solid", borderWidth: 1, 'borderRadius': theme.radius.xxs },
                                        input: { 'height': 50, fontSize: theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1], marginTop: theme.spacing.xxs_input },
                                        dropdown: { 'border': 'none', 'borderRadius': theme.radius.xxs, "boxShadow": theme.shadows.xs, 'color': theme.colors.textColor[0] },
                                        item: { 'fontSize': theme.fontSizes.lg, 'color': theme.colors.textColor[0], fontFamily: theme.fontFamily[1] },
                                        hovered: { 'backgroundColor': theme.colors.bgColor[0] }
                                    }}
                                    clearable
                                    searchable
                                    onChange={(e) => {
                                        getKeyContacts(e)
                                    }}
                                />
                                }
                            </Grid.Col>
                            <Grid.Col span={6} sx={(theme) => ({
                                paddingLeft: 20, display: "flex", alignItems: "flex-end"

                            })}>
                                {dropDownData && <Box >
                                    <Button
                                        rightIcon={<BiPlus />}
                                        className="buttonMediumYesNo yesNoBtn"
                                        // sx={(theme) => ({
                                        //     color: theme.colors.primary[0],
                                        //     fontSize: theme.fontSizes.xl,
                                        //     fontFamily: theme.fontFamily[0],
                                        //     fontWeight: 900,
                                        //     borderRadius: theme.radius.xxs
                                        // })}
                                        styles={(theme) => ({
                                            root: {
                                                color: theme.colors.primary[0],
                                                fontSize: theme.fontSizes.xl,
                                                fontFamily: theme.fontFamily[0],
                                                fontWeight: 900,
                                                borderRadius: theme.radius.xxs,
                                                height: 50,
                                            },
                                        })}
                                        onClick={(e) => {
                                            addAnother()
                                        }}
                                    >
                                        Add new
                                    </Button>
                                </Box>}

                            </Grid.Col>
                            <Grid.Col span={8}>

                            </Grid.Col>
                        </Grid>
                        <Grid columns={24}>
                            {selectedKeyContact !== null && selectedKeyContact.length !== 0 &&
                                <Grid.Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                    <Card shadow="sm" p="lg" m={5} radius="xxs" style={{ border: '1px solid #E9ECEF' }}>

                                        <Card.Section>
                                            <Grid columns={12} style={{ display: "flex" }}>
                                                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Box className="profile-image" style={{ paddingLeft: 20, paddingTop: 20 }}>
                                                        {selectedKeyContact[0]?.profile_image ? <Image

                                                            src={selectedKeyContact[0]?.profile_image}
                                                            alt="Random unsplash image"
                                                            width={70}
                                                            height={70}
                                                        /> : <Avatar radius="50%" size={70} />}
                                                    </Box>
                                                </Grid.Col>
                                                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Box style={{ paddingLeft: 10, paddingTop: 15 }}>
                                                        {selectedKeyContact.map((item, key) => {
                                                            return <Title order={3} sx={(theme) => ({ color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })} key={key}>{item.first_name} {item.last_name}</Title>
                                                        })}
                                                        <Title order={3} sx={(theme) => ({ color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })}>{ }</Title>
                                                        <Text sx={(theme) => ({ fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.xs })} color="dimmed">
                                                            {/* {console.log(selectedKeyContact[0].user_registration_status)} */}
                                                            {selectedKeyContact[0]?.user_registration_status === 'true'
                                                                ? "Last login: " + selectedKeyContact[0].last_visited_infobinder
                                                                // ? "Last login: " +monthName[selectedKeyContact[0].last_active_at.split(" ")[0].split('-')[1]]+ " " +selectedKeyContact[0].last_active_at.split(" ")[0].split('-')[2]+ ", " +selectedKeyContact[0].last_active_at.split(" ")[0].split('-')[0] + " " + selectedKeyContact[0].last_active_at.split(" ")[1]
                                                                : ""
                                                            }
                                                        </Text>

                                                    </Box>
                                                </Grid.Col>
                                            </Grid>
                                        </Card.Section>


                                        <Box className="">
                                            <Box style={{ paddingTop: 15 }}>

                                                {selectedKeyContact[0]?.permission_status === 'true'
                                                    ? <Button fullWidth className="permissionBtn" style={{  color: "#399e69", backgroundColor: "#239e6940", borderRadius: theme.radius.xxs, padding: 5 }}><Box className="setBtnText"><Box><IconCheck size={20} /></Box><Box>Has access to all your<br/>  binder's information</Box></Box></Button>
                                                    : <Button fullWidth className="permissionBtn" sx={(theme) => ({  color: "red", backgroundColor: "#FEDADA", borderRadius: theme.radius.xxs, padding: 5 })}><Box className="setBtnText"><Box><IconForbid2 size={20} /></Box><Box>No access to any information <br/> of your binder</Box></Box></Button>
                                                }
                                            </Box>
                                            <Box style={{ paddingTop: 15 }}>
                                                <Button
                                                    rightIcon={<Mail />}
                                                    fullWidth
                                                    variant="outline"
                                                    radius="xxs"
                                                    sx={(theme) => ({
                                                        color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                        borderColor: theme.colors.buttonColor[0],
                                                        '&:hover': {
                                                            backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                        }
                                                    })}
                                                    onClick={(e) => { editPermissionAndInvite(keyContactId) }}
                                                >
                                                    Set permission and invite
                                                </Button>
                                            </Box>
                                            <Box style={{ paddingTop: 15 }}>
                                                <Button
                                                    rightIcon={<IconPencil />}
                                                    variant="outline"
                                                    radius="xxs"
                                                    fullWidth
                                                    sx={(theme) => ({
                                                        color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                        borderColor: theme.colors.buttonColor[0],
                                                        '&:hover': {
                                                            backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                        }
                                                    })}
                                                    onClick={(e) => { editUserDetails(keyContactId) }}
                                                >
                                                    Edit contact information
                                                </Button>
                                            </Box>
                                            <Box style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                {selectedKeyContact[0]?.permission_status === 'true' ? <Button
                                                    rightIcon={<IoClose />}
                                                    variant="outline"
                                                    radius="xxs"
                                                    fullWidth
                                                    sx={(theme) => ({
                                                        color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                        borderColor: theme.colors.buttonColor[0],
                                                        '&:hover': {
                                                            backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                        }
                                                    })}
                                                    onClick={(e) => {

                                                    }}
                                                >
                                                    Revoke access
                                                </Button>
                                                    : ""
                                                }
                                            </Box>
                                        </Box>
                                        {/* <Text size="sm" color="black">
                                    With Fjord Tours you can explore more of the magical fjord landscapes with tours and
                                    activities on and around the fjords of Norway
                                </Text> */}

                                    </Card>
                                </Grid.Col>


                            }
                            {contactHasAccess.map((ele, index) => {
                                return (
                                    <Grid.Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} key={index}>
                                        <Card shadow="sm" p="lg" m={5} radius="xxs" style={{ border: '1px solid #E9ECEF' }}>
                                            <Card.Section>
                                                <Grid columns={12} >
                                                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Box className="profile-image" style={{ paddingLeft: 20, paddingTop: 20, }}>
                                                            {ele.profile_image ? <Image

                                                                src={ele.profile_image}
                                                                alt="Random unsplash image"
                                                                width={70}
                                                                height={70}
                                                            /> :
                                                                <Avatar radius="50%" size={70} />
                                                            }
                                                        </Box>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} style={{ display: "flex", flexDirection:"column", alignItems: "center" }}>
                                                              <Title order={3} sx={(theme) => ({ color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })}>{ele.first_name} {ele.last_name}</Title>
                                                                    <Text sx={(theme) => ({ fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.xs })} color="dimmed">
                                                                    {ele.user_registration_status === 'true'
                                                                        ? "Last login: " + ele.last_visited_infobinder
                                                                        // ? "Last login: " +monthName[ele.last_visited_infobinder.split(" ")[0].split('-')[1]]+ " " +ele.last_visited_infobinder.split(" ")[0].split('-')[2]+ ", " +ele.last_visited_infobinder.split(" ")[0].split('-')[0] + " " + ele.last_visited_infobinder.split(" ")[1]
                                                                        : ""
                                                                    }
                                                            </Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={12}>

                                                        <>
                                                            <Grid.Col>

                                                                <Button
                                                                    onClick={() => {
                                                                        // ele.user_registration_status === "true" ? 
                                                                        review(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                                        // : reviewNotReg(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                                    }}
                                                                    radius="xxs"
                                                                    className="permissionBtn"
                                                                    fullWidth
                                                                    styles={(theme) => ({
                                                                        root: {
                                                                            backgroundColor: theme.colors.custom[0],
                                                                            fontSize: theme.fontSizes.sm,
                                                                            fontWeight: 600,
                                                                            border: 0,
                                                                            height: 40,
                                                                            color: theme.colors.custom[1],

                                                                            '&:hover': {
                                                                                backgroundColor: theme.colors.custom[2],
                                                                                color: theme.colors.custom[3],
                                                                            },
                                                                        }
                                                                    })}
                                                                > {ele.first_name}'s view of your Information <br /> Binder once he/she has access</Button>
                                                            </Grid.Col>
                                                            <Grid.Col >
                                                                <Button
                                                                    onClick={() => {
                                                                        // ele.user_registration_status === "true" ? 
                                                                        reviewMock(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                                        // : reviewMock(ele.user_id, ele.testator_image, ele.first_name, ele.last_name)
                                                                    }}
                                                                    radius="xxs"
                                                                    className="permissionBtn"
                                                                    fullWidth
                                                                    styles={(theme) => ({
                                                                        root: {
                                                                            backgroundColor: theme.colors.customViolet[0],
                                                                            fontSize: theme.fontSizes.sm,
                                                                            fontWeight: 600,
                                                                            border: 0,
                                                                            height: 42,
                                                                            color: theme.colors.customViolet[1],

                                                                            '&:hover': {
                                                                                backgroundColor: theme.colors.customViolet[2],
                                                                                color: theme.colors.customViolet[3]
                                                                            },
                                                                        }
                                                                    })}
                                                                >  {ele.first_name}'s  view of your Information <br /> Binder after your passing.
                                                                </Button>
                                                            </Grid.Col>
                                                        </>


                                                    </Grid.Col>

                                                </Grid>
                                            </Card.Section>
                                            <Box className="">
                                                <Box style={{ paddingTop: 20 }}>

                                                    {ele.permission_status === 'true'
                                                        ? <Button fullWidth className="permissionBtn" style={{ color: "#399e69", backgroundColor: "#239e6940", borderRadius: theme.radius.xxs, padding: 5 }}><Box className="setBtnText"><Box><IconCheck size={20} /></Box><Box>Has access to all your binder's<br /> information</Box></Box></Button>
                                                        : <Button fullWidth className="permissionBtn" style={{ color: "red", backgroundColor: "#FEDADA", borderRadius: theme.radius.xxs, padding: 5 }}><Box className="setBtnText"><Box><IconForbid2 size={20} /></Box><Box>No access to any information of <br />your binder</Box></Box></Button>
                                                    }
                                                </Box>
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        // className="permissionBtn"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        id={ele.key_contact_id}
                                                        onClick={(e) => { editPermissionAndInviteHasAccess(ele.key_contact_id) }}
                                                    >
                                                      <Box className="setBtnText"><Box> Set permission and invite</Box><Box><IconUser size={20}/></Box> </Box> 
                                                    </Button>
                                                </Box>
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        rightIcon={<IconPencil />}
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        onClick={(e) => { editUserDetailsHasAccess(ele.key_contact_id) }}
                                                    >
                                                        Edit contact information
                                                    </Button>
                                                </Box>
                                                <Box style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                    {ele.permission_status === 'true' ? <Button
                                                        rightIcon={<IoClose />}
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        onClick={(e) => {
                                                            showConfirmModal(ele.key_contact_id, ele.first_name, ele.last_name)
                                                        }}
                                                    >
                                                        Revoke access
                                                    </Button>
                                                        : ""
                                                    }
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid.Col>
                                )

                            })

                            }

                            {contactPartialAccess.map((ele, index) => {
                                return (
                                    <Grid.Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} key={index}>
                                        <Card shadow="sm" p="lg" m={5} radius="xxs" style={{ border: '1px solid #E9ECEF' }}>
                                            <Card.Section>
                                                <Grid columns={12} style={{ display: "flex" }}>
                                                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Box className="profile-image" style={{ paddingTop: 20 }}>
                                                            {ele.profile_image ? <Image

                                                                src={ele.profile_image}
                                                                alt="Random unsplash image"
                                                                width={70}
                                                                height={70}
                                                            /> :
                                                                <Avatar radius="50%" size={70} />
                                                            }
                                                        </Box>
                                                    </Grid.Col>
                                                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Title order={3} sx={(theme) => ({ color: theme.colors.textColor[0], fontFamily: theme.fontFamily[0] })}>{ele.first_name} {ele.last_name}</Title>
                                                    </Grid.Col>
                                                    <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "center" }} pt={0}>
                                                        <Text sx={(theme) => ({ fontFamily: theme.fontFamily[0], fontSize: theme.fontSizes.xs })} color="dimmed">
                                                            {ele.user_registration_status === 'true'
                                                                ? "Last login: " + ele.last_visited_infobinder
                                                                // ? "Last login: " +monthName[ele.last_active_at.split(" ")[0].split('-')[1]]+ " " +ele.last_active_at.split(" ")[0].split('-')[2]+ ", " +ele.last_active_at.split(" ")[0].split('-')[0] + " " + ele.last_active_at.split(" ")[1]
                                                                : ""
                                                            }
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid>
                                            </Card.Section>
                                            <Box className="">
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        onClick={() => {
                                                            // ele.user_registration_status === "true" ? 
                                                            review(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                            // : reviewNotReg(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                        }}
                                                        radius="xxs"
                                                        fullWidth
                                                        className="permissionBtn"
                                                        styles={(theme) => ({
                                                            root: {
                                                                backgroundColor: theme.colors.custom[0],
                                                                fontSize: theme.fontSizes.sm,
                                                                fontWeight: 600,
                                                                border: 0,
                                                                height: 40,

                                                                color: theme.colors.custom[1],

                                                                '&:hover': {
                                                                    backgroundColor: theme.colors.custom[2],
                                                                    color: theme.colors.custom[3],
                                                                },
                                                            }
                                                        })}
                                                    > {ele.first_name}'s view of your Information<br /> Binder once he/she has access</Button>
                                                </Box>
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        onClick={() => {
                                                            // ele.user_registration_status === "true" ? 
                                                            reviewMock(ele.user_id, ele.testator_image, ele.first_name, ele.last_name, ele.key_contact_id)
                                                            // : reviewMock(ele.user_id, ele.testator_image, ele.first_name, ele.last_name)
                                                        }}
                                                        radius="xxs"
                                                        fullWidth
                                                        className="permissionBtn"
                                                        styles={(theme) => ({
                                                            root: {
                                                                backgroundColor: theme.colors.customViolet[0],
                                                                fontSize: theme.fontSizes.xs,
                                                                fontWeight: 600,
                                                                border: 0,
                                                                height: 42,
                                                                color: theme.colors.customViolet[1],

                                                                '&:hover': {
                                                                    backgroundColor: theme.colors.customViolet[2],
                                                                    color: theme.colors.customViolet[3]
                                                                },
                                                            }
                                                        })}
                                                    > {ele.first_name}'s view of your Information<br /> Binder after your passing.</Button>
                                                </Box>
                                                <Box style={{ paddingTop: 20 }}>

                                                    {ele.permission_status === 'true'
                                                        ?  <Button fullWidth className="permissionBtn"  style={{ color: "#D9A000", backgroundColor: "#ffcc3d33", borderRadius: theme.radius.xxs, fontWeight: 500, padding: 5 }}><Box className="setBtnText"><Box><IconCheck size={20} /></Box><Box>Access provided</Box></Box></Button>
                                                        :  <Button fullWidth className="permissionBtn"  style={{ color: "red", backgroundColor: "#FEDADA", borderRadius: theme.radius.xxs }}><Box className="setBtnText"><Box><IconForbid2 size={20} /></Box><Box>No access to any information of your binder</Box></Box></Button>
                                                    }
                                                </Box>
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        // rightIcon={<IconUser />}
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        id={ele.key_contact_id}
                                                        onClick={(e) => { editPermissionAndInviteHasAccess(ele.key_contact_id) }}
                                                    >
                                                      <Box className="setBtnText"><Box> Set permission and invite</Box><Box><IconUser size={20}/></Box> </Box> 
                                                    </Button>
                                                </Box>
                                                <Box style={{ paddingTop: 15 }}>
                                                    <Button
                                                        rightIcon={<IconPencil />}
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        onClick={(e) => { editUserDetailsHasAccess(ele.key_contact_id) }}
                                                    >
                                                        Edit contact information
                                                    </Button>
                                                </Box>
                                                <Box style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                    {ele.permission_status === 'true' ? <Button
                                                        rightIcon={<IoClose />}
                                                        fullWidth
                                                        variant="outline"
                                                        radius="xxs"
                                                        sx={(theme) => ({
                                                            color: theme.colors.buttonColor[0], height: 40, fontSize: theme.fontSizes.xl, fontWeight: 500,
                                                            borderColor: theme.colors.buttonColor[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colors.buttonColor[0], color: "#fff",
                                                            }
                                                        })}
                                                        onClick={(e) => {
                                                            showConfirmModal(ele.key_contact_id, ele.first_name, ele.last_name)
                                                        }}
                                                    >
                                                        Revoke access
                                                    </Button>
                                                        : ""
                                                    }
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid.Col>
                                )

                            })

                            }
                        </Grid>

                        <Grid columns={24}>

                        </Grid>

                        <div style={{ "width": "100%", "paddingBottom": "160px" }}></div>

                    </>
                }
                <>
                    <Affix
                        sx={(theme) => ({
                            "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                            [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                'left': 300
                            },
                            [`@media (max-width:1280px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                'left': 270
                            },
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                'height': '110px',
                                left: "0px"
                            },
                        })}
                    >
                        <Affix position={{}} sx={(theme) => ({
                            bottom: 70, left: 330, zIndex: 2,
                            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                left: "45px"
                            },
                        })}>
                            <Button
                                radius="xxs"
                                sx={(theme) => ({
                                    color: theme.colors.textColor[0],
                                    fontSize: theme.fontSizes.xxl,
                                    fontFamily: theme.fontFamily[0],
                                    fontWeight: 300,
                                    height: 50,
                                    backgroundColor: "transparent",

                                    '&:hover': {
                                        backgroundColor: theme.colors.buttonColor[9],
                                        borderWidth: 1,
                                        BorderStyle: "solid",
                                        borderColor: theme.colors.primary[0],
                                    }
                                })}
                                leftIcon={<BiChevronLeft />}
                                onClick={() => {
                                    navigate('/infoBinder');
                                }}
                            >
                                Back
                            </Button>
                        </Affix>
                        <FooterWill navigate={navigate} />
                    </Affix>

                </>
            </div>
        </>
    )
}

export default AccessControl;