import React from "react";
import axios from "axios";
import { BASE_URL } from "./flowReviewApis/global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE, OPTIONS, UPDATE",
    },
    onUploadProgress: function (progressEvent) {
        // console.log("Pregress event: ", progressEvent)
    },
    
})

export const Home = (topicId) => {
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let data = {}
    let formData = {}
    let addTopicUrl = BASE_URL + "/whitney/public/api/v1/dashboard"
    data['uuid'] = userData.uuid
    data['access_token'] = userData.access_token

    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    return whitney.post(addTopicUrl, formData)
}