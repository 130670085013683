import { Accordion, ActionIcon, Avatar, Box, Button, Divider, FileButton, Grid, Image, LoadingOverlay, Modal, Table, UnstyledButton } from "@mantine/core"
import { IconCamera, IconX } from "@tabler/icons";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { BASE_URL } from "../apis/flowReviewApis/global";
import FlowTitle from "../Component/flowTitle";
import { getContactInformation, updateContactInfo } from "../apis/keycontacatApis";
import InputControlPersonalInfo from '../Component/inputControlPersonalInfo'
import { EditIcon } from "../Component/IconComponent";
import { BiPencil } from "react-icons/bi";
import '../Custom.css';
import { showNotification } from "@mantine/notifications";




const PersonalInfo = ({ navigate, setLoggedIn, setProfileImage, profileImage, userLoginData, setDynamicMenu }) => {
    // console.log(profileImage)
    const initialValues = {
        first_name: "",
        middle_name: "",
        last_name: "",
        nick_name: "",
        gender: "",
        ssn: "",
        phone_c_code: "",
        phone_no: "",
        email: "",
        home_phone_c_code: "",
        home_phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        county: "",
        country_id: "",
        zip_code: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [prefill, setPrefill] = useState();
    const [file, setFile] = useState(null);
    const [fileURL, setFileUrl] = useState(null);
    const resetRef = useRef(null);

    const [imageRef, setImageRef] = useState();

    const [croppedImage, SetCroppedImage] = useState()
    const [croppedImageUrl, SetCroppedImageUrl] = useState()

    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(true)
    const [keyContactInfo, setKeyContactInfo] = useState({})

    const [crop, setCrop] = useState({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    })

    const clearFile = () => {
        setFile(null);
        resetRef.current?.();
    };

    const uploadFile = (e) => {
        setOpened(true)
        setFile(e)
        // console.log(e)
        setFileUrl(URL.createObjectURL(e))
    }
    // console.log(fileURL)
    async function cropImage(crop) {
        // console.log(crop)
        // console.log(crop.height)
        // console.log(crop.width)
        // console.log(imageRef)
        if (imageRef && crop.width && crop.height) {
            const getCoppedImage = await getCroppedImage(
                imageRef,
                crop,
                "croppedImage.jpeg" // destination filename
            );
            // console.log(getCoppedImage)
            SetCroppedImage(getCoppedImage)



            const croppedImageUrl = window.URL.createObjectURL(getCoppedImage);
            SetCroppedImageUrl(croppedImageUrl)


            // calling the props function to expose
            // croppedImage to the parent component
            // onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        // creating the cropped image from the source image
        const canvas = document.createElement("canvas");
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                // returning an error
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }

                blob.name = fileName;
                // creating a Object URL representing the Blob object given
                // const croppedImageUrl = window.URL.createObjectURL(blob);

                resolve(blob);
            }, "image/jpeg");
        });
    }

    function onImageLoad(e) {
        // console.log(e.currentTarget)
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        setImageRef(e.currentTarget)


    }

    function upLoadImage() {
        // console.log(croppedImage)
        // var wavefilefromblob = new File(croppedImage);
        // console.log(wavefilefromblob)
        const url = BASE_URL + '/whitney/public/api/v1/profile_image';
        // console.log(url)
        let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"));
        let data = {}
        data['uuid'] = userData.uuid
        data['access_token'] = userData.access_token
        data['SaveImage'] = "yes"

        const formData = new FormData();
        Object.keys(data).map((item, index) => {
            formData.set(item, data[item])
        })

        // formData.append("uploaded_file", croppedImage)
        // console.log(croppedImage)
        if (croppedImage !== undefined) {
            formData.append("uploaded_file", croppedImage, "croppedImage.jpeg")
        } else {
            formData.append("uploaded_file", file)
        }



        axios.post(url, formData, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type':  "multipart/form-data",
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) => {
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }else {
                setProfileImage(response.data.profile_image)
                setOpened(false)
                // console.log(userLoginData)
                userLoginData.profile_image = response.data.profile_image
                // console.log(userLoginData)
                localStorage.setItem("project_whiteney_user_data", JSON.stringify(userLoginData))
                setLoading(false)
            }

        })
            .catch((error) => {
                alert(error)
            })
    }
    useEffect(() => {
        setDynamicMenu({menuStatus: false})
        setLoading(true);
        getContactInformation().then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                // let ansObj = {}
                // let prefillObj = {}
                // response.data.inputList.map((item, index) => {

                //     ansObj = { ...ansObj, [item?.id]: item.prefill }
                //     prefillObj = { ...prefillObj, [item.label]: item.prefill }

                // })
                // setAnswer(ansObj)
                // setPrefill(prefillObj)
                setKeyContactInfo(response.data.table_data[0])
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }, [])

    function saveContactInfo() {

        let data = {
            "Save": "yes",
            ...answer

        }
        console.log("data", data)
        setLoading(true)
        updateContactInfo(data).then((response) => {
            console.log("Response of get willflow object: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)

            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else {
                setKeyContactInfo(response.data)
                setLoading(false)
            }

        }, (error) => {
            console.log("Error in getting willflow object: ", error)
        })
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }
    console.log(keyContactInfo)
    return (
        <>
            <Grid columns={24}>
                <Grid.Col span={24}>
                    <FlowTitle title="Profile picture" />
                    <Divider my="sm" variant="dotted" />
                </Grid.Col>

                <Grid.Col span={12}>
                    <div className="profile-image" style={{ display: "flex" }}>
                        <div>
                            {profileImage ? <Image

                                src={userLoginData.profile_image}
                                alt="Random unsplash image"
                                width={100}
                                height={100}
                            /> : <Avatar radius="50%" size={100} />}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', alignContent: 'flex-end', marginLeft: -35, zIndex: 2 }}>
                            <FileButton
                                resetRef={resetRef}
                                onChange={(e) => {
                                    uploadFile(e)
                                }}
                                accept="image/png,image/jpeg"
                            >
                                {(props) => <UnstyledButton {...props}><IconCamera size={24} /></UnstyledButton>}
                            </FileButton>
                        </div>
                    </div>
                </Grid.Col>
                <Grid.Col span={6}></Grid.Col>
                <Grid.Col span={6}></Grid.Col>

                {/* <Grid.Col span={24}>
                    <FlowTitle title="Personal information" />
                    <Divider my="sm" variant="dotted" />
                </Grid.Col> */}

            </Grid>
            {/* {!loading &&
                <Grid columns={24} >
                    {keyContactInfo?.inputList.map((item, index) => {
                        return (

                            <InputControlPersonalInfo
                                key={index}
                                data={item}
                                setAnswer={setAnswer}
                                answer={answer}
                                setPrefill={setPrefill}


                            />

                        )
                    })}
                </Grid>
            } */}

            {/* <Grid columns={24} >
                <Grid.Col style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        radius="xxs"
                        sx={(theme) => ({
                            backgroundColor: theme.colors.primary[0],
                            height: 50,
                            fontSize: theme.fontSizes.xxl,
                            fontWeight: 300,
                            fontFamily: theme.fontFamily[0],
                            '&:hover': {
                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                            }
                        })}
                        onClick={(event)=>{saveContactInfo()}}

                    >Save</Button>
                </Grid.Col>
            </Grid> */}
            <Grid columns={24} >
                <Grid.Col span={24}>
                    <Accordion chevronPosition="left" defaultValue="Account Setting">
                        <Accordion.Item value="Account Setting" className="accordianBorder">
                            <Accordion.Control>  <FlowTitle title="Account Setting" /></Accordion.Control>
                            <Accordion.Panel>
                                <Grid columns={12} >
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Email</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.email}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Username</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.user_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Phone no</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.phone_c_code} {keyContactInfo.phone_no}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>


                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value="Personal information" className="accordianBorder">
                            <Grid columns={12}>
                                <Grid.Col span={11} >
                                    <Accordion.Control >
                                        <FlowTitle title="Personal information" />
                                    </Accordion.Control>
                                </Grid.Col>
                                <Grid.Col span={1} style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                                        <ActionIcon variant="subtle"
                                        onClick={(event)=>{
                                            navigate("/editPersonalInfo")
                                        }}
                                        >
                                            <BiPencil size={22} />
                                        </ActionIcon>
                
                                </Grid.Col>
                            </Grid>

                            <Accordion.Panel>
                                 <Grid columns={12} >
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>First name</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.first_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Middle name</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.middle_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Last name</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.last_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Nick name</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.nick_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Username</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.user_name}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Social security number</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.ssn}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Home phone no</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.home_phone_c_code} {keyContactInfo.home_phone}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Address line 1</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.address_line1}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Address line 2</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.address_line2}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>City</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.city}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>State</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.state}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="tableBorder">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Country</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.country_id}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                    <Grid.Col span={12} className="">
                                        <Grid columns={12} >
                                            <Grid.Col span={3}>Zip code</Grid.Col>
                                            <Grid.Col span={1}>:</Grid.Col>
                                            <Grid.Col span={8}>{keyContactInfo.zip_code}</Grid.Col>
                                        </Grid>
                                    </Grid.Col>

                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>

                </Grid.Col>

            </Grid>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Crop Image"
            >
                <Box>
                    <ReactCrop
                        crop={crop}
                        onChange={c => setCrop(c)}
                        onComplete={(cropConfig) => cropImage(cropConfig)}

                        crossorigin="anonymous" // to avoid CORS-related problems
                        ruleOfThirds
                    >
                        <img src={fileURL} onLoad={onImageLoad} />
                    </ReactCrop>
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-around" }}>
                    <Box>
                        <Button
                            onClick={() => { setOpened(false) }}
                            radius="xxs"
                            sx={(theme) => ({
                                backgroundColor: theme.colors.buttonColor[10],
                                height: 50,
                                fontSize: theme.fontSizes.xxl,
                                fontWeight: 300,
                                fontFamily: theme.fontFamily[0],
                                '&:hover': {
                                    backgroundColor: theme.fn.darken(theme.colors.buttonColor[11], 0.2),
                                }
                            })}
                        > Close</Button>
                    </Box>
                    <Box>
                        <Button
                            radius="xxs"
                            sx={(theme) => ({
                                backgroundColor: theme.colors.buttonColor[0],
                                height: 50,
                                fontSize: theme.fontSizes.xxl,
                                fontWeight: 300,
                                fontFamily: theme.fontFamily[0],
                                '&:hover': {
                                    backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                }
                            })}
                            onClick={upLoadImage}
                        > Save</Button>
                    </Box>

                </Box>

            </Modal>
        </>
    )
}

export default PersonalInfo