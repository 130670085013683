import React from "react";
import axios from "axios";
import { BASE_URL } from "./flowReviewApis/global";

const whitney = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    onUploadProgress: function (progressEvent) {
        // console.log("Pregress event: ", progressEvent)
    },

})

// export const getWillFlow = (flowId,data,formdata, binderId) => {
//     console.log("binder id from: ", flowId,data,formdata, binderId)
//     if (!flowId){
//         console.log("Error in will flow please send flowid with will flow")
//         return
//     }
//     let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
//     let formData = {}
//     let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId
//     if(!data) {
//         let data = {}
//         data['uuid'] = userData.uuid
//         data['access_token'] = userData.access_token
//         if(binderId) {
//             console.log("Binder id added: ", binderId)
//             data["main_binder_id"] = binderId
//         }
//         formData = new FormData()
//         Object.keys(data).map((item, index) => {
//             formData.set(item, data[item])
//         })
//         return whitney.post(willFlowUrl,formData)
//     } else {
//         data['uuid'] = userData.uuid
//         data['access_token'] = userData.access_token
//         if(binderId?.length > 0) {
//             data["main_binder_id"] = binderId
//         }
//         console.log("Data test >>>>", data)
//         if(!formdata){
//             formData = new FormData()
//             Object.keys(data).map((item, index) => {
//                 formData.set(item, data[item])
//             })
//         } else {
//             formData = new FormData(data)
//         }
//         console.log("Form data", formData)
//         return whitney.post(willFlowUrl, formData)
//     }
// }

// export const getPowerOfAttorney = (flowId, data, formdata, binderId) => {

//     console.log("Data: ",  data)

//     if (!flowId) {
//         return
//     }
//     let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
//     let formData = {}
//     let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId
//     if (!data) {
//         let data = {}
//         data['uuid'] = userData.uuid
//         data['access_token'] = userData.access_token
//         if (binderId) {
//             data["main_binder_id"] = binderId
//         }
//         formData = new FormData()
//         Object.keys(data).map((item, index) => {

//             Object.keys(data).map((item, index) => {

//                 if (Array.isArray(data[item])) {
//                     Array.data[item].orEach(element => {
//                         formData.append("uploaded_files[]", element)

//                     })
//                 }
//                 else {
//                     formData.set(item, data[item])
//                 }

//             })
//         })
//         return whitney.post(willFlowUrl, formData)
//     } else {
//         data['uuid'] = userData.uuid
//         data['access_token'] = userData.access_token
//         if (binderId?.length > 0) {
//             data["main_binder_id"] = binderId
//         }
//         if (!formdata) {
//             formData = new FormData()
//             Object.keys(data).map((item, index) => {
//                 if (Array.isArray(data[item])) {
//                     data[item].forEach(element => {
//                         formData.append("uploaded_files[]", element)

//                     })
//                 }
//                    else {
//                     formData.set(item, data[item])
//                 }

//             })
//         } else {
//             formData = new FormData(data)
//         }
//         console.log("Form data", data)
//         return whitney.post(willFlowUrl, formData)
//     }
// }
export const getPowerOfAttorney = (flowId, submitData, formdata, binderId) => {
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let data = {}
    let formData = new FormData()
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId

    if (!flowId) {
        return
    }

    if (data) {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
            ...submitData

        }
        // console.log(data)
    } else {
        data = {
            'uuid': userData.uuid,
            'access_token': userData.access_token,
        }
    }
    console.log(data)
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })

    return whitney.post(willFlowUrl, formData)

}

export const listBinderDetails = (flowId) => {
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderlist/category/10001/topic/" + flowId
    let data = {}
    data['uuid'] = userData.uuid
    data['access_token'] = userData.access_token
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })
    return whitney.post(willFlowUrl, formData)
}

export const removePOA = (flowId, data, formdata, binderId) => {
    console.log(flowId, data, formdata, binderId)

    if (!flowId) {
        return
    }
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))
    let formData = {}
    let willFlowUrl = BASE_URL + "/whitney/public/api/v1/binderdetails/category/10001/topic/" + flowId


    data['uuid'] = userData.uuid
    data['access_token'] = userData.access_token
    data["poa_id"] = data.poa_id
    data["delete"] = data.delete


    console.log(data)
    formData = new FormData()
    Object.keys(data).map((item, index) => {
        formData.set(item, data[item])
    })

    // console.log("Form data", formData)
    return whitney.post(willFlowUrl, formData)




}