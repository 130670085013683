import { Box, Text, useMantineTheme } from "@mantine/core";
import React from "react";
/*Login Page Title Text*/
export const LoginTitle = ({ title }) => {
    return (
        <>
            <Text

                sx={(theme) => ({
                    fontSize: theme.fontSizes.lxxl,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[0],
                    fontWeight: 600,
                    [`@media (max-width: 1280px)`]: {
                        fontSize: 25,
                        lineHeight: "25px"
                    },
                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        fontSize: theme.fontSizes.xl,
                        lineHeight: theme.lineHeight[4]
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        fontSize: theme.fontSizes.lg,
                        lineHeight: theme.lineHeight[2]
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: theme.fontSizes.lg,
                        lineHeight: "12px"
                    },
                    [`@media (max-width: 768px)`]: {
                        fontSize: theme.fontSizes.lg,
                        lineHeight: theme.lineHeight[4]
                    },
                })}
            >{title} </Text>
        </>
    )
}
/*Login Page Title2 Text*/
export const LoginTitle2 = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.xxl,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[0],
                    fontWeight: 600,

                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        fontSize: theme.fontSizes.lg,
                        lineHeight: theme.lineHeight[1]
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        fontSize: theme.fontSizes.xs,
                        lineHeight: theme.lineHeight[0]
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: theme.fontSizes.xxs,
                        lineHeight: "12px"
                    },
                })}
            >{title} </Text>
        </>
    )
}
/*Login Page Title2 Text*/
export const MyRolesCardTitle = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.lxl,
                    fontFamily: theme.fontFamily[0],
                    color: theme.colors.primary[0],
                    fontWeight: 700,
                    textAlign: "center",
                    [`@media (max-width: 1280px)`]: {
                        fontSize: theme.fontSizes.md,
                    },
                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        fontSize: theme.fontSizes.md,
                        lineHeight: theme.lineHeight[1]
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        fontSize: theme.fontSizes.md,
                        lineHeight: theme.lineHeight[1]
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: theme.fontSizes.md,
                        lineHeight: theme.lineHeight[1]
                    },
                })}
            >{title} </Text>
        </>
    )
}
/*Login Page Title2 Text*/
export const LoginTitle3 = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.lg,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[0],
                    fontWeight: 600,
                    lineHeight: "20px",
                    [`@media (max-width: 1280px)`]: {
                        fontSize: 18,
                        lineHeight: "18px"
                    },

                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        fontSize: theme.fontSizes.sm,
                        lineHeight: theme.lineHeight[1]
                    },
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        fontSize: theme.fontSizes.xs,
                        lineHeight: theme.lineHeight[0]
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: theme.fontSizes.sm,
                        lineHeight: "12px"
                    },
                })}
            >{title} </Text>
        </>
    )
}
/*Bottom padding */
export const SpacingBottom = () => {
    <Box
        sx={(theme) => ({
            paddingBottom: "0.4vw",
            [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                paddingBottom: "0.4vw",
            },
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                paddingBottom: "0.4vw",
            },
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                paddingBottom: "0.4vw",
            },
        })}
    ></Box>
}
/*Login Page Sub-Title Text*/
export const LoginSubTitle = ({ title, className }) => {
    return (

        <Text
            className={className}
            sx={(theme) => ({
                fontSize: theme.fontSizes.md,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.primary[0],
                fontWeight: 500,
                [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                    fontSize: theme.fontSizes.lg,
                    lineHeight: theme.lineHeight[1]
                },
                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    fontSize: theme.fontSizes.xs,
                    lineHeight: theme.lineHeight[0]
                },
                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                    fontSize: theme.fontSizes.xxs,
                    lineHeight: "12px"
                },
            })}
        >{title} </Text>

    )
}
/*Login Page Sub-Title Text*/
export const InputLabel = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.xl,
                    fontFamily: theme.fontFamily[0],
                    color: theme.colors.primary[0],
                    fontWeight: 600
                })}
            >{title}  <span style={{ color: 'red' }}> *</span></Text>
        </>
    )
}

/*Card Title Text*/
export const CardTitle = ({ title, style }) => {
    return (
        <>
            <Text
                style={style}
                sx={(theme) => ({
                    fontSize: theme.fontSizes.xl,
                    fontFamily: theme.fontFamily[0],
                    color: theme.colors.primary[0],
                    fontWeight: 600,
                    [`@media (max-width: 1280px)`]: {
                        fontSize: theme.fontSizes.md,
                    },
                    [`@media (max-width: 1176px)`]: {
                        fontSize: theme.fontSizes.xs,
                    },
                    [`@media (max-width: 1024px)`]: {
                        fontSize: theme.fontSizes.md,
                    },
                })}
            >{title}  </Text>
        </>
    )
}
/*Info Text*/
export const TextInfo = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: 13,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[5],
                    paddingTop: "0.2vw",
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        fontSize: theme.fontSizes.xxs,
                        lineHeight: "10px",
                        paddingTop: "0.2vw",
                    },
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                        fontSize: theme.fontSizes.xxs,
                        lineHeight: "10px",
                        paddingTop: "0.2vw",
                    },
                })}
            >{title} </Text>
        </>
    )
}

export const PageTitle = ({ title }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.lxl,
                    fontFamily: theme.fontFamily[0],
                    color: theme.colors.primary[0],
                    fontWeight: 700,
                    [`@media (max-width: 1366px)`]: {
                        fontSize: theme.fontSizes.xxl,
                    },
                    [`@media (max-width: 1280px)`]: {
                        fontSize: theme.fontSizes.xl,
                    },
                    [`@media (max-width: 1024px)`]: {
                        fontSize: theme.fontSizes.xl,
                    },
                })}
            >{title} </Text>
        </>
    )
}

export const PageDiscription = ({ description }) => {
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.lg,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[2],
                    fontWeight: 400,
                    [`@media (max-width: 1280px)`]: {
                        fontSize: theme.fontSizes.sm,
                    },
                    [`@media (max-width: 1024px)`]: {
                        fontSize: theme.fontSizes.sm,
                    },
                })}
            >{description} </Text>

        </>
    )
}


export const BreadCurmbText = ({ title }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.lg,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.primary[0],
                fontWeight: 600
            })}
        >{title} </Text>

    </>
}


export const BreadCurmbText2 = ({ title }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.lg,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.primary[3],
                fontWeight: 600
            })}
        >{title} </Text>

    </>
}

export const WelcomeText = ({ title }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.lg,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.primary[1],
                fontWeight: 400,
                [`@media (max-width: 1280px)`]: {
                    fontSize: theme.fontSizes.md,
                },

            })}
        >{title} </Text>

    </>
}
export const PermissionHeading = ({ title }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.lg,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.textColor[0],
                fontWeight: 600
            })}
        >{title} </Text>

    </>
}
export const PermissionText = ({ title }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.md,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.textColor[0],
                fontWeight: 400
            })}
        >{title} </Text>

    </>
}


export const UserName = ({ firstName, lastName }) => {
    return <>
        <Text
            sx={(theme) => ({
                fontSize: theme.fontSizes.xl,
                fontFamily: theme.fontFamily[1],
                color: theme.colors.primary[0],
                fontWeight: 400,
                [`@media (max-width: 1280px)`]: {
                    fontSize: theme.fontSizes.md,
                },
            })}
        >{firstName}  {lastName}</Text>

    </>
}

export const UserBadge = ({ title }) => {
    return <>
        <Box >
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.md,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.primary[6],
                    backgroundColor: theme.colors.primary[7],
                    display: "inline-block",
                    fontWeight: 600,
                    padding: 8,
                    [`@media (max-width: 1280px)`]: {
                        fontSize: theme.fontSizes.sm,
                    },
                })}
            >{title}</Text>
        </Box>
    </>
}
// on my Role Page
export const TextDim = () => {
    const theme = useMantineTheme("");
    return (
        <>
            <Text
                sx={(theme) => ({
                    fontSize: theme.fontSizes.lxxl,
                    fontFamily: theme.fontFamily[1],
                    color: theme.colors.textColor[1],
                    backgroundColor: theme.colors.textColor[7],
                    fontWeight: 600
                })}
            >
            </Text>
        </>
    )
}