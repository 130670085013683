import React, { useEffect, useState } from "react";
import FlowTitle from '../../Component/flowTitle'
import { Affix, Button, Text, LoadingOverlay, Grid, Box, Group, Badge } from '@mantine/core'
import { BiChevronLeft } from 'react-icons/bi'
import { RiPagesLine } from 'react-icons/ri'

import TipModal from '../../Component/modal'
import { listBinderDetails } from '../../apis/livingWillApis'
import { getLivingWillFlowReviewAs } from '../../apis/flowReviewApis/livingWillReviewApis'
import FooterWill from "../../Component/footer2";
import '../../Custom.css';
import { useLocation } from "react-router-dom";
import { BsExclamationCircle } from "react-icons/bs";
import { modifiedJson } from "../../apis/willFlowApis";
import { IconCheck, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import InputControlReviewAs from "../../Component/Review/inputControlReviewAs";

const LivingWillReviewAs = ({ navigate, setLoggedIn }) => {
    const { state } = useLocation();
    const { topicId, testatorId, testatorImage , testatorName, keyContactId, live, mode, mouckUp, bgMock, bgColor } = state;
    // console.log(topicId, testatorId, testatorName, keyContactId, live, mode, mouckUp)
    const initialValues = {
        document_ids: "",
        notes: ""
    };
    const [answer, setAnswer] = useState(initialValues)
    const [active, setActive] = useState(1);  // for stepper

    const [counter, setCounter] = useState(0)
    const [modalMessage, setModalMessage] = useState({})
    const [modalStatus, setModalStatus] = useState(false)
    const [willFlow, setWillFlow] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingMock, setLoadingMock] = useState(false)
    const [prevStep, setPrevStep] = useState(-1)
    const [prefill, setPrefill] = useState()
    const [editMode, setEditMode] = useState({})
    const [countRandomStep, setCountRandomStep] = useState("")

    const [questionId, setQuestionId] = useState(false)

    const [formError, setFormError] = useState();
    const [handleSkips, setHandleSkips] = useState(false);
    const [binderId, setBinderId] = useState();

    const [mockupBar, setMockUpBar] = useState(mouckUp ? true : false)
    const [writePermission, setWritePermission] = useState("")
    let userData = JSON.parse(localStorage.getItem("project_whiteney_user_data"))

    const handleClick = (step, btnClick) => {
        console.log(step)
        console.log(btnClick)
   
        setHandleSkips(false)
        if (step.step === 1 && btnClick === "secondery") {
            let temp = {
                title: "Do Not Have living will ?",
                content: "Living will is a crucial component of your essential doc please refer to our help section to get more information about living will."
            }
            setModalMessage(temp)
            setModalStatus(!modalStatus)
        } else if (btnClick === "continue with api") {
            if (step.lastStep && step.step === 2) {
                submitNotes(step)
            }else if (step.lastStep && step.step === 4 ) {
                submitNotes(step)
            } else if(step.step === 2 || step.step === 4){
                submitNotes(step)
            }
            else if (step.lastStep && step.step === 3) {
                submitForm(step)            // call step -3 
            }else if(step.step === 3){
                submitForm(step)   
            }
            else {
                setPrevStep(counter)
                setCounter(counter + 1)
            }
        } else if (!step.lastStep) {
            setPrevStep(counter)
            setCounter(counter + 1)
        } else {
            navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
            setCounter(0)
        }
    }



    const submitNotes = (step) => {
        // console.log("Submit Notes called")
        let data = {}
        console.log(answer)
        // console.log(answer.answer)
        // check Answer type and set it
        if (!answer) {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                setCounter(0)
            }
            return
        }

        if (step.step == 2) {
            // console.log("step-2 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        if (step.step == 4) {
            console.log("step-4 Notes")
            data = {
                "SaveData": "Submit",
                "question_id": questionId,
                "answer": answer.answer
            }
            // console.log(data)
        }
        setLoading(true)
        getLivingWillFlowReviewAs(topicId, testatorId, keyContactId, live, mode, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            } else if (step.lastStep) {
                console.log("last step found line 283")
                navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                setLoading(false)
            } else {
                if (!step.lastStep) {
                    setPrevStep(counter)
                    setCounter(counter + 1)
                } else {
                    navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                    // setCounter(0)
                }
                setWillFlow(modifiedJson(response.data))
                setLoading(false)
                if(writePermission === "yes"){
                    showNotification({
                        id: 'load-data',
                        color: 'teal',
                        title: response.data.status,
                        message: response.data.message,
                        icon: <IconCheck size={16} />,
                        autoClose: 2000,
                    })
                }
          
            }
        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    // submitForm (upload files)
    const submitForm = (step) => {
        let data = {}
        if (!answer || typeof (answer) != 'object') {
            if (!step.lastStep) {
                setPrevStep(counter)
                setCounter(counter + 1)
            } else {
                navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                // setCounter(0)
            }
            return
        }

        if (step.step === 3) {                   //upload will step -3 
            // console.log(questionId)
            // console.log(answer)
            data = {
                "SaveDocument": "Submit",    // 
                "question_id": questionId,
                ...answer
            }
        }
        console.log(data)
        setLoading(true)
        getLivingWillFlowReviewAs(topicId, testatorId, keyContactId, live, mode, data).then((response) => {
            console.log("Api call success: ", response.data)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }  else if (step.lastStep) {
                console.log("last step found line 283")
                navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                setLoading(false)
            }else {

                if (response.data.status === 'error') {
                    // console.log("error")
                    // console.log(response.data.message)
                    // setFormError(response.data.message);
                    setPrevStep(counter)
                    setCounter(counter + 1)
                    setLoading(false);

                } else if (response.data.status === 'success') {
                    // console.log("sucess")
                    if (!step.lastStep) {
                        setPrevStep(counter)
                        setCounter(counter + 1)
                    } else {
                        navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                        // setCounter(0)
                    }
                    setWillFlow(modifiedJson(response.data))
                    setLoading(false)
                        setEditMode(false)
                    setFormError("");
                    if(writePermission === "yes"){
                        showNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: response.data.status,
                            message: response.data.message,
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        })
                    }
                }

            }
        }, (error) => {
            // console.log("Error in API call: ", error)
            setPrevStep(counter)
            setCounter(counter + 1)
            setLoading(false)
        })
    }

    const backBtnClick = (step) => {
        setHandleSkips(true)
        setFormError("");
        // console.log(willFlow.steps[0])
        if (counter != 0) {

            if (countRandomStep.includes(1) && step.step === 2) {
                if ((willFlow.steps[countRandomStep.indexOf(1)].visible) === false) {
                    console.log("con true")
                    navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
                    // setLoading(false)
                } else {
                    setCounter(prevStep)
                    setPrevStep(prevStep - 1)
                }
            }else {
                setCounter(prevStep)
                setPrevStep(prevStep - 1)
            }
        } else {
            navigate('/userTopicReviewAs', { state: { contactDetail: { testatorId: testatorId, testatorImage: testatorImage, testatorName: testatorName, isAlive: live, keyContactId: keyContactId, orangeBar: true } } });
        }
    }



    useEffect(() => {
        if (!loading) {
            // console.log(willFlow.steps[counter])
            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj) 
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [editMode])

    useEffect(() => {
        setLoading(true)
        listBinderDetails("200000004").then((response) => {
            // console.log("Binder List for topic Id 200000004: ", response.data)
        }, (err) => {
            // console.log("Error in getting binder List")
        })
        getLivingWillFlowReviewAs(topicId, testatorId, keyContactId, live, mode).then((response) => {
            // console.log("Response of get willflow object: ", response.data)
            // console.log(response.data.main_binder_id)
            if (response.data.message === "Invalid user") {
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            }else if(response.data.message === "Session timeout."){
                showNotification({
                    id: 'load-data',
                    color: 'red',
                    title: response.data.status,
                    message: response.data.message,
                    icon: <IconX size={16} />,
                    autoClose: 2000,
                })
                localStorage.removeItem("project_whiteney_email")
                localStorage.removeItem("project_whiteney_user_data")
                setLoading(false)
                setLoggedIn(false)
            
            }  else {
                let responseData = response.data
                responseData.steps = response.data.steps.filter((val) => {
                    return val.permissionStatus === true
                })
                setCountRandomStep(countRandomSteps(response.data))
                setWillFlow(modifiedJson(response.data))
                setBinderId(response.data.main_binder_id)
                setWritePermission(response.data.write_permission)
            }
        }, (error) => {
            // console.log("Error in getting willflow object: ", error)
        })
    }, [])

    function countRandomSteps(willData) {
        console.log(willData)
        let willRandomStepsArr = []
        willData.steps.map((item, index) => {
            // console.log(item)
            willRandomStepsArr.push(item.step)
        })

        // console.log(willRandomStepsArr)
        return willRandomStepsArr
    }

    useEffect(() => {
        if (Object.keys(willFlow).length > 0) {
            setLoading(false)
        }
    }, [willFlow])



    useEffect(() => {
        console.log(handleSkips)
        console.log(loading)
        if (!loading && handleSkips === false) {   // backbutton case
            if (willFlow.steps[counter].visible === false && willFlow.steps[counter].step === 1) {
                // console.log("step 4 is false")
                setCounter(counter + 1)
            }


            let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
        if (!loading && handleSkips === true) {   // backbutton case
           let temp = willFlow.steps[counter]
            let prefillObj = {}
            let ansObj = {}
            temp.inputList.map((item, index) => {
                if (item?.prefill != undefined) {
                    prefillObj = { ...prefillObj, [item.label]: item.prefill }
                    ansObj = { ...ansObj, [item?.id]: item.prefill }
                }
            })
            // console.log("answer Object: ", ansObj, prefillObj)
            setQuestionId(willFlow.steps[counter].question_id)
            setPrefill(prefillObj)
            setAnswer(ansObj)
            setPrevStep(counter - 1)
        }
    }, [counter, loading])

    if (loadingMock) {
        return (
            <LoadingOverlay visible={loadingMock} />
        )
    }

    if (loading) {
        return (
            <LoadingOverlay visible={loading} />
        )
    }

    return (
        <div>

            <div className="mainContainer" style={{ 'height': window.innerHeight - 148, background: bgColor }}>
                <Grid columns={12} style={{ padding: 0 }}>
                    {/*Orange Top Bar */}
                    {mockupBar && <>
                        <Grid.Col span={12} pl="zx" pr="zx">
                            <Box className={bgMock ? "ViewMock" : "viewAs"}>
                                {bgMock === true &&
                                    <Grid columns={12}>
                                        <Grid.Col span={9}>
                                            <Group position="left">
                                                <BsExclamationCircle /><Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[1] })}>You are viewing this  as {testatorName}  (post demise)</Text>
                                            </Group>
                                        </Grid.Col>
                                        <Grid.Col span={3}>
                                            <Group position="right">
                                            <Box  onClick={() => { navigate('/accessControl');}} sx={(theme) => ({  cursor: "pointer",backgroundColor: theme.colors.textColor[9], color: theme.colors.textColor[0], padding:5, borderRadius:20 })}>View as {userData.first_name} {userData.last_name}</Box>
                                            </Group>
                                        </Grid.Col>
                                    </Grid>}
                                {bgMock === false && <Group style={{ padding: 5 }}>
                                    <BsExclamationCircle size={22} /><Text sx={(theme) => ({ color: theme.colors.textColor[0], fontWeight: 700, fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[1] })}>You are viewing this page as a {testatorName}</Text>
                                </Group>}
                            </Box>
                        </Grid.Col>
                    </>
                    }

                </Grid>
                <TipModal modalMessage={modalMessage} status={modalStatus} setStatus={setModalStatus} />

              
                {!loading &&
                    <Grid columns={24} >
                        <Grid.Col span={24} pl="zx" pr="zx" pt={22} pb={10}>
                            <Box sx={(theme) => ({ display: "flex", alignItems: "center", color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontFamily: theme.fontFamily[1] })}>
                                <RiPagesLine />
                                <Text sx={(theme) => ({ color: theme.colors.textColor[0], fontSize: theme.fontSizes.xxl, fontWeight: 600, fontFamily: theme.fontFamily[0], marginLeft: theme.spacing.xxs })}>  Living Will</Text>
                            </Box>
                        </Grid.Col>
                    </Grid>
                }
                <Grid columns={24}>
                    <Grid.Col span={24} pl="zx" pr="zx" pt={5}>
                        {!loading && <div>
                            <FlowTitle title={willFlow?.steps[counter]?.title} description={willFlow?.steps[counter]?.description} label={willFlow?.steps[counter]?.label} />
                        </div>}
                    </Grid.Col>

                </Grid>



                {formError ? <Text style={{ color: 'red' }}>{formError}</Text> : ""}
                {!loading && <Grid columns={24}>
                    { willFlow?.steps[counter]?.inputList.map((item, index) => {
                        return (

                            <InputControlReviewAs
                                data={item}
                                key={index}
                                handleButtonClick={handleClick}
                                step={willFlow?.steps[counter]}
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                modalStatus={modalStatus}
                                setModalStatus={setModalStatus}
                                setAnswer={setAnswer}
                                answer={answer}
                                prefill={prefill}
                                editMode={editMode}    // for benif and executor files
                                setEditMode ={setEditMode}
                                questionId={willFlow?.steps[counter].question_id}
                                binderId={binderId}
                                testatorId={testatorId}
                                writePermission={writePermission}
                                setLoadingMock={setLoadingMock}
                                loadingMock={loadingMock}
                                mode={mode}
                                live={live}
                                keyContactIdPermitted={keyContactId}

                                setPrefill={setPrefill}

                                flowId="200000004"
                                willFlow={{ get: () => { return willFlow }, set: setWillFlow }}


                                setLoggedIn={setLoggedIn}
                                navigate={setLoggedIn}
                            />

                        )
                    })
                    }
                    <div style={{ "width": "100%", "paddingBottom": "120px" }}></div>
                </Grid>}

                {/* </div>} */}
                {!modalStatus && !loading &&
                    <>
                        <Affix
                            sx={(theme) => ({
                                "bottom": 0, 'left': 300, "width": '100%', "padding": '1vw', "backgroundColor": '#f6fbfd', 'height': '100px', zIndex: 1,
                                [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                                    'left': 300
                                },
                                [`@media (max-width:1280px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                    'left': 270
                                },
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    'height': '110px',
                                    left: "0px"
                                },
                            })}
                        >
                            <Affix position={{}} sx={(theme) => ({
                                bottom: 70, left: 330, zIndex: 2,
                                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                                    left: "45px"
                                },
                            })}>
                                <Button
                                    radius="xxs"
                                    sx={(theme) => ({
                                        color: theme.colors.textColor[0],
                                        fontSize: theme.fontSizes.xxl,
                                        fontFamily: theme.fontFamily[0],
                                        fontWeight: 300,
                                        height: 50,
                                        backgroundColor: "transparent",

                                        '&:hover': {
                                            backgroundColor: theme.colors.buttonColor[9],
                                            borderWidth: 1,
                                            BorderStyle: "solid",
                                            borderColor: theme.colors.primary[0],
                                        }
                                    })}
                                    leftIcon={<BiChevronLeft />}
                                    onClick={() => {
                                        backBtnClick(willFlow?.steps[counter])
                                    }}
                                >
                                    Back
                                </Button>
                            </Affix>
                            {willFlow?.steps[counter]?.continueBtn?.visible &&
                                <Affix position={{ bottom: 70, right: 45, zIndex: 2 }}>
                                    <Button
                                        radius="xxs"
                                        sx={(theme) => ({
                                            backgroundColor: theme.colors.primary[0],
                                            height: 50,
                                            fontSize: theme.fontSizes.xxl,
                                            fontWeight: 300,
                                            fontFamily: theme.fontFamily[0],
                                            '&:hover': {
                                                backgroundColor: theme.fn.darken(theme.colors.primary[0], 0.2),
                                            }
                                        })}
                                        onClick={() => {
                                            if (willFlow?.steps[counter].step === 2 || willFlow?.steps[counter].step === 3 || willFlow?.steps[counter].step === 4) {
                                                handleClick(willFlow?.steps[counter], "continue with api")
                                            } else {
                                                handleClick(willFlow?.steps[counter], "continue without api")
                                            }
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Affix>
                            }
                            <FooterWill navigate={navigate}/>
                        </Affix>

                    </>
                }
            </div>
        </div>
    )
}

export default LivingWillReviewAs