import React from 'react';
import { Logout } from 'tabler-icons-react';
import { ThemeIcon, UnstyledButton, Group, Text,useMantineTheme } from '@mantine/core';
import "../Custom.css"

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
}

function MainLinkSub({ icon, color, label, navigate , setLoggedIn}: MainLinkProps) {
  const theme = useMantineTheme("");
   return (
    <UnstyledButton
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group  onClick = {() => {
                                localStorage.removeItem("project_whiteney_email")
                                localStorage.removeItem("project_whiteney_user_data")
                                setLoggedIn(false)
                                navigate('/')
                            }}>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text sx={(theme)=>({color: theme.colors.textColor[2],  fontSize: theme.fontSizes.md, fontFamily: theme.fontFamily[0], fontWeight:600, [`@media (max-width: 1280px)`]: {fontSize:theme.fontSizes.sm}})}  >{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const data = [
  { icon: <Logout  
  color='#62697b'   
  
  />,  label: 'Logout' },
 
];

export function MainLinkSubIcon({navigate, setLoggedIn}) {
  const links = data.map((link) => <MainLinkSub {...link} key={link.label}  navigate = {navigate} setLoggedIn = {setLoggedIn}/>);
  return <div>{links}</div>;
}