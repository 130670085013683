import React from "react";
import '../Custom.css';
import { PageDiscription, PageTitle } from "./textLabel";
import { Box, useMantineTheme } from "@mantine/core";

const FlowTitle = ({ title, description, label }) => {
    const theme = useMantineTheme("");
    return (<>
        <Box sx={(theme) => ({
            display: "flex", paddingBottom: 8,
            '@media (max-width: 1600px)': {
                paddingBottom: 4,
            },
            '@media (max-width: 1366px)': {
                paddingBottom: 4,
            },
            '@media (max-width: 1280px)': {
                paddingBottom: 4,
            },
        })} >
            <Box>
                <PageTitle title={title} /> 
            </Box>

            {label &&
                <Box
                    className="flagButton"
                    sx={(theme) => ({ marginLeft: theme.spacing.md, fontWeight: 600, fontSize: theme.fontSizes.lg, '@media (max-width: 1024px)': {
                        fontSize: theme.fontSizes.md, 
                    }, })}
                >
                    <span
                    // sx={(theme) => ({  })}
                    >{label}</span>
                </Box>
            }

        </Box>

        <Box sx={(theme) => ({
            marginTop: theme.spacing.xs,
            '@media (max-width: 1366px)': {
                marginTop: theme.spacing.xxs,
            },
            '@media (max-width: 1280px)': {
                marginTop: theme.spacing.xxs,
            },
        })} >
            <PageDiscription description={description} />
        </Box>

    </>
    )
}

export default FlowTitle